import './manual.scss';
import { VxAPI } from '../../api/vx.api';
import Header from '../../components/header/Header';

const Manual = () => {

  return (
    <>
    <Header/>
      <div className="manual-download-page page-border">
        <div className="build-download">
          <h1>Latest Help Guides!</h1>

          <a href="https://qint-my.sharepoint.com/:w:/g/personal/dbryant_quantuminterface_com/EfKQLCH6KARLnAkb8jJaSnQBN_xIxDoBjHD5HLYxTEI8yw?e=0qR1BQ" target="_blank">
            QIVX Quick Start Guide
          </a>
          <a href="https://qint-my.sharepoint.com/:w:/g/personal/dbryant_quantuminterface_com/EdoEjWk2D_dNjpq-xiOz6fIBa4S3LbTfMiOZeceJq2XGFQ" target="_blank">
            QIVX Manual (In depth version of Quick Start Guide)
          </a>
          <button id="login-page-btn" type="button" onClick={() => window.location.assign('/login')}>
              <span>Log In</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Manual;
