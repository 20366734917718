import { useEffect, useState } from "react";
import AddTagForm from "../../components/addTagForm/AddTagForm";
import { useUserData, useUserRoleData } from "../account/Account";

const AddTags = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;
    const userCustomerId = userData?.customerId

    useEffect(() => {
        if (userRoleName && userRoleName !== '') {
          if (userRoleName !== 'Admin') {
            window.location.assign('/');
          }
        }
      }, [userRoleName]);


    return (
        <>
            <AddTagForm 
            userCustomerId = {userCustomerId}
            />
        </>
    )
}

export default AddTags;