import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../components/toggleSwitch/ToggleSwitch';
import './saveAsLesson.scss';
import { filterAlphabet } from '../../utils/helpers';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import { VxAPI } from '../../api/vx.api';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { useNavigate, useParams } from 'react-router-dom';
import { TagObjectDTO } from '../../components/dto/tagObject.dto';
import { DropdownSelectStateDTO } from '../../components/dto/dropdown.selectState.dto';
import { useUserRoleData, useUserData } from '../account/Account';
import Auth from '../../utils/auth';
import { CircularProgress } from '@material-ui/core';
import RequiredAsterisk from '../../components/requiredAsterisk/RequiredAsterisk';

const SaveAsLesson = () => {
  const params = useParams();
  const navigate = useNavigate();
  const paramsLessonId = Number(params.lessonId);
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;
  const userCustomerId = userData?.customerId;
  const [roleName, setRoleName] = useState<string>('');
  const [toggled, setToggled] = useState(false);
  const tagsFilter = filterAlphabet;
  const [parentLesson, setParentLesson] = useState<LessonObjectDTO>();
  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>();
  const [moduleObjectArrayData, setModuleObjectArrayData] = useState<ModuleObjectDTO[]>();
  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>();
  const [loading, setLoading] = useState(true);
  const [selectState, setSelectState] = useState<DropdownSelectStateDTO>({
    customerId: undefined,
    courseId: undefined,
    moduleId: undefined,
  });
  const [lessonTextState, setLessonTextState] = useState({
    title: null,
    description: null,
    publishState: 0,
    author: userData?.userId,
    parent: paramsLessonId,
  });
  const [readonly, setReadOnly] = useState<boolean>(false);
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tagFilter, setTagFilter] = useState<string>('All');
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [screensizeWidth, setScreensizeWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const fetchData = async () => {
      const lessonData = await VxAPI.getSingleLessonById(paramsLessonId);
      if (lessonData) {
        setParentLesson(lessonData);
      }
      const coursesData = await VxAPI.getAllCoursesData();
      setCourseObjectArrayData(coursesData);
      const modulesData = await VxAPI.getAllModulesData();
      setModuleObjectArrayData(modulesData);
      const tagsData = await VxAPI.getAllTagsData();
      setTagObjectArrayData(tagsData);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);
  const handleToggle = async () => {
    setToggled(!toggled);
    setReadOnly(!readonly);
  };
  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setErrorMessage('');
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value), customerId: userCustomerId });
    return;
  };
  const handleFilterClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const targetEl = e.target as HTMLParagraphElement;
    const text = targetEl.textContent as string;
    setTagFilter(text);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrorMessage('');
    setLessonTextState({ ...lessonTextState, [e.target.name]: e.target.value });
  };
  const handleTagSelect = (e: React.MouseEvent<HTMLLIElement>) => {
    const targetEl = e.target as HTMLLIElement;
    const tagId = Number(targetEl.id.split('-').at(-1));
    let tagIdArray: number[] = [];
    // check if we have parent tags we need to add
    const foundTag = tagObjectArrayData?.find((tag) => tag.tagId === tagId);
    const tagTypeId = tagObjectArrayData?.find((tag) => tag.title === foundTag?.type)?.tagId;
    const tagSub1Id = tagObjectArrayData?.find((tag) => tag.title === foundTag?.sub1)?.tagId;
    const tagSub2Id = tagObjectArrayData?.find((tag) => tag.title === foundTag?.sub2)?.tagId;
    if (tagTypeId && !selectedTags.includes(tagTypeId)) {
      tagIdArray.push(tagTypeId);
    }
    if (tagSub1Id && !selectedTags.includes(tagSub1Id)) {
      tagIdArray.push(tagSub1Id);
    }
    if (tagSub2Id && !selectedTags.includes(tagSub2Id)) {
      tagIdArray.push(tagSub2Id);
    }
    setSelectedTags((selectedTags) => selectedTags.concat(tagIdArray));
  };
  const handleTagRemoval = (e: React.MouseEvent<HTMLLIElement>) => {
    const targetEl = e.target as HTMLLIElement;
    const tagId = Number(targetEl.id.split('-').at(-1));
    let tagIdArray: number[] = [];
    // check if we have child tags we need to remove
    const foundTag = tagObjectArrayData?.find((tag) => tag.tagId === tagId);
    if (foundTag?.title === foundTag?.type) {
      selectedTags.forEach((tagId) => {
        const foundChildTag = tagObjectArrayData?.find((tag) => tag.tagId === tagId && tag.type === foundTag?.type);
        if (foundChildTag && selectedTags.includes(foundChildTag.tagId as number)) {
          tagIdArray.push(foundChildTag.tagId as number);
        }
      });
    }
    if (foundTag?.title === foundTag?.sub1) {
      selectedTags.forEach((tagId) => {
        const foundChildTag = tagObjectArrayData?.find((tag) => tag.tagId === tagId && tag.sub1 === foundTag?.sub1);
        if (foundChildTag && selectedTags.includes(foundChildTag.tagId as number)) {
          tagIdArray.push(foundChildTag.tagId as number);
        }
      });
    }
    if (foundTag?.title === foundTag?.sub2) {
      tagIdArray.push(foundTag?.tagId as number);
    }
    setSelectedTags((selectedTags) => {
      return selectedTags.filter((tag: number) => !tagIdArray.includes(tag));
    });
  };
  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName === 'Admin' || userRoleName === 'Producer') {
        return;
      } else {
        window.location.assign('/');
      }
    }
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const handleSaveAs = async () => {
    if (
      selectState.courseId === undefined ||
      selectState.courseId === 0 ||
      selectState.moduleId === 0 ||
      selectState.moduleId === undefined
    ) {
      setErrorMessage(
        'Be sure to select a course & module the lesson belongs to. If no courses/modules available to select, please create one first.',
      );
      return;
    }

    if (lessonTextState.title === '' || !lessonTextState.title) {
      setErrorMessage('Make sure all required fields are completed.');
      return;
    }
    try {
      setProgressMessage('Saving new version...');
      const progressDivEl = document.getElementById('progress-div');
      if (progressDivEl) {
        progressDivEl.style.display = 'flex';
      }
      // set created date
      let currentTime = Date.now(); // get date as integer
      let currentDate = new Date(currentTime); // conver integer into date with timezone
      let createdDate = currentDate;

      const resp = await VxAPI.addLesson({
        title: lessonTextState.title,
        description: lessonTextState.description,
        publishState: lessonTextState.publishState,
        author: lessonTextState.author,
        parent: lessonTextState.parent,
        createdDate: createdDate,
        readOnly: readonly,
        tags: selectedTags,
        moduleId: selectState.moduleId,
      });

      if (resp.lessonId) {
        setProgressMessage('Copying parent assets...');
        const parentFileName = parentLesson?.assets?.Lesson[0].filename;
        const parentFileNameSplitArray = parentFileName?.split('.');
        const fileExtension = parentFileNameSplitArray
          ? parentFileNameSplitArray[parentFileNameSplitArray.length - 1]
          : '';
        const childFileNameSplitArray = resp.title.split(' ');
        const updatedChildFileName = childFileNameSplitArray.join('_');

        const childFileName = `${resp.lessonId}-Lesson_${updatedChildFileName}.${fileExtension}`;

        const copyResp = await VxAPI.copyParentLessonAssets(
          {
            fileName: childFileName as string,
            parentFileName: parentFileName as string,
          },
          selectState.customerId as number,
        );
        if (copyResp.error) {
          let errorMessage =
            'An error has occured when attempting to copy the asset files. Please try again. If error persists contact your administrator.';
          const progressDivEl = document.getElementById('progress-div');
          const buttonEl = document.createElement('button');
          buttonEl.className = 'progress-button';
          buttonEl.textContent = 'Close';
          buttonEl.onclick = async () => {
            const inActiveLessonResp = await VxAPI.DeleteLesson(
              resp.lessonId,
            );
            if (inActiveLessonResp?.success) {
              window.location.assign('/lessons');
            }
          };
          setProgressMessage(errorMessage);
          progressDivEl?.appendChild(buttonEl);
        }
        if (copyResp.url && copyResp.fileName) {
          setProgressMessage('Saving asset name/location to lesson...');
          const addLessonAssetsResp = await VxAPI.editLesson(
            {
              assets: {
                Lesson: [
                  {
                    filename: copyResp.fileName,
                    networkPath: copyResp.url,
                  },
                ],
              },
            },
            resp.lessonId,
          );
          if (addLessonAssetsResp.lessonId) {
            window.location.assign('/lessons');
          } else {
            setProgressMessage('An error has occured. Please try again. If error persists contact your administrator.');
          }
        }
      }
    } catch (error: unknown) {
      let errorMessage =
        'An error has occured when attempting to copy the asset files. Please try again. If error persists contact your administrator.';
      if (error instanceof Error) {
        console.error('An error occurred:', error.message);
        errorMessage = error.message;
      }
      const progressDivEl = document.getElementById('progress-div');
      const buttonEl = document.createElement('button');
      buttonEl.className = 'progress-button';
      buttonEl.textContent = 'Close';
      buttonEl.onclick = () => window.location.assign('/lessons');
      setProgressMessage(errorMessage);
      progressDivEl?.appendChild(buttonEl);
    }
  };

  window.addEventListener('resize', () => {
    setScreensizeWidth(window.innerWidth);
  });

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div className="saveAs-lesson-page">
          <h1>
            Save New Lesson Version: <br></br>
            {parentLesson?.title}
          </h1>
          <div className="lesson-metadata">
            <div className="lesson-hierarchy">
              <div className="lesson-title-div">
                <div className="title-toggle">
                  <h2>
                    Title <RequiredAsterisk color="red" screensizeWidth={window.innerWidth} />
                  </h2>
                  <div className="readonly-toggle">
                    <label htmlFor="readonly">Read Only</label>
                    <ToggleSwitch toggle={toggled} handleToggle={handleToggle} />
                  </div>
                </div>
                <input type="text" name="title" placeholder="Enter text..." onChange={handleChange} />
              </div>
              <div className="dropdown">
                <label htmlFor="courseId">
                  Course <RequiredAsterisk color="red" screensizeWidth={screensizeWidth} />
                </label>
                <select name="courseId" id="course-dropdown" onChange={handleSelection}>
                  <option value="">---</option>
                  {courseObjectArrayData?.map((course: CourseObjectDTO, index: number) => (
                    <option value={course.courseId}>{course.title}</option>
                  ))}
                </select>
              </div>
              <div className="dropdown">
                <label htmlFor="moduleId">
                  Module <RequiredAsterisk color="red" screensizeWidth={window.innerWidth} />
                </label>
                <select name="moduleId" id="module-dropdown" onChange={handleSelection}>
                  <option value="">---</option>
                  {moduleObjectArrayData
                    ?.filter((module) => module.courseId === selectState.courseId)
                    .map((module: ModuleObjectDTO) => (
                      <option value={module.moduleId}>{module.title}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="lesson-overview">
              <label htmlFor="description">Overview</label>
              <textarea name="description" placeholder="Enter Text..." onChange={handleChange}></textarea>
            </div>
          </div>
          <div className="lesson-tags">
            <div className="lesson-tags-header">
              <h2>Tags</h2>
              <div className="alphabet">
                {tagsFilter.map((letter: string, index: number) => (
                  <p
                    key={`lesson-tag-${letter}-${index}`}
                    style={{
                      backgroundColor: tagFilter === letter ? 'white' : '',
                      borderRadius: '0.25rem',
                      fontWeight: tagFilter === letter ? 'bold' : 'unset',
                      padding: tagFilter === letter ? '0.25rem' : '0rem',
                    }}
                    onClick={handleFilterClick}
                  >
                    {letter}
                  </p>
                ))}
              </div>
            </div>
            <div className="tag-line"></div>
            <div className="tags-added">
              <h3>TAGS ADDED: </h3>
              <ul className="tags-added-list">
                {selectedTags?.map((tagId) => (
                  <li id={`tag-selected-${tagId}`} onClick={handleTagRemoval}>
                    {tagObjectArrayData?.find((tag) => tag.tagId === tagId)?.title}{' '}
                    <span id={`span-selected-${tagId}`} onClick={handleTagRemoval}>
                      X
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tag-line"></div>
            <div className="tags-list-div">
              <ul className="tags-list">
                {tagObjectArrayData
                  ?.sort((a: any, b: any) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > a.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .filter((tag) => {
                    if (selectedTags.includes(tag.tagId as number)) return;
                    const firstLetter = tag.title?.split('')[0];
                    if (tagFilter === '#') {
                      if (!Number.isNaN(Number(firstLetter))) {
                        return tag;
                      }
                    } else if (tagFilter === 'All') {
                      return tag;
                    } else {
                      if (firstLetter?.toLowerCase() === tagFilter.toLowerCase()) {
                        return tag;
                      }
                    }
                  })
                  .map((tag: TagObjectDTO) => {
                    return (
                      <li id={`tag-${tag.title}-${tag.tagId}`} onClick={handleTagSelect}>
                        {tag.title}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="saveAs-lesson-btns">
            <button type="button" onClick={() => navigate(-1)}>
              <span>Cancel</span>
            </button>
            <button type="submit">
              <span onClick={handleSaveAs}>Save</span>
            </button>
          </div>
          <div className="progress" id="progress-div" style={{ display: 'none' }}>
            {progressMessage ? <h3 className="message">{progressMessage}</h3> : null}
            <CircularProgress />
          </div>
          {errorMessage ? <h3 className="message">{errorMessage}</h3> : null}
        </div>
      )}
    </>
  );
};

export default SaveAsLesson;
