import { useEffect, useState, MouseEvent } from 'react';

import './customers.scss';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { useUserData } from '../account/Account';
import Auth from '../../utils/auth';

const Customers = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  if (superUser === false) {
    window.location.assign('/');
  }

  const [loading, setLoading] = useState(true);
  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);
  const [updatedCustomersArray, setUpdatedCustomersArray] = useState<CustomerObjectDTO[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await VxAPI.getAllCustomersData();
      if (customerData) {
        setCustomersObjectArrayData(customerData);
        setUpdatedCustomersArray(customerData);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const customerId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/customers/edit/${customerId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (searchText === '') {
      setUpdatedCustomersArray(customersObjectArrayData);
    }

    const updatedCustomers = customersObjectArrayData?.filter(
      (customer) =>
        customer.firstName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        customer.lastName?.toString().toLowerCase()?.includes(searchText.toLowerCase()) ||
        customer.location?.toString().toLowerCase()?.includes(searchText.toLowerCase()) ||
        customer.email?.toString().toLowerCase()?.includes(searchText.toLowerCase()) ||
        customer.phone?.toString().toLowerCase()?.includes(searchText.toLowerCase()),
    );

    setUpdatedCustomersArray(updatedCustomers);
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="customers-div">
          <div className="row justify-space-btw">
            <h1>Customers</h1>
            <div id="customer-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button
                id="add-customer-btn"
                type="button"
                onClick={() => window.location.assign('/customers/add')}
              >
                <span>+Add Customer</span>
              </button>
            </div>
            <div id="customers-titles">
              <h2 id="customer-name-header">Name</h2>
              <div id="secondary-customer-headers" className="row">
                <h2 id="customer-phone-header">Phone</h2>
                <h2 id="customer-location-header">Location</h2>
                <h2 id="customer-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedCustomersArray
            ? updatedCustomersArray.slice(0, itemsToShow).map((customer: CustomerObjectDTO, index: number) => (
                <>
                  <div id="customer-info-div" key={index}>
                    <div id="customer-name">
                      <p>
                        {customer.firstName} {customer.lastName}
                      </p>
                      <p id="customer-email"> {customer.email}</p>
                    </div>
                    <div id="secondary-customer-data">
                      <p id="customer-phone">{customer.phone}</p>
                      <p id="customer-location">{customer.location}</p>
                      <div id="customer-more">
                        <button
                          type="button"
                          id={`edit-cust-btn-${customer.customerId}`}
                          className="edit-btn"
                          onClick={handleEditBtnClick}
                        >
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="customer-line"></div>
                </>
              ))
            : null}
          <>
            {!expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedCustomersArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Customers;
