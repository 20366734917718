import { useEffect, useState, MouseEvent } from 'react';

import './useCases.scss';
import { VxAPI } from '../../api/vx.api';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData } from '../account/Account';
import Auth from '../../utils/auth';

const UseCases = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;
  const [updatedUseCasesArray, setUpdatedUseCasesArray] = useState<UseCasesObjectDTO[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  if (superUser === false) {
    window.location.assign('/');
  }

  const [loading, setLoading] = useState(true);
  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const useCaseData = await VxAPI.getAllUseCasesData();
      if (useCaseData) {
        setUseCasesObjectArrayData(useCaseData);
        setUpdatedUseCasesArray(useCaseData);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const useCaseId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/useCases/edit/${useCaseId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (searchText === '') {
      setUpdatedUseCasesArray(useCasesObjectArrayData);
    }

    const updatedUseCases = useCasesObjectArrayData?.filter((useCase) =>
      useCase.title?.toLowerCase()?.includes(searchText.toLowerCase()),
    );

    setUpdatedUseCasesArray(updatedUseCases);
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="useCases-div">
          <div className="row justify-space-btw">
            <h1>Use Cases</h1>
            <div id="useCase-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button
                id="add-useCase-btn"
                type="button"
                onClick={() => window.location.assign('/useCases/add')}
              >
                <span>+Add UseCase</span>
              </button>
            </div>
            <div id="useCases-titles">
              <h2 id="useCase-name-header">Name</h2>
              <div id="secondary-useCase-headers" className="row">
                <h2 id="useCase-desc-header">Description</h2>
                <h2 id="useCase-notes-header">Notes</h2>
                <h2 id="useCase-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedUseCasesArray
            ? updatedUseCasesArray.slice(0, itemsToShow).map((useCase: UseCasesObjectDTO, index: number) => (
                <>
                  <div id="useCase-info-div" key={index}>
                    <div id="useCase-name">
                      <p>{useCase.title}</p>
                    </div>
                    <div id="secondary-useCase-data">
                      <p id="useCase-desc">{useCase.description}</p>
                      <p id="useCase-notes">{useCase.notes ? useCase.notes : '-'}</p>
                      <div id="useCase-more">
                        <button
                          type="button"
                          id={`edit-useCase-btn-${useCase.useCaseId}`}
                          className="edit-btn"
                          onClick={handleEditBtnClick}
                        >
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="useCase-line"></div>
                </>
              ))
            : null}
          <>
            {!expanded && updatedUseCasesArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedUseCasesArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default UseCases;
