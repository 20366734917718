import { useEffect, useState } from 'react';
import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';

import { UserRoleObjectDTO } from '../../components/dto/userRoleObject.dto';
import { UserRoleDataDTO } from '../../components/dto/userRoleData.dto';
import { UserDataDTO } from '../../components/dto/userData.dto';
import Nav from '../../components/nav/Nav';
import './account.scss';
import { Outlet, useOutletContext } from 'react-router';
import Header from '../../components/header/Header';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { HierarchicalLessonObjectDTO } from '../../components/dto/hierarchicalLessonObject.dto';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UserCustomerDataDTO } from '../../components/dto/userCustomerData.dto';
import Home from '../home/Home';

// declaring context type to pass props to nested children of account (Outlet)
type ContextTypeUserData = {
  userData: UserDataDTO | null;
};

type ContextTypeUserRoleData = {
  userRoleData: UserRoleDataDTO | null;
};

type ContextTypeInstructorHierarchyClassData = {
  instructorHierarchyClassData: ClassObjectDTO[] | null;
};

type ContextTypeInstructorLessonsArrayData = {
  instructorLessonsArrayData: HierarchicalLessonObjectDTO[] | null;
};

type ContextTypeCustomerHierarchyClassData = {
  customerHierarchyClassData: ClassObjectDTO[] | null;
};

type ContextTypeCustomerUsersList = {
  customerUsersList: UserDataDTO[] | null;
};

const Account = () => {
  const loggedIn = Auth.loggedIn();
  const loggedInUser = Auth.getProfile('id_token');

  const [userData, setUserData] = useState<UserDataDTO>();
  const [userRoleArray, setUserRoleArray] = useState<UserRoleObjectDTO[]>([
    {
      id: 0,
      title: '',
      description: '',
    },
  ]);
  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>();
  const [userRoleData, setUserRoleData] = useState<UserRoleDataDTO>({
    title: '',
  });
  const [userCustomerData, setUserCustomerData] = useState<UserCustomerDataDTO>({
    title: '',
  });
  const [instructorHierarchyClassData, setInstructorHierarchyClassData] = useState<ClassObjectDTO[]>();
  const [customerHierarchyClassData, setCustomerHierarchyClassData] = useState<ClassObjectDTO[]>();
  const [instructorLessonsArrayData, setInstructorLessonsArrayData] = useState<HierarchicalLessonObjectDTO[]>();
  const [customerUsersList, setCustomerUsersList] = useState<UserDataDTO[]>();

  useEffect(() => {
    if (loggedInUser && loggedInUser.userType) {
      const confirmUser = async () => {
        const resp = await VxAPI.confirmUserExists(loggedInUser.userId, loggedInUser.email, loggedInUser.customerId);
        if (!resp) Auth.logout();
      };
      confirmUser().catch(console.error);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedIn) {
      const fetchData = async () => {
        const userData = await VxAPI.getUserData();

        if (userData) {
          const {
            userId,
            customerId,
            isSuperuser,
            firstName,
            lastName,
            userRole,
            email,
            address,
            address2,
            city,
            state,
            zip,
            phone,
          } = userData;

          setUserData({
            userId: userId,
            customerId: customerId,
            isSuperuser: isSuperuser,
            firstName: firstName,
            lastName: lastName,
            userRole: userRole,
            email: email,
            address: address,
            address2: address2,
            city: city,
            state: state,
            zip: zip,
            phone: phone,
          });
        }

        const customerUserData = await VxAPI.getAllUserData();
        setCustomerUsersList(customerUserData);

        const userRoleData = await VxAPI.getUserRoles();

        setUserRoleArray(userRoleData);

        const instructorHierarchyData = await VxAPI.getAllClassesHierarchyDataByInstructorId();

        setInstructorHierarchyClassData(instructorHierarchyData);

        const customerHierarchyData = await VxAPI.getAllClassesHierarchyDataByCustomerId();
        setCustomerHierarchyClassData(customerHierarchyData);

        const customerData = await VxAPI.getAllCustomersData();

        setCustomersObjectArrayData(customerData);
      };

      fetchData().catch(console.error);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userRoleArray) {
      for (let i = 0; i < userRoleArray.length; i++) {
        if (userData?.userRole === userRoleArray[i].id) {
          setUserRoleData({ title: userRoleArray[i].title });
          return;
        }
      }
    }
  }, [userRoleArray]);

  useEffect(() => {
    if (customersObjectArrayData && userData) {
      const customerTitleFirst = customersObjectArrayData.find(
        (customer) => customer.customerId === userData.customerId,
      )?.firstName;
      const customerTitleLast = customersObjectArrayData.find(
        (customer) => customer.customerId === userData.customerId,
      )?.lastName;

      if (customerTitleLast) {
        setUserCustomerData({ title: `${customerTitleFirst} ${customerTitleLast}` });
        return;
      }

      setUserCustomerData({ title: customerTitleFirst });
    }
  }, [customersObjectArrayData, userData]);

  useEffect(() => {
    let instructorLessonsArray: any = [];
    // if user is a super user - show all lesssons for customer base
    // else show just the lessons where user is the instructor for the lesson (ie: userId is in principal array)
    if (userData && userData.isSuperuser) {
      if (customerHierarchyClassData) {
        customerHierarchyClassData.map((classData: ClassObjectDTO, index: number) => {
          classData.courseList?.map((course: CourseObjectDTO) => {
            course.courseModules?.map((module: ModuleObjectDTO) => {
              module.lessons?.map((lesson: LessonObjectDTO) => {
                const instructorLessonDataObject = {
                  classId: classData.classId,
                  className: classData.title,
                  students: classData.students,
                  lesson,
                };
                instructorLessonsArray.push(instructorLessonDataObject);
              });
            });
          });
        });
      }
    } else {
      if (instructorHierarchyClassData) {
        instructorHierarchyClassData.map((classData: ClassObjectDTO, index: number) => {
          classData.courseList?.map((course: CourseObjectDTO) => {
            course.courseModules?.map((module: ModuleObjectDTO) => {
              module.lessons?.map((lesson: LessonObjectDTO) => {
                if (lesson.principals?.includes(Number(userData?.userId))) {
                  const instructorLessonDataObject = {
                    classId: classData.classId,
                    className: classData.title,
                    students: classData.students,
                    lesson,
                  };
                  instructorLessonsArray.push(instructorLessonDataObject);
                }
              });
            });
          });
        });
      }
    }
    setInstructorLessonsArrayData(instructorLessonsArray);
  }, [instructorHierarchyClassData, customerHierarchyClassData, userData]);

  return (
    <>
      {userData && loggedIn ? (
        <>
          <Header
            firstName={userData.firstName}
            lastName={userData.lastName}
            userRole={userRoleData.title}
            userRoleId={userData.userRole}
            userRoles={userData.isSuperuser ? userRoleArray : undefined}
            customerName={userCustomerData.title}
            customers={userData.isSuperuser ? customersObjectArrayData : undefined}
            superUser={userData.isSuperuser}
            customerId={userData.customerId}
            userId={userData.userId}
            email={userData.email}
          />
          <div id="nav-div">
            <Nav isSuperuser={userData.isSuperuser} userRole={userRoleData.title} />
            <div className="page-border">
              {/* this is a placeholder that will render all of the nested elements of Account*/}
              <Outlet
                context={{
                  userData,
                  userRoleData,
                  instructorHierarchyClassData,
                  instructorLessonsArrayData,
                  customerHierarchyClassData,
                  customerUsersList
                }}
              />
            </div>
          </div>
        </>
      ) : loggedIn ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <Home />
      )}
    </>
  );
};

export default Account;

// function passed to children as props through Outlet component
export function useUserData() {
  return useOutletContext<ContextTypeUserData>();
}

export function useUserRoleData() {
  return useOutletContext<ContextTypeUserRoleData>();
}

export function useHierarchyClassData() {
  return useOutletContext<ContextTypeInstructorHierarchyClassData>();
}

export function useInstructorLessonsArrayData() {
  return useOutletContext<ContextTypeInstructorLessonsArrayData>();
}

export function useCustomerHierarchyClassData() {
  return useOutletContext<ContextTypeCustomerHierarchyClassData>();
}

export function useCustomerUsersList() {
  return useOutletContext<ContextTypeCustomerUsersList>();
}