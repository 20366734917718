import { useEffect, useState, MouseEvent } from 'react';

import './latestTest.scss';
import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';

const LatestTest = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/login');
  }

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loggedIn) {
      setLoading(false);
    }
  }, [loggedIn]);

  const handleHeadsetDownload = async () => {
    const resp = await VxAPI.downloadLatestBuildTest('QVXHeadsetLatest.apk');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };

  const handleDesktopDownload = async () => {
    const resp = await VxAPI.downloadLatestBuildTest('QVXDesktopLatest.zip');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };
  // this is the correct custom download way of dynamically sending files names
  const handleHeadsetDownloadV3a = async () => {
    const resp = await VxAPI.downloadLatestBuildTestV3('testing-builds/QVXHeadsetLatest.apk');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };
 // this is the correct custom download way of dynamically sending files names TODO: Change in api
  const handleDesktopDownloadV3a = async () => {
    const resp = await VxAPI.downloadLatestBuildTestV3('testing-builds/QVXDesktopLatest.zip');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };
  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <>
          <div className="download-page">
            <div className="build-download">
              <h1>Latest Test Builds!</h1>
              <a href="#" onClick={handleHeadsetDownload}>
                QIVX Headset APK
              </a>
              <a href="#" onClick={handleDesktopDownload}>
                QIVX Desktop App
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LatestTest;
