import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import './studentQuizResult.scss';
import Auth from '../../utils/auth';
import { useUserData } from '../account/Account';
import { CSVLink } from 'react-csv';

const StudentQuizResult = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }
  const { userData } = useUserData();
  const navigate = useNavigate();
  const params = useParams();
  const paramsLessonId = Number(params.lessonId);
  const paramsQuizGuid = params.guid;
  const paramsUserId = Number(params.userId);
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<UserObjectDTO>();
  const [quizResultsData, setQuizResultsData] = useState<any>();

  const quizResultHeaders = [
    {
      label: 'Student Name',
      key: 'studentName',
    },
    {
      label: 'Lesson Name',
      key: 'lessonName',
    },
    {
      label: 'Quiz Name',
      key: 'quizName',
    },
    {
      label: 'Overall Quiz Score',
      key: 'quizScore',
    },
    {
      label: 'Overall Quiz Confidence',
      key: 'quizConfidence',
    },
    {
      label: 'Quiz Attempt #',
      key: 'quizAttempt',
    },
    {
      label: 'Quiz Attempt Score',
      key: 'quizAttemptScore',
    },
    {
      label: 'Quiz Attempt Confidence',
      key: 'quizAttemptConfidence',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const studentData = await VxAPI.getSingleUserData(paramsUserId);
      if (studentData) {
        setStudent(studentData);
        setLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, []);

  const studentMetricsLessonObject = student?.studentMetrics?.lessons?.find(
    (lesson) => lesson.lessonId === paramsLessonId,
  );
  const quizMetrics = studentMetricsLessonObject?.quizResults.find(
    (completedQuiz) => completedQuiz.guid === paramsQuizGuid,
  );
  let scoreAsDecimal = Number(quizMetrics?.quizGlobalScore) / 100;
  let correctAnswers = scoreAsDecimal * (quizMetrics?.quizAttempts[0].questions.length || 0);

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  useEffect(() => {
    if (studentMetricsLessonObject && quizMetrics && scoreAsDecimal) {
      const quizResultData: any = [];

      quizMetrics.quizAttempts.map((quizAttempt, index) => {
        if (index === 0) {
          let studentResults = {
            studentName: `${student?.firstName} ${student?.lastName}`,
            lessonName: studentMetricsLessonObject.title,
            quizName: quizMetrics.title,
            quizScore: `${quizMetrics.quizGlobalScore}%`,
            quizConfidence: `${quizMetrics.quizGlobalConfidence * 100}%`,
            quizAttempt: index + 1,
            quizAttemptScore: `${quizAttempt.quizAttemptScore}%`,
            quizAttemptConfidence: `${quizAttempt.quizAttemptConfidence * 100}%`,
          };
          quizResultData.push(studentResults);
        } else {
          let studentResults = {
            quizAttempt: index + 1,
            quizAttemptScore: `${quizAttempt.quizAttemptScore}%`,
            quizAttemptConfidence: `${quizAttempt.quizAttemptConfidence * 100}%`,
          };
          quizResultData.push(studentResults);
        }
      });

      setQuizResultsData(quizResultData);
    }
  }, [studentMetricsLessonObject, quizMetrics, scoreAsDecimal]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="student-quiz-results-display">
          <h1>
            {student?.firstName} {student?.lastName}
          </h1>
          <h2>
            Lesson: {studentMetricsLessonObject?.title} <br></br>
            {quizMetrics?.title} ({quizMetrics?.quizAttempts.length} Attempts)
          </h2>
          <div className="student-quiz-overall-summary">
            <p className="quiz-overall-score-perc">Overall: {quizMetrics?.quizGlobalScore.toFixed(2)}%</p>
            {(quizMetrics?.quizGlobalConfidence || 0) >= 0.70 ? (
              <>
                <div className="student-confidence-status">
                  <div className="high-confidence-cir"></div>
                  <p>High Confidence</p>
                </div>
              </>
            ) : (quizMetrics?.quizGlobalConfidence || 0) >= 0.45 ? (
              <>
                <div className="student-confidence-status">
                  <div className="medium-confidence-cir"></div>
                  <p>Medium Confidence</p>
                </div>
              </>
            ) : (
              <>
                <div className="student-confidence-status">
                  <div className="low-confidence-cir"></div>
                  <p>Low Confidence</p>
                </div>
              </>
            )}
          </div>
          {quizMetrics?.quizAttempts.map((quizAttempt, index) => {
            let quizAttemptScoreAsDecimal = Number(quizAttempt.quizAttemptScore) / 100;
            let quizAttemptCorrectAnswers = quizAttemptScoreAsDecimal * quizAttempt.questions.length;

            return (
              <div key={`attempt-${index}`} className="student-quiz-attempt-summary">
                <p className="quiz-attempt-score-perc">
                  Attempt {index + 1}: {quizAttempt.quizAttemptScore.toFixed(2)}% (
                  {quizAttemptCorrectAnswers.toFixed(0)}/{quizAttempt.questions.length})
                </p>
                {(quizAttempt.quizAttemptConfidence || 0) >= 0.70 ? (
                  <>
                    <div className="student-confidence-status">
                      <div className="high-confidence-cir"></div>
                      <p>High Confidence</p>
                    </div>
                  </>
                ) : (quizAttempt.quizAttemptConfidence || 0) >= 0.45 ? (
                  <>
                    <div className="student-confidence-status">
                      <div className="medium-confidence-cir"></div>
                      <p>Medium Confidence</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="student-confidence-status">
                      <div className="low-confidence-cir"></div>
                      <p>Low Confidence</p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {/* <p id="elapsed-time">Elapsed Time: {studentMetricsLessonObject?.totalTime}</p> */}
          {quizResultHeaders && quizResultsData ? (
            <>
              <button id="download-results-btn">
                <CSVLink
                  download={`${student?.firstName} ${student?.lastName} ${quizMetrics?.title} Results`}
                  data={quizResultsData}
                  headers={quizResultHeaders}
                >
                  Download Quiz Results
                </CSVLink>
              </button>
            </>
          ) : null}
          {quizMetrics?.quizAttempts
            .sort((a: any, b: any) => {
              const date1 = new Date(a.submissionDate).getTime();
              const date2 = new Date(b.submissionDate).getTime();

              if (date1 < date2) {
                return 1;
              }

              if (date1 > date2) {
                return -1;
              }

              return 0;
            })
            .map((quizAttempt, index) => {
              const quizAttemptIndex = quizMetrics.quizAttempts.findIndex(
                (attempt) => attempt.submissionDate === quizAttempt.submissionDate,
              );
              return (
                <>
                  <div id="student-quiz-results">
                    <div className="quiz-results-header">
                      <h3>
                        {quizAttempt.questions.length === 1
                          ? `${quizAttempt.questions.length} Total Question`
                          : !quizAttempt.questions.length
                          ? '0 Total Questions'
                          : `Quiz Attempt ${quizMetrics.quizAttempts.length - quizAttemptIndex}: ${
                              quizAttempt.questions.length
                            } Total Questions`}
                      </h3>
                      <h4>Submitted: {quizAttempt.submissionDate}</h4>
                    </div>
                    <div className="question-list-div">
                      {quizAttempt.questions.map((question, index: number) => {
                        let userSelection = question.usersChoice;
                        let correctSelection = question.correctChoice;
                        const questionGlobalConfidencePercentage = question.questionGlobalConfidence * 100;
                        return (
                          <>
                            <div key={index} className="question-card">
                              <div className="question-header">
                                <h4>Question {index + 1}</h4>
                                {userSelection === correctSelection ? (
                                  <p className="correct-mark">&#10003;</p>
                                ) : (
                                  <p className="incorrect-mark">X</p>
                                )}
                              </div>
                              <p className="question">{question.question}</p>
                              <div className="question-details">
                                {question.choices.map((choice, index) => {
                                  return (
                                    <div className="question-choices">
                                      {index === correctSelection ? (
                                        <>
                                          <div className="choice">
                                            <div className="banner-div">
                                              <p className="correct-banner">Correct</p>
                                              <div className="arrow-right-correct"></div>
                                            </div>
                                            <input className="radio-btn" type="radio" checked />
                                            <p key={index}>{choice}</p>
                                            {correctSelection === userSelection ? (
                                              <p
                                                className={`question-confidence ${
                                                  questionGlobalConfidencePercentage < 45
                                                    ? 'low-conf'
                                                    : questionGlobalConfidencePercentage < 70
                                                    ? 'med-conf'
                                                    : 'high-conf'
                                                }`}
                                              >
                                                ({questionGlobalConfidencePercentage.toFixed(2)}% Confidence)
                                              </p>
                                            ) : null}
                                          </div>
                                        </>
                                      ) : index !== correctSelection && index === userSelection ? (
                                        <>
                                          <div className="choice">
                                            <div className="banner-div">
                                              <p className="incorrect-banner">Incorrect</p>
                                              <div className="arrow-right-incorrect"></div>
                                            </div>
                                            <input className="radio-btn" type="radio" checked />
                                            <p key={index}>{choice}</p>
                                            <p
                                              className={`question-confidence ${
                                                questionGlobalConfidencePercentage < 45
                                                  ? 'low-conf'
                                                  : questionGlobalConfidencePercentage < 70
                                                  ? 'med-conf'
                                                  : 'high-conf'
                                              }`}
                                            >
                                              ({questionGlobalConfidencePercentage.toFixed(2)}% Confidence)
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="choice">
                                            <div className="banner-div">
                                              <p className="empty-banner"></p>
                                              <div className="arrow-right-empty"></div>
                                            </div>
                                            <input className="radio-btn" type="radio" disabled />
                                            <p key={index}>{choice}</p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          <div className="back-btn-div">
            <button className="back-btn" type="button" onClick={() => navigate(-1)}>
              <span>Back</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentQuizResult;
