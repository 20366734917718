import { useEffect, useState } from "react";

import { VxAPI } from "../../api/vx.api";
import Auth from '../../utils/auth';
import './removeHeadsetWords.scss'

const RemoveHeadsetWords = () => {
    const [message, setMessage] = useState('');

    const [userId, setUserId] = useState(0)

    useEffect(() => {
        const loggedInUser = Auth.getProfile('id_token')

        if (loggedInUser) {
            setUserId(loggedInUser.userId)
        }
    }, [])

    const handleClick = async () => {
        const newToken = await Auth.refreshToken();
        setMessage('Removing...')

        const resp = await VxAPI.removeHeadsetWords(
            userId
        )

        if (resp.ok) {
            setMessage('Headset selected words successfully removed.')

            setTimeout(() => {
                setMessage('')
            }, 3000)

        } else {
            setMessage('Your headset selected words could not be removed. Please try again.')
        }
    }
    return (
        <div id='remove-headset-words'>
            <h2>Forgot Headset Selected Words?</h2>
            <div id='remove-headset-words-btn-div' className='row justify-center'>
                <button type='button' onClick={handleClick}><span>Remove Words</span></button>
            </div>
            {message ? (
                <h3 id="message">{message}</h3>
            ) : null}
        </div>
    )
}

export default RemoveHeadsetWords;