import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { AddTagSelectStateDTO } from '../dto/addTag.selectState.dto';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import './addTagForm.scss';
import { useNavigate } from 'react-router-dom';
import { TagObjectDTO } from '../dto/tagObject.dto';

const AddTagForm: React.FC<QiEmployeeProps> = (props) => {
  const navigate = useNavigate();
  const { userCustomerId } = props;
  const [message, setMessage] = useState('');
  const [tierSelection, setTierSelection] = useState<AddTagSelectStateDTO>({
    tier1Selection: null,
    tier2Selection: null,
  });
  const [selectState, setSelectState] = useState<AddTagSelectStateDTO>({
    customerId: undefined,
    tagLevel: 'Tier 1',
  });
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    type: '',
    sub1: '',
    sub2: '',
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>();
  const [tier1Tags, setTier1Tags] = useState<TagObjectDTO[]>([]);
  const [tier2Tags, setTier2Tags] = useState<TagObjectDTO[]>([]);
  const [tier3Tags, setTier3Tags] = useState<TagObjectDTO[]>([]);

  const tagLevels = ['Tier 1', 'Tier 2', 'Tier 3'];

  const { customerId } = selectState;
  const { title, description, type, sub1, sub2 } = formState;

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    if (e.target.name === 'tagLevel') {
      setSelectState({ ...selectState, [e.target.name]: e.target.value, customerId: userCustomerId });
      return;
    }
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value), customerId: userCustomerId });
    return;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    if (e.target.name === 'title') {
      switch (selectState.tagLevel) {
        case 'Tier 1':
          setFormState({ ...formState, [e.target.name]: e.target.value, type: e.target.value });
          setSelectState({ ...selectState, customerId: userCustomerId });
          return;
        case 'Tier 2':
          setFormState({ ...formState, [e.target.name]: e.target.value, sub1: e.target.value });
          setSelectState({ ...selectState, customerId: userCustomerId });
          return;
        case 'Tier 3':
          setFormState({ ...formState, [e.target.name]: e.target.value, sub2: e.target.value });
          setSelectState({ ...selectState, customerId: userCustomerId });
          return;
      }
    }
    setFormState({ ...formState, [e.target.name]: e.target.value });
    setSelectState({ ...selectState, customerId: userCustomerId });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();
    const dupTag = tagObjectArrayData?.find(tag => tag.title?.toLowerCase().trim() === title.toLowerCase().trim());
    if(dupTag){
      setMessage(`${title} already exits. Please rename to create tag.`)
      return;
    }

    switch (selectState.tagLevel) {
      case 'Tier 1':
        if (title === '' || type === '' || customerId === undefined || customerId === 0) {
          setMessage('Make sure all required fields are completed.');
          return;
        }
        break;
      case 'Tier 2':
        if (
          title === '' ||
          type === '' ||
          !type ||
          sub1 === '' ||
          !sub1 ||
          customerId === undefined ||
          customerId === 0
        ) {
          setMessage('Make sure all required fields are completed.');
          return;
        }
        break;
      case 'Tier 3':
        if (
          title === '' ||
          type === '' ||
          !type ||
          sub1 === '' ||
          !sub1 ||
          sub2 === '' ||
          !sub2 ||
          customerId === undefined ||
          customerId === 0
        ) {
          setMessage('Make sure all required fields are completed.');
          return;
        }
        break;
    }

    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone

    let createdDate = currentDate;

    const resp = await VxAPI.addTag({
      customerId,
      createdDate,
      title,
      description,
      type: selectState.tagLevel === 'Tier 1' ? title : type,
      sub1,
      sub2,
    });

    if (resp.tagId) {
      // reset input fields after tag tier selection
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

      // reset textarea fields after tag tier selection
      Array.from(document.querySelectorAll('textarea')).forEach((input) => (input.value = ''));
      //reset tier 1 drop down
      Array.from(document.querySelectorAll('select')).forEach((select) => (select.selectedIndex = 0));
      setMessage('Tag added! Feel free to add another!');
      switch (selectState.tagLevel) {
        case 'Tier 1':
          tier1Tags.push(formState);
          break;
        case 'Tier 2':
          tier2Tags.push(formState);
          break;
      }
      setSelectState({ customerId: undefined, tagLevel: 'Tier 1' });
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }
      const tagArrayData = await VxAPI.getAllTagsData();
      if (tagArrayData) {
        setTagObjectArrayData(tagArrayData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (tagObjectArrayData) {
      let filteredTier1Tags: TagObjectDTO[] = [];
      let filteredTier2Tags: TagObjectDTO[] = [];
      let filteredTier3Tags: TagObjectDTO[] = [];
      tagObjectArrayData.forEach((tag) => {
        if (tag.type && !tag.sub1 && !tag.sub2) {
          filteredTier1Tags.push(tag);
        } else if (tag.type && tag.sub1 && !tag.sub2) {
          filteredTier2Tags.push(tag);
        } else {
          filteredTier3Tags.push(tag);
        }
      });
      setTier1Tags(filteredTier1Tags);
      setTier2Tags(filteredTier2Tags);
      setTier3Tags(filteredTier3Tags);
    }
  }, [tagObjectArrayData]);

  const handleTierSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    setTierSelection({ ...tierSelection, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormState({
      title: '',
      description: '',
      type: '',
      sub1: '',
      sub2: '',
    });
    setTierSelection({
      tier1Selection: null,
      tier2Selection: null,
    });
    // reset input fields after tag tier selection
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

    // reset textarea fields after tag tier selection
    Array.from(document.querySelectorAll('textarea')).forEach((input) => (input.value = ''));
    //reset tier 1 drop down
    let tier1SelectionDropDownEl = document.getElementById('type-selection') as HTMLSelectElement | null;
    tier1SelectionDropDownEl ? (tier1SelectionDropDownEl.selectedIndex = 0) : (tier1SelectionDropDownEl = null);
  }, [selectState.tagLevel]);

  useEffect(() => {
    setFormState({
      ...formState,
      type: tierSelection.tier1Selection as string,
      sub1: tierSelection.tier2Selection as string,
    });
  }, [tierSelection]);
  return (
    <div id="add-tag-div">
      <h1>Add a Tag</h1>
      <form onSubmit={handleSubmit}>
        <div className="dropdown-div">
          <div className="add-tag-cust">
            <label htmlFor="name">Customer</label>
            <p id="cust-name">
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.firstName}{' '}
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.lastName}
            </p>
          </div>
          <div className="add-tag-dropdown">
            <label htmlFor="tagLevel">Tag Level *</label>
            <select name="tagLevel" onChange={handleSelection}>
              {tagLevels
                ? tagLevels.map((tagLevel: string, index: number) => (
                    <option key={index} value={tagLevel}>
                      {tagLevel}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="dropdown-div">
          {selectState.tagLevel === 'Tier 2' ? (
            <>
              <div className="add-tag-dropdown">
                <label htmlFor="tier1Selection">Tier 1 Name *</label>
                <select name="tier1Selection" onChange={handleTierSelection} id={`type-selection`}>
                  <option value={''}>---</option>
                  {tier1Tags
                    ? tier1Tags
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag: TagObjectDTO, index: number) => {
                        return (
                          <option key={`tag-${index}`} value={tag.title}>
                            {tag.title}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </>
          ) : selectState.tagLevel === 'Tier 3' ? (
            <>
              <div className="add-tag-dropdown">
                <label htmlFor="tier1Selection">Tier 1 Name *</label>
                <select
                  name="tier1Selection"
                  onChange={handleTierSelection}
                  id={`type-selection`}
                >
                  <option value={''}>---</option>
                  {tier1Tags
                    ? tier1Tags
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag: TagObjectDTO, index: number) => {
                        return (
                          <option key={`tag-${index}`} value={tag.title}>
                            {tag.title}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
              <div className="add-tag-dropdown">
                <label htmlFor="tier2Selection">Tier 2 Name *</label>
                <select name="tier2Selection" onChange={handleTierSelection} id={`sub1-selection`}>
                  <option value={''}>---</option>
                  {tier2Tags
                    ? tier2Tags
                        .filter((tag) => tag.type === tierSelection.tier1Selection)
                        .sort((a: any, b: any) => {
                          if (a.title < b.title) {
                            return -1;
                          }
                          if (a.title > b.title) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((tag: TagObjectDTO, index: number) => {
                          return (
                            <option key={`tag-${index}`} value={tag.title}>
                              {tag.title}
                            </option>
                          );
                        })
                    : null}
                </select>
              </div>
            </>
          ) : null}
        </div>
        <div className="add-tag-form-input">
          <label htmlFor="title">Tag Name *</label>
          <input type="text" name="title" defaultValue={title} onChange={handleChange} />
        </div>
        <div className="add-tag-form-textarea">
          <label htmlFor="description">Description</label>
          <textarea name="description" defaultValue={description} onChange={handleChange} />
        </div>
        <div id="add-tag-btns">
          <button type="button" onClick={() => window.location.assign("/tagsTable")}>
            <span>Cancel</span>
          </button>
          <button type="submit">
            <span>Confirm</span>
          </button>
        </div>
      </form>
      {message ? <h3 className="message">{message}</h3> : null}
    </div>
  );
};

export default AddTagForm;
