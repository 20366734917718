import { useEffect, useState } from 'react';

import AddUserForm from '../../components/addUserForm/AddUserForm';
import { useCustomerUsersList, useUserRoleData } from '../account/Account';
import AddMultipleUsers from '../../components/addMultipleUsers/AddMultipleUsers';
import './addUsers.scss';

const AddUsers = () => {
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;

  const { customerUsersList } = useCustomerUsersList();

  const [roleName, setRoleName] = useState<string>('');
  const [emailList, setEmailList] = useState<string[]>([]);

  if (roleName === 'Student' || roleName === 'Instructor') {
    window.location.assign('/');
  }
  useEffect(() => {
    if (userRoleName) {
      setRoleName(userRoleName ? userRoleName : '');
    }
  }, [userRoleName]);

  useEffect(() => {
    if (customerUsersList) {
      let emails: string[] = [];
      customerUsersList.forEach((user) => emails.push(user.email));
      setEmailList(emails);
    }
  }, [customerUsersList]);

  return (
    <div className="add-users-container">
      <AddMultipleUsers emailList={emailList} />
      <AddUserForm />
    </div>
  );
};

export default AddUsers;
