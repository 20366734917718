import { ForgetPwDTO } from "./dto/forgetpw.dto";
import { LoginDTO } from "./dto/login.dto";
import { ResetPwDTO } from "./dto/resetpw.dto";
import Auth from "../utils/auth";
import { SetPinDTO, UpdatePinDTO, UpdatePwDTO } from "./dto/updatepw.dto";
import { UpdateUserDTO } from "./dto/updateUser.dto";
import { AddTagDTO } from "./dto/addTag.dto";
import { AddAppDTO } from "./dto/addApp.dto";
import { AddCustomerDTO } from "./dto/addCustomer.dto";
import { AddUseCaseDTO } from "./dto/addUseCase.dto";
import { AddMultipleUsersDTO, AddUserDTO } from "./dto/addUser.dto";
import { UserObjectDTO } from "./dto/userObject.dto";
import { AppObjectDTO } from "../components/dto/appObject.dto";
import { LocationObjectDTO } from "../components/dto/locationObject.dto";
import { TagObjectDTO } from "../components/dto/tagObject.dto";
import { CustomerObjectDTO } from "../components/dto/customerObject.dto";
import { UseCasesObjectDTO } from "../components/dto/useCasesObject.dto";
import { CourseObjectDTO } from "../components/dto/courseObject.dto";
import { AddClassDTO } from "./dto/addClass.dto";
import { ClassObjectDTO } from "../components/dto/classObject.dto";
import { AddCourseDTO } from "./dto/addCourse.dto";
import { ModuleObjectDTO } from "../components/dto/moduleObject.dto";
import { AddModuleDTO } from "./dto/addModule.dto";
import { RemoveUsersFromClassDTO } from "./dto/removeUsersFromClass.dto";
import { AddUsersToClassDTO } from "./dto/addUsersToClass.dto";
import { LessonObjectDTO } from "../components/dto/lessonObject.dto";
import { AddCoursesToClassDTO } from "./dto/addCoursesToClass.dto";
import { AddMultipleTagsDTO } from "./dto/addMultipleTags.dto";
import { CopyParentLessonAssetsDTO } from "./dto/copyParentLessonAssets.dto";

export class VxAPI {
    public static async resetPw(resetPwRequest: ResetPwDTO) {
        const resp = await fetch(`/v1/auth/password/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(resetPwRequest)
        })

        return resp;
    }
    public static async forgetPw(forgetPwRequest: ForgetPwDTO) {
        const resp = await fetch(`/v1/auth/password/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(forgetPwRequest)
        })

        return resp;
    }
    public static async login(loginRequest: LoginDTO) {
        const resp = await fetch(`/v1/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(loginRequest)
        })

        const data = await resp.json();

        return data;
    }
    public static async confirmUserExists(userId: number, email: string, customerId: number) {
        const token = Auth.getToken('id_token');

        const resp = await fetch(`/v1/User/${userId}/confirm`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify({email: email, customerId: customerId})
        })

        if(!resp.ok) {
            return false;
        } else {
            return true;
        }
    }
    public static async updateSuperUserToken(email: string) {
        const token = Auth.getToken('id_token');
        

        const resp = await fetch(`/v1/auth/token/update`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify({email: email})
        })

        const data = await resp.json();

        return data;
    }
    public static async updatePw(updatePwRequest: UpdatePwDTO) {
        const token = Auth.getToken('id_token');
        const resp = await fetch(`/v1/auth/password/update`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(updatePwRequest)
        })

        return resp;
    }
    public static async setPin(setPwRequest: SetPinDTO) {
        const token = Auth.getToken('id_token');
        const resp = await fetch(`/v1/auth/pin/set`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(setPwRequest)
        })

        const data = await resp.json();

        return data;
    }
    public static async updatePin(updatePinRequest: UpdatePinDTO) {
        const token = Auth.getToken('id_token');
        const resp = await fetch(`/v1/auth/pin/update`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },

            body: JSON.stringify(updatePinRequest)
        })

        return resp;
    }
    public static async removeHeadsetWords(userId: number): Promise<any>{
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/auth/password/headset/words/remove/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            return resp;
        }
    }
    public static async getUserData() {
        const token = Auth.getToken('id_token');
        if (token) {
            const loggedInUser = Auth.getProfile('id_token');
            if (loggedInUser) {

                const { userId } = loggedInUser;

                const resp = await fetch(`/v1/User/${userId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });

                const data = await resp.json();

                const {
                    customerId,
                    isSuperuser,
                    firstName,
                    lastName,
                    userRole,
                    email,
                    address,
                    address2,
                    city,
                    state,
                    zip,
                    phone
                }
                    = data

                return {
                    userId,
                    customerId,
                    isSuperuser,
                    firstName,
                    lastName,
                    userRole,
                    email,
                    address,
                    address2,
                    city,
                    state,
                    zip,
                    phone
                };
            }

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getUserRoles() {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/UserRole`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data


        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async updateUser(updateUserRequest: UpdateUserDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/User/${updateUserRequest.userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(updateUserRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addTag(addTagRequest: AddTagDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Tag`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addTagRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addMultipleTags(addTagsRequest: AddMultipleTagsDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Tag/bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addTagsRequest)
            })
            const data = await resp.json();
            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addUser(addUserRequest: AddUserDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            let numUserRole = addUserRequest.userRole;
            if(addUserRequest.userRole && typeof addUserRequest.userRole === 'string')
            {
                numUserRole = parseInt(addUserRequest.userRole);
            }
            // add user permissions based on 
            switch (numUserRole) {
                case 2:
                    addUserRequest.permissions = [
                        36, 28, 32, 38, 40, 56, 70, 44
                    ];
                    break;
                case 3:
                    addUserRequest.permissions = [
                        33,
                        34,
                        35,
                        36,
                        69,
                        25,
                        26,
                        27,
                        28,
                        29,
                        30,
                        31,
                        32,
                        37,
                        38,
                        39,
                        40,
                        53,
                        54,
                        55,
                        56,
                        70,
                        71,
                        44
                    ];
                    break;
                case 4:
                    addUserRequest.permissions = [
                        33,
                        34,
                        35,
                        36,
                        69,
                        25,
                        26,
                        27,
                        28,
                        29,
                        30,
                        31,
                        32,
                        44
                    ];
                    break;
                default:
                    break;
            }
            const resp = await fetch(`/v1/User`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addUserRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addMultipleUsers(addMultipleUserRequest: AddMultipleUsersDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/User/bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addMultipleUserRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addUseCase(addUseCaseRequest: AddUseCaseDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/UseCase`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addUseCaseRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addCustomer(addCustomerRequest: AddCustomerDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addCustomerRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addApp(addAppRequest: AddAppDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/App`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addAppRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addClass(addClassRequest: AddClassDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Class`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addClassRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addCourse(addCourseRequest: AddCourseDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Course`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addCourseRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addModule(addModuleRequest: AddModuleDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/CourseModule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addModuleRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async addLesson(addLessonRequest: LessonObjectDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Lesson`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(addLessonRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async copyParentLessonAssets(copyParentLessonReq: CopyParentLessonAssetsDTO, customerId: number) {
        try {
            const token = Auth.getToken('id_token');
            if (token) {
                const resp = await fetch(`/v1/Lesson/copy/parent/customer/${customerId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },

                    body: JSON.stringify(copyParentLessonReq)
                })

                if(!resp.ok) {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
                            
                const data = await resp.json();

                return data;
            } else {
                return { error: 'Not Authorized' }
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error('An error occurred:', error.message);
                return { error: 'Not Authorized' }
            }
        }
    }
    public static async DeleteLesson(lessonId: number) {
        try {
            const token = Auth.getToken('id_token');
            if (token) {
                const resp = await fetch(`/v1/Lesson/${lessonId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })

                if(!resp.ok) {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }

                return {success: "Lesson deleted."};
            } else {
                return { error: 'Not Authorized' }
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error('An error occurred:', error.message);
                return { error: 'Not Authorized' }
            }
        }
    }
    public static async getRefreshToken() {
        const profile = Auth.getProfile('id_token')
        if (profile) {
            const refreshToken = Auth.getToken('refresh_token');
            if (refreshToken) {
                const { email } = profile
                const resp = await fetch(`/v1/auth/token/refresh`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        refreshToken: refreshToken
                    })
                });
                const data = await resp.json();
                return data;
            }
        }
    }
    public static async getAllUserData() {
        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/User/customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data
            
        }
    }
    public static async getAllTagsData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/Tag/customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data
        
        }
    }
    public static async getAllLocations(): Promise<any> {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Location`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            let locationObjectArray: LocationObjectDTO[] = []

            data.forEach((location: LocationObjectDTO) => {

                locationObjectArray.push({
                    locationId: location.locationId,
                    title: location.title
                })
            })

            return locationObjectArray
        }
    }
    public static async getAllCustomersData(): Promise<any> {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/Customer`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;
            let customerObjectArray: CustomerObjectDTO[] = []

            data.forEach((customer: CustomerObjectDTO) => {

                customerObjectArray.push({
                    customerId: customer.customerId,
                    lastName: customer.lastName,
                    firstName: customer.firstName,
                    phone: customer.phone,
                    email: customer.email,
                    location: customer.location,
                })
            })
            return customerObjectArray
        }
    }
    public static async getAllUseCasesData() {
        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const resp = await fetch(`/v1/UseCase`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data;
        }
    }
    public static async getAllAppsData(): Promise<any> {
        const token = Auth.getToken('id_token');

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/App/customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;
           
            return data;
          
        }
    }
    public static async getAllCoursesData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/Course/customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data;
            
        }
    }
    public static async getAllModulesData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (!loggedInUser) {
            return { error: 'You need to be logged in.' }
        }

        const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/CourseModule/customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data;
    }
    public static async getAllLessonsData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (!loggedInUser) {
            return { error: 'You need to be logged in.' }
        }

        const { customerId } = loggedInUser;
       
        const resp = await fetch(`/v1/Lesson/customer/${customerId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        const dataResp = await resp.json();

        const { data } = dataResp;

        return data;
    }
    public static async getSingleUserData(userId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/User/${userId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editUser(editUserRequest: UserObjectDTO, userId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            let numUserRole = editUserRequest.userRole ? editUserRequest.userRole : 0;
            if(editUserRequest.userRole && typeof editUserRequest.userRole === 'string')
            {
                numUserRole = parseInt(editUserRequest.userRole);
            }
            if (editUserRequest.userRole) {
                switch (numUserRole) {
                    case 2:
                        editUserRequest.permissions = [
                            36, 28, 32, 38, 40, 56, 70, 44
                        ];
                        break;
                    case 3:
                        editUserRequest.permissions = [
                            33,
                            34,
                            35,
                            36,
                            69,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30,
                            31,
                            32,
                            37,
                            38,
                            39,
                            40,
                            53,
                            54,
                            55,
                            56,
                            70,
                            71,
                            44
                        ];
                        break;
                    case 4:
                        editUserRequest.permissions = [
                            33,
                            34,
                            35,
                            36,
                            69,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30,
                            31,
                            32,
                            44
                        ];
                        break;
                    default:
                        editUserRequest.permissions = null
                }
            }
            const resp = await fetch(`/v1/User/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editUserRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleCustomerData(customerId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Customer/${customerId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editCustomer(editCustomerRequest: CustomerObjectDTO, customerId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Customer/${customerId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editCustomerRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleUseCaseData(useCaseId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/UseCase/${useCaseId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editUseCase(editUseCaseRequest: UseCasesObjectDTO, useCaseId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/UseCase/${useCaseId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editUseCaseRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleAppData(appId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/App/${appId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editApp(editAppRequest: AppObjectDTO, appId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/App/${appId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editAppRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleTagData(tagId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Tag/${tagId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editTag(editTagRequest: TagObjectDTO, tagId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Tag/${tagId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editTagRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getAllClassesData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/Class`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();
            const { data } = dataResp;

            // check to see if customer belongs to QI - if so return all data
            if (customerId === 1) {
                return data;
            } else {
                let userArray = data.filter((classInfo: ClassObjectDTO) => (classInfo.customerId === customerId))
                return userArray
            }
        }
    }
    public static async getAllClassesHierarchyData() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId } = loggedInUser;

            const resp = await fetch(`/v1/Class/hierarchy`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();
            const { data } = dataResp;

            // check to see if customer belongs to QI - if so return all data
            if (customerId === 1) {
                return data;
            } else {
                let userArray = data.filter((classInfo: ClassObjectDTO) => (classInfo.customerId === customerId))
                return userArray
            }
        }
    }
    public static async getAllClassesHierarchyDataByInstructorId() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId, userId } = loggedInUser;

            const resp = await fetch(`/v1/Class/customer/${customerId}/instructor/${userId}/hierarchy`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();
            const { data } = dataResp;

            return data
        }
    }
    public static async getAllClassesHierarchyDataByCustomerId() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { customerId, userId } = loggedInUser;

            const resp = await fetch(`/v1/Class/customer/${customerId}/hierarchy`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const dataResp = await resp.json();
            const { data } = dataResp;

            return data
        }
    }
    public static async getAllHierarchyDataSingleClass(classId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Class/${classId}/hierarchy`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getSingleClassData(classId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Class/${classId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editClass(editClassRequest: ClassObjectDTO, classId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Class/${classId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editClassRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleCourseData(courseId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Course/${courseId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editCourse(editCourseRequest: CourseObjectDTO, courseId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Course/${courseId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editCourseRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getSingleModuleData(moduleId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/CourseModule/${moduleId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async editModule(editModuleRequest: ModuleObjectDTO, moduleId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/CourseModule/${moduleId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editModuleRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async editLesson(editLessonRequest: LessonObjectDTO, lessonId: number | undefined) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/Lesson/${lessonId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },

                body: JSON.stringify(editLessonRequest)
            })

            const data = await resp.json();

            return data;
        } else {
            return { error: 'Not Authorized' }
        }
    }
    public static async getLessonsByInstructorId() {

        const token = Auth.getToken('id_token');

        if (!token) {
            Auth.logout();
        }

        const loggedInUser = Auth.getProfile('id_token');
        if (loggedInUser) {

            const { userId } = loggedInUser;

            const resp = await fetch(`/v1/Lesson/instructor/${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            })

            const dataResp = await resp.json();

            const { data } = dataResp;

            return data;
        }
    }
    public static async getSingleLessonById(lessonId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Lesson/${lessonId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getSingleQuizById(quizId: number) {
        const token = Auth.getToken('id_token');
        if (token) {

            const resp = await fetch(`/v1/Quiz/${quizId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await resp.json();

            return data

        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getLessonsByStudentIdForInstructor(userId: number) {
        const token = Auth.getToken('id_token');
        if (token) {
            let loggedInUser = Auth.getProfile('id_token')
            if(loggedInUser) {
                let instructorId = loggedInUser.userId
                const resp = await fetch(`/v1/Lesson/student/${userId}/instructor/${instructorId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const dataResp = await resp.json();
                const { data } = dataResp;
                return data;
            }
        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getUserByRoleByCustomerId(userRole: number) {
        const token = Auth.getToken('id_token');
        if (token) {
            let loggedInUser = Auth.getProfile('id_token')
            if(loggedInUser) {
                let customerId = loggedInUser.customerId
                const resp = await fetch(`/v1/User/customer/${customerId}/role/${userRole}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const dataResp = await resp.json();
                const { data } = dataResp;
                return data;
            }
        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async addUsersToClass(classId: number | undefined, userIdArray: AddUsersToClassDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/User/add/class/${classId}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(userIdArray)
            });
            const dataResp = await resp.json();
            const { data } = dataResp;
            return data;
        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async removeUsersFromClass(classId: number | undefined, userIdArray: RemoveUsersFromClassDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            const resp = await fetch(`/v1/User/remove/class/${classId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(userIdArray)
            });
            const dataResp = await resp.json();
            const { data } = dataResp;
            return data;
        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async getUsersByArrayOfIds(userIdArray: RemoveUsersFromClassDTO) {
        const token = Auth.getToken('id_token');
        if (token) {
            let loggedInUser = Auth.getProfile('id_token')
            if(loggedInUser) {
                let customerId = loggedInUser.customerId
                const resp = await fetch(`/v1/User/customer/${customerId}/array`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(userIdArray)
                });
                const dataResp = await resp.json();
                const { data } = dataResp;
                return data;
            }
        } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async addCourseToClass(courseIdArray: AddCoursesToClassDTO, classId: number) {
        const token = Auth.getToken('id_token');
        if (token) {
                const resp = await fetch(`/v1/Course/add/class/${classId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(courseIdArray)
                });
                const dataResp = await resp.json();
                const { data } = dataResp;
                return data;
            } else {
            return { error: 'You need to be logged in.' }
        }
    }
    public static async downloadLatestBuild(fileName: string) {
        try {
            const resp = await fetch(`/v1/latest-build/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ fileName: fileName })
            });
    
            if (!resp.ok) {
                // Log the error status and message from the response
                const errorData = await resp.json();
                console.error(`Error ${resp.status}: ${errorData.error}`);
                throw new Error(`Error ${resp.status}: ${errorData.error}`);
            }
    
            const dataResp = await resp.json();
            return dataResp;
        } catch (error) {
            // Log any errors that occur during the fetch or JSON parsing
            console.error('Error in downloadLatestBuild:', error);
            throw error;  // Re-throw the error after logging it
        }
    }
    public static async downloadLatestBuildTest(fileName: string) {
        const resp = await fetch(`/v1/latest-build/download/test`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({fileName: fileName})
        });
        const dataResp = await resp.json();
       
        return dataResp;
    }
    public static async downloadLatestBuildTestV2(fileName: string) {
        const resp = await fetch(`/v1/latest-build/download/test/v2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({fileName: fileName})
        });
        const dataResp = await resp.json();
       
        return dataResp;
    }
    public static async downloadLatestBuildTestV3(fileName: string) {
        const resp = await fetch(`/v1/latest-build/download/test/v3`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({fileName: fileName})
        });
        const dataResp = await resp.json();
       
        return dataResp;
    }
}


