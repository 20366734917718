export enum QPointState
    {
        Deselected = "Deselected",
        Selected = "Selected",
        Animation = "Animation",
        Previewed = "Previewed",
        Clicked = 'Clicked'
    }

export enum ViewType
{
    Image = 'image',
    Link = 'link',
    Button = 'button',
    Label = 'label',
    List = 'list'
}