import { useEffect, useState, MouseEvent } from 'react';

import './users.scss';
import { UserRoleObjectDTO } from '../../components/dto/userRoleObject.dto';
import { VxAPI } from '../../api/vx.api';
import { UserDataDTO } from '../../components/dto/userData.dto';
import { TagObjectDTO } from '../../components/dto/tagObject.dto';
import { useUserData, useUserRoleData, useInstructorLessonsArrayData } from '../account/Account';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Users = () => {
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;
  const {instructorLessonsArrayData} = useInstructorLessonsArrayData();
  const [roleName, setRoleName] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  if (roleName === 'Student') {
    window.location.assign('/');
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loading, setLoading] = useState(true);
  const [usersObjectArrayData, setUsersObjectArrayData] = useState<UserDataDTO[]>([
    {
      userId: 0,
      customerId: 0,
      isSuperuser: false,
      isActive: false,
      isStaff: false,
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      userRole: 0,
      phone: '',
      level: '',
      location: '',
      trainingRole: 0,
      createdDate: new Date(),
      permissions: [0],
      tags: [0],
      courses: [0],
      classes: [0],
      dateOfBirth: new Date(),
    },
  ]);

  const [userRoleArray, setUserRoleArray] = useState<UserRoleObjectDTO[]>([
    {
      id: 0,
      title: '',
      description: '',
    },
  ]);

  const [tagsArray, setTagsArray] = useState<TagObjectDTO[]>([
    {
      tagId: 0,
      customerId: 0,
      createdDate: new Date(),
      title: '',
      description: '',
      type: '',
      sub1: '',
      sub2: '',
    },
  ]);

  const [searchText, setSearchText] = useState<string>('');

  const [updatedUsersArray, setUpdatedUsersArray] = useState<UserDataDTO[]>([
    {
      userId: 0,
      customerId: 0,
      isSuperuser: false,
      isActive: false,
      isStaff: false,
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      userRole: 0,
      phone: '',
      level: '',
      location: '',
      trainingRole: 0,
      createdDate: new Date(),
      permissions: [0],
      tags: [0],
      courses: [0],
      classes: [0],
      dateOfBirth: new Date(),
    },
  ]);

  const [studentsArray, setStudentsArray] = useState<UserDataDTO[]>();
  const [updatedStudentsArray, setUpdatedStudentsArray] = useState<UserDataDTO[]>();
  const [instructorLessonsIdArray, setInstructorLessonsIdArray] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await VxAPI.getAllUserData();

      if (userData) {
        const studentUsers = userData.filter((user: UserDataDTO) => user.userRole === 1);
        setStudentsArray(studentUsers);
        setUpdatedStudentsArray(studentUsers);
        setUsersObjectArrayData(userData);
        setUpdatedUsersArray(userData);
        setLoading(false);
      }

      const userRoleData = await VxAPI.getUserRoles();

      setUserRoleArray(userRoleData);

      const tagsData = await VxAPI.getAllTagsData();

      setTagsArray(tagsData);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userRoleName) {
      setRoleName(userRoleName ? userRoleName : '');
    }
  }, [userRoleName]);

  useEffect(() => {
    if (userRoleName) {
      setRoleName(userRoleName ? userRoleName : '');
    }
    if(userRoleName === 'Instructor') {
      if(instructorLessonsArrayData) {
        let lessonIds: number[] = []
        instructorLessonsArrayData.forEach(lessonData => {
          if(lessonData.lesson) {
            if(!lessonIds.includes(lessonData.lesson.lessonId as number)) {
              lessonIds.push(lessonData.lesson.lessonId as number);
            }
          }
        })
        setInstructorLessonsIdArray(lessonIds);
      }
    }
  }, [userRoleName, instructorLessonsArrayData]);

  // const handleMoreIconClick = (e: MouseEvent<SVGElement>) => {
  //   console.log('event', e.currentTarget);
  //   const userId = e.currentTarget.id.split('-').pop();
  //   console.log('userId', userId);

  //   // window.location.assign(`/users/edit/${userId}`);
  // };

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const userId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/users/edit/${userId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (searchText === '') {
      setUpdatedUsersArray(usersObjectArrayData);
      setUpdatedStudentsArray(studentsArray);
    }

    const updatedUsers = usersObjectArrayData.filter(
      (user) =>
        user.firstName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.lastName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase()?.includes(searchText.toLowerCase()),
    );

    const updatedStudents = studentsArray?.filter(
      (user) =>
        user.firstName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.lastName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase()?.includes(searchText.toLowerCase()),
    );

    setUpdatedUsersArray(updatedUsers);
    setUpdatedStudentsArray(updatedStudents);
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="users-div">
          <div id="users-top-header" className="row justify-space-btw">
            <h1>{userRoleName === 'Instructor' ? 'Students' : 'Users'}</h1>
            {userRoleName === 'Admin' ? (
              <div id="user-action">
                <input
                  id="search-text"
                  type="text"
                  placeholder="Search"
                  name="searchText"
                  onChange={handleSearch}
                ></input>
                <button id="add-user-btn" type="button" onClick={(e) => window.location.assign('/users/add')}>
                  <span>+Add User</span>
                </button>
              </div>
            ) : (
              <div id="user-action">
                <input
                  id="search-text"
                  type="text"
                  placeholder="Search"
                  name="searchText"
                  onChange={handleSearch}
                ></input>
              </div>
            )}
            <div id="users-titles">
              <h2 id="user-name-header">Name</h2>
              <div id="secondary-user-headers">
                <h2 id="user-role-header">Role</h2>
                <h2 id="user-metrics-header">Quiz Metrics?</h2>
                <h2 id="user-status-header">Status</h2>
                {userRoleName === 'Instructor' || userRoleName === 'Producer' ? (
                  <h2 style={{ display: 'none' }} id="user-more-header">
                    More
                  </h2>
                ) : (
                  <h2 id="user-more-header">More</h2>
                )}
              </div>
            </div>
          </div>
          <>
            {userRoleName === 'Instructor' || userRoleName === 'Producer' ? (
              <>
                {updatedStudentsArray
                  ? updatedStudentsArray
                      .sort((a: any, b: any) => {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.firstName > b.firstName) {
                          return 1;
                        }
                        return 0;
                      })
                      .slice(0, itemsToShow)
                      .map((user: UserDataDTO, index: number) => {
                        let hasMetrics = false;
                        const hasLesson = user.studentMetrics?.lessons;
                        if(userRoleName !== 'Instructor') {
                          if(hasLesson && hasLesson.length > 0) {
                            const hasQuiz = hasLesson[0].quizResults
                            if(hasQuiz && hasQuiz.length > 0) {
                              const hasResult = hasQuiz[0].guid? hasQuiz[0].guid : null;
                              if(hasResult) {
                                hasMetrics = true;
                              }
                            }
                          }
                        } else {
                          hasLesson?.forEach(lesson => {
                            if(instructorLessonsIdArray.includes(lesson.lessonId)) {
                              const hasQuiz = lesson.quizResults
                              if(hasQuiz && hasQuiz.length > 0) {
                                const hasResult = hasQuiz[0].guid? hasQuiz[0].guid : null;
                                if(hasResult) {
                                  hasMetrics = true;
                                }
                              }
                            }
                          })
                        }
                        return (
                          <>
                            <div
                              id="user-info-div"
                              className="select-div"
                              onClick={() => window.location.assign(`/students/${user.userId}/view/lessons`)}
                              key={index}
                            >
                              <div id="user-name">
                                <p>
                                  {user.firstName} {user.lastName}
                                </p>
                                <p id="user-email"> {user.email}</p>
                              </div>
                              <div id="secondary-user-data">
                                <p id="user-role">
                                  {userRoleArray.find((role) => role.id === user.userRole)?.title || '-'}
                                </p>
                                <div id="user-metrics">
                                  <div className={hasMetrics ? 'user-hasMetrics-icon' : 'user-noMetrics-icon'}>
                                    {hasMetrics ? (
                                      <FontAwesomeIcon icon={faCheck} />
                                    ) : (
                                      <FontAwesomeIcon icon={faXmark} />
                                    )}
                                  </div>
                                </div>
                                {user.isActive ? (
                                  <div id="user-status">
                                    <div id="active-cir"></div>
                                    <p>Active</p>
                                  </div>
                                ) : (
                                  <div id="user-status">
                                    <div id="invited-cir"></div>
                                    <p>Invited</p>
                                  </div>
                                )}
                                <div style={{ display: 'none' }} id="user-more">
                                  <button
                                    type="button"
                                    id={`edit-user-btn-${user.userId}`}
                                    className="edit-btn"
                                    onClick={handleEditBtnClick}
                                  >
                                    <span>Edit</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="user-line"></div>
                          </>
                        );
                      })
                  : null}
              </>
            ) : userRoleName !== 'Instructor' ? (
              <>
                {updatedUsersArray
                  ? updatedUsersArray
                      .sort((a: any, b: any) => {
                        if (a.firstName < b.firstName) {
                          return -1;
                        }
                        if (a.firstName > b.firstName) {
                          return 1;
                        }
                        return 0;
                      })
                      .slice(0, itemsToShow)
                      .map((user: UserDataDTO, index: number) => {
                        let hasMetrics = false;
                        const hasLesson = user.studentMetrics?.lessons;
                        if(hasLesson && hasLesson.length > 0) {
                          const hasQuiz = hasLesson[0].quizResults
                          if(hasQuiz && hasQuiz.length > 0) {
                            const hasResult = hasQuiz[0].guid? hasQuiz[0].guid : null;
                            if(hasResult) {
                              hasMetrics = true;
                            }
                          }
                        }
                        const userRoleTitle = userRoleArray.find((role) => role.id === user.userRole)?.title;
                        return (
                          <>
                            <div id="user-info-div" key={index}>
                              <div
                                id="user-name"
                                className={user.userRole === 1 ? 'select-div' : undefined}
                                onClick={
                                  user.userRole === 1
                                    ? () => window.location.assign(`/students/${user.userId}/view/lessons`)
                                    : undefined
                                }
                              >
                                <p>
                                  {user.firstName} {user.lastName}
                                </p>
                                <p id="user-email"> {user.email}</p>
                              </div>
                              <div id="secondary-user-data">
                                <p id="user-role">{userRoleTitle}</p>
                                <div id="user-metrics">
                                  <div
                                    className={
                                      userRoleTitle !== 'Student'
                                        ? 'none'
                                        : hasMetrics
                                        ? 'user-hasMetrics-icon'
                                        : 'user-noMetrics-icon'
                                    }
                                  >
                                    {hasMetrics && userRoleTitle === 'Student' ? (
                                      <FontAwesomeIcon icon={faCheck} />
                                    ) : !hasMetrics && userRoleTitle === 'Student' ? (
                                      <FontAwesomeIcon icon={faXmark} />
                                    ) : null}
                                  </div>
                                </div>
                                {user.isActive ? (
                                  <div id="user-status">
                                    <div id="active-cir"></div>
                                    <p>Active</p>
                                  </div>
                                ) : (
                                  <div id="user-status">
                                    <div id="invited-cir"></div>
                                    <p>Invited</p>
                                  </div>
                                )}
                                <div
                                  id="user-more"
                                  aria-controls={open ? `demo-positioned-menu-${user.userId}` : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClick}
                                >
                                  <button
                                    type="button"
                                    id={`edit-user-btn-${user.userId}`}
                                    className="edit-btn"
                                    onClick={handleEditBtnClick}
                                  >
                                    <span>Edit</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="user-line"></div>
                          </>
                        );
                      })
                  : null}
              </>
            ) : null}
          </>
          <>
            {(!expanded && updatedUsersArray.length <= 10) ||
            (!expanded &&
              userRoleName !== 'Admin' &&
              updatedStudentsArray?.length &&
              updatedStudentsArray.length <= 10) ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedUsersArray.length > itemsToShow ||
              (updatedStudentsArray?.length && updatedStudentsArray.length > itemsToShow) ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Users;
