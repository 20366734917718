import { useState, useEffect } from "react";
import './contactInfo.scss';
import { VxAPI } from "../../api/vx.api";
import { useUserData, useUserRoleData } from "../../pages/account/Account";
import { ContactInfoDTO } from "../../pages/dto/contact-info-dto";
import { validateEmail } from "../../utils/helpers";
import RemoveHeadsetWords from "../removeHeadsetWords/RemoveHeadsetWords";
import UpdatePw from "../updatePw/UpdatePw";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const ContactInfo = () => {

    const [editContactInfo, setEditContactInfo] = useState<boolean>(false);
    const [contactInfo, setContactInfo] = useState<ContactInfoDTO>({
        email: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: ''
    })
    const [message, setMessage] = useState('');

    const { userData } = useUserData();

    const { userRoleData } = useUserRoleData();

    useEffect(() => {
        if (userData) {
            setContactInfo(userData);
        }
    }, [userData])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage('');
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    }

    const handleContactInfoSubmit = async (e: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {
        e.preventDefault();

        if (userData) {
            const { userId } = userData;

            const {
                email,
                address,
                address2,
                city,
                state,
                zip,
                phone
            } = contactInfo

            const validEmail = validateEmail(email);

            if (!validEmail) {
                setMessage('Please enter a valid email submitting')
                return;
            }

            const resp = await VxAPI.updateUser({
                userId,
                email,
                address,
                address2,
                city,
                state,
                zip,
                phone
            })

            if (resp) {
                window.location.reload();
            }
        }
    }

    return(
        <>
        <div id='bio-div'>
            <div id='bio-img'>
                <span><AccountCircleIcon fontSize='large' /><br /></span>
            </div>
            <div id='bio-name'>
                <h1>{userData?.firstName} {userData?.lastName}</h1>
                <h2>{userRoleData?.title}</h2>
            </div>
        </div>
        {!editContactInfo ? (
            <div id='contact-info'>
                <div className="row flex-dir-col">
                    <h3>Email</h3>
                    <p>{userData?.email}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>Phone</h3>
                    <p>{userData?.phone === null ? '-' : userData?.phone}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>Mailing Address Line 1</h3>
                    <p>{userData?.address === null ? '-' : userData?.address}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>Mailing Address Line 2</h3>
                    <p>{userData?.address2 === null ? '-' : userData?.address2}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>City</h3>
                    <p>{userData?.city === null ? '-' : userData?.city}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>State</h3>
                    <p>{userData?.state === null ? '-' : userData?.state}</p>
                </div>
                <div className="row flex-dir-col">
                    <h3>Zip</h3>
                    <p>{userData?.zip === null ? '-' : userData?.zip}</p>
                </div>
                <div id='edit-btn-div' className="row justify-center">
                    <button type="button" onClick={() => setEditContactInfo(true)}><span>Edit Profile</span></button>
                </div>
            </div>
        ) : (
            <form id='edit-contact-info' onSubmit={handleContactInfoSubmit}>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='email'>Email</label>
                    <p className='contact-input'>{userData?.email}</p>
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='phone'>Phone</label>
                    <input className='contact-input' type='phone' name='phone' defaultValue={userData?.phone ? userData.phone : '-'} onChange={handleChange} />
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='address'>Mailing Address Line 1</label>
                    <input className='contact-input' type='address' name='address' defaultValue={userData?.address ? userData.address : '-'} onChange={handleChange} />
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='address2'>Mailing Address Line 2</label>
                    <input className='contact-input' type='address2' name='address2' defaultValue={userData?.address2 ? userData.address2 : '-'} onChange={handleChange} />
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='city'>City</label>
                    <input className='contact-input' type='city' name='city' defaultValue={userData?.city ? userData.city : '-'} onChange={handleChange} />
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='state'>State</label>
                    <input className='contact-input' type='state' name='state' defaultValue={userData?.state ? userData.state : '-'} onChange={handleChange} />
                </div>
                <div className='row flex-dir-col'>
                    <label className='contact-label' htmlFor='zip'>Zip</label>
                    <input className='contact-input' type='zip' name='zip' defaultValue={userData?.zip ? userData.zip : '-'} onChange={handleChange} />
                </div>
                {message ? (
                    <div className='row message-div'>
                        <p className='message'>{message}</p>
                    </div>
                ) : null}
                <div id='edit-contact-btns' className='row'>
                    <button id='edit-contact-cancel' type='button' onClick={() => setEditContactInfo(false)}><span>Cancel</span></button>
                    <button id='edit-contact-save' type="submit"><span>Confirm</span></button>
                </div>
            </form>
        )}
        <UpdatePw />
        <RemoveHeadsetWords />
    </>
    )
}

export default ContactInfo;