import { useEffect, useState } from "react";
import EditTagForm from "../../components/editTagForm/EditTagForm";

import { useUserData, useUserRoleData } from "../account/Account";

const EditTag = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;
    const userCustomerId = userData?.customerId
    useEffect(() => {
        if (userRoleName && userRoleName !== '') {
          if (userRoleName !== 'Admin') {
            window.location.assign('/');
          }
        }
      }, [userRoleName]);

    return (
        <>
            <EditTagForm
                userCustomerId={userCustomerId}
            />
        </>
    )
}

export default EditTag;