import { VxAPI } from '../../api/vx.api';
import qiLogo from '../../assets/images/qi_logo.png';
import { DashboardToHeaderProps } from '../dto/dashboardToHeaderProps.dto';
import './header.scss';
import Auth from '../../utils/auth';
import React from 'react';

const Header: React.FC<DashboardToHeaderProps> = (props) => {
  const loggedIn = Auth.loggedIn();
  
  const {
    firstName,
    lastName,
    userRole,
    customerName,
    superUser,
    customerId,
    customers,
    userId,
    email,
    userRoles,
    userRoleId,
  } = props;

  const handleCompanyChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updateUserCompanyResp = await VxAPI.editUser({ customerId: Number(e.target.value) }, userId);

    if (!updateUserCompanyResp.userId) return;
    const newTokenResp = await VxAPI.updateSuperUserToken(email as string);
    if (!newTokenResp.token) return;
    Auth.updateToken(newTokenResp.token);
    window.location.assign('/');
  };

  const handleRoleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updateUserRoleResp = await VxAPI.editUser({ userRole: Number(e.target.value) }, userId);
    if (!updateUserRoleResp.userId) return;
    window.location.assign('/');
  };

  const handleProfileNav = async () => {
    window.location.assign(`/profile/${userId}`);
  };

  return (
    <header>
      <img src={qiLogo} alt="Quantum Interface Logo."></img>
      <div id="account-info" style={{display: loggedIn ? 'flex' : 'none'}}>
        <div id="account-name">
          <h1>
            {firstName} {lastName}
          </h1>
          <div className={superUser ? 'superUser-dropdowns' : ''}>
            {superUser ? (
              <select name="userRole" onChange={handleRoleChange}>
                <option value={userRoleId}>{userRole}</option>
                {userRoles?.map((role) => {
                  if (role.id === userRoleId) return;
                  return <option value={role.id}>{role.title}</option>;
                })}
              </select>
            ) : (
              <h2>{userRole}</h2>
            )}
            {superUser ? (
              <select name="customerId" onChange={handleCompanyChange}>
                <option value={customerId}>{customerName}</option>
                {customers?.map((customer) => {
                  if (customer.customerId === customerId) return;
                  return (
                    <option value={customer.customerId}>
                      {customer.firstName} {customer.lastName}
                    </option>
                  );
                })}
              </select>
            ) : (
              <h2>{customerName}</h2>
            )}
          </div>
        </div>
        <div id="avatar">
          <div id="avatar-head"></div>
          <div id="avatar-body"></div>
          <div id='avatar-dd'>
            <p onClick={handleProfileNav}>Profile</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
