import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './editLessonForm.scss';
import { LessonObjectDTO } from '../dto/lessonObject.dto';
import { ModuleObjectDTO } from '../dto/moduleObject.dto';
import { CourseObjectDTO } from '../dto/courseObject.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';

const EditLessonForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId } = props;
  const params = useParams();
  const paramsLessonId = Number(params.lessonId);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [lessonInfo, setLessonInfo] = useState<LessonObjectDTO>({
    lessonId: 0,
    moduleId: 0,
    title: '',
    description: '',
    notes: '',
    tags: [0],
    principals: [0],
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [instructors, setInstructors] = useState<UserObjectDTO[]>();

  const [selectInstructorState, setSelectInstructorState] = useState<(number | undefined)[]>([]);
  const [currentlySelectedInstructorIds, setCurrentlySelectedInstructorIds] = useState<(number | undefined)[]>([]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>();
  const [availableTags, setAvailableTags] = useState<TagObjectDTO[]>();

  const [lessonsObjectWithUseCaseNames, setLessonsObjectWithUseCaseNames] = useState<LessonObjectDTO>();

  const [moduleObjectArrayData, setModuleObjectArrayData] = useState<ModuleObjectDTO[]>();

  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>();

  const [selectTagState, setSelectTagState] = useState<(number)[]>([]);
  const [currentlySelectedTagIds, setCurrentlySelectedTagIds] = useState<(number)[]>([]);
  const [lessonUseCaseId, setLessonUseCaseId] = useState<string | number | undefined>();
  const { lessonId, moduleId, title, description, notes, tags, principals } = lessonInfo;


  const handleTagSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newTags = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newTags.push(Number(selectedOptions[i].value));
    }

    setSelectTagState(newTags);
  };

  const handleInstructorSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newInstructors = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newInstructors.push(Number(selectedOptions[i].value));
    }

    setSelectInstructorState(newInstructors);
  };

  const handleTagReset = () => {
    setSelectTagState(currentlySelectedTagIds);
  };

  const handleInstructorReset = () => {
    setSelectInstructorState(currentlySelectedInstructorIds);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setLessonInfo({ ...lessonInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (title === '' || description === '') {
      setMessage('Make sure all required fields are completed.');
      return;
    }
    let tags: (number)[] = selectTagState;
    if(selectTagState?.length > 0) {
      selectTagState.forEach(tagId => {
        const foundTag = tagArray?.find(tag => tag.tagId === tagId);
        const tagType = tagArray?.find(tag => tag.title === foundTag?.type)?.tagId
        const tagSub1 = tagArray?.find(tag => tag.title === foundTag?.sub1)?.tagId
        const tagSub2 = tagArray?.find(tag => tag.title === foundTag?.sub2)?.tagId
        if(tagType && !tags.includes(tagType)){tags.push(tagType)};
        if(tagSub1 && !tags.includes(tagSub1)){tags.push(tagSub1)};
        if(tagSub2 && !tags.includes(tagSub2)){tags.push(tagSub2)};
      })
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let modifiedDate = currentDate;

    setMessage('Editing...');
    const resp = await VxAPI.editLesson(
      {
        title,
        description,
        notes,
        tags: selectTagState,
        principals: selectInstructorState,
        modifiedDate,
      },
      paramsLessonId,
    );

    if (resp.lessonId) {
      window.location.assign('/lessons');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const lessonData = await VxAPI.getSingleLessonById(paramsLessonId);

      if (lessonData) {
        setLessonInfo(lessonData);
        if (lessonData.tags && lessonData.tags[0] !== 0) {
          setSelectTagState(lessonData.tags);
          setCurrentlySelectedTagIds(lessonData.tags);
        }
        if (lessonData.principals && lessonData.principals[0] !== 0) {
          setSelectInstructorState(lessonData.principals);
          setCurrentlySelectedInstructorIds(lessonData.principals);
        }
      }

      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }

      const modulesData = await VxAPI.getAllModulesData();

      if (modulesData) {
        setModuleObjectArrayData(modulesData);
      }

      const coursesData = await VxAPI.getAllCoursesData();

      if (coursesData) {
        setCourseObjectArrayData(coursesData);
      }

      const tagsData = await VxAPI.getAllTagsData();

      if (tagsData) {
        setTagArray(tagsData);
        setAvailableTags(tagsData);
      }

      const instructorRole = 2;
      const instructorData = await VxAPI.getUserByRoleByCustomerId(instructorRole);
      if (instructorData) {
        setInstructors(instructorData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (
      useCaseArray[0].useCaseId !== 0 &&
      courseObjectArrayData &&
      moduleObjectArrayData &&
      lessonInfo.lessonId !== 0
    ) {
      let course = courseObjectArrayData.find((course) => {
        let module = moduleObjectArrayData.find((module) => module.moduleId === lessonInfo.moduleId);
        return course.courseId === module?.courseId;
      });
      let courseId = course?.courseId;
      let customerId = course?.customerId;
      let courseUseCaseId = courseObjectArrayData?.find((course) => course.courseId === courseId)?.useCaseId;
      let customerFirstName = customerArray?.find((customer) => customer.customerId === customerId)?.firstName;
      let customerLastName = customerArray?.find((customer) => customer.customerId === customerId)?.lastName;
      let useCaseName = useCaseArray.find((useCase) => useCase.useCaseId === courseUseCaseId)?.title;
      let moduleName = moduleObjectArrayData.find((module) => module.moduleId === lessonInfo.moduleId)?.title;
      lessonInfo.moduleTitle = moduleName;
      lessonInfo.useCaseTitle = useCaseName;
      lessonInfo.customerFirstName = customerFirstName;
      lessonInfo.customerLastName = customerLastName;
      setLessonUseCaseId(course?.useCaseId);
      setLessonsObjectWithUseCaseNames(lessonInfo);
      setLoading(false);
    }
  }, [lessonInfo, useCaseArray, courseObjectArrayData, moduleObjectArrayData]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="edit-lesson-div">
          <h1>Edit Lesson</h1>
          <form onSubmit={handleSubmit}>
            <div className="dropdown-div">
              <div className="edit-lesson-hardcode">
                <label htmlFor="name">Customer</label>
                <p id="lesson-cust-name">
                  {lessonsObjectWithUseCaseNames?.customerFirstName} {lessonsObjectWithUseCaseNames?.customerLastName}
                </p>
              </div>
              <div className="edit-lesson-hardcode">
                <label htmlFor="useCaseId">Use Case</label>
                <p id="lesson-cust-useCase">
                  {/* {useCaseArray.find((useCase) => useCase.useCaseId === lessonInfo.courseInfo?.useCaseId)?.title} */}
                  {lessonsObjectWithUseCaseNames?.useCaseTitle}
                </p>
              </div>
            </div>
            <div className="edit-lesson-row">
              <div className="edit-lesson-form-input">
                <label htmlFor="title">Title *</label>
                <input type="text" name="title" defaultValue={title as string} onChange={handleChange} />
              </div>
              <div className="edit-lesson-hardcode">
                <label htmlFor="Course Name">Module Name</label>
                <p id="lesson-cust-course">{lessonsObjectWithUseCaseNames?.moduleTitle}</p>
              </div>
            </div>
            <div className="edit-lesson-form-textarea">
              <label htmlFor="description">Description *</label>
              <textarea name="description" defaultValue={description as string} onChange={handleChange} />
            </div>
            <div className="edit-lesson-form-textarea">
              <label htmlFor="notes">Notes</label>
              <textarea name="notes" defaultValue={notes} onChange={handleChange} />
            </div>
            <div id="selection-divs">
              <div className="add-tag-dropdown">
                <label id="tag-dropdown-header" htmlFor="selectTagState[]">
                  Edit Tags
                </label>
                <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={availableTags?.length} name="tagArray" onChange={handleTagSelection}>
                  {availableTags
                    ? availableTags
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag, index: number) => (
                        <option
                          {...(selectTagState.includes(Number(tag.tagId)) ? { selected: true } : null)}
                          key={index}
                          value={tag.tagId}
                        >
                          {tag.title}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleTagReset}>
                  <span>Reset</span>
                </button>
                <div id="tag-list-div">
                  <h3>Selected Tags (Count: {selectTagState.length})</h3>
                  <ul id="tag-list">
                    {selectTagState &&
                      selectTagState
                      .sort((a: any, b: any) => {
                        if (a.title < b.title) {
                          return -1;
                        }
                        if (a.title > b.title) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((selectedTag) => {
                        let foundTag = availableTags?.find((tag) => tag.tagId === selectedTag);
                        return <li>{foundTag?.title}</li>;
                      })}
                  </ul>
                </div>
              </div>
              <div className="add-instructor-dropdown">
                <label id="instructor-dropdown-header" htmlFor="selectInstructorState[]">
                  Edit Instructors
                </label>
                <h2 id="instructor-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={instructors?.length} name="instructorArray" onChange={handleInstructorSelection}>
                  {instructors
                    ? instructors
                    .sort((a: any, b: any) => {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((instructor, index: number) => (
                        <option
                          {...(selectInstructorState.includes(Number(instructor.userId)) ? { selected: true } : null)}
                          key={index}
                          value={instructor.userId}
                        >
                          {instructor.firstName} {instructor.lastName}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleInstructorReset}>
                  <span>Reset</span>
                </button>
                <div id="instructor-list-div">
                  <h3>Selected Instructors (Count: {selectInstructorState.length})</h3>
                  <ul id="instructor-list">
                    {selectInstructorState &&
                      selectInstructorState.map((selectedInstructor) => {
                        let foundInstructor = instructors?.find(
                          (instructor) => instructor.userId === selectedInstructor,
                        );
                        return (
                          <li>
                            {foundInstructor?.firstName} {foundInstructor?.lastName}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div id="edit-lesson-btns">
              <button type="button" onClick={() => window.location.assign('/lessons')}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Confirm</span>
              </button>
            </div>
          </form>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default EditLessonForm;
