import AddUseCaseForm from "../../components/addUseCaseForm/AddUseCaseForm";
import { useUserData } from "../account/Account";

const AddUseCases = () => {

    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;
    
    if(superUser === false) {
        window.location.assign('/')
    }

    return(
        <>
        <AddUseCaseForm/>
        </>
    ) 
}

export default AddUseCases;