import { useEffect, useState, MouseEvent } from 'react';

import './courses.scss';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import Auth from '../../utils/auth';

const Courses = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();

  const [loading, setLoading] = useState(true);
  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>([
    {
      useCaseId: 0,
      customerId: 0,
      courseId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      tags: [0],
    },
  ]);

  const [coursesObjectArrayWithUseCaseNames, setCoursesObjectArrayWithUseCaseNames] = useState<CourseObjectDTO[]>();

  const [updatedCoursesArray, setUpdatedCoursesArray] = useState<CourseObjectDTO[]>([
    {
      useCaseId: '',
      customerId: 0,
      courseId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      tags: [0],
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const courseData = await VxAPI.getAllCoursesData();
      if (courseData) {
        setCourseObjectArrayData(courseData);
        setLoading(false);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      const useCaseData = await VxAPI.getAllUseCasesData();

      setUseCasesObjectArrayData(useCaseData);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName !== 'Admin') {
        window.location.assign('/');
      }
    }

    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const courseId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/courses/edit/${courseId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (useCasesObjectArrayData[0].useCaseId !== 0) {
      const coursesWithUseCaseName = courseObjectArrayData.map((course) => {
        course.useCaseId = useCasesObjectArrayData.find((useCase) => useCase.useCaseId === course.useCaseId)?.title;
        return course;
      });
      setCoursesObjectArrayWithUseCaseNames(coursesWithUseCaseName);
      setUpdatedCoursesArray(coursesWithUseCaseName);
      setLoading(false);
    }
  }, [courseObjectArrayData, useCasesObjectArrayData]);

  useEffect(() => {
    if (coursesObjectArrayWithUseCaseNames) {
      if (searchText === '') {
        setUpdatedCoursesArray(coursesObjectArrayWithUseCaseNames);
      }

      const updatedCourses = coursesObjectArrayWithUseCaseNames?.filter(
        (course) =>
          course.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          course.useCaseId?.toString().toLowerCase()?.includes(searchText.toLowerCase()),
      );

      setUpdatedCoursesArray(updatedCourses);
    }
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="courses-div">
          <div className="row justify-space-btw">
            <h1>Courses</h1>
            <div id="course-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button
                id="add-course-btn"
                type="button"
                onClick={() => window.location.assign('/courses/add')}
              >
                <span>+Add Course</span>
              </button>
            </div>
            <div id="courses-titles">
              <h2 id="course-name-header">Course Name</h2>
              <div id="secondary-course-headers" className="row">
                <h2 id="course-cust-header">Customer</h2>
                <h2 id="course-useCase-header">Use Case</h2>
                <h2 id="course-desc-header">Description</h2>
                <h2 id="course-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedCoursesArray
            ? updatedCoursesArray
            .sort((a: any, b: any) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            })
            .slice(0, itemsToShow).map((courses: CourseObjectDTO, index: number) => (
                <>
                  <div id="course-info-div" key={index}>
                    <div id="course-name">
                      <p>{courses.title}</p>
                    </div>
                    <div id="secondary-course-data">
                      <p id="course-cust">
                        {
                          customersObjectArrayData.find((customer) => customer.customerId === courses.customerId)
                            ?.firstName
                        }{' '}
                        {
                          customersObjectArrayData.find((customer) => customer.customerId === courses.customerId)
                            ?.lastName
                        }
                      </p>
                      <p id="course-useCase">
                        {courses.useCaseId}
                      </p>
                      <p id="course-desc">{courses.description}</p>
                      <div id="course-more">
                        <button
                          type="button"
                          id={`edit-course-btn-${courses.courseId}`}
                          className="edit-btn"
                          onClick={handleEditBtnClick}
                        >
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="course-line"></div>
                </>
              ))
            : null}
          <>
            {!expanded && updatedCoursesArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedCoursesArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Courses;
