import { useState, useEffect } from "react";
import EditUserForm from "../../components/editUserForm/EditUserForm";
import { useUserData, useUserRoleData } from "../account/Account";

const EditUser = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const userCustomerId = userData?.customerId

    const [roleName, setRoleName] = useState<string>('')

    if (roleName === 'Student' || roleName === 'Instructor') {
        window.location.assign('/')
    }

    useEffect(() => {
        if (userRoleName) {
            setRoleName(userRoleName ? userRoleName : '')
        }
    }, [userRoleName])

    return (
        <>
            <EditUserForm
                userCustomerId={userCustomerId}
            />
        </>
    )
}

export default EditUser;