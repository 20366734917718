import EditCustomerForm from "../../components/editCustomerForm/EditCustomerForm";
import { useUserData } from "../account/Account";

const EditCustomer = () => {

    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;

    if (superUser === false) {
        window.location.assign('/')
    }

    return (
        <>
            <EditCustomerForm />
        </>
    )
}

export default EditCustomer;