import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import './editTagForm.scss';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const EditTagForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId } = props;

  const navigate = useNavigate();
  const params = useParams();
  const paramsTagId = Number(params.tagId);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);
  const [originalTagLevel, setOriginalTagLevel] = useState<string>();
  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>();
  // const [tier1Tags, setTier1Tags] = useState<TagObjectDTO[]>([]);
  // const [tier2Tags, setTier2Tags] = useState<TagObjectDTO[]>([]);
  // const [tier3Tags, setTier3Tags] = useState<TagObjectDTO[]>([]);
  const [currentTier1Selection, setCurrentTier1Selection] = useState<string>();
  const [tagInfo, setTagInfo] = useState({
    title: '',
    description: '',
    type: '',
    sub1: '',
    sub2: '',
    customerId: 0,
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);

  const { title, description, type, sub1, sub2, customerId } = tagInfo;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    switch(originalTagLevel)
    {
      case 'Tier 1':
        if(e.target.name === 'title')
        {
          setTagInfo({ ...tagInfo, [e.target.name]: e.target.value, type: e.target.value });
          return;
        }
        setTagInfo({ ...tagInfo, [e.target.name]: e.target.value});
        break;
      case 'Tier 2':
        if(e.target.name === 'title')
        {
          setTagInfo({ ...tagInfo, [e.target.name]: e.target.value, sub1: e.target.value });
          return;
        }
        setTagInfo({ ...tagInfo, [e.target.name]: e.target.value});
        break;
      case 'Tier 3':
        if(e.target.name === 'title')
        {
          setTagInfo({ ...tagInfo, [e.target.name]: e.target.value, sub2: e.target.value });
          return;
        }
        setTagInfo({ ...tagInfo, [e.target.name]: e.target.value});
        break;
    }
    
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (title === '' || type === '' || customerId === undefined || customerId === 0) {
      setMessage('Make sure all required fields are completed.');
      return;
    }

    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let modifiedDate = currentDate;

    switch (originalTagLevel) {
      case 'Tier 1':
        const respTier1 = await VxAPI.editTag(
          {
            title: type,
            description,
            type,
            sub1,
            sub2,
            modifiedDate,
          },
          paramsTagId,
        );

        if (respTier1.tagId) {
          window.location.assign('/tagsTable');
        } else {
          setMessage('An error has occured. Please try again. If error persists contact your administrator.');
        }
        break;
      case 'Tier 2':
        const respTier2 = await VxAPI.editTag(
          {
            title: sub1,
            description,
            type,
            sub1,
            sub2,
            modifiedDate,
          },
          paramsTagId,
        );

        if (respTier2.tagId) {
          window.location.assign('/tagsTable');
        } else {
          setMessage('An error has occured. Please try again. If error persists contact your administrator.');
        }
        break;
      case 'Tier 3':
        const respTier3 = await VxAPI.editTag(
          {
            title: sub2,
            description,
            type,
            sub1,
            sub2,
            modifiedDate,
          },
          paramsTagId,
        );

        if (respTier3.tagId) {
          window.location.assign('/tagsTable');
        } else {
          setMessage('An error has occured. Please try again. If error persists contact your administrator.');
        }
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const tagData = await VxAPI.getSingleTagData(paramsTagId);

      if (tagData) {
        setTagInfo(tagData);
        if (tagData.sub2) {
          setOriginalTagLevel('Tier 3');
        }
        if (tagData.sub1 && !tagData.sub2) {
          setOriginalTagLevel('Tier 2');
        }
        if (tagData.type && !tagData.sub1 && !tagData.sub2) {
          setOriginalTagLevel('Tier 1');
        }
        const tagArrayData = await VxAPI.getAllTagsData();
        if (tagArrayData) {
          setTagObjectArrayData(tagArrayData);
        }
        setLoading(false);
      }

      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setLoggedInUserCustomerId(userCustomerId);
    if (userCustomerId !== null && tagInfo.customerId !== 0) {
      if (tagInfo.customerId !== userCustomerId) {
        window.location.assign('/');
      }
    }
  }, [userCustomerId, tagInfo]);

  // useEffect(() => {
  //   if (tagObjectArrayData) {
  //     let filteredTier1Tags: TagObjectDTO[] = [];
  //     let filteredTier2Tags: TagObjectDTO[] = [];
  //     let filteredTier3Tags: TagObjectDTO[] = [];
  //     tagObjectArrayData.forEach((tag) => {
  //       if (tag.type && !tag.sub1 && !tag.sub2) {
  //         filteredTier1Tags.push(tag);
  //       } else if (tag.type && tag.sub1 && !tag.sub2) {
  //         filteredTier2Tags.push(tag);
  //       } else {
  //         filteredTier3Tags.push(tag);
  //       }
  //     });
  //     setTier1Tags(filteredTier1Tags);
  //     setTier2Tags(filteredTier2Tags);
  //     setTier3Tags(filteredTier3Tags);
  //   }
  // }, [tagObjectArrayData]);

  // const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setTagInfo({ ...tagInfo, [e.target.name]: e.target.value });
  //   if (e.target.name === 'type' && originalTagLevel === 'Tier 3') {
  //     setCurrentTier1Selection(e.target.value);
  //   }
  // };

  useEffect(() => {
    if (!currentTier1Selection) return;
    const sub1SelectionEl = document.getElementById('sub1-selection') as HTMLSelectElement;
    setTagInfo({ ...tagInfo, sub1: sub1SelectionEl.value });
  }, [currentTier1Selection]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="edit-tag-div">
          <h1>Edit Tag</h1>
          <form onSubmit={handleSubmit}>
            <div className="dropdown-div">
              <div className="edit-tag-cust">
                <label htmlFor="name">Customer</label>
                <p id="cust-name">
                  {customerArray.find((customer) => customer.customerId === tagInfo.customerId)?.firstName}{' '}
                  {customerArray.find((customer) => customer.customerId === tagInfo.customerId)?.lastName}
                </p>
              </div>
              <div id="edit-tag-tier">
                <label htmlFor="tagLevel">Tag Level</label>
                <p>{originalTagLevel}</p>
              </div>
            </div>
            <div className="edit-tag-form-input">
              <label htmlFor="title">Tag Name *</label>
              <input type='text' name='title' defaultValue={title} onChange={handleChange} />
            </div>
            <div className="edit-tag-form-textarea">
              <label htmlFor="description">Description</label>
              <textarea name="description" defaultValue={description} onChange={handleChange} />
            </div>
            {/* <div className="edit-tag-row">
              {originalTagLevel === 'Tier 1' ? (
                <div className={originalTagLevel === 'Tier 1' ? 'edit-tag-form-input' : 'edit-tag-dropdown'}>
                  <label htmlFor="type">Tier 1 Name</label>
                  <input type="text" name="type" defaultValue={type} onChange={handleChange} />
                </div>
              ) : // <>
              //   <select
              //     name="type"
              //     onChange={handleSelection}
              //     defaultValue={tier1Tags.find((tag) => tag.title === tagInfo.type)?.title}
              //     id={`type-selection`}
              //   >
              //     {tier1Tags
              //       ? tier1Tags.map((tag: TagObjectDTO, index: number) => {
              //           const hasChildren = !!tier2Tags.find(childTag => childTag.type === tag.type);
              //           if(!hasChildren && originalTagLevel === 'Tier 3')return;
              //           return (
              //             <option key={`tag-${index}`} value={tag.title}>
              //               {tag.title}
              //             </option>
              //           );
              //         })
              //       : null}
              //   </select>
              // </>
              null}
              {originalTagLevel === 'Tier 2' ? (
                <>
                  <div className={originalTagLevel === 'Tier 2' ? 'edit-tag-form-input' : 'edit-tag-dropdown'}>
                    <label htmlFor="sub1">Tier 2 Name</label>
                    <input type="text" name="sub1" defaultValue={sub1} onChange={handleChange} />
                  </div>
                </>
              ) : // <>
              //   <select
              //     name="sub1"
              //     onChange={handleSelection}
              //     defaultValue={tier2Tags.find((tag) => tag.title === tagInfo.sub1)?.title}
              //     id="sub1-selection"
              //   >
              //     {tier2Tags
              //       ? tier2Tags
              //           .filter((tag) => tag.type === tagInfo.type)
              //           .map((tag: TagObjectDTO, index: number) => (
              //             <option key={`tag-${index}`} value={tag.title}>
              //               {tag.title}
              //             </option>
              //           ))
              //       : null}
              //   </select>
              // </>
              null}
              <div
                className="edit-tag-form-input"
                style={{ display: originalTagLevel === 'Tier 3' ? 'block' : 'none' }}
              >
                <label htmlFor="sub2">Tier 3 Name</label>
                <input type="text" name="sub2" defaultValue={sub2} onChange={handleChange} />
              </div>
            </div> */}
            <div id="edit-tag-btns">
              <button type="button" onClick={() => navigate(-1)}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Save</span>
              </button>
            </div>
          </form>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default EditTagForm;
