import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './addClassForm.scss';
import { AddClassSelectStateDTO } from '../dto/addClass.selectState.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const AddClassForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId, loggedInId } = props;
  const [message, setMessage] = useState('');
  const [selectState, setSelectState] = useState<AddClassSelectStateDTO>({
    customerId: undefined,
    useCaseId: undefined,
  });
  const [selectTagsState, setSelectTagsState] = useState<(number | undefined)[]>([]);
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    notes: '',
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const [students, setStudents] = useState<UserObjectDTO[]>();
  const [selectStudentState, setSelectStudentState] = useState<(number | undefined)[]>([]);

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value), customerId: userCustomerId });
  };

  const handleStudentSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newStudents = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newStudents.push(Number(selectedOptions[i].value));
    }

    setSelectStudentState(newStudents);
  };

  const { customerId, useCaseId } = selectState;
  const { title, description, notes } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (
      title === '' ||
      description === '' ||
      customerId === undefined ||
      customerId === 0 ||
      useCaseId === undefined ||
      useCaseId === 0
    ) {
      setMessage('Make sure all required fields are completed.');
      return;
    }

    const creator = loggedInId;
    let tags: (number | undefined)[] = selectTagsState;
    if (selectTagsState?.length > 0) {
      selectTagsState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let createdDate = currentDate;

    const resp = await VxAPI.addClass({
      customerId,
      useCaseId,
      title,
      description,
      notes,
      creator,
      tags,
      createdDate,
    });

    if (resp.classId) {
      if (selectStudentState.length > 0) {
        const addUsersResp = await VxAPI.addUsersToClass(resp.classId, { userIdArray: selectStudentState });
        if (!addUsersResp[0].userId) {
          setMessage(
            'Class has been created, however, the student(s) could not be added to class. Please refresh and try again. If error persists contact your administrator.',
          );
          return;
        }
      }
      window.location.assign('/classes');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }
      const studentRole = 1;
      const studentData = await VxAPI.getUserByRoleByCustomerId(studentRole);
      if (studentData) {
        setStudents(studentData);
      }
      const tagData = await VxAPI.getAllTagsData();
      if (tagData) {
        setTagArray(tagData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleMultiSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newItems = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newItems.push(Number(selectedOptions[i].value));
    }

    switch (e.target.name) {
      case 'studentArray':
        setSelectStudentState(newItems);
        break;
      case 'tagArray':
        setSelectTagsState(newItems);
        break;
    }
  };

  return (
    <div id="add-class-div">
      <h1>Add a New Class</h1>
      <form onSubmit={handleSubmit}>
        <div className="dropdown-div">
          <div className="add-class-cust">
            <label htmlFor="name">Customer</label>
            <p id="class-cust-name">
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.firstName}{' '}
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.lastName}
            </p>
          </div>
          <div className="add-class-dropdown">
            <label htmlFor="useCaseId">Use Case *</label>
            <select name="useCaseId" onChange={handleSelection}>
              <option value={0}>---</option>
              {useCaseArray
                ? useCaseArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map((useCase: UseCasesObjectDTO, index: number) => (
                    <option key={index} value={useCase.useCaseId}>
                      {useCase.title}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="add-class-row">
          <div className="add-class-form-input">
            <label htmlFor="title">Class Name *</label>
            <input type="text" name="title" defaultValue={title} onChange={handleChange} />
          </div>
        </div>
        <div className="add-class-form-textarea">
          <label htmlFor="description">Description *</label>
          <textarea name="description" defaultValue={description} onChange={handleChange} />
        </div>
        <div className="add-class-form-textarea">
          <label htmlFor="notes">Notes</label>
          <textarea name="notes" defaultValue={notes} onChange={handleChange} />
        </div>
        <div className="selection-div">
          <div className="add-student-dropdown">
            <label id="student-dropdown-header" htmlFor="selectStudentState[]">
              Add Students
            </label>
            <h2 id="student-select-header">Hold CTRL or CMD to select multiple</h2>
            <select multiple size={students?.length} name="studentArray" onChange={handleStudentSelection}>
              {students
                ? students
                .sort((a: any, b: any) => {
                  if (a.firstName < b.firstName) {
                    return -1;
                  }
                  if (a.firstName > b.firstName) {
                    return 1;
                  }
                  return 0;
                })
                .map((student, index: number) => (
                    <option key={index} value={student.userId}>
                      {student.firstName} {student.lastName}
                    </option>
                  ))
                : null}
            </select>
            <div id="student-list-div">
              <h3>Enrolled Students (Count: {selectStudentState.length})</h3>
              <ul id="student-list">
                {selectStudentState &&
                  selectStudentState
                  .sort((a: any, b: any) => {
                    if (a.firstName < b.firstName) {
                      return -1;
                    }
                    if (a.firstName > b.firstName) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((enrolledStudent) => {
                    let foundStudent = students?.find((student) => student.userId === enrolledStudent);
                    return (
                      <li>
                        {foundStudent?.firstName} {foundStudent?.lastName}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="add-tag-dropdown">
            <label id="tag-dropdown-header" htmlFor="selectTagsState[]">
              Add Tags
            </label>
            <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
            <select multiple size={tagArray?.length} name="tagArray" onChange={handleMultiSelection}>
              {tagArray
                ? tagArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map((tag: TagObjectDTO, index: number) => (
                    <option key={index} value={tag.tagId}>
                      {tag.title}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div id="add-class-btns">
          <button type="button" onClick={() => window.location.assign('/classes')}>
            <span>Cancel</span>
          </button>
          <button type="submit">
            <span>Confirm</span>
          </button>
        </div>
      </form>
      {message ? <h3 className="message">{message}</h3> : null}
    </div>
  );
};

export default AddClassForm;
