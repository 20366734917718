import { useEffect, useRef, useState } from 'react';

import { useUserRoleData } from '../account/Account';
import './dashboard.scss';
import Auth from '../../utils/auth';
import { useUserData } from '../account/Account';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { VxAPI } from '../../api/vx.api';
import SidebarChart, { SideBarChartPropsForScreenSize } from '../../components/charts/bar/SideBar';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { averageArrays, averageQuizGlobalConfidece, returnFormattedDate } from '../../utils/helpers';
import Certificate, { CertificateProps } from '../../components/certificate/CertificatePDF';
import StudentDashboard, { StudentDashProps } from '../../components/dashboard/StudentDash';
import { DecodedTokenDTO } from '../../utils/dto/decodedToken.dto';

const Dashboard = () => {
  const loggedIn = Auth.loggedIn();
  const loggedInUser = Auth.getProfile('id_token') as DecodedTokenDTO;
  if (!loggedIn) {
    window.location.assign('/');
  }
  const [loading, setLoading] = useState(true);
  const [searchTextClasses, setSearchTextClasses] = useState<string>('');
  const [searchTextStudents, setSearchTextStudents] = useState<string>('');
  const [searchTextCourses, setSearchTextCourses] = useState<string>('');
  const [searchTextModules, setSearchTextModules] = useState<string>('');
  const [searchTextLessons, setSearchTextLessons] = useState<string>('');
  const [collapse, setCollapse] = useState<string>('');
  const [studentsCollapse, setStudentsCollapse] = useState<boolean[]>([]);
  const [coursesCollapse, setCoursesCollapse] = useState<boolean[]>([]);
  const [modulesCollapse, setModulesCollapse] = useState<boolean[]>([]);
  const [lessonsCollapse, setLessonsCollapse] = useState<boolean[]>([]);
  const [studentDashData, setStudentDashData] = useState<StudentDashProps | null>(null);
  const [sideBarProps, setSideBarProps] = useState<SideBarChartPropsForScreenSize>({
    borderWidth: 1,
    displayLegend: false,
    minBarLength:
      window.innerWidth < 576
        ? 1
        : window.innerWidth < 768
        ? 2
        : window.innerWidth < 992
        ? 3
        : window.innerWidth < 1200
        ? 4
        : 5,
    dataMax: 100,
    dataMin: 0,
    titleFontSize:
      window.innerWidth < 576
        ? 10
        : window.innerWidth < 768
        ? 10
        : window.innerWidth < 992
        ? 12
        : window.innerWidth < 1200
        ? 14
        : 16,
    yAxisLabelFontSize:
      window.innerWidth < 576
        ? 10
        : window.innerWidth < 768
        ? 10
        : window.innerWidth < 992
        ? 12
        : window.innerWidth < 1200
        ? 14
        : 16,
    height: 100,
    width: 100,
    padding: 10,
  });
  const [classObjectArrayData, setClassObjectArrayData] = useState<ClassObjectDTO[]>([
    {
      customerId: 0,
      useCaseId: 0,
      classId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      courses: [0],
      principals: [0],
      tags: [0],
    },
  ]);
  const [updatedClassObjectArrayData, setUpdatedClassObjectArrayData] = useState<ClassObjectDTO[]>([]);
  const [students, setStudents] = useState<UserObjectDTO[]>();
  const { userData } = useUserData();
  const { userRoleData } = useUserRoleData();
  const courseRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const moduleRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const lessonRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [nonNullModuleRefs, setNonNullModuleRefs] = useState<HTMLDivElement[]>([]);
  const [nonNullLessonRefs, setNonNullLessonRefs] = useState<HTMLDivElement[]>([]);
  const chartHeight = 6;
  const [displayCert, setDisplayCert] = useState<boolean>(false);
  const [certificateData, setCertificateData] = useState<CertificateProps | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const classData = await VxAPI.getAllClassesHierarchyData();
      if (classData) {
        setClassObjectArrayData(classData);
        setUpdatedClassObjectArrayData(classData);
      }
      const studentRole = 1;
      const studentData = await VxAPI.getUserByRoleByCustomerId(studentRole);
      if (studentData) {
        setStudents(studentData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (loggedInUser && userData) {
      if (userData.userId !== loggedInUser.userId) {
        Auth.logout();
      }
    }
  }, [loggedInUser, userData]);

  useEffect(() => {
    if (loggedInUser && userRoleData?.title && classObjectArrayData && students) {
      if (studentDashData !== null) return;
      if (loggedInUser.userRole === 1) {
        setStudentDashData({
          studentClassData: classObjectArrayData.filter((classData) =>
            classData.students?.includes(loggedInUser.userId),
          ),
          studentData: students.find((user) => user.userId === loggedInUser.userId) as UserObjectDTO,
        });
        setLoading(false);
        return;
      }
      setStudentDashData({ studentClassData: [], studentData: null });
      setLoading(false);
    }
  }, [userRoleData, classObjectArrayData, students, loggedInUser]);

  const handleStudentsArrow = (index: number) => {
    if (collapse !== 'Students') {
      setCollapse('Students');
      setStudentsCollapse((prevState) => {
        const previousIndexState = prevState[index];
        const newState = new Array(prevState.length).fill(false); // Set all values to false
        newState[index] = !previousIndexState; // Set the specified index to true
        return newState;
      });
      setCoursesCollapse([]);
      setModulesCollapse([]);
      return;
    }
    if (collapse === 'Students') {
      setCollapse('');
      setStudentsCollapse([]);
    }
  };

  const handleCoursesArrow = (index: number) => {
    if (collapse !== 'Courses') {
      setCollapse('Courses');
      setCoursesCollapse((prevState) => {
        const previousIndexState = prevState[index];
        const newState = new Array(prevState.length).fill(false); // Set all values to false
        newState[index] = !previousIndexState; // Set the specified index to true
        return newState;
      });
      setStudentsCollapse([]);
      setModulesCollapse([]);
      return;
    }
    if (collapse === 'Courses') {
      setCollapse('');
      setCoursesCollapse([]);
    }
  };

  const handleModulesArrow = (index: number) => {
    setModulesCollapse((prevState) => {
      const previousIndexState = prevState[index];
      const newState = new Array(prevState.length).fill(false); // Set all values to false
      newState[index] = !previousIndexState; // Set the specified index to true
      return newState;
    });
    setLessonsCollapse([]);
  };

  const handleLessonsArrow = (index: number) => {
    setLessonsCollapse((prevState) => {
      const previousIndexState = prevState[index];
      const newState = new Array(prevState.length).fill(false); // Set all values to false
      newState[index] = !previousIndexState; // Set the specified index to true
      return newState;
    });
  };

  const setCourseRef = (courseId: string, element: HTMLDivElement | null) => {
    courseRefs.current[courseId] = element;
  };

  const setModuleRef = (moduleId: string, element: HTMLDivElement | null) => {
    console.log('setting module ref: ', element);
    moduleRefs.current[moduleId] = element;
  };

  const setLessonRef = (lessonId: string, element: HTMLDivElement | null) => {
    lessonRefs.current[lessonId] = element;
  };

  const getNonNullRefs = (refs: { [key: string]: HTMLDivElement | null }) => {
    return Object.values(refs).filter((ref) => ref !== null);
  };

  useEffect(() => {
    if (!courseRefs.current) return;
    const nonNullModuleRefs = getNonNullRefs(moduleRefs.current);
    const nonNullLessonRefs = getNonNullRefs(lessonRefs.current);
    if (nonNullModuleRefs.length <= 0) return;
    const courseId = nonNullModuleRefs[0]?.id.split('-')[0];
    setNonNullModuleRefs(nonNullModuleRefs as HTMLDivElement[]);
    setNonNullLessonRefs(nonNullLessonRefs as HTMLDivElement[]);
    // console.log('course: ', courseRefs.current[courseId as string]);
    // console.log('modules: ', nonNullModuleRefs);
    // console.log('lessons: ', nonNullLessonRefs);
  }, [lessonsCollapse, modulesCollapse, collapse]);

  const handleCertficateData = (date: string, name: string, course: string, signatureName: string) => {
    if (certificateData === null) {
      setCertificateData({ date, name, course, signatureName });
      setDisplayCert(true);
    } else {
      setCertificateData(null);
      setDisplayCert(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoursesCollapse([]);
    setModulesCollapse([]);
    setStudentsCollapse([]);
    switch (e.target.name) {
      case 'searchTextClasses':
        setSearchTextClasses(e.target.value);
        break;
      case 'searchTextStudents':
        setSearchTextStudents(e.target.value);
        break;
      case 'searchTextCourses':
        setSearchTextCourses(e.target.value);
        break;
      case 'searchTextModules':
        setSearchTextModules(e.target.value);
        break;
      case 'searchTextLessons':
        setSearchTextLessons(e.target.value);
        break;
    }
  };

  const handleSearchClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCoursesCollapse([]);
    setModulesCollapse([]);
    setStudentsCollapse([]);
    const target = e.target as HTMLButtonElement;
    switch (target.name) {
      case 'searchTextClasses':
        setSearchTextClasses('');
        break;
      case 'searchTextStudents':
        setSearchTextStudents('');
        break;
      case 'searchTextCourses':
        setSearchTextCourses('');
        break;
      case 'searchTextModules':
        setSearchTextModules('');
        break;
      case 'searchTextLessons':
        setSearchTextLessons('');
        break;
    }
  };

  useEffect(() => {
    if (
      searchTextClasses.trim() === '' &&
      searchTextCourses.trim() === '' &&
      searchTextStudents.trim() === '' &&
      searchTextModules.trim() === '' &&
      searchTextLessons.trim() === ''
    ) {
      setUpdatedClassObjectArrayData(classObjectArrayData);
      return;
    }

    let updatedClassData = classObjectArrayData;

    // console.log('updatedClassData BEFORE: ', updatedClassData);

    if (searchTextClasses !== '') {
      updatedClassData = updatedClassData.filter((data) =>
        data.title?.toLowerCase().includes(searchTextClasses.toLowerCase()),
      );
    }
    if (searchTextStudents !== '') {
      const updatedStudents = students?.filter((user) => {
        if (user.firstName && user.lastName) {
          const fullName = user.firstName.toLowerCase().trim() + ' ' + user.lastName.toLowerCase().trim();
          return fullName.includes(searchTextStudents.toLowerCase().trim());
        }
      });
      const validStudentIds = new Set(updatedStudents?.map((student) => student.userId));

      const updatedClassesForStudents = updatedClassData
        .map((data) => {
          return { ...data, students: data.students?.filter((id) => validStudentIds.has(id)) };
        })
        .filter((data) => data.students && data.students.length > 0);
      updatedClassData = updatedClassesForStudents;
    }

    if (searchTextCourses !== '') {
      const updatedClassesForCourses = updatedClassData
        .map((data) => {
          return {
            ...data,
            courseList: data.courseList?.filter((course) => {
              return course.title?.toLowerCase().includes(searchTextCourses.toLowerCase().trim());
            }),
          };
        })
        .filter((data) => data.courseList && data.courseList.length > 0);
      updatedClassData = updatedClassesForCourses;
    }

    if (searchTextModules !== '') {
      // console.log('updated class data BEFORE: ', updatedClassData);
      const updatedClassesForModules = updatedClassData
        .map((data) => {
          const updatedCourseList = data.courseList
            ?.map((course) => {
              const updatedModuleList = {
                ...course,
                courseModules: course.courseModules?.filter((module) =>
                  module.title?.toLowerCase().includes(searchTextModules.toLowerCase().trim()),
                ),
              };
              return updatedModuleList;
            })
            .filter((course) => course.courseModules && course.courseModules.length > 0);
          return {
            ...data,
            courseList: updatedCourseList,
          };
        })
        .filter((data) => data.courseList && data.courseList.length > 0);
      updatedClassData = updatedClassesForModules as ClassObjectDTO[];
    }

    if (searchTextLessons !== '') {
      // console.log('updated class data BEFORE: ', updatedClassData);
      const updatedClassesForLessons = updatedClassData
        .map((data) => {
          const updatedCourseList = data.courseList
            ?.map((course) => {
              const updatedModules = course.courseModules
                ?.map((module) => {
                  const updatedLessonList = {
                    ...module,
                    lessons: module.lessons?.filter((lesson) =>
                      lesson.title?.toLowerCase().includes(searchTextLessons.toLowerCase().trim()),
                    ),
                  };
                  return updatedLessonList;
                })
                .filter((module) => module.lessons && module.lessons.length > 0);
              const updatedModuleList = {
                ...course,
                courseModules: updatedModules,
              };
              return updatedModuleList;
            })
            .filter((course) => course.courseModules && course.courseModules.length > 0);
          return {
            ...data,
            courseList: updatedCourseList,
          };
        })
        .filter((data) => data.courseList && data.courseList.length > 0);
      updatedClassData = updatedClassesForLessons as ClassObjectDTO[];
    }
    setUpdatedClassObjectArrayData(updatedClassData);
  }, [searchTextClasses, searchTextCourses, searchTextLessons, searchTextModules, searchTextStudents]);

  return (
    <>
      {loading ? (
        <>
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        </>
      ) : userRoleData?.title === 'Student' && studentDashData?.studentData !== null ? (
        <>
          <StudentDashboard
            studentData={studentDashData?.studentData}
            studentClassData={studentDashData?.studentClassData as ClassObjectDTO[]}
          />
        </>
      ) : displayCert && certificateData ? (
        <>
          <Certificate
            name={certificateData.name}
            course={certificateData.course}
            date={certificateData.date}
            signatureName={certificateData.signatureName}
            callback={() => handleCertficateData('', '', '', '')}
          />
        </>
      ) : (
        <>
          <div className="dashboard">
            <div className="dash-header">
              <h2>Class Filters</h2>
              <div className="filters">
                <div className="filter">
                  <label htmlFor="searchTextClasses">Class</label>
                  <div className="filter-inputs">
                    <input
                      type="text"
                      name="searchTextClasses"
                      placeholder="Class Search"
                      onChange={handleSearch}
                      value={searchTextClasses}
                    />
                    <button
                      className="clear-filter"
                      name="searchTextClasses"
                      onClick={handleSearchClear}
                      style={{ visibility: searchTextClasses.trim() !== '' ? 'visible' : 'hidden' }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="filter">
                  <label htmlFor="searchTextStudents">Student</label>
                  <div className="filter-inputs">
                    <input
                      type="text"
                      name="searchTextStudents"
                      placeholder="Student Search"
                      onChange={handleSearch}
                      value={searchTextStudents}
                    />
                    <button
                      className="clear-filter"
                      name="searchTextStudents"
                      onClick={handleSearchClear}
                      style={{ visibility: searchTextStudents.trim() !== '' ? 'visible' : 'hidden' }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="filter">
                  <label htmlFor="searchTextCourses">Course</label>
                  <div className="filter-inputs">
                    <input
                      type="text"
                      name="searchTextCourses"
                      placeholder="Course Search"
                      onChange={handleSearch}
                      value={searchTextCourses}
                    />
                    <button
                      className="clear-filter"
                      name="searchTextCourses"
                      onClick={handleSearchClear}
                      style={{ visibility: searchTextCourses.trim() !== '' ? 'visible' : 'hidden' }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="filter">
                  <label htmlFor="searchTextModules">Module</label>
                  <div className="filter-inputs">
                    <input
                      type="text"
                      name="searchTextModules"
                      placeholder="Module Search"
                      onChange={handleSearch}
                      value={searchTextModules}
                    />
                    <button
                      className="clear-filter"
                      name="searchTextModules"
                      onClick={handleSearchClear}
                      style={{ visibility: searchTextModules.trim() !== '' ? 'visible' : 'hidden' }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="filter">
                  <label htmlFor="searchTextLessons">Lesson</label>
                  <div className="filter-inputs">
                    <input
                      type="text"
                      name="searchTextLessons"
                      placeholder="Lesson Search"
                      onChange={handleSearch}
                      value={searchTextLessons}
                    />
                    <button
                      className="clear-filter"
                      name="searchTextLessons"
                      onClick={handleSearchClear}
                      style={{ visibility: searchTextLessons.trim() !== '' ? 'visible' : 'hidden' }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <h1>Classes</h1>
            </div>

            {updatedClassObjectArrayData && updatedClassObjectArrayData.length > 0 ? (
              <>
                {updatedClassObjectArrayData.map((unit: ClassObjectDTO, index: number) => {
                  return (
                    <>
                      <div className="unit-header">
                        <h1 className="unit-title">{unit.title}</h1>
                        <div className={studentsCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'}>
                          <h1>Students</h1>
                          <FontAwesomeIcon
                            id={`students-dd-icon`}
                            icon={studentsCollapse[index] ? faAngleUp : faAngleDown}
                            onClick={() => handleStudentsArrow(index)}
                          />
                        </div>
                        <div className={coursesCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'}>
                          <h1>Courses</h1>
                          <FontAwesomeIcon
                            id={`courses-dd-icon`}
                            icon={coursesCollapse[index] ? faAngleUp : faAngleDown}
                            onClick={() => handleCoursesArrow(index)}
                          />
                        </div>
                        <>
                          {collapse !== '' ? (
                            <>
                              {collapse === 'Students' && studentsCollapse[index] ? (
                                <div className="unit-dd">
                                  <div className="unit-details">
                                    <>
                                      {unit.students?.map((studentId: number, index: number) => {
                                        let lessonCompletedDates: string[] = [];
                                        const student = students?.find((user) => user.userId === studentId);
                                        const studentCompletedLessonIds = student?.studentMetrics?.lessons?.map(
                                          (lesson) => {
                                            if (lesson.dateCompleted) {
                                              lessonCompletedDates.push(lesson.dateCompleted as unknown as string);
                                              return lesson.lessonId;
                                            }
                                          },
                                        );
                                        const latestCompletedDate = lessonCompletedDates.reduce((latest, current) => {
                                          return current > latest ? current : latest;
                                        }, lessonCompletedDates[0]);
                                        const fullClassData = classObjectArrayData.find(
                                          (classData) => classData.classId === unit.classId,
                                        );
                                        const courseNames = fullClassData?.courseList?.map(
                                          (course: CourseObjectDTO) => {
                                            return course.title;
                                          },
                                        );
                                        // count the number of lessons for the course
                                        const courseLessonsIds = fullClassData?.courseList?.map(
                                          (course: CourseObjectDTO) => {
                                            return course.courseModules?.flatMap((module: ModuleObjectDTO) => {
                                              return module.lessons?.map((lesson: LessonObjectDTO) => {
                                                return lesson.lessonId;
                                              });
                                            });
                                          },
                                        );
                                        const overallConfidence = student?.studentMetrics?.lessons
                                          ? averageQuizGlobalConfidece(
                                              student.studentMetrics,
                                              courseLessonsIds?.flat() as unknown as number[],
                                            )
                                          : null;
                                        // count if lesson id exists on student metrics and has completed date
                                        // get progress value and multiply by 100
                                        const progressArray = courseLessonsIds?.map((idArray) => {
                                          let count = 0;
                                          idArray?.map((id) => {
                                            if (studentCompletedLessonIds?.includes(id)) count++;
                                          });
                                          return idArray?.length ? (count / idArray.length) * 100 : 0;
                                        });

                                        // Determine bar colors based on data values
                                        const backgroundColors = progressArray?.map((value) => {
                                          if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                          if (value > 0 && value < 100) return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                          return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                        });

                                        let chartDivHeight =
                                          (courseNames?.length as number) > 1
                                            ? chartHeight * (courseNames?.length as number)
                                            : 12;

                                        return (
                                          <div className="student-info">
                                            <div className="stud-metrics">
                                              <h1>
                                                {student?.firstName} {student?.lastName}
                                              </h1>
                                              <div className="student-conf">
                                                <p>
                                                  {overallConfidence !== null ? (
                                                    <>
                                                      Overall Confidence:{' '}
                                                      <span
                                                        style={
                                                          overallConfidence < 0.45
                                                            ? { color: 'red' }
                                                            : overallConfidence < 0.7
                                                            ? { color: 'yellow' }
                                                            : { color: 'green' }
                                                        }
                                                      >
                                                        {(overallConfidence * 100).toFixed(2)}%
                                                      </span>
                                                    </>
                                                  ) : (
                                                    'No Metrics'
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                            {(courseNames?.length as number) === 0 ? (
                                              <p style={{ marginTop: '1rem' }}>Class is not enrolled in any courses</p>
                                            ) : (
                                              <div className="datachart">
                                                <div
                                                  className="student-progress-chart"
                                                  style={{
                                                    height: `${chartDivHeight}rem`,
                                                  }}
                                                >
                                                  <SidebarChart
                                                    dataValues={progressArray as number[]}
                                                    yLabels={courseNames as string[]}
                                                    barColors={backgroundColors as string[]}
                                                    barBorderColors={backgroundColors as string[]}
                                                    borderWidth={sideBarProps.borderWidth}
                                                    displayLegend={sideBarProps.displayLegend}
                                                    title="Student Progress"
                                                    className="student-progress-chart"
                                                    minBarLength={sideBarProps.minBarLength}
                                                    dataMax={sideBarProps.dataMax}
                                                    dataMin={sideBarProps.dataMin}
                                                    titleFontSize={sideBarProps.titleFontSize}
                                                    yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                                    height={
                                                      (courseNames?.length as number) > 1
                                                        ? chartHeight * (courseNames?.length as number)
                                                        : 12
                                                    }
                                                    width={sideBarProps.width}
                                                    padding={sideBarProps.padding}
                                                  />
                                                  <div
                                                    className="cert-btn-divs"
                                                    style={{
                                                      marginTop: `${
                                                        chartDivHeight /
                                                        (progressArray?.length ? progressArray.length + 1 : 1)
                                                      }rem`,
                                                    }}
                                                  >
                                                    {progressArray?.map((score, index) => {
                                                      const currentCourseName =
                                                        courseNames && courseNames?.length > 0
                                                          ? courseNames[index]
                                                          : '';
                                                      if (score === 100) {
                                                        return (
                                                          <div
                                                            className="cert-btn"
                                                            style={{
                                                              height: `${
                                                                chartDivHeight /
                                                                (progressArray?.length ? progressArray.length + 1 : 1)
                                                              }rem`,
                                                            }}
                                                          >
                                                            <button
                                                              onClick={() =>
                                                                handleCertficateData(
                                                                  returnFormattedDate(latestCompletedDate),
                                                                  `${student?.firstName} ${student?.lastName}`,
                                                                  currentCourseName as string,
                                                                  'Authorizing Official',
                                                                )
                                                              }
                                                            >
                                                              Cert
                                                            </button>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="no-cert-btn"
                                                            style={{
                                                              height: `${
                                                                chartDivHeight /
                                                                (progressArray?.length ? progressArray.length + 1 : 1)
                                                              }rem`,
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </>
                                  </div>
                                </div>
                              ) : collapse === 'Courses' && coursesCollapse[index] ? (
                                <div className="unit-dd">
                                  <div className="unit-details">
                                    <>
                                      {unit.courseList?.map((course: CourseObjectDTO, index: number) => {
                                        const classStudents: (UserObjectDTO | undefined)[] | undefined =
                                          unit.students?.map((studentId: number) => {
                                            return students?.find(
                                              (student: UserObjectDTO) => student.userId === studentId,
                                            );
                                          });
                                        let lessonCompletedDates: string[] = [];
                                        const studentCompletedLessonIds = classStudents?.map((student) =>
                                          student?.studentMetrics?.lessons?.map((lesson) => {
                                            if (lesson.dateCompleted) {
                                              lessonCompletedDates.push(lesson.dateCompleted as unknown as string);
                                              return lesson.lessonId;
                                            }
                                          }),
                                        );
                                        // console.log('student completed lessonid: ', studentCompletedLessonIds);
                                        const latestCompletedDate = lessonCompletedDates.reduce((latest, current) => {
                                          return current > latest ? current : latest;
                                        }, lessonCompletedDates[0]);
                                        const studentNames = classStudents?.map((student) => {
                                          return `${student?.firstName} ${student?.lastName}`;
                                        });
                                        const fullCourseData = classObjectArrayData
                                          .find((classData) => classData.classId === unit.classId)
                                          ?.courseList?.find((c) => c.courseId === course.courseId);
                                        // count the number of lessons for the course
                                        const courseLessonsIds =
                                          fullCourseData?.courseModules?.flatMap((module: ModuleObjectDTO) => {
                                            return Array.isArray(module.lessons)
                                              ? module.lessons.map((lesson: LessonObjectDTO) => lesson.lessonId)
                                              : [];
                                          }) || [];
                                        // count if lesson id exists on student metrics and has completed date
                                        // get progress value and multiply by 100
                                        const progressArray = courseLessonsIds?.map((id) => {
                                          return studentCompletedLessonIds?.map((lId) => {
                                            let count = 0;
                                            if (lId?.includes(id)) count = 1;
                                            return lId?.length ? count : 0;
                                          });
                                        });

                                        const studentsDataValues = averageArrays(progressArray as number[][], true);

                                        // Determine bar colors based on data values
                                        const backgroundColors = studentsDataValues?.map((value) => {
                                          if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                          if (value > 0 && value < 100) return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                          return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                        });

                                        const modules = course.courseModules;

                                        let chartDivHeight =
                                          (studentNames?.length as number) > 1
                                            ? chartHeight * (studentNames?.length as number)
                                            : 12;
                                        const nonNullModuleKeys =
                                          Object.keys(moduleRefs.current).filter(
                                            (key) => moduleRefs.current[key] !== null,
                                          )[0] || null;
                                        console.log('moduleRefs: ', moduleRefs.current);
                                        const modulesOpen = modulesCollapse.some((value) => value === true);
                                        // console.log('modules open: ', modulesOpen);
                                        // console.log(nonNullModuleKeys);
                                        const currentCourseId = nonNullModuleKeys
                                          ? nonNullModuleKeys.split('-')[0]
                                          : null;
                                        // console.log('current course id: ', currentCourseId);
                                        const hideElement =
                                          currentCourseId && currentCourseId !== `${course.courseId}` && modulesOpen;
                                        // console.log('hide element: ', hideElement);
                                        const expandedElement =
                                          currentCourseId && currentCourseId === `${course.courseId}` && modulesOpen;
                                        // console.log('expanded el: ', expandedElement);

                                        return (
                                          <div
                                            style={{
                                              display: hideElement ? 'none' : 'block',
                                            }}
                                            className={expandedElement ? 'student-info expanded' : 'student-info'}
                                            id={`${course.courseId}`}
                                            ref={(el) => setCourseRef(`${course.courseId}`, el)}
                                          >
                                            <h1 className={modulesCollapse[index] ? 'active-font' : undefined}>
                                              {course.title}
                                            </h1>
                                            <div className="datachart">
                                              <div
                                                className="course-progress-chart"
                                                style={{
                                                  height: `${chartDivHeight}rem`,
                                                }}
                                              >
                                                <SidebarChart
                                                  dataValues={studentsDataValues}
                                                  yLabels={studentNames as string[]}
                                                  barColors={backgroundColors}
                                                  barBorderColors={backgroundColors}
                                                  borderWidth={sideBarProps.borderWidth}
                                                  displayLegend={sideBarProps.displayLegend}
                                                  title="Course Progress"
                                                  className="course-progress-chart"
                                                  minBarLength={sideBarProps.minBarLength}
                                                  dataMax={sideBarProps.dataMax}
                                                  dataMin={sideBarProps.dataMin}
                                                  titleFontSize={sideBarProps.titleFontSize}
                                                  yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                                  height={
                                                    (studentNames?.length as number) > 1
                                                      ? chartHeight * (studentNames?.length as number)
                                                      : 12
                                                  }
                                                  width={sideBarProps.width}
                                                  padding={sideBarProps.padding}
                                                />
                                                <div
                                                  className="cert-btn-divs"
                                                  style={{
                                                    marginTop: `${
                                                      chartDivHeight /
                                                      (studentsDataValues?.length ? studentsDataValues.length + 1 : 1)
                                                    }rem`,
                                                  }}
                                                >
                                                  {studentsDataValues.map((score, index) => {
                                                    const currentStudent =
                                                      studentNames && studentNames?.length > 0
                                                        ? studentNames[index]
                                                        : '';
                                                    if (score === 100) {
                                                      return (
                                                        <div
                                                          className="cert-btn"
                                                          style={{
                                                            height: `${
                                                              chartDivHeight /
                                                              (studentsDataValues?.length
                                                                ? studentsDataValues.length + 1
                                                                : 1)
                                                            }rem`,
                                                            alignContent:
                                                              studentsDataValues.length && studentsDataValues.length > 2
                                                                ? 'center'
                                                                : undefined,
                                                          }}
                                                        >
                                                          <button
                                                            onClick={() =>
                                                              handleCertficateData(
                                                                returnFormattedDate(latestCompletedDate),
                                                                currentStudent,
                                                                course.title as string,
                                                                'Authorizing Official',
                                                              )
                                                            }
                                                          >
                                                            Cert
                                                          </button>
                                                        </div>
                                                      );
                                                    } else {
                                                      return (
                                                        <div
                                                          className="no-cert-btn"
                                                          style={{
                                                            height: `${
                                                              chartDivHeight /
                                                              (studentsDataValues?.length
                                                                ? studentsDataValues.length + 1
                                                                : 1)
                                                            }rem`,
                                                          }}
                                                        ></div>
                                                      );
                                                    }
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={
                                                modulesCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'
                                              }
                                            >
                                              <h1>Modules</h1>
                                              <FontAwesomeIcon
                                                id={`modules-dd-icon-${course.courseId}`}
                                                icon={modulesCollapse[index] ? faAngleUp : faAngleDown}
                                                onClick={() => handleModulesArrow(index)}
                                              />
                                            </div>
                                            <>
                                              {modulesCollapse[index] ? (
                                                <>
                                                  {modules?.map((module: ModuleObjectDTO, index: number) => {
                                                    // console.log('module lessons: ', module.lessons? module.lessons : 'no lessons')
                                                    const fullModuleData = classObjectArrayData
                                                      .find((classData) => classData.classId === unit.classId)
                                                      ?.courseList?.find((c) => c.courseId === course.courseId)
                                                      ?.courseModules?.find((m) => m.moduleId === module.moduleId);
                                                    const fullLessonData = Array.isArray(fullModuleData?.lessons)
                                                      ? fullModuleData?.lessons
                                                      : [];
                                                    const lessons = Array.isArray(module.lessons) ? module.lessons : [];
                                                    const moduleProgressArray = fullLessonData?.map((lesson) => {
                                                      return studentCompletedLessonIds?.map((lId) => {
                                                        let count = 0;
                                                        // console.log('lId: ', lId);
                                                        if (lId?.includes(lesson.lessonId)) count = 1;
                                                        return lId?.length ? count : 0;
                                                      });
                                                    });
                                                    const moduleDataValues = averageArrays(
                                                      moduleProgressArray as number[][],
                                                      true,
                                                    );

                                                    // Determine bar colors based on data values
                                                    const moduleBackgroundColors = moduleDataValues?.map((value) => {
                                                      if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                                      if (value > 0 && value < 100) return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                                      return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                                    });

                                                    const nonNullLessonKeys =
                                                      Object.keys(lessonRefs.current).filter(
                                                        (key) => lessonRefs.current[key] !== null,
                                                      )[0] || null;
                                                    // console.log('lessonRefs: ', lessonRefs.current);
                                                    const lessonsOpen = lessonsCollapse.some((value) => value === true);
                                                    // console.log('lessons open: ', lessonsOpen);
                                                    // console.log(nonNullLessonKeys);
                                                    const currentModuleId = nonNullLessonKeys
                                                      ? nonNullLessonKeys.split('-')[1]
                                                      : null;
                                                    // console.log('current Module id: ', currentModuleId);

                                                    return (
                                                      <div
                                                        style={{
                                                          display:
                                                            currentModuleId &&
                                                            currentModuleId !== `${module.moduleId}` &&
                                                            lessonsOpen
                                                              ? 'none'
                                                              : 'block',
                                                        }}
                                                        className="module-info"
                                                        id={`${course.courseId}-${module.moduleId}`}
                                                        ref={(el) =>
                                                          setModuleRef(`${course.courseId}-${module.moduleId}`, el)
                                                        }
                                                      >
                                                        <h1
                                                          className={lessonsCollapse[index] ? 'active-font' : undefined}
                                                        >
                                                          {module.title}
                                                        </h1>
                                                        <div className="datachart">
                                                          <div
                                                            className="course-progress-chart"
                                                            style={{
                                                              height: `${chartDivHeight}rem`,
                                                            }}
                                                          >
                                                            <SidebarChart
                                                              dataValues={moduleDataValues}
                                                              yLabels={studentNames as string[]}
                                                              barColors={moduleBackgroundColors}
                                                              barBorderColors={moduleBackgroundColors}
                                                              borderWidth={sideBarProps.borderWidth}
                                                              displayLegend={sideBarProps.displayLegend}
                                                              title="Module Progress"
                                                              className="course-progress-chart"
                                                              minBarLength={sideBarProps.minBarLength}
                                                              dataMax={sideBarProps.dataMax}
                                                              dataMin={sideBarProps.dataMin}
                                                              titleFontSize={sideBarProps.titleFontSize}
                                                              yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                                              height={
                                                                (studentNames?.length as number) > 1
                                                                  ? chartHeight * (studentNames?.length as number)
                                                                  : 12
                                                              }
                                                              width={sideBarProps.width}
                                                              padding={sideBarProps.padding}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={
                                                            lessonsCollapse[index]
                                                              ? 'unit-header-dd active'
                                                              : 'unit-header-dd'
                                                          }
                                                        >
                                                          <h1>Lessons</h1>
                                                          <FontAwesomeIcon
                                                            id={`lessons-dd-icon-${module.moduleId}`}
                                                            icon={lessonsCollapse[index] ? faAngleUp : faAngleDown}
                                                            onClick={() => handleLessonsArrow(index)}
                                                          />
                                                        </div>
                                                        <>
                                                          {lessonsCollapse[index] ? (
                                                            <>
                                                              {lessons?.map(
                                                                (lesson: LessonObjectDTO, index: number) => {
                                                                  const lessonProgressArray: number[] = [];
                                                                  studentCompletedLessonIds?.map((lId) => {
                                                                    if (lId?.includes(lesson.lessonId)) {
                                                                      lessonProgressArray.push(100);
                                                                    } else {
                                                                      lessonProgressArray.push(0);
                                                                    }
                                                                  });

                                                                  // Determine bar colors based on data values
                                                                  const lessonBackgroundColors =
                                                                    lessonProgressArray?.map((value) => {
                                                                      if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                                                      if (value > 0 && value < 100)
                                                                        return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                                                      return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                                                    });
                                                                  return (
                                                                    <div
                                                                      className="lesson-info"
                                                                      id={`${course.courseId}-${module.moduleId}-${lesson.lessonId}`}
                                                                      ref={(el) =>
                                                                        setLessonRef(
                                                                          `${course.courseId}-${module.moduleId}-${lesson.lessonId}`,
                                                                          el,
                                                                        )
                                                                      }
                                                                    >
                                                                      <h1>{lesson.title}</h1>
                                                                      <div className="datachart">
                                                                        <div
                                                                          className="course-progress-chart"
                                                                          style={{
                                                                            height: `${chartDivHeight}rem`,
                                                                          }}
                                                                        >
                                                                          <SidebarChart
                                                                            dataValues={lessonProgressArray}
                                                                            yLabels={studentNames as string[]}
                                                                            barColors={lessonBackgroundColors}
                                                                            barBorderColors={lessonBackgroundColors}
                                                                            borderWidth={sideBarProps.borderWidth}
                                                                            displayLegend={sideBarProps.displayLegend}
                                                                            title="Lesson Progress"
                                                                            className="course-progress-chart"
                                                                            minBarLength={sideBarProps.minBarLength}
                                                                            dataMax={sideBarProps.dataMax}
                                                                            dataMin={sideBarProps.dataMin}
                                                                            titleFontSize={sideBarProps.titleFontSize}
                                                                            yAxisLabelFontSize={
                                                                              sideBarProps.yAxisLabelFontSize
                                                                            }
                                                                            height={
                                                                              (studentNames?.length as number) > 1
                                                                                ? chartHeight *
                                                                                  (studentNames?.length as number)
                                                                                : 12
                                                                            }
                                                                            width={sideBarProps.width}
                                                                            padding={sideBarProps.padding}
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                },
                                                              )}
                                                            </>
                                                          ) : null}
                                                        </>
                                                      </div>
                                                    );
                                                  })}
                                                </>
                                              ) : null}
                                            </>
                                          </div>
                                        );
                                      })}
                                    </>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      </div>
                    </>
                  );
                })}
              </>
            ) : searchTextClasses !== '' ||
              searchTextCourses !== '' ||
              searchTextLessons !== '' ||
              searchTextModules !== '' ||
              searchTextStudents !== '' ? (
              <div className="no-class">There are no classes associated with the filtered criteria.</div>
            ) : (
              <div className="no-class"> Get started by creating classes for your account!</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
