import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import './studentQuizzes.scss';
import Auth from '../../utils/auth';
import { useInstructorLessonsArrayData, useUserData, useCustomerHierarchyClassData } from '../account/Account';
import { HierarchicalLessonObjectDTO } from '../../components/dto/hierarchicalLessonObject.dto';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import {
  StudentMetricsLessonObjectDTO,
  StudentMetricsQuizResultObjectDTO,
} from '../../components/dto/studentMetrics.dto';

const StudentQuizzes = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }
  const { userData } = useUserData();
  const navigate = useNavigate();
  const params = useParams();
  const { instructorLessonsArrayData } = useInstructorLessonsArrayData();
  const paramsLessonId = Number(params.lessonId);
  const paramsUserId = Number(params.userId);
  const [loading, setLoading] = useState(true);
  const [instructorLesson, setInstructorLesson] = useState<HierarchicalLessonObjectDTO>();
  const [student, setStudent] = useState<UserObjectDTO>();
  const [studentLessonMetrics, setStudentLessonMetrics] = useState<StudentMetricsLessonObjectDTO>();
  const { customerHierarchyClassData } = useCustomerHierarchyClassData();

  useEffect(() => {
    const fetchData = async () => {
      const studentData = await VxAPI.getSingleUserData(paramsUserId);
      if (studentData) {
        setStudent(studentData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  useEffect(() => {
    if (userData?.userRole) {
      if (userData?.userRole === 2) {
        const foundLesson = instructorLessonsArrayData?.find(
          (instructorLesson) => instructorLesson.lesson?.lessonId === paramsLessonId,
        );
        if (foundLesson) {
          let studentLessonMetrics = student?.studentMetrics?.lessons?.find(
            (lesson) => lesson.lessonId === Number(paramsLessonId),
          );
          setInstructorLesson(foundLesson);
          setStudentLessonMetrics(studentLessonMetrics);
          setLoading(false);
        }
      } else {
        let studentLessonsArray: any = [];
        if (customerHierarchyClassData) {
          let studentClassArray: ClassObjectDTO[] = [];
          customerHierarchyClassData.map((classData: ClassObjectDTO, index: number) => {
            if (classData.students && classData.students.length > 0) {
              if (classData.students.includes(Number(paramsUserId))) {
                studentClassArray.push(classData);
              }
            }
          });
          if (studentClassArray.map.length > 0) {
            studentClassArray.map((classData: ClassObjectDTO) => {
              classData.courseList?.map((course: CourseObjectDTO) => {
                course.courseModules?.map((module: ModuleObjectDTO) => {
                  module.lessons?.map((lesson: LessonObjectDTO) => {
                    const studentLessonDataObject = {
                      classId: classData.classId,
                      className: classData.title,
                      students: classData.students,
                      lesson,
                    };
                    studentLessonsArray.push(studentLessonDataObject);
                  });
                });
              });
            });
          }
          let foundLesson = studentLessonsArray.find(
            (studentLesson: HierarchicalLessonObjectDTO) => studentLesson.lesson?.lessonId === paramsLessonId,
          );
          let studentLessonMetrics = student?.studentMetrics?.lessons?.find(
            (lesson) => lesson.lessonId === Number(paramsLessonId),
          );
          setInstructorLesson(foundLesson);
          setStudentLessonMetrics(studentLessonMetrics);
          setLoading(false);
        }
      }
    }
  }, [instructorLessonsArrayData, userData?.userRole, customerHierarchyClassData]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="student-quizzes-display">
          <h1>{instructorLesson?.lesson?.title}</h1>
          <p id="quizzes-completed-header">
            Quizzes Completed:{' '}
            {student?.studentMetrics?.lessons?.find(
              (studentLesson) => studentLesson.lessonId === instructorLesson?.lesson?.lessonId,
            )
              ? student?.studentMetrics?.lessons?.find(
                  (studentLesson) => studentLesson.lessonId === instructorLesson?.lesson?.lessonId,
                )?.quizResults?.length && student?.studentMetrics?.lessons?.find(
                  (studentLesson) => studentLesson.lessonId === instructorLesson?.lesson?.lessonId,
                )?.quizResults[0].guid ? student?.studentMetrics?.lessons?.find(
                  (studentLesson) => studentLesson.lessonId === instructorLesson?.lesson?.lessonId,
                )?.quizResults?.length
              : '0' : '0'}
          </p>
          <div id="quiz-list-display">
            <h2>
              {studentLessonMetrics?.quizResults?.length === 1 && studentLessonMetrics?.quizResults[0].guid
                ? `${studentLessonMetrics.quizResults?.length} Total Quiz`
                : studentLessonMetrics?.quizResults?.length === 0 || !studentLessonMetrics?.quizResults?.length || !studentLessonMetrics?.quizResults[0].guid
                ? `0 Total Quizzes`
                : `${studentLessonMetrics?.quizResults?.length} Total Quizzes`}
            </h2>
            <div id="quiz-list-div">
              <div id="quiz-headers">
                <h3 id="quiz-name-header">Quiz Title</h3>
                <div id="secondary-quiz-headers">
                  <h3 id="quiz-grade-header">Avg Grade</h3>
                  <h3 id="quiz-student-confidence-header">Avg Confidence</h3>
                  <h3 id="quiz-attempts-header">Attempts</h3>
                </div>
              </div>
              {studentLessonMetrics?.quizResults
                ? studentLessonMetrics.quizResults.map((quiz: StudentMetricsQuizResultObjectDTO, index: number) => {
                    if(!quiz.guid)return;
                    return (
                      <>
                        {quiz ? (
                          <>
                            <div
                              id="quiz-info-div"
                              key={index}
                              onClick={() =>
                                window.location.assign(
                                  `/lessons/${studentLessonMetrics.lessonId}/quizzes/${quiz.guid}/students/view/${student?.userId}`,
                                )
                              }
                            >
                              <div id="quiz-name">
                                <p>{quiz.title}</p>
                              </div>
                              <div id="secondary-quiz-data">
                                <div id="quiz-grade">
                                  <p>{quiz?.quizGlobalScore?.toFixed(2)}%</p>
                                </div>
                                <>
                                  {(quiz?.quizGlobalConfidence || 0) >= 0.70 ? (
                                    <>
                                      <div id="student-confidence-status">
                                        <div id="high-confidence-cir"></div>
                                        <p>High</p>
                                      </div>
                                    </>
                                  ) : (quiz?.quizGlobalConfidence || 0) >= 0.45 ? (
                                    <>
                                      <div id="student-confidence-status">
                                        <div id="medium-confidence-cir"></div>
                                        <p>Medium</p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div id="student-confidence-status">
                                        <div id="low-confidence-cir"></div>
                                        <p>Low</p>
                                      </div>
                                    </>
                                  )}
                                </>
                                <div id="quiz-attempts">
                                  <p>
                                    {
                                      studentLessonMetrics.quizResults.find(
                                        (lessonQuiz) => lessonQuiz.guid === quiz.guid,
                                      )?.quizAttempts.length
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="quiz-line"></div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                : null}
              <div className="back-btn-div">
                <button className="back-btn" type="button" onClick={() => navigate(-1)}>
                  <span>Back</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentQuizzes;
