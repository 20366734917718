import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import './studentLessons.scss';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { useInstructorLessonsArrayData, useUserData, useCustomerHierarchyClassData } from '../account/Account';
import Auth from '../../utils/auth';
import { HierarchicalLessonObjectDTO } from '../../components/dto/hierarchicalLessonObject.dto';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';

const StudentLessons = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }
  const navigate = useNavigate();
  const params = useParams();
  const paramsUserId = Number(params.userId);
  const { instructorLessonsArrayData } = useInstructorLessonsArrayData();
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<UserObjectDTO>();
  const [studentEnrolledLessons, setStudentEnrolledLessons] = useState<HierarchicalLessonObjectDTO[]>();
  const { userData } = useUserData();
  const { customerHierarchyClassData } = useCustomerHierarchyClassData();

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const studentData = await VxAPI.getSingleUserData(paramsUserId);
      if (studentData) {
        setStudent(studentData);
      }
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userData?.userRole) {
      if (userData?.userRole === 2) {
        const studentsLessons = instructorLessonsArrayData?.filter((instructorLesson: HierarchicalLessonObjectDTO) => {
          return instructorLesson.students?.includes(Number(paramsUserId));
        });

        if (studentsLessons) {
          setStudentEnrolledLessons(studentsLessons);
          setLoading(false);
        }
      } else {
        let studentLessonsArray: any = [];
        if (customerHierarchyClassData) {
          let studentClassArray: ClassObjectDTO[] = [];
          customerHierarchyClassData.map((classData: ClassObjectDTO, index: number) => {
            if(classData.students && classData.students.length > 0) {
              if(classData.students.includes(Number(paramsUserId))) {
                studentClassArray.push(classData)
              }
            }
          });
          if(studentClassArray.map.length > 0) {
            studentClassArray.map((classData: ClassObjectDTO) => {
              classData.courseList?.map((course: CourseObjectDTO) => {
                course.courseModules?.map((module: ModuleObjectDTO) => {
                  module.lessons?.map((lesson: LessonObjectDTO) => {
                    const studentLessonDataObject = {
                      classId: classData.classId,
                      className: classData.title,
                      students: classData.students,
                      lesson
                    }
                    studentLessonsArray.push(studentLessonDataObject)
                  })
                })
              })
            })
          }
          setStudentEnrolledLessons(studentLessonsArray);
          setLoading(false);
        }
      }
    }
  }, [instructorLessonsArrayData, userData?.userRole, customerHierarchyClassData]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <>
          <div id="bio-div">
            <div id="bio-img">
              <span>
                <AccountCircleIcon fontSize="large" />
                <br />
              </span>
            </div>
            <div id="bio-name">
              <h1>
                {student?.firstName} {student?.lastName}
              </h1>
              <h2>Student</h2>
            </div>
          </div>
          <div id="student-lessons-display">
            <div className="row flex-dir-col">
              <h3 id="student-email-header">Email</h3>
              <p id="student-email">{student?.email}</p>
            </div>
            <h4>Lessons Enrolled In:</h4>
            <div className="lessons-display-div">
              <h5>
                {studentEnrolledLessons?.length === 1
                  ? `1 Total Lesson`
                  : `${studentEnrolledLessons?.length} Total Lessons`}
              </h5>
              {studentEnrolledLessons?.map((instructorLesson, index) => {
                return (
                  <div key={instructorLesson.lesson?.lessonId} className="lesson-div">
                    <div
                      className="lesson-info-row"
                      onClick={() =>
                        window.location.assign(
                          `/students/${paramsUserId}/lessons/${instructorLesson.lesson?.lessonId}/view/quizzes`,
                        )
                      }
                    >
                      <div className="lesson-abrev-div">
                        <p>LN{index + 1}</p>
                      </div>
                      <div className="lesson-details">
                        <p className="lesson-name">{instructorLesson.lesson?.title}</p>
                        <p className="instructor-name">
                          Instructor : {userData?.firstName} {userData?.lastName}
                        </p>
                        <p className="quizzes-completed-info">
                          Quizzes Completed:{' '}
                          {student?.studentMetrics?.lessons?.find(
                            (studentLesson) => studentLesson.lessonId === instructorLesson.lesson?.lessonId,
                          )
                            ? student?.studentMetrics?.lessons?.find(
                                (studentLesson) => studentLesson.lessonId === instructorLesson.lesson?.lessonId,
                              )?.quizResults?.length && student?.studentMetrics?.lessons?.find(
                                (studentLesson) => studentLesson.lessonId === instructorLesson.lesson?.lessonId,
                              )?.quizResults[0].guid ? student?.studentMetrics?.lessons?.find(
                                (studentLesson) => studentLesson.lessonId === instructorLesson.lesson?.lessonId,
                              )?.quizResults?.length
                            : ' 0' : ' 0'}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="back-btn-div">
              <button className="back-btn" type="button" onClick={() => navigate(-1)}>
                <span>Back</span>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StudentLessons;
