import { useEffect } from "react";

import EditClassForm from "../../components/editClassForm/EditClassForm";
import { useUserData, useUserRoleData } from "../account/Account";

const EditClass = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const userCustomerId = userData?.customerId

    useEffect(() => {
        if (userRoleName && userRoleName !== '') {
          if (userRoleName !== 'Admin') {
            window.location.assign('/');
          }
        }
      }, [userRoleName]);

    return (
        <>
            <EditClassForm
                userCustomerId={userCustomerId}
            />
        </>
    )
}

export default EditClass;