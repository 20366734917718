import { useState, useEffect } from 'react';
import { useUserData, useUserRoleData } from '../account/Account';
import './profile.scss';
import Auth from '../../utils/auth';
import ContactInfo from '../../components/contactInfo/ContactInfo';
import LessonsDisplay from '../../components/lessonsDisplay/LessonsDisplay';

const Profile = () => {
    const loggedIn = Auth.loggedIn();
    const loggedInUser = Auth.getProfile('id_token');
  
    if (!loggedIn) {
      window.location.assign('/');
    }
    const [loading, setLoading] = useState(true);
  
    const { userData } = useUserData();
    const { userRoleData } = useUserRoleData();
  
    useEffect(() => {
      if (loggedInUser && userData) {
        if (userData.userId !== loggedInUser.userId) {
          Auth.logout();
        }
      }
    }, [loggedInUser, userData]);
  
    useEffect(() => {
      if (userRoleData?.title) {
        setLoading(false);
      }
    }, [userRoleData]);
  return (
    <>
      {loading ? (
        <>
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        </>
      ) : (
        <div className="dashboard">
          <div className="contact-info-div">
            <ContactInfo />
          </div>
          {userRoleData?.title === 'Instructor' ? (
            <div className="lesson-display-div">
                <LessonsDisplay />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Profile;
