import QINode from "./QINode";

export default class ConfidenceTracker
    {
        confidences: Map<number, Set<number>>;     
        // MJG removed confidences from constructor and set default to 'new Map()'
        constructor() {
            this.confidences = new Map<number, Set<number>>()
        }
        add(qiNode: QINode): void
        {
            if (this.confidences.get(qiNode.id) != null) return;
            this.confidences.set(qiNode.id, new Set<number>());
        }

        remove(qiNode: QINode)
        {
            this.confidences.delete(qiNode.id);
        }

        clearConfidences()
        {
            this.confidences.forEach((v,k) =>
                {
                    v.clear();
                });
        }

        getAllAverageConfidences(): Map<number, number>
        {            
            let averageConfidences = new Map<number, number>();

            this.confidences.forEach((v, k) => 
            {
                let total = 0;
                v.forEach(confidence => 
                    {
                        total += confidence;
                    });
                total = total / v.size;
                averageConfidences.set(k, total);                
            });
            return averageConfidences;
        }

        getAverageConfidenceById(id: number): number
        {
            let total = 0;
            this.confidences.get(id)!.forEach( value => 
            {
                total += value;
            });
            return total / this.confidences.get(id)!.size;            
        }

        //TODO Create this logic
        getAverageConfidencesByIds(qiNodeIds: number[]): Map<number, number>
        {
            return new Map<number, number>();
        }

        tryUpdateConfidence(qiNode: QINode)
        {
            if(this.confidences.get(qiNode.id) == null) return;
            this.confidences.get(qiNode.id)?.add(qiNode.currentConfidence);
        }
    }