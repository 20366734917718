import { Link } from 'react-router-dom';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

import Auth from '../../utils/auth';
import { DashboardToNavProps } from '../dto/dashboardToNavProps.dto';
import './nav.scss';

const Nav: React.FC<DashboardToNavProps> = (props) => {
  const userLoggedIn = Auth.loggedIn();
  const { isSuperuser, userRole } = props;

  return (
    <nav>
      {isSuperuser && userRole === 'Admin' ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/users">
            <span>
              <DomainOutlinedIcon fontSize="large" />
              <br />
            </span>
            Users
          </Link>
          <Link to="/customers">
            <span>
              <GroupOutlinedIcon fontSize="large" />
              <br />
            </span>
            Customers
          </Link>
          <Link to="/useCases">
            <span>
              <WorkOutlineOutlinedIcon fontSize="large" />
              <br />
            </span>
            Use Cases
          </Link>
          <Link to="/tags">
            <span>
              <LocalOfferOutlinedIcon fontSize="large" />
              <br />
            </span>
            Tags
          </Link>
          <Link to="/tagsTable">
            <span>
              <LocalOfferOutlinedIcon fontSize="large" />
              <br />
            </span>
            Tags Table
          </Link>
          {/* <Link to="/apps">
            <span>
              <AppsOutlinedIcon fontSize="large" />
              <br />
            </span>
            Apps
          </Link> */}
          <Link to="/classes">
            <span>
              <SchoolOutlinedIcon fontSize="large" />
              <br />
            </span>
            Classes
          </Link>
          <Link to="/courses">
            <span>
              <MenuBookIcon fontSize="large" />
              <br />
            </span>
            Courses
          </Link>
          <Link to="/modules">
            <span>
              <ViewModuleIcon fontSize="large" />
              <br />
            </span>
            Modules
          </Link>
          <Link to="/lessons">
            <span>
              <AssignmentOutlinedIcon fontSize="large" />
              <br />
            </span>
            Lessons
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
          <Link to="/downloads/test">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Test
          </Link>
        </ul>
      ) : !isSuperuser && userRole === 'Admin' ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/users">
            <span>
              <DomainOutlinedIcon fontSize="large" />
              <br />
            </span>
            Users
          </Link>
          <Link to="/tags">
            <span>
              <LocalOfferOutlinedIcon fontSize="large" />
              <br />
            </span>
            Tags
          </Link>
          <Link to="/tagsTable">
            <span>
              <LocalOfferOutlinedIcon fontSize="large" />
              <br />
            </span>
            Tags Table
          </Link>
          {/* <Link to="/apps">
            <span>
              <AppsOutlinedIcon fontSize="large" />
              <br />
            </span>
            Apps
          </Link> */}
          <Link to="/classes">
            <span>
              <SchoolOutlinedIcon fontSize="large" />
              <br />
            </span>
            Classes
          </Link>
          <Link to="/courses">
            <span>
              <MenuBookIcon fontSize="large" />
              <br />
            </span>
            Courses
          </Link>
          <Link to="/modules">
            <span>
              <ViewModuleIcon fontSize="large" />
              <br />
            </span>
            Modules
          </Link>
          <Link to="/lessons">
            <span>
              <AssignmentOutlinedIcon fontSize="large" />
              <br />
            </span>
            Lessons
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      ) : userRole === 'Producer' ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/users">
            <span>
              <DomainOutlinedIcon fontSize="large" />
              <br />
            </span>
            Students
          </Link>
          <Link to="/lessons">
            <span>
              <AssignmentOutlinedIcon fontSize="large" />
              <br />
            </span>
            Lessons
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      ) : userRole === 'Instructor' ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/users">
            <span>
              <DomainOutlinedIcon fontSize="large" />
              <br />
            </span>
            Students
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      ) : userRole === 'Student' ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      ) : userLoggedIn ? (
        <ul>
          <Link to="/" onClick={() => Auth.logout()}>
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Logout
          </Link>
          <Link to="/">
            <span>
              <DashboardOutlinedIcon fontSize="large" />
              <br />
            </span>
            Dashboard
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      ) : (
        <ul>
          <Link to="/login">
            <span>
              <ExitToAppOutlinedIcon fontSize="large" />
              <br />
            </span>
            Login
          </Link>
          <Link to="/downloads">
            <span>
              <CloudDownloadOutlinedIcon fontSize="large" />
              <br />
            </span>
            Latest Builds
          </Link>
        </ul>
      )}
    </nav>
  );
};

export default Nav;
