import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './login.scss';
import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';
import qiLogo from '../../assets/images/qi_logo.png';
import { validateEmail } from '../../utils/helpers';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const Login = () => {
  const loggedInUser = Auth.getProfile('id_token');
  const url = window.location.toString();
  const urlArray = url.split('.');
  const userType = urlArray[2] === 'com' ? 'com' : urlArray[1] === 'com' ? 'com' : 'gov';
  Auth.addUserType(userType);

  useEffect(() => {
    if (loggedInUser && loggedInUser.userType) {
      const confirmUser = async () => {
        const resp = await VxAPI.confirmUserExists(loggedInUser.userId, loggedInUser.email, loggedInUser.customerId);
        if (!resp) Auth.logout();
        window.location.assign('/');
      };
      confirmUser().catch(console.error);
    }
  }, [loggedInUser]);

  const [message, setMessage] = useState('');
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });
  const [passVisible, setPassVisible] = useState(false);

  const { email, password } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault();

    const validEmail = validateEmail(email);

    if (email === '' || password === '' || !validEmail) {
      setMessage('Please enter a valid email and password before submitting');
      return;
    }

    const resp = await VxAPI.login({
      email,
      password,
    });

    if (resp.token) {
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

      const token = resp.token;
      const refreshToken = resp.refreshToken;

      Auth.login(token, refreshToken);
    } else {
      setMessage(resp.message);
    }
  };

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
        ev.preventDefault();
        handleSubmit(ev);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [email, password]);

  return (
    <div className="box">
      <div className="row">
        <h1 className="title">
            Login to QIVX
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row flex-dir-col">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" defaultValue={email} onChange={handleChange} />
        </div>
        <div className="row flex-dir-col">
          <label htmlFor="password">
            Password
            {passVisible ? (
              <VisibilityOffOutlinedIcon onClick={() => setPassVisible(!passVisible)} />
            ) : (
              <VisibilityOutlinedIcon onClick={() => setPassVisible(!passVisible)} />
            )}
          </label>
          <input
            type={passVisible ? 'text' : 'password'}
            name="password"
            defaultValue={password}
            onChange={handleChange}
          />
        </div>
        <div className="row justify-right form-link-div">
          <Link to="/forgot">Forgot Your Password?</Link>
        </div>
        <div className="row">
          <div className="line"></div>
        </div>
        <div className="row justify-center form-btn-div">
          <button type="submit">
            <span>Log In</span>
          </button>
        </div>
      </form>
      {message ? (
        <div className="row message-div">
          <p className="message">{message}</p>
        </div>
      ) : null}
      <div id="qi-logo-div">
        <img src={qiLogo} alt="Quantum Interface Logo."></img>
      </div>
    </div>
  );
};

export default Login;
