import Vector2 from "./Utilities/Vector2";
import Vector3 from "./Utilities/Vector3";

export default class Bounds
    {
        center: Vector3;
        size: Vector2;

        constructor(center: Vector3, size: Vector2)
        {
            this.center = center;
            this.size = size;
        }
    }