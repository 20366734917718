import { useEffect } from "react";
import EditLessonForm from "../../components/editLessonForm/EditLessonForm";

import { useUserData, useUserRoleData } from "../account/Account";

const EditLesson = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const userCustomerId = userData?.customerId
    useEffect(() => {
        if (userRoleName && userRoleName !== '') {
          if (userRoleName === 'Admin' || userRoleName === 'Producer') {
            return;
          } else {
            window.location.assign('/');
          }
        }
      }, [userRoleName]);

    return (
        <>
            <EditLessonForm
                userCustomerId={userCustomerId}
            />
        </>
    )
}

export default EditLesson;