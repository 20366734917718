import { useEffect, useState, MouseEvent } from 'react';

import './classes.scss';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import Auth from '../../utils/auth';
import { ClassObjectForSearchDTO } from '../../components/dto/classObjectForSearch.dto';

const Classes = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  const [loading, setLoading] = useState(true);
  const [classObjectArrayData, setClassObjectArrayData] = useState<ClassObjectDTO[]>([
    {
      customerId: 0,
      useCaseId: 0,
      classId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      courses: [0],
      principals: [0],
      tags: [0],
    },
  ]);

  const [classesObjectArrayWithUseCaseNames, setClassObjectArrayWithUseCaseNames] =
    useState<ClassObjectForSearchDTO[]>();

  const [updatedClassArray, setUpdatedClassArray] = useState<ClassObjectForSearchDTO[]>([
    {
      customerId: 0,
      useCaseId: '',
      classId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      courses: [0],
      principals: [0],
      tags: [0],
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [coursesObjectArrayData, setCoursesObjectArrayData] = useState<CourseObjectDTO[]>([
    {
      useCaseId: 0,
      customerId: 0,
      courseId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      classes: [],
    },
  ]);

  const [students, setStudents] = useState<UserObjectDTO[]>();

  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const classData = await VxAPI.getAllClassesHierarchyData();
      if (classData) {
        setClassObjectArrayData(classData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      const useCaseData = await VxAPI.getAllUseCasesData();

      setUseCasesObjectArrayData(useCaseData);

      const courseData = await VxAPI.getAllCoursesData();

      setCoursesObjectArrayData(courseData);

      const studentRole = 1;
      const studentData = await VxAPI.getUserByRoleByCustomerId(studentRole);
      if (studentData) {
        setStudents(studentData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName !== 'Admin') {
        window.location.assign('/');
      }
    }
  }, [userRoleName]);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const classId = e.currentTarget.id.split('-').pop();

    window.location.assign(`classes/edit/${classId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (useCasesObjectArrayData[0].useCaseId !== 0 && students) {
      const classesWithUseCaseName = classObjectArrayData.map((classInfo: ClassObjectForSearchDTO) => {
        let studentNameArray: string[] = [];
        if (classInfo.students && classInfo.students.length > 0) {
          classInfo.students.map((studentId) => {
            let studentFirstName = students?.find((user) => user.userId === studentId)?.firstName;
            let studentLastName = students?.find((user) => user.userId === studentId)?.lastName;
            let studentName = `${studentFirstName} ${studentLastName}`;

            if (studentName.includes('undefined')) {
              return;
            }
            studentNameArray.push(studentName);
          });
        }
        classInfo.students = studentNameArray;
        classInfo.useCaseId = useCasesObjectArrayData.find(
          (useCase) => useCase.useCaseId === classInfo.useCaseId,
        )?.title;
        return classInfo;
      });
      setClassObjectArrayWithUseCaseNames(classesWithUseCaseName);
      setUpdatedClassArray(classesWithUseCaseName);
      setLoading(false);
    }
  }, [classObjectArrayData, useCasesObjectArrayData, students]);

  useEffect(() => {
    if (classesObjectArrayWithUseCaseNames) {
      if (searchText === '') {
        setUpdatedClassArray(classesObjectArrayWithUseCaseNames);
      }

      const updatedClasss = classesObjectArrayWithUseCaseNames?.filter(
        (classInfo) =>
          classInfo.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          classInfo.useCaseId?.toString().toLowerCase()?.includes(searchText.toLowerCase()) ||
          classInfo.students?.toString().toLowerCase().includes(searchText.toLowerCase()),
      );

      setUpdatedClassArray(updatedClasss);
    }
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="classes-div">
          <div className="row justify-space-btw">
            <h1>Classes</h1>
            <div id="class-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button id="add-class-btn" type="button" onClick={() => window.location.assign('classes/add')}>
                <span>+Add Class</span>
              </button>
            </div>
            <div id="classes-titles">
              <h2 id="class-name-header">Class Name</h2>
              <div id="secondary-class-headers" className="row">
                <h2 id="class-cust-header">Customer</h2>
                <h2 id="class-useCase-header">Use Case</h2>
                <h2 id="class-courses-header">Courses</h2>
                <h2 id="class-students-header">Students</h2>
                <h2 id="class-desc-header">Description</h2>
                <h2 id="class-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedClassArray
            ? updatedClassArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .slice(0, itemsToShow)
                .map((classes: ClassObjectForSearchDTO, index: number) => (
                  <>
                    <div id="class-info-div" key={index}>
                      <div id="class-name">
                        <p>{classes.title}</p>
                      </div>
                      <div id="secondary-class-data">
                        <p id="class-cust">
                          {
                            customersObjectArrayData.find((customer) => customer.customerId === classes.customerId)
                              ?.firstName
                          }{' '}
                          {
                            customersObjectArrayData.find((customer) => customer.customerId === classes.customerId)
                              ?.lastName
                          }
                        </p>
                        <p id="class-useCase">{classes.useCaseId}</p>
                        <p id="class-courses">
                          {coursesObjectArrayData.find((course) => course.courseId === classes.courses)?.title}
                        </p>
                        <ul id="class-students">
                          {classes.students
                          ?.sort((a: any, b: any) => {
                            if (a < b) {
                              return -1;
                            }
                            if (a > b) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((studentName, index) => {
                            if (classes.students?.length === index + 1) {
                              return <li key={`student-${index}`}>{studentName}</li>;
                            }
                            return <li key={`student-${index}`}>{studentName},</li>;
                          })}
                        </ul>
                        <p id="class-desc">{classes.description}</p>
                        <div id="class-more">
                          <button
                            type="button"
                            id={`edit-class-btn-${classes.classId}`}
                            className="edit-btn"
                            onClick={handleEditBtnClick}
                          >
                            <span>Edit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="class-line"></div>
                  </>
                ))
            : null}
          <>
            {!expanded && updatedClassArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedClassArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Classes;
