import React, { useState, useEffect } from 'react';

import { VxAPI } from '../../api/vx.api';
import { FormStateDTO } from '../dto/addUser.formState.dto';
import { AddUserSelectStateDTO } from '../dto/addUser.selectState.dto';
import Auth from '../../utils/auth';
import { UserRoleObjectDTO } from '../dto/userRoleObject.dto';
import { LocationObjectDTO } from '../dto/locationObject.dto';
import './addUserForm.scss';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from '@material-ui/core';
import { TagObjectDTO } from '../dto/tagObject.dto';

const AddUserForm: React.FC<QiEmployeeProps> = (props) => {
  const { qiEmployee } = props;
  const [message, setMessage] = useState('');
  const [selectTagsState, setSelectTagsState] = useState<number[]>([]);
  let [selectState, setSelectState] = useState<AddUserSelectStateDTO>({
    userRole: undefined,
    location: undefined,
    customerId: undefined,
  });
  const [formState, setFormState] = useState<FormStateDTO>({
    lastName: '',
    firstName: '',
    email: '',
  });

  const [userRoleArray, setUserRoleArray] = useState<UserRoleObjectDTO[]>([
    {
      id: 0,
      title: '',
      description: '',
    },
  ]);

  const [locationArray, setLocationArray] = useState<LocationObjectDTO[]>([
    {
      locationId: 0,
      title: '',
    },
  ]);

  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const roleDesc = [
    {
      name: 'Admin',
      desc: 'Can grant and assign user roles, create lessons, invite students to lessons, and access student information. This user can also access student confidence metrics as they navigate lessons.',
    },
    {
      name: 'Instructor',
      desc: 'Can create lessons, make quizzes, invite students to lessons, and access student information. This user can also access student confidence metrics as they navigate lessons.',
    },
    {
      name: 'Producer',
      desc: 'Can create certified lessons within a domain.',
    },
    {
      name: 'Student',
      desc: 'Can participate in lessons and quizzes. ',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const userRoleData = await VxAPI.getUserRoles();

      if (userRoleData) {
        setUserRoleArray(userRoleData);
      }

      const locationData = await VxAPI.getAllLocations();

      if (locationData) {
        setLocationArray(locationData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const tagsData = await VxAPI.getAllTagsData();

      if (tagsData) {
        setTagArray(tagsData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
      const loggedInUser = Auth.getProfile('id_token');
      if (loggedInUser) {
        let { customerId } = loggedInUser;
        setSelectState({ ...selectState, [e.target.name]: e.target.value, customerId: customerId });
      }
  };

  const handleMultiSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newItems = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newItems.push(Number(selectedOptions[i].value));
    }

    switch (e.target.name) {
      case 'tagArray':
        setSelectTagsState(newItems);
        break;
    }
  };

  const handleRoleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');

    if (!qiEmployee) {
      const loggedInUser = Auth.getProfile('id_token');
      if (loggedInUser) {
        let { customerId } = loggedInUser;
        setSelectState({ ...selectState, [e.target.name]: e.target.value, customerId: customerId });
        return;
      }
    }
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value) });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setMessage('Sending Invite...');
    const newToken = await Auth.refreshToken();

    const { userRole, location, customerId } = selectState;
    const { lastName, firstName, email } = formState;

    if (
      userRole === undefined ||
      userRole === 0 ||
      customerId === undefined ||
      customerId === 0 ||
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      location === '' ||
      location === undefined ||
      location === 'select'
    ) {
      setMessage('Make sure all fields are completed.');
      return;
    }
    let tags: number[] = selectTagsState;
    if (selectTagsState?.length > 0) {
      selectTagsState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone

    let createdDate = currentDate;

    const resp = await VxAPI.addUser({
      lastName,
      firstName,
      email,
      location,
      userRole,
      customerId,
      createdDate,
      tags,
    });
    if (resp.userId) {
      window.location.assign('/users');
    } else if (resp.message === 'duplicate key value violates unique constraint "UQ_cf7f971029de7cb3c0ea40cc1ed"') {
      setMessage('Email already exists. Please tell user to click the forgot password link if necessary.');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  return (
    <>
      <div id="add-user-div">
        <h1>Add User</h1>
        <h2>1. Select Role</h2>
        <form onSubmit={handleSubmit}>
          <div id="select-role-div">
            {userRoleArray
              ? userRoleArray.map((role: UserRoleObjectDTO, index: number) => (
                  <div className="select-role-items">
                    <label key={`label-${index}`} className="radio-container">
                      {role.title}
                      <input
                        className="radio-input"
                        type="radio"
                        key={`button-${index}`}
                        name="userRole"
                        value={role.id}
                        onChange={handleRoleSelection}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <Tooltip
                      title={
                        <p>
                          <span id="tooltip-title">{role.title}</span>
                          <br></br> <br></br>{' '}
                          <span id="tooltip-desc">
                            {roleDesc.find((roleDesc) => roleDesc.name === role.title)?.desc}
                          </span>
                        </p>
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                ))
              : null}
            <div className="add-tag-dropdown">
              <label id="tag-dropdown-header" htmlFor="selectTagsState[]">
                2. Select Tags
              </label>
              <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
              <select multiple size={tagArray?.length} name="tagArray" onChange={handleMultiSelection}>
                {tagArray
                  ? tagArray
                  .sort((a: any, b: any) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((tag: TagObjectDTO, index: number) => (
                      <option key={index} value={tag.tagId}>
                        {tag.title}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <h3>3. Personal Information</h3>
          <div id="add-user-name">
            <div id="add-user-first">
              <label htmlFor="firstName">
                First Name<span>*</span>
              </label>
              <input type="text" name="firstName" defaultValue={formState.firstName} onChange={handleChange} />
            </div>
            <div id="add-user-last">
              <label htmlFor="lastName">
                Last Name<span>*</span>
              </label>
              <input type="text" name="lastName" defaultValue={formState.lastName} onChange={handleChange} />
            </div>
          </div>
          <div id="add-location-cust-div">
            <div id="add-user-location">
              <label htmlFor="location">
                Location<span>*</span>
              </label>
              <select name="location" onChange={handleSelection}>
                <option value="select">---</option>
                {locationArray
                  ? locationArray
                  .sort((a: any, b: any) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((location: LocationObjectDTO, index: number) => (
                      <option key={`location-${index}`} value={location.title}>
                        {location.title}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            {qiEmployee ? (
              <div id="add-user-customer">
                <label htmlFor="customerId">
                  Customer<span>*</span>
                </label>
                <select name="customerId" onChange={handleSelection}>
                  <option value={0}>---</option>
                  {customerArray
                    ? customerArray.map((customer: CustomerObjectDTO, index: number) => (
                        <option key={index} value={customer.customerId}>
                          {customer.firstName} {customer.lastName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            ) : null}
          </div>
          <h4>4. Invitation Address</h4>
          <div id="add-user-email">
            <label htmlFor="email">
              Email Address<span>*</span>
            </label>
            <input type="email" name="email" defaultValue={formState.email} onChange={handleChange} />
          </div>
          <div id="add-user-btns">
            <button id="cancel-btn" type="button" onClick={() => window.location.assign('/users')}>
              <span>Cancel</span>
            </button>
            <button id="invite-btn" type="submit">
              <span>Send Invite</span>
            </button>
          </div>
        </form>
        {message ? <h3 className="message">{message}</h3> : null}
      </div>
    </>
  );
};

export default AddUserForm;
