import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import './singleQuiz.scss';
import Auth from '../../utils/auth';
import { useInstructorLessonsArrayData, useUserData } from '../account/Account';
import { HierarchicalLessonObjectDTO } from '../../components/dto/hierarchicalLessonObject.dto';
import { calcArrayAvg } from '../../utils/helpers';

const SingleQuiz = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userData } = useUserData();
  const { instructorLessonsArrayData } = useInstructorLessonsArrayData();
  const navigate = useNavigate();
  const params = useParams();
  const paramsLessonId = Number(params.lessonId);
  const paramsClassId = Number(params.classId);
  const paramsQuizGuid = params.guid;
  const [loading, setLoading] = useState(true);
  const [instructorLesson, setInstructorLesson] = useState<HierarchicalLessonObjectDTO>();
  const [studentUserIds, setStudentUserIds] = useState<number[]>([]);
  const [students, setStudents] = useState<UserObjectDTO[]>([]);
  const [gradedStudents, setGradedStudents] = useState<UserObjectDTO[]>([]);
  const [quizName, setQuizName] = useState<string>();
  const [quizAvgScore, setQuizAvgScore] = useState<number>();
  const [allStudentMetrics, setAllStudentMetrics] = useState<any[]>();

  useEffect(() => {
    let eachQuizScore: number[] = [];
    const quizGuidMetricsArrayObjects: any = [];
    if (students.length > 0) {
      let filteredStudents: any = [];
      students.map((student: UserObjectDTO) => {
        student?.studentMetrics?.lessons?.map((lesson) => {
          if (lesson.lessonId === paramsLessonId) {
            filteredStudents.push(student);
            lesson.quizResults.map((quiz) => {
              if (paramsQuizGuid === quiz.guid) {
                const quizGuidArrayObject = {
                  userId: student.userId,
                  firstName: student.firstName,
                  lastName: student.lastName,
                  quizGuid: quiz.guid,
                  quizTitle: quiz.title,
                  userQuizGlobalScore: quiz.quizGlobalScore,
                  userQuizGlobalConfidence: quiz.quizGlobalConfidence
                };
                quizGuidMetricsArrayObjects.push(quizGuidArrayObject);
                eachQuizScore.push(quiz.quizGlobalScore);
              }
            });
          }
        });
      });
      setQuizAvgScore(calcArrayAvg(eachQuizScore));
      setGradedStudents(filteredStudents);
      setQuizName(quizGuidMetricsArrayObjects[0].quizTitle);
      setAllStudentMetrics(quizGuidMetricsArrayObjects);
    }
  }, [students.length]);

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  useEffect(() => {
    const foundLesson = instructorLessonsArrayData?.find(
      (instructorLesson) => instructorLesson.classId === paramsClassId && instructorLesson.lesson?.lessonId === paramsLessonId,
    );
    if (foundLesson) {
      setInstructorLesson(foundLesson);
      if (foundLesson.students) {
        setStudentUserIds(foundLesson.students);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (studentUserIds.length > 0) {
        const enrolledStudentsData = await VxAPI.getUsersByArrayOfIds({ userIdArray: studentUserIds });
        if (enrolledStudentsData) {
          setStudents(enrolledStudentsData);
        }
      }
    };
    fetchData().catch(console.error);
    setLoading(false);
  }, [studentUserIds]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="quiz-display">
          <h1>{quizName}</h1>
          <p id="avg-score">Average: {quizAvgScore?.toFixed(2)}%</p>
          <div id="student-display">
            <h2>
              {students?.length === 1
                ? `${students?.length} Total Student`
                : !students?.length
                ? '0 Total Students'
                : `${students?.length} Total Students`}
            </h2>
            <div id="student-list-div">
              <div id="student-headers">
                <h3 id="student-name-header">Student</h3>
                <div id="secondary-student-headers">
                  <h3 id="student-grade-header">Avg Grade</h3>
                  <h3 id="student-confidence-header">Avg Confidence</h3>
                  {/* <h3 id="student-time-header">Time Elapsed</h3> */}
                </div>
              </div>
              {students
                ? students.map((student: UserObjectDTO, index: number) => {
                    const currentStudentMetrics = allStudentMetrics?.find(
                      (quizMetric) => quizMetric.userId === student.userId,
                    );
                    return (
                      <>
                        {currentStudentMetrics ? (
                          <>
                            <div
                              key={index}
                              id="student-info-div"
                              onClick={() =>
                                window.location.assign(
                                  `/lessons/${paramsLessonId}/quizzes/${paramsQuizGuid}/students/view/${student.userId}`,
                                )
                              }
                            >
                              <div id="student-name">
                                <p>
                                  {student.firstName} {student.lastName}
                                </p>
                              </div>
                              <div id="secondary-student-data">
                                <div id="student-grade">
                                  <p>
                                      {currentStudentMetrics.userQuizGlobalScore.toFixed(2)}%
                                  </p>
                                </div>
                                <>
                                  {(currentStudentMetrics.userQuizGlobalConfidence || 0) >= 0.70 ? (
                                      <>
                                        <div id="student-confidence-status">
                                          <div id="high-confidence-cir"></div>
                                          <p>High</p>
                                        </div>
                                      </>
                                    ) : (currentStudentMetrics.userQuizGlobalConfidence || 0) >= 0.45 ? (
                                      <>
                                        <div id="student-confidence-status">
                                          <div id="medium-confidence-cir"></div>
                                          <p>Medium</p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div id="student-confidence-status">
                                          <div id="low-confidence-cir"></div>
                                          <p>Low</p>
                                        </div>
                                      </>
                                    )}
                                </>
                                {/* <div id="student-time">
                                    <p>{studentMetricsLessonObject?.totalTime}</p>
                                  </div> */}
                              </div>
                            </div>
                            <div className="student-line"></div>
                          </>
                        ) : (
                          <>
                            <div key={index} id="student-no-info-div">
                              <div id="student-name">
                                <p>
                                  {student.firstName} {student.lastName}
                                </p>
                              </div>
                              <div id="secondary-student-no-data">
                                <div id="student-grade">
                                  <p>--</p>
                                </div>
                                <div id="student-confidence-status">
                                  <p>--</p>
                                </div>
                                {/* <div id="student-time">
                                    <p>{studentMetricsLessonObject?.totalTime || '--'}</p>
                                  </div> */}
                              </div>
                            </div>
                            <div className="student-line"></div>
                          </>
                        )}
                      </>
                    );
                  })
                : null}
              <div className="back-btn-div">
                <button className="back-btn" type="button" onClick={() => navigate(-1)}>
                  <span>Back</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleQuiz;
