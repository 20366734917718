import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { UserDataDTO } from '../../components/dto/userData.dto';
import { useParams } from 'react-router-dom';
import { LocationObjectDTO } from '../../components/dto/locationObject.dto';
import { UserRoleObjectDTO } from '../../components/dto/userRoleObject.dto';
import Auth from '../../utils/auth';
import './editUserForm.scss';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const EditUserForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId, qiEmployee } = props;

  const params = useParams();
  const paramsUserId = Number(params.userId);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [birthday, setBirthday] = useState('');
  const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);
  const [userInfo, setUserInfo] = useState<UserDataDTO>({
    userId: 0,
    email: '',
    customerId: 0,
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    userRole: 0,
    phone: '',
    level: '',
    location: '',
    dateOfBirth: new Date(),
  });
  const [userRoleArray, setUserRoleArray] = useState<UserRoleObjectDTO[]>([
    {
      id: 0,
      title: '',
      description: '',
    },
  ]);

  const [locationArray, setLocationArray] = useState<LocationObjectDTO[]>([
    {
      locationId: 0,
      title: '',
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const [availableTags, setAvailableTags] = useState<TagObjectDTO[]>();
  const [selectTagState, setSelectTagState] = useState<number[]>([]);
  const [currentlySelectedTagIds, setCurrentlySelectedTagIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await VxAPI.getSingleUserData(paramsUserId);

      if (userData) {
        const stringDate = userData.dateOfBirth;
        setBirthday(stringDate);
        setUserInfo(userData);
        if (userData.tags && userData.tags[0] !== 0) {
          setSelectTagState(userData.tags);
          setCurrentlySelectedTagIds(userData.tags);
        }
        setLoading(false);
      }

      const userRoleData = await VxAPI.getUserRoles();

      setUserRoleArray(userRoleData);

      const locationData = await VxAPI.getAllLocations();

      if (locationData) {
        setLocationArray(locationData);
      }

      const tagsData = await VxAPI.getAllTagsData();

      if (tagsData) {
        setTagArray(tagsData);
        setAvailableTags(tagsData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    if (e.target.name === 'userRole') {
      setUserInfo({ ...userInfo, [e.target.name]: Number.parseInt(e.target.value) });
      return;
    }
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleTagSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newTags = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newTags.push(Number(selectedOptions[i].value));
    }

    setSelectTagState(newTags);
  };

  const handleTagReset = () => {
    setSelectTagState(currentlySelectedTagIds);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();
    const {
      userId,
      userRole,
      location,
      lastName,
      firstName,
      phone,
      dateOfBirth,
      city,
      state,
      level,
      zip,
      address2,
      address,
    } = userInfo;

    if (
      userRole === undefined ||
      userRole === 0 ||
      firstName === '' ||
      lastName === '' ||
      location === '' ||
      location === undefined
    ) {
      setMessage('Make sure all required fields are completed.');
      return;
    }
    let tags: number[] = selectTagState;
    if (selectTagState?.length > 0) {
      selectTagState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let modifiedDate = currentDate;

    const resp = await VxAPI.editUser(
      {
        lastName,
        firstName,
        location,
        userRole,
        phone,
        dateOfBirth,
        city,
        state,
        zip,
        level,
        address2,
        address,
        modifiedDate,
        tags,
      },
      userId,
    );

    if (resp.userId) {
      window.location.assign('/users');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    setLoggedInUserCustomerId(userCustomerId);

    if (userCustomerId !== null && userInfo.customerId !== 0) {
      if (userInfo.customerId !== userCustomerId) {
        window.location.assign('/');
      }
    }
  }, [userCustomerId, userInfo]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="edit-user-div">
          <h1>Edit User Info</h1>
          <form onSubmit={handleSubmit}>
            <div className="edit-user-row">
              <div className="edit-user-form-input">
                <label htmlFor="firstName">First Name*</label>
                <input type="text" name="firstName" defaultValue={userInfo.firstName} onChange={handleChange} />
              </div>
              <div className="edit-user-form-input">
                <label htmlFor="lastName">Last Name*</label>
                <input type="text" name="lastName" defaultValue={userInfo.lastName} onChange={handleChange} />
              </div>
            </div>
            <div className="edit-user-row">
              <div className="edit-user-form-input">
                <label htmlFor="phone">Phone</label>
                <input type="phone" name="phone" defaultValue={userInfo.phone} onChange={handleChange} />
              </div>
              <div className="edit-user-form-input">
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <input type="date" name="dateOfBirth" defaultValue={birthday} onChange={handleChange} />
              </div>
            </div>
            <div className="edit-user-row">
              <div className="edit-user-form-input">
                <label htmlFor="address">Mailing Address Line 1</label>
                <input type="text" name="address" defaultValue={userInfo.address} onChange={handleChange} />
              </div>
              <div className="edit-user-form-input">
                <label htmlFor="address2">Mailing Address Line 2</label>
                <input type="text" name="address2" defaultValue={userInfo.address2} onChange={handleChange} />
              </div>
            </div>
            <div className="edit-user-row">
              <div className="edit-user-form-input">
                <label htmlFor="city">City</label>
                <input type="text" name="city" defaultValue={userInfo.city} onChange={handleChange} />
              </div>
              <div className="edit-user-form-input">
                <label htmlFor="state">State</label>
                <input type="text" name="state" defaultValue={userInfo.state} onChange={handleChange} />
              </div>
            </div>
            <div className="edit-user-row">
              <div className="edit-user-form-input">
                <label htmlFor="zip">Zip</label>
                <input type="text" name="zip" defaultValue={userInfo.zip} onChange={handleChange} />
              </div>
              <div className="edit-user-form-input">
                <label htmlFor="level">Level</label>
                <input type="text" name="level" defaultValue={userInfo.level} onChange={handleChange} />
              </div>
            </div>
            <div className="dropdown-div">
              <div className="edit-user-dropdown">
                <label htmlFor="userRole">User Role*</label>
                <select name="userRole" onChange={handleSelection}>
                  <option value={userInfo.userRole}>
                    {userRoleArray.find((role) => role.id === userInfo.userRole)?.title}
                  </option>
                  {userRoleArray
                    ? userRoleArray.map((role: UserRoleObjectDTO, index: number) => (
                        <option
                          style={{ display: role.id === userInfo.userRole ? 'none' : 'block' }}
                          key={index}
                          value={role.id}
                        >
                          {role.title}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="edit-user-dropdown">
                <label htmlFor="location">Location*</label>
                <select name="location" onChange={handleSelection}>
                  <option value={userInfo.location}>{userInfo.location}</option>
                  {locationArray
                    ? locationArray
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((location: LocationObjectDTO, index: number) => (
                        <option
                          style={{ display: location.title === userInfo.location ? 'none' : 'block' }}
                          key={index}
                          value={location.title}
                        >
                          {location.title}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="add-tag-dropdown">
                  <label id="tag-dropdown-header" htmlFor="selectTagState[]">
                    Edit User Tags
                  </label>
                  <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
                  <select multiple size={availableTags?.length} name="tagArray" onChange={handleTagSelection}>
                    {availableTags
                      ? availableTags
                      .sort((a: any, b: any) => {
                        if (a.title < b.title) {
                          return -1;
                        }
                        if (a.title > b.title) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((tag, index: number) => (
                          <option
                            {...(selectTagState.includes(Number(tag.tagId)) ? { selected: true } : null)}
                            key={index}
                            value={tag.tagId}
                          >
                            {tag.title}
                          </option>
                        ))
                      : null}
                  </select>
                  <button type="button" id="reset-btn" onClick={handleTagReset}>
                    <span>Reset</span>
                  </button>
                </div>
            </div>
            <div id="edit-user-btns">
              <button type="button" onClick={() => window.location.assign('/users')}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Save</span>
              </button>
            </div>
          </form>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default EditUserForm;
