import { useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';
import './addUseCaseForm.scss';

const AddUseCaseForm = () => {
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({
        title: '',
        description: '',
        notes: '',
    })

    const { title, description, notes } = formState

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage('');
        setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        if (
            title === '' ||
            description === ''
        ) {
            setMessage('Make sure all required fields are completed.')
            return;
        }

        const resp = await VxAPI.addUseCase({
            title,
            description,
            notes
        })


        if (resp.useCaseId) {
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );

           window.location.assign('/useCases')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }
    return (
        <div id='add-useCase-div'>
            <h1>Create a Use Case</h1>
            <form onSubmit={handleSubmit}>
                <div className='add-useCase-form-input'>
                    <label htmlFor='title'>Name of Use Case *</label>
                    <input type='text' name='title' defaultValue={title} onChange={handleChange} />
                </div>
                <div className='add-useCase-form-textarea'>
                    <label htmlFor='description'>Description * </label>
                    <textarea name='description' defaultValue={description} onChange={handleChange} />
                </div>
                <div className='add-useCase-form-textarea'>
                    <label htmlFor='notes'>Notes</label>
                    <textarea name='notes' defaultValue={notes} onChange={handleChange} />
                </div>
                <div id="add-useCase-btns">
                    <button type="button" onClick={() => window.location.assign('/useCases')}><span>Cancel</span></button>
                    <button type="submit"><span>Create</span></button>
                </div>
            </form>
            {message ? (
                <h3 className="message">{message}</h3>
            ) : null}
        </div>
    )
}

export default AddUseCaseForm;