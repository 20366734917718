import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './editClassForm.scss';
import { ClassObjectDTO } from '../dto/classObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const EditClassForm: React.FC<QiEmployeeProps> = (props) => {
  const { qiEmployee, userCustomerId } = props;
  const params = useParams();
  const paramsClassId = Number(params.classId);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const [availableTags, setAvailableTags] = useState<TagObjectDTO[]>();
  const [selectTagState, setSelectTagState] = useState<number[]>([]);
  const [currentlySelectedTagIds, setCurrentlySelectedTagIds] = useState<number[]>([]);
  const [selectState, setSelectState] = useState<(number | undefined)[]>([]);
  const [currentlyEnrolledStudentIds, setCurrentlyEnrolledStudentIds] = useState<(number | undefined)[]>([]);

  const [classInfo, setClassInfo] = useState<ClassObjectDTO>({
    title: '',
    description: '',
    notes: '',
    customerId: 0,
    useCaseId: 0,
    classId: 0,
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [students, setStudents] = useState<UserObjectDTO[]>();

  const { title, description, notes, customerId, useCaseId } = classInfo;

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newStudents = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newStudents.push(Number(selectedOptions[i].value));
    }

    setSelectState(newStudents);
  };

  const handleTagSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newTags = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newTags.push(Number(selectedOptions[i].value));
    }

    setSelectTagState(newTags);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setClassInfo({ ...classInfo, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setSelectState(currentlyEnrolledStudentIds);
  };

  const handleTagReset = () => {
    setSelectTagState(currentlySelectedTagIds);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (
      title === '' ||
      description === '' ||
      customerId === undefined ||
      customerId === 0 ||
      useCaseId === undefined ||
      useCaseId === 0
    ) {
      setMessage('Make sure all required fields are completed.');
      return;
    }
    let tags: number[] = selectTagState;
    if (selectTagState?.length > 0) {
      selectTagState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let modifiedDate = currentDate;

    let studentIdsToRemove: (number | undefined)[] = [];
    let studentIdsToAdd: (number | undefined)[] = [];

    currentlyEnrolledStudentIds.forEach((studentId) => {
      if (!selectState.includes(studentId)) {
        studentIdsToRemove.push(studentId);
      }
    });

    selectState.forEach((studentId) => {
      if (!currentlyEnrolledStudentIds.includes(studentId)) {
        studentIdsToAdd.push(studentId);
      }
    });

    if (studentIdsToRemove.length > 0) {
      const removeUsersResp = await VxAPI.removeUsersFromClass(classInfo.classId, { userIdArray: studentIdsToRemove });
      if (!removeUsersResp[0].userId) {
        setMessage(
          'User could not be removed from class. Please refresh and try again. If error persists contact your administrator.',
        );
        return;
      }
    }

    if (studentIdsToAdd.length > 0) {
      const addUsersResp = await VxAPI.addUsersToClass(classInfo.classId, { userIdArray: studentIdsToAdd });
      if (!addUsersResp[0].userId) {
        setMessage(
          'User(s) could not be added to class. Please refresh and try again. If error persists contact your administrator.',
        );
        return;
      }
    }

    setMessage('Editing...');
    const resp = await VxAPI.editClass(
      {
        customerId,
        useCaseId,
        title,
        description,
        notes,
        tags,
        modifiedDate,
      },
      paramsClassId,
    );

    if (resp.classId) {
      window.location.assign('/classes');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const classData = await VxAPI.getAllHierarchyDataSingleClass(paramsClassId);
      if (classData) {
        setClassInfo(classData);
        if (classData.students.length > 0) {
          const enrolledStudentsData = await VxAPI.getUsersByArrayOfIds({ userIdArray: classData.students });
          const enrolledStudents = [];
          for (let i = 0; i < enrolledStudentsData.length; i++) {
            enrolledStudents.push(enrolledStudentsData[i].userId);
          }
          setSelectState(enrolledStudents);
          setCurrentlyEnrolledStudentIds(enrolledStudents);
        }
        if (classData.tags && classData.tags[0] !== 0) {
          setSelectTagState(classData.tags);
          setCurrentlySelectedTagIds(classData.tags);
        }
        setLoading(false);
      }

      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }
      const studentRole = 1;
      const studentData = await VxAPI.getUserByRoleByCustomerId(studentRole);
      if (studentData) {
        setStudents(studentData);
      }

      const tagsData = await VxAPI.getAllTagsData();

      if (tagsData) {
        setTagArray(tagsData);
        setAvailableTags(tagsData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setLoggedInUserCustomerId(userCustomerId);

    if (userCustomerId !== null && classInfo.customerId !== 0) {
      if (classInfo.customerId !== userCustomerId) {
        window.location.assign('/');
      }
    }
  }, [userCustomerId, classInfo]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="edit-class-div">
          <h1>Edit Class</h1>
          <form onSubmit={handleSubmit}>
            <div className="dropdown-div">
              <div className="edit-class-hardcode">
                <label htmlFor="name">Customer</label>
                <p id="class-cust-name">
                  {customerArray.find((customer) => customer.customerId === classInfo.customerId)?.firstName}{' '}
                  {customerArray.find((customer) => customer.customerId === classInfo.customerId)?.lastName}
                </p>
              </div>
              <div className="edit-class-hardcode">
                <label htmlFor="useCaseId">Use Case</label>
                <p id="class-cust-useCase">
                  {useCaseArray.find((useCase) => useCase.useCaseId === classInfo.useCaseId)?.title}
                </p>
              </div>
            </div>
            <div className="edit-class-row">
              <div className="edit-class-form-input">
                <label htmlFor="title">Class Name *</label>
                <input type="text" name="title" defaultValue={title} onChange={handleChange} />
              </div>
            </div>
            <div className="edit-class-form-textarea">
              <label htmlFor="description">Description *</label>
              <textarea name="description" defaultValue={description} onChange={handleChange} />
            </div>
            <div className="edit-class-form-textarea">
              <label htmlFor="notes">Notes</label>
              <textarea name="notes" defaultValue={notes} onChange={handleChange} />
            </div>
            <div className="selection-div">
              <div className="edit-student-dropdown">
                <label id="student-dropdown-header" htmlFor="selectState[]">
                  Edit Students
                </label>
                <h2 id="student-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={students?.length} name="studentArray" onChange={handleSelection}>
                  {students
                    ? students
                    .sort((a: any, b: any) => {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((student, index: number) => (
                        <option
                          {...(selectState.includes(Number(student.userId)) ? { selected: true } : null)}
                          key={index}
                          value={student.userId}
                        >
                          {student.firstName} {student.lastName}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleReset}>
                  <span>Reset</span>
                </button>
                <div id="student-list-div">
                  <h3>Enrolled Students (Count: {selectState.length})</h3>
                  <ul id="student-list">
                    {selectState &&
                      selectState
                      .map((enrolledStudent) => {
                        let foundStudent = students?.find((student) => student.userId === enrolledStudent);
                        return (
                          <li>
                            {foundStudent?.firstName} {foundStudent?.lastName}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="edit-tag-dropdown">
                <label id="tag-dropdown-header" htmlFor="selectTagState[]">
                  Edit Tags
                </label>
                <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={availableTags?.length} name="tagArray" onChange={handleTagSelection}>
                  {availableTags
                    ? availableTags
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag, index: number) => (
                        <option
                          {...(selectTagState.includes(Number(tag.tagId)) ? { selected: true } : null)}
                          key={index}
                          value={tag.tagId}
                        >
                          {tag.title}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleTagReset}>
                  <span>Reset</span>
                </button>
              </div>
            </div>
            <div id="edit-class-btns">
              <button type="button" onClick={() => window.location.assign('/classes')}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Confirm</span>
              </button>
            </div>
          </form>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default EditClassForm;
