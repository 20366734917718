import React, { useEffect, useState, MouseEvent } from 'react';

import './lessons.scss';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import Auth from '../../utils/auth';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { TagObjectDTO } from '../../components/dto/tagObject.dto';
import { faCheck, faEllipsisH, faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Lessons = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName === 'Admin' || userRoleName === 'Producer') {
        return;
      } else {
        window.location.assign('/');
      }
    }
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<string>('');
  const [openModal, setOpenModal] = useState<number>();
  const [lessonObjectArrayData, setLessonObjectArrayData] = useState<LessonObjectDTO[]>([
    {
      moduleId: 0,
      lessonId: 0,
      title: '',
      author: 0,
      principals: [0],
      description: '',
      notes: '',
      tags: [0],
    },
  ]);

  const [lessonsObjectArrayWithUseCaseNames, setLessonsObjectArrayWithUseCaseNames] = useState<LessonObjectDTO[]>();

  const [updatedLessonsArray, setUpdatedLessonsArray] = useState<LessonObjectDTO[]>([
    {
      moduleId: 0,
      lessonId: 0,
      title: '',
      author: 0,
      principals: [0],
      description: '',
      notes: '',
      tags: [0],
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>();

  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>();
  const [moduleObjectArrayData, setModuleObjectArrayData] = useState<ModuleObjectDTO[]>();

  const [searchText, setSearchText] = useState<string>('');
  const [searchTagText, setSearchTagText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const lessonData = await VxAPI.getAllLessonsData();
      if (lessonData) {
        setLessonObjectArrayData(lessonData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      const useCaseData = await VxAPI.getAllUseCasesData();

      setUseCasesObjectArrayData(useCaseData);

      const coursesData = await VxAPI.getAllCoursesData();

      setCourseObjectArrayData(coursesData);

      const modulesData = await VxAPI.getAllModulesData();

      setModuleObjectArrayData(modulesData);

      const tagsData = await VxAPI.getAllTagsData();

      setTagObjectArrayData(tagsData);
    };

    fetchData().catch(console.error);
  }, []);

  const handleEditBtnClick = (e: MouseEvent<HTMLLIElement>) => {
    const lessonId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/lessons/edit/${lessonId}`);
  };

  const handleSaveAsClick = (ev: MouseEvent<HTMLLIElement>) => {
    const lessonId = ev.currentTarget.id.split('-').pop();

    window.location.assign(`/lessons/${lessonId}/saveAs`);
  };

  const handleDeleteClick = async (ev: MouseEvent<HTMLLIElement>) => {
    setMessage('');
    const lessonId = ev.currentTarget.id.split('-').pop();

    const deleteConfirm = window.confirm('Are you sure you would like to DELETE this lesson?');

    if (deleteConfirm) {
      const editLessonResp = await VxAPI.editLesson(
        {
          isActive: false,
        },
        Number(lessonId),
      );
      if (editLessonResp.lessonId) {
        window.location.assign(`/lessons`);
      } else {
        setMessage('An error has occured when attempting to delete the lesson. Please try again.');
      }
    }
  };

  const handlePublishClick = async (ev: MouseEvent<HTMLLIElement>) => {
    setMessage('');
    const lessonId = ev.currentTarget.id.split('-').pop();
    const lessonPublishedState = lessonObjectArrayData.find(
      (lesson) => lesson.lessonId === Number(lessonId),
    )?.publishState;

    const publishConfirm = window.confirm(
      lessonPublishedState === 0
        ? 'Are you sure you would like to PUBLISH this lesson?'
        : 'Are you sure you would like to UNPUBLISH this lesson?',
    );

    if (publishConfirm) {
      const editLessonResp = await VxAPI.editLesson(
        {
          publishState: lessonPublishedState === 0 ? 10 : 0,
        },
        Number(lessonId),
      );
      if (editLessonResp.lessonId) {
        window.location.assign(`/lessons`);
      } else {
        setMessage(
          lessonPublishedState === 0
            ? 'An error has occured when attempting to PUBLISH the lesson. Please try again.'
            : 'An error has occured when attempting to UNPUBLISH the lesson. Please try again.',
        );
      }
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleTagSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTagText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (
      useCasesObjectArrayData[0].useCaseId !== 0 &&
      courseObjectArrayData &&
      moduleObjectArrayData &&
      tagObjectArrayData
    ) {
      const lessonsWithUpdatedStringData = lessonObjectArrayData.map((lesson) => {
        let tagsStringArray: (string | undefined)[] = [];
        let course = courseObjectArrayData.find((course) => {
          let module = moduleObjectArrayData.find((module) => module.moduleId === lesson.moduleId);
          return course.courseId === module?.courseId;
        });
        let courseId = course?.courseId;
        let customerId = course?.customerId;
        let courseUseCaseId = courseObjectArrayData?.find((course) => course.courseId === courseId)?.useCaseId;
        let customerFirstName = customersObjectArrayData?.find(
          (customer) => customer.customerId === customerId,
        )?.firstName;
        let customerLastName = customersObjectArrayData?.find(
          (customer) => customer.customerId === customerId,
        )?.lastName;
        let useCaseName = useCasesObjectArrayData.find((useCase) => useCase.useCaseId === courseUseCaseId)?.title;
        let moduleName = moduleObjectArrayData.find((module) => module.moduleId === lesson.moduleId)?.title;
        if (lesson.tags && lesson.tags[0] !== 0 && lesson.tags.length > 0) {
          lesson.tags.map((tagId) => {
            let tagTitle = tagObjectArrayData?.find((foundTag) => foundTag.tagId === tagId)?.title;
            if (tagTitle) {
              tagsStringArray.push(tagTitle);
            }
          });
        }
        lesson.tags = tagsStringArray;
        lesson.moduleTitle = moduleName;
        lesson.useCaseTitle = useCaseName;
        lesson.customerFirstName = customerFirstName;
        lesson.customerLastName = customerLastName;
        lesson.parentTitle = lesson.parent
          ? lessonObjectArrayData.find((lessons) => lessons.lessonId === lesson.parent)?.title
          : 'ORIGINAL';
        return lesson;
      });
      setLessonsObjectArrayWithUseCaseNames(lessonsWithUpdatedStringData);
      setUpdatedLessonsArray(lessonsWithUpdatedStringData);
      setLoading(false);
    }
  }, [
    lessonObjectArrayData,
    useCasesObjectArrayData,
    courseObjectArrayData,
    moduleObjectArrayData,
    tagObjectArrayData,
  ]);

  useEffect(() => {
    if (lessonsObjectArrayWithUseCaseNames) {
      if (searchText === '') {
        setUpdatedLessonsArray(lessonsObjectArrayWithUseCaseNames);
      }

      const updatedLessons = lessonsObjectArrayWithUseCaseNames?.filter(
        (lesson) =>
          lesson.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          lesson.parentTitle?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          lesson.moduleTitle?.toLowerCase()?.includes(searchText.toLowerCase()),
      );

      if (searchTagText !== '') {
        const updatedLessonsByTags = updatedLessons?.filter((lesson) =>
          lesson.tags?.toString().toLowerCase()?.includes(searchTagText.toLowerCase()),
        );
        setUpdatedLessonsArray(updatedLessonsByTags);
        return;
      }

      setUpdatedLessonsArray(updatedLessons);
    }
  }, [searchText, searchTagText]);

  const handleModal = (e: MouseEvent<HTMLDivElement>) => {
    const eventEl = e.target as HTMLElement;
    if (eventEl.nodeName === 'UL' || eventEl.nodeName === 'LI') return;
    const eventElIdWordSplit = eventEl.id.split('-');
    if (eventElIdWordSplit.includes('modal')) return;
    let lessonId: number | null = null;
    if (eventEl.nodeName === 'path') {
      const parentElIdWordSplit = eventEl?.parentElement?.id.split('-');
      lessonId = Number(parentElIdWordSplit ? parentElIdWordSplit[2] : 0);
    } else {
      lessonId = Number(eventEl?.id.split('-')[2]);
    }
    if (openModal && lessonId !== openModal) return;
    if (Number.isNaN(lessonId)) return;
    let moreCircleDivEl = null;
    let modalEl = document.getElementById(`more-modal-${lessonId}`);
    if (!modalEl && !openModal) {
      const modalEl = document.createElement('div');
      modalEl.id = `more-modal-${lessonId}`;
      modalEl.className = 'more-modal';
      const unorderedListEl = document.createElement('ul');
      unorderedListEl.className = 'modal-ul';
      modalEl.appendChild(unorderedListEl);
      const editListEl = document.createElement('li');
      editListEl.id = `edit-lesson-btn-${lessonId}`;
      editListEl.className = 'modal-li';
      editListEl.textContent = 'Edit';
      editListEl.addEventListener('click', (ev) =>
        handleEditBtnClick(ev as unknown as React.MouseEvent<HTMLLIElement>),
      );
      unorderedListEl.appendChild(editListEl);
      const foundLesson = lessonObjectArrayData.find((lesson) => lesson.lessonId === lessonId);
      const lessonPublishedState = foundLesson?.publishState;
      if (lessonPublishedState !== 0) {
        const saveAsListEl = document.createElement('li');
        saveAsListEl.id = `save-as-lesson-${lessonId}`;
        saveAsListEl.className = 'modal-li';
        saveAsListEl.textContent = 'Save As';
        saveAsListEl.addEventListener('click', (ev) =>
          handleSaveAsClick(ev as unknown as React.MouseEvent<HTMLLIElement>),
        );
        unorderedListEl.appendChild(saveAsListEl);
      }
      const isLessonActive = foundLesson?.isActive;
      if (isLessonActive && userRoleName === 'Producer') {
        const deleteLessonEl = document.createElement('li');
        deleteLessonEl.id = `delete-lesson-${lessonId}`;
        deleteLessonEl.className = 'modal-li';
        deleteLessonEl.textContent = 'Delete';
        deleteLessonEl.addEventListener('click', (ev) =>
          handleDeleteClick(ev as unknown as React.MouseEvent<HTMLLIElement>),
        );
        unorderedListEl.appendChild(deleteLessonEl);
        const publishLessonEl = document.createElement('li');
        publishLessonEl.id = `publish-lesson-${lessonId}`;
        publishLessonEl.className = 'modal-li';
        publishLessonEl.textContent = lessonPublishedState === 0 ? 'Publish' : 'Unpublish';
        publishLessonEl.addEventListener('click', (ev) =>
          handlePublishClick(ev as unknown as React.MouseEvent<HTMLLIElement>),
        );
        unorderedListEl.appendChild(publishLessonEl);
      }
      switch (eventElIdWordSplit[1]) {
        case 'icon':
          moreCircleDivEl = eventEl.parentElement;
          break;
        case 'circle':
          moreCircleDivEl = eventEl;
          break;
        default:
          moreCircleDivEl = eventEl.parentElement?.parentElement;
          break;
      }
      if (moreCircleDivEl) {
        moreCircleDivEl.appendChild(modalEl);
        setOpenModal(lessonId);
      }
    }
    if (openModal) {
      closeModal(lessonId);
      setOpenModal(undefined);
    }
  };

  const closeModal = (lessonId: number) => {
    const modalEl = document.getElementById(`more-modal-${lessonId}`);
    const moreCircleDivEl = document.getElementById(`more-circle-${lessonId}`);
    if (modalEl && moreCircleDivEl) {
      moreCircleDivEl.removeChild(modalEl);
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="lessons-div">
          <div className="row justify-space-btw">
            <h1>Lessons</h1>
            <div id="lesson-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search Name, Revised Lesson, or Module"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <input
                id="search-tag-text"
                type="text"
                placeholder="Search on Tags"
                name="searchTagText"
                onChange={handleTagSearch}
              ></input>
            </div>
            <div id="lessons-titles">
              <h2 id="lesson-name-header">Lesson Name</h2>
              <div id="secondary-lesson-headers" className="row">
                <h2 id="lesson-publish-header">Published?</h2>
                <h2 id="lesson-revision-header">Revised Lesson?</h2>
                <h2 id="lesson-moduleName-header">Module</h2>
                <h2 id="lesson-tags-header">Tags</h2>
                <h2 id="lesson-desc-header">Description</h2>
                <h2 id="lesson-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedLessonsArray
            ? updatedLessonsArray
                .filter((lesson) => lesson.isActive === true)
                .sort((a: any, b: any) => {
                  if (a.parentTitle === 'ORIGINAL') {
                    return -1;
                  }
                  if (a.parentTitle < b.parentTitle) {
                    return -1;
                  }
                  if (a.parentTitle > b.parentTitle) {
                    return 1;
                  }
                  return 0;
                })
                .slice(0, itemsToShow)
                .map((lesson: LessonObjectDTO, index: number) => {
                  return (
                    <>
                      <div id="lesson-info-div" key={index}>
                        <div
                          id="lesson-name"
                          className="select-div"
                          onClick={() =>
                            window.location.assign(`/lessons/${lesson.lessonId}/view/version-history`)
                          }
                        >
                          <p>{lesson.title}</p>
                        </div>
                        <div id="secondary-lesson-data">
                          <div id="lesson-publish">
                            <div
                              className={lesson.publishState === 0 ? 'lesson-unpublish-icon' : 'lesson-publish-icon'}
                            >
                              {lesson.publishState === 0 ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </div>
                            <p>{lesson.publishState === 0 ? 'Unpublished' : 'Published'}</p>
                          </div>
                          <div id="lesson-revision">
                            <div className={!lesson.parent ? 'lesson-original-icon' : 'lesson-revised-icon'}>
                              {!lesson.parent ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faCheck} />}
                            </div>
                            <p>{lesson.parentTitle}</p>
                          </div>
                          <p id="lesson-moduleName">{lesson.moduleTitle}</p>
                          <ul id="lesson-tags">
                            {lesson.tags?.map((tag: number | string | undefined, index: number) => {
                              if (lesson.tags?.length === index + 1) {
                                return <li className="lesson-tag-name">{tag}</li>;
                              }
                              return <li className="lesson-tag-name">{tag},&nbsp;</li>;
                            })}
                          </ul>
                          <p id="lesson-desc">{lesson.description}</p>
                          <div id={`lesson-more-${lesson.lessonId}`} className="lesson-more">
                            <div id={`more-circle-${lesson.lessonId}`} className="more-circle" onClick={handleModal}>
                              <FontAwesomeIcon id={`more-icon-${lesson.lessonId}`} icon={faEllipsisH} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lesson-line"></div>
                    </>
                  );
                })
            : null}
          <>
            {!expanded && updatedLessonsArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedLessonsArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default Lessons;
