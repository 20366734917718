import React, { useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import qiLogo from '../../assets/images/qi_logo.png';
import qPin from '../../assets/images/q-pin.png';
import './certificatePDF.scss';

export interface CertificateProps {
  name: string;
  course: string;
  date: string;
  signatureName: string
  callback?: () => void;
}

const CertificatePDF: React.FC<CertificateProps> = ({ name, course, date, signatureName, callback }) => {
  const certificateRef = useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    if (certificateRef.current) {
      const canvas = await html2canvas(certificateRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 10; // Margin in mm
      const imgWidth = pageWidth - 2 * margin; // Adjust to fit within the margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

      pdf.addImage(imgData, 'PNG', margin, (pageHeight - imgHeight) / 2, imgWidth, imgHeight);
      pdf.save('certificate.pdf');
    }
  };

  // Adjust the size of the certificate container to match the aspect ratio
  useEffect(() => {
    if (certificateRef.current) {
      const certificateElement = certificateRef.current;
      const aspectRatio = 297 / 210; // A4 landscape aspect ratio
      const width = certificateElement.offsetWidth;
      certificateElement.style.height = `${width / aspectRatio}px`;
    }
  }, []);

  return (
    <div>
      <button id='pdf-dl-btn' onClick={generatePDF}>Download PDF</button>
      <button id='remove-cert-btn' onClick={callback}>Back</button>
      <div ref={certificateRef} style={{ width: '297mm', height: '210mm', position: 'relative'}}>
        <div className='cert' style={{ width: '100%', height: '100%' }}>
          <div className="outer-border">
            <div className="mid-border">
              <div className="inner-border">
                <img
                  className="corner-decoration corner-left-top"
                  src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
                  alt="Decoration"
                />
                <img
                  className="corner-decoration corner-right-top"
                  src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
                  alt="Decoration"
                />
                <img
                  className="corner-decoration corner-right-bottom"
                  src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
                  alt="Decoration"
                />
                <img
                  className="corner-decoration corner-left-bottom"
                  src="https://i.ibb.co/4mKvK3N/corner-decoration.jpg"
                  alt="Decoration"
                />
                <img
                  className="vertical-decoration top"
                  src="https://i.ibb.co/JRTK9z4/horizontally-centered-vertical-decoration.png"
                  alt="Decoration"
                />
                <img
                  className="vertical-decoration bottom"
                  src="https://i.ibb.co/JRTK9z4/horizontally-centered-vertical-decoration.png"
                  alt="Decoration"
                />
                <div className={`cert`}>
                  <img id="q-logo-img" src={qiLogo} alt="Quantum Interface Logo." />
                  <h1>Certificate of Completion</h1>
                  <p>
                    This is to certify that <br />
                    <strong id="cert-name">{name}</strong> <br />
                    has successfully completed the course: <br style={{ marginTop: '2rem' }} />
                    <strong id="crs-name">{course}</strong>
                  </p>
                  <div className="sigs">
                    <p id='comp-date'>Date: {date}</p>
                    <p id='sig'>Signature: <span>{signatureName}</span></p>
                  </div>
                  <img
                    src={qPin}
                    id="watermark"
                    alt="Watermark"
                    style={{
                      opacity: 0.1,
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificatePDF;

