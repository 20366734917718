import { StudentMetricsDTO, StudentMetricsLessonsDTO } from "../components/dto/studentMetrics.dto";

export function validateEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

export const calcArrayAvg = (array: any) => array.reduce((a: any, b: any) => a + b, 0) / array.length;

export const formatTimeStamp = (timestamp: string) =>
{
  if(!timestamp)return null;
  const dateTimeSplit = timestamp.split('T');
  const time = dateTimeSplit[1].split('.')[0];
  const formattedDateTimeObject = 
  {
    date: dateTimeSplit[0],
    time: time
  }
  
  return formattedDateTimeObject;
}

export const returnFormattedDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

export const filterAlphabet = 
[
  'All',
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export function isAlphanumeric(str: string): boolean {
  return /^[a-zA-Z0-9]+$/.test(str);
}

export const validatePin = (pin: string) => {
  // Check if the PIN length is between 4 and 8 digits
  if (pin.length < 4 || pin.length > 8) {
    return 'PIN must be between 4 and 8 digits.';
  }

  if(!isAlphanumeric(pin)) {
    return 'PIN must contain numbers or letters only.'
  }

  // PIN is valid
  return true;
}

export const averageArrays = (arrays: number[][], percentage: boolean): number[] => {
  if (arrays.length === 0) return [];

  const numArrays = arrays.length;
  const arrayLength = arrays[0].length;

  // Initialize an array to store the sums
  const sums = new Array(arrayLength).fill(0);

  // Accumulate sums for each index
  for (const array of arrays) {
    for (let i = 0; i < array.length; i++) {
      sums[i] += array[i];
    }
  }

  // Calculate averages
  const averages = percentage ? sums.map(sum => (sum / numArrays) * 100) : sums.map(sum => sum / numArrays);

  return averages;
}

export const averageQuizGlobalConfidece = (data: StudentMetricsLessonsDTO, applicableLessonIds: number[]) => {
  const lessons = data.lessons;
  // console.log('applicable lessons: ', applicableLessonIds);
  // console.log('lessons: ', lessons)
  let totalConfidence = 0;
  let count = 0;

  lessons.forEach(lesson => {
    if(applicableLessonIds.includes(lesson.lessonId)) {
      lesson.quizResults.forEach(quiz => {
        totalConfidence += quiz.quizGlobalConfidence;
        count++;
      });
    }
  });

  // console.log('total confidence: ', totalConfidence);
  // console.log('total count: ', count);

  return isNaN(totalConfidence / count) ? null : totalConfidence / count;
}