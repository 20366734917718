import Bounds from "./Bounds";
import Quaternion from "./Utilities/Quaternion";
import Vector2 from "./Utilities/Vector2";

// updated class to interface
export default class QINodeblueprint
    {
        position: Vector2;
        rotation: Quaternion;
        bounds: Bounds;
        selectionConfidence: number;
        deselectionConfidence: number;
        animationConfidence: number;
        scale: Vector2;
        constructor(
            position: Vector2,
            rotation: Quaternion,
            bounds: Bounds,
            selectionConfidence: number,
            deselectionConfidence: number,
            animationConfidence: number,
            scale: Vector2
        ) 
        {
            this.position = position;
            this.rotation = rotation;
            this.bounds = bounds;
            this.selectionConfidence = selectionConfidence;
            this.deselectionConfidence = deselectionConfidence;
            this.animationConfidence = animationConfidence;
            this.scale = scale;
        }
        
    }