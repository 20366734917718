import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './editUseCaseForm.scss';

const EditUseCaseForm = () => {
    const params = useParams();
    const paramsUseCaseId = Number(params.useCaseId);

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [useCaseInfo, setUseCaseInfo] = useState<UseCasesObjectDTO>({
        title: '',
        description: '',
        notes: '',
    })

    const { title, description, notes } = useCaseInfo

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage('');
        setUseCaseInfo({ ...useCaseInfo, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        if (
            title === '' ||
            description === ''
        ) {
            setMessage('Make sure all required fields are completed.')
            return;
        }

        const resp = await VxAPI.editUseCase({
            title,
            description,
            notes
        }, paramsUseCaseId)


        if (resp.useCaseId) {

            window.location.assign('/useCases')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const useCaseData = await VxAPI.getSingleUseCaseData(paramsUseCaseId);

            if (useCaseData) {
                setUseCaseInfo(useCaseData);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error)
    }, []);

    return (
        <>
            {loading ? (
                <div>
                    <h1 className="loading">Loading...</h1>
                </div>
            ) : (
                <div id='edit-useCase-div'>
                    <h1>Edit Use Case</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='edit-useCase-form-input'>
                            <label htmlFor='title'>Name of Use Case *</label>
                            <input type='text' name='title' defaultValue={title} onChange={handleChange} />
                        </div>
                        <div className='edit-useCase-form-textarea'>
                            <label htmlFor='description'>Description * </label>
                            <textarea name='description' defaultValue={description} onChange={handleChange} />
                        </div>
                        <div className='edit-useCase-form-textarea'>
                            <label htmlFor='notes'>Notes</label>
                            <textarea name='notes' defaultValue={notes} onChange={handleChange} />
                        </div>
                        <div id="edit-useCase-btns">
                            <button type="button" onClick={() => window.location.assign('/useCases')}><span>Cancel</span></button>
                            <button type="submit"><span>Save</span></button>
                        </div>
                    </form>
                    {message ? (
                        <h3 className="message">{message}</h3>
                    ) : null}
                </div>
            )}
        </>
    )
}

export default EditUseCaseForm;