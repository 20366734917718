import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import './singleLesson.scss';
import Auth from '../../utils/auth';
import { useInstructorLessonsArrayData, useUserData } from '../account/Account';
import { HierarchicalLessonObjectDTO } from '../../components/dto/hierarchicalLessonObject.dto';
import { calcArrayAvg } from '../../utils/helpers';

const SingleLesson = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }
  const { userData } = useUserData();
  const { instructorLessonsArrayData } = useInstructorLessonsArrayData();
  const params = useParams();
  const paramsLessonId = Number(params.lessonId);
  const paramsClassId = Number(params.classId);
  const [loading, setLoading] = useState(true);
  const [instructorLesson, setInstructorLesson] = useState<HierarchicalLessonObjectDTO>({});
  const [studentUserIds, setStudentUserIds] = useState<number[]>([]);
  const [students, setStudents] = useState<UserObjectDTO[]>([]);
  const [gradedStudents, setGradedStudents] = useState<UserObjectDTO[]>([]);
  const [quizGuids, setQuizGuids] = useState<string[]>()
  const [studentMetricsAllQuizzes, setStudentMetricsAllQuizzes] = useState<any[]>();

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (studentUserIds.length > 0) {
        const enrolledStudentsData = await VxAPI.getUsersByArrayOfIds({ userIdArray: studentUserIds });
        if (enrolledStudentsData) {
          setStudents(enrolledStudentsData);
        }
      }
    };
    fetchData().catch(console.error);
    setLoading(false);
  }, [studentUserIds]);

  useEffect(() => {
    const quizGuidArray: string[] = []
    const quizGuidMetricsArrayObjects: any = []
    if (students.length > 0) {
      let filteredStudents: any = [];
      students.map((student: UserObjectDTO) => {
        student?.studentMetrics?.lessons?.map((lesson) => {
          if(lesson.lessonId === paramsLessonId) {
            filteredStudents.push(student)
            lesson?.quizResults?.map( quiz => {
              quizGuidArray.push(quiz.guid)
              const quizGuidArrayObject = {
                userId: student.userId,
                quizGuid: quiz.guid? quiz.guid : null,
                quizTitle: quiz.title? quiz.title : null,
                userQuizGlobalScore: quiz.quizGlobalScore? quiz.quizGlobalScore : null
              }
              quizGuidMetricsArrayObjects.push(quizGuidArrayObject);
            })
          }
        });
      });
      setGradedStudents(filteredStudents);

      const uniqueQuizGuidArray = [...new Set(quizGuidArray)]
      setQuizGuids(uniqueQuizGuidArray)
      setStudentMetricsAllQuizzes(quizGuidMetricsArrayObjects)
    }
  }, [students]);

  useEffect(() => {
    const foundLesson = instructorLessonsArrayData?.find(
      (instructorLesson) => instructorLesson.classId === paramsClassId && instructorLesson.lesson?.lessonId === paramsLessonId,
    );
    if (foundLesson) {
      setInstructorLesson(foundLesson);
      if (foundLesson.students) {
        setStudentUserIds(foundLesson.students);
      }
    }
  }, [instructorLessonsArrayData]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="lesson-display">
          <h1>{instructorLesson.lesson?.title}</h1>
          <div id="quiz-list-display">
            <h2>
              {quizGuids?.length && quizGuids?.length > 1
                ? `${quizGuids?.length} Total Quizzes`
                : quizGuids?.length === 1 && quizGuids[0]
                ? `${quizGuids?.length} Total Quiz`
                : '0 Total Quizzes'}
            </h2>
            <div id="quiz-list-div">
              <div id="quiz-headers">
                <h3 id="quiz-name-header">Quiz Title</h3>
                <div id="secondary-quiz-headers">
                  <h3 id="quiz-grade-header">Average Grade</h3>
                  <h3 id="quiz-student-comp-header">Students Completed</h3>
                </div>
              </div>
              {quizGuids
                ? quizGuids.map((quiz: string, index: number) => {
                  if(!quiz)return;
                  let quizGrades: number[] = []
                  let studentIdsThatCompletedQuiz: number[] = []
                  const quizName = studentMetricsAllQuizzes?.find(studentMetric => studentMetric.quizGuid === quiz)?.quizTitle;
                  studentMetricsAllQuizzes?.forEach(metric => {
                    if(metric.quizGuid === quiz) {
                      quizGrades.push(metric.userQuizGlobalScore)
                      studentIdsThatCompletedQuiz.push(metric.userId);
                    }
                  })
                  const quizAverageGrade = calcArrayAvg(quizGrades)
                  const uniqueStudentIdsThatCompletedQuiz = [...new Set(studentIdsThatCompletedQuiz)];
                    return (
                      <>
                        <div
                          id="quiz-info-div"
                          key={index}
                          onClick={() =>
                            window.location.assign(`/class/${paramsClassId}/lessons/${paramsLessonId}/quizzes/view/${quiz}`)
                          }
                        >
                          <div id="quiz-name">
                            <p>{quizName}</p>
                          </div>
                          <div id="secondary-quiz-data">
                            <div id="quiz-grade">
                              <p>{isNaN(quizAverageGrade) ? '' : `${quizAverageGrade.toFixed(2)}%`}</p>
                            </div>
                            <div id="quiz-student-comp">
                              <p>
                                {gradedStudents.length === 0
                                  ? `0% (0/${studentUserIds.length})`
                                  : `${Math.round((uniqueStudentIdsThatCompletedQuiz.length / students.length) * 100).toFixed(2)}% (${
                                      uniqueStudentIdsThatCompletedQuiz.length
                                    }/${students.length})`}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="quiz-line"></div>
                      </>
                    );
                  })
                : null}
              <div className="back-btn-div">
                <button className="back-btn" type="button" onClick={() => window.location.assign('/')}>
                  <span>Back</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleLesson;
