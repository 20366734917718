import { useEffect, useState, useCallback } from 'react';
import { VxAPI } from '../../api/vx.api';

import './forgotPw.scss';
import qiLogo from '../../assets/images/qi_logo.png';
import { validateEmail } from '../../utils/helpers';

const ForgotPw = () => {
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({
        email: '',
    });

    const { email } = formState;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage('');
        setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {
        e.preventDefault();

        const email = formState.email;
        const validEmail = validateEmail(email);

        if (email === '' || !validEmail) {
            setMessage('Please enter a valid email address before submitting')
            return;
        }

        setMessage('Sending...')

        const resp = await VxAPI.forgetPw({
            email
        })

        if (resp.ok) {
            setMessage('If there is an email associated with your account, an email has been sent to you with instructions to reset your password.')
        } else {
            setMessage('There was an error when sending the email. Please verify your admin added your email to the account. If message persists please contact your administrator.')
        }

        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
    }, [formState.email])

    useEffect(() => {
        const listener = (ev: KeyboardEvent) => {
            if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
                ev.preventDefault()
                handleSubmit(ev);
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, handleSubmit])

    return (
        <div className='box'>
            <div className='row'>
                <h1 id='forgotPw-title' className='title'>Forgot Password</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='row flex-dir-col'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='email' defaultValue={email} onChange={handleChange} />
                </div>
                <div className='row justify-center form-btn-div'>
                    <button id='forgetPw-btn' type='submit'><span>Confirm</span></button>
                </div>
            </form>
            {message ? (
                <div className='row message-div'>
                    <p className='message'>{message}</p>
                </div>
            ) : null}
            <div id='qi-logo-div'>
                <img src={qiLogo} alt='Quantum Interface Logo.'></img>
            </div>
        </div>
    )
}

export default ForgotPw;