export default class Vector2
    {
        x: number;
        y: number;

        constructor(x: number, y: number)
        {
            this.x = x;
            this.y = y;
        }

        // Add this vector with another vector.
        // Returns a new vector.
        add(v: Vector2) {
            return new Vector2(this.x + v.x, this.y + v.y);
         }
 
         // Subtract another vector from this one.
         // Returns a new vector.
         sub(v: Vector2) {
             return new Vector2(this.x - v.x, this.y - v.y);
         }
 
         // Project this vector onto a normal vector.
         // Returns a new vector.
         project(normal: Vector2) {
             return normal.scale(this.dot(normal));
         }
 
         // Take the dot product of this vector with another vector.
         // Returns the dot product value.
         dot(v: Vector2) {
             return this.x * v.x + this.y * v.y;
         }
 
         // Scale the components of this vector.
         // Returns a new vector.
         scale(s: number) {
             return new Vector2(this.x * s, this.y * s);
         }
 
         // Return the magnitude (length) of this vector.
         magnitude() {
             return Math.sqrt(this.x * this.x + this.y * this.y);
         }
 
         // Return a normalized version of this vector.
         normalized() {
             return this.scale(1.0 / this.magnitude());
         }
 
         copy() {
             return new Vector2(this.x, this.y);
         }
 
         distanceTo(v: Vector2) {
             return this.sub(v).magnitude();
         }
    }