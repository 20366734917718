import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './addModuleForm.scss';
import { CourseObjectDTO } from '../dto/courseObject.dto';
import { AddModuleSelectStateDTO } from '../dto/addModule.selectState.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const AddModuleForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId, loggedInId } = props;
  const [message, setMessage] = useState('');
  const [selectState, setSelectState] = useState<AddModuleSelectStateDTO>({
    courseId: undefined,
    customerId: undefined,
    useCaseId: undefined,
  });
  const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    type: 'type',
    notes: '',
    version: '1',
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [courseArray, setCourseArray] = useState<CourseObjectDTO[]>([
    {
      title: '',
      description: '',
      notes: '',
      customerId: 0,
      useCaseId: 0,
    },
  ]);

  const [updatedCourseArray, setUpdatedCourseArray] = useState<CourseObjectDTO[]>([
    {
      title: '',
      description: '',
      notes: '',
      customerId: 0,
      useCaseId: 0,
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const [selectTagsState, setSelectTagsState] = useState<number[]>([]);

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value) });
  };

  const { courseId } = selectState;
  const { title, description, notes, version, type } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    let versionArray = version.split('.');
    let periodCount = versionArray.length;
    let versionNumber = Number(version);

    if (periodCount > 1 || Number.isInteger(versionNumber) !== true || versionNumber <= 0) {
      setMessage('Please enter a version number in valid form as a whole number: "1", "2", etc.');
      return;
    }
    if (courseId === undefined || courseId === 0) {
      setMessage(
        'Be sure to select a course the module belongs to. If no courses available to select, please create one first.',
      );
      return;
    }

    if (title === '' || description === '' || version === '') {
      setMessage('Make sure all required fields are completed.');
      return;
    }
    let tags: number[] = selectTagsState;
    if (selectTagsState?.length > 0) {
      selectTagsState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let createdDate = currentDate;

    setMessage('Adding...');
    const resp = await VxAPI.addModule({
      title,
      description,
      notes,
      type,
      version: versionNumber,
      courseId,
      tags,
      createdDate,
    });

    if (resp.moduleId) {
      window.location.assign('/modules');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }

      const courseData = await VxAPI.getAllCoursesData();

      if (courseData) {
        setCourseArray(courseData);
      }

      const tagData = await VxAPI.getAllTagsData();
      if (tagData) {
        setTagArray(tagData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setSelectState({ ...selectState, courseId: undefined });
    setUpdatedCourseArray(
      courseArray
        ? courseArray.filter((customerCourse) => selectState.customerId === customerCourse.customerId)
        : courseArray,
    );
  }, [selectState.customerId]);

  useEffect(() => {
    setLoggedInUserCustomerId(userCustomerId);

    if (userCustomerId !== null) {
      setSelectState({ ...selectState, customerId: userCustomerId });
    }
  }, [userCustomerId]);

  const handleMultiSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newItems = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newItems.push(Number(selectedOptions[i].value));
    }

    switch (e.target.name) {
      case 'tagArray':
        setSelectTagsState(newItems);
        break;
    }
  };

  return (
    <div id="add-module-div">
      <h1>Add a New Module</h1>
      <form onSubmit={handleSubmit}>
        <div className="dropdown-div">
          <div className="add-module-hardcode">
            <label htmlFor="name">Customer</label>
            <p id="module-cust-name">
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.firstName}{' '}
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.lastName}
            </p>
          </div>
          <div className="add-usecase-dropdown">
            <label htmlFor="useCaseId">Use Case *</label>
            <select name="useCaseId" onChange={handleSelection}>
              <option value={0}>---</option>
              {useCaseArray
                ? useCaseArray
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((useCase: UseCasesObjectDTO, index: number) => (
                      <option key={index} value={useCase.useCaseId}>
                        {useCase.title}
                      </option>
                    ))
                : null}
            </select>
          </div>
        </div>
        <div className="add-module-row">
          <div className="add-module-form-input">
            <label htmlFor="title">Title *</label>
            <input type="text" name="title" defaultValue={title} onChange={handleChange} />
          </div>
          <div className="add-module-course-dropdown">
            <label className="add-module-label-courseName" htmlFor="courseId">
              Course *
            </label>
            <select name="courseId" onChange={handleSelection}>
              {selectState.customerId === 0 || selectState.customerId === undefined ? (
                <option value={0}>---</option>
              ) : (
                <>
                  <option value={0}>---</option>
                  {courseArray
                    ? courseArray
                        .filter((course: CourseObjectDTO) => course.useCaseId === selectState.useCaseId)
                        .sort((a: any, b: any) => {
                          if (a.title < b.title) {
                            return -1;
                          }
                          if (a.title > b.title) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((course: CourseObjectDTO, index: number) => (
                          <option key={index} value={course.courseId}>
                            {course.title}
                          </option>
                        ))
                    : null}
                </>
              )}
            </select>
          </div>
        </div>
        <div className="add-module-row">
          <div className="add-module-form-input">
            <label htmlFor="version">Version *</label>
            <input type="text" name="version" defaultValue={version} onChange={handleChange} />
          </div>
        </div>
        <div className="add-module-row">
          <div className="add-tag-dropdown">
            <label id="tag-dropdown-header" htmlFor="selectTagsState[]">
              Add Tags
            </label>
            <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
            <select multiple size={tagArray?.length} name="tagArray" onChange={handleMultiSelection}>
              {tagArray
                ? tagArray
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag: TagObjectDTO, index: number) => (
                      <option key={index} value={tag.tagId}>
                        {tag.title}
                      </option>
                    ))
                : null}
            </select>
          </div>
        </div>
        <div className="add-module-form-textarea">
          <label htmlFor="description">Description *</label>
          <textarea name="description" defaultValue={description} onChange={handleChange} />
        </div>
        <div className="add-module-form-textarea">
          <label htmlFor="notes">Notes</label>
          <textarea name="notes" defaultValue={notes} onChange={handleChange} />
        </div>
        <div id="add-module-btns">
          <button type="button" onClick={() => window.location.assign('/modules')}>
            <span>Cancel</span>
          </button>
          <button type="submit">
            <span>Confirm</span>
          </button>
        </div>
      </form>
      {message ? <h3 className="message">{message}</h3> : null}
    </div>
  );
};

export default AddModuleForm;
