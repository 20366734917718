import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartData, ChartOptions } from 'chart.js';
import './sideBar.scss';

export interface SideBarChartProps {
  dataValues: number[];
  yLabels: string[];
  barColors: string[];
  barBorderColors: string[];
  borderWidth: number;
  displayLegend: boolean;
  legendData?: LegendData[] | null;
  title: string;
  titleFontSize: number;
  className?: string | undefined;
  minBarLength?: number;
  dataMin?: number;
  dataMax?: number;
  yAxisLabelFontSize?: number;
  height: number;
  width: number;
  padding: number;
}

export interface SideBarChartPropsForScreenSize {
  borderWidth: number;
  displayLegend: boolean;
  titleFontSize: number;
  minBarLength: number;
  dataMin: number;
  dataMax: number;
  yAxisLabelFontSize: number;
  height: number;
  width: number;
  padding: number;
}

export interface LegendData {
  text: string;
  fillStyle: string;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SidebarChart: React.FC<SideBarChartProps> = (props: SideBarChartProps) => {
  // console.log('props: ', props);

  const data: ChartData<'bar'> = {
    labels: props.yLabels,
    datasets: [
      {
        label: props.title,
        data: props.dataValues,
        backgroundColor: props.barColors,
        borderColor: props.barBorderColors,
        borderWidth: props.borderWidth,
        minBarLength: props.minBarLength,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          font: {
            size: props.yAxisLabelFontSize,
          },
          autoSkip: false,
          color: 'white',
        },
      },
      x: {
        min: props.dataMin,
        max: props.dataMax,
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: props.title,
        color: 'white',
        font: {
          size: props.titleFontSize,
        },
      },
      legend: {
        display: props.displayLegend,
        labels: {
          generateLabels: (chart) => {
            return props.legendData ? props.legendData : [];
          },
        },
      },
    },
    layout: {
      padding: {
        top: props.padding,
      },
    },
  };

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
};

export default SidebarChart;
