import { Routes, Route } from 'react-router-dom';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import ForgotPw from './pages/forgotPw/ForgotPw';
import ResetPw from './pages/resetPw/ResetPw';
import Dashboard from './pages/dashboard/Dashboard';
import Users from './pages/users/Users';
import Tags from './pages/tags/Tags';
import UseCases from './pages/useCases/UseCases';
import Customers from './pages/customers/Customers';
import QiApps from './pages/qiApps/QiApps';
import Account from './pages/account/Account';
import AddUsers from './pages/addUsers/AddUsers';
import AddCustomers from './pages/addCustomers/AddCustomers';
import AddUseCases from './pages/addUseCases/AddUseCases';
import AddQiApps from './pages/addQiApps/AddQiApps';
import AddTags from './pages/addTags/AddTags';
import EditUser from './pages/editUser/EditUser';
import EditCustomer from './pages/editCustomer/EditCustomer';
import EditUseCase from './pages/editUseCase/EditUseCase';
import EditQiApp from './pages/editQiApp/EditQiApp';
import EditTag from './pages/editTag/EditTag';
import Classes from './pages/classes/Classes';
import AddClasses from './pages/addClasses/AddClasses';
import EditClass from './pages/editClass/EditClass';
import Courses from './pages/courses/Courses';
import AddCourses from './pages/addCourses/AddCourses';
import EditCourse from './pages/editCourse/EditCourse';
import Modules from './pages/modules/Modules';
import AddModules from './pages/addModules/AddModules';
import EditModule from './pages/editModule/EditModule';
import SingleLesson from './pages/singleLesson/SingleLesson';
import SingleQuiz from './pages/singleQuiz/SingleQuiz';
import StudentQuizResult from './pages/studentQuizResult/StudentQuizResult';
import StudentLessons from './pages/studentLessons/StudentLessons';
import StudentQuizzes from './pages/studentQuizzes/StudentQuizzes';
import Lessons from './pages/lessons/Lessons';
import EditLesson from './pages/editLesson/EditLesson';
import LatestBuild from './pages/latestBuild/LatestBuild';
import LessonVersion from './pages/lessonVersion/LessonVersion';
import SaveAsLesson from './pages/saveAsLesson/SaveAsLesson';
import TagsTable from './pages/tagsTable/TagsTable';
import Manual from './pages/manual/Manual';
import { useEffect } from 'react';
import LatestTest from './pages/testBuild/LatestTest';
import Profile from './pages/profile/Profile';

function App() {
  //redirect to downloads page if using old downloads page
  useEffect(() => {
    const url = window.location.toString();
    const urlStringArray = url.split('/');
    const lastUrlItem = urlStringArray[urlStringArray.length - 1];
    const secondToLastItem = urlStringArray[urlStringArray.length - 2];
    if (lastUrlItem === 'latest-builds' && secondToLastItem === 'download') {
      window.location.assign('/downloads');
    }
  });

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPw />} />
        <Route path="/manuals" element={<Manual />} />
        <Route path="/users/:email/password-reset" element={<ResetPw />} />
        <Route path="/" element={<Account />}>
          <Route index element={<Dashboard />} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/downloads" element={<LatestBuild />} />
          <Route path="/downloads/test" element={<LatestTest />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUsers />} />
          <Route path="/users/edit/:userId" element={<EditUser />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/tagsTable" element={<TagsTable />} />
          <Route path="/tags/add" element={<AddTags />} />
          <Route path="/tags/edit/:tagId" element={<EditTag />} />
          <Route path="/useCases" element={<UseCases />} />
          <Route path="/useCases/add" element={<AddUseCases />} />
          <Route path="/useCases/edit/:useCaseId" element={<EditUseCase />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomers />} />
          <Route path="/customers/edit/:customerId" element={<EditCustomer />} />
          <Route path="/apps" element={<QiApps />} />
          <Route path="/apps/add" element={<AddQiApps />} />
          <Route path="/apps/edit/:appId" element={<EditQiApp />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/classes/add" element={<AddClasses />} />
          <Route path="/classes/edit/:classId" element={<EditClass />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/add" element={<AddCourses />} />
          <Route path="/courses/edit/:courseId" element={<EditCourse />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/modules/add" element={<AddModules />} />
          <Route path="/modules/edit/:moduleId" element={<EditModule />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/lessons/edit/:lessonId" element={<EditLesson />} />
          <Route path="/lessons/:lessonId/saveAs" element={<SaveAsLesson />} />
          <Route path="/lessons/:lessonId/view/version-history" element={<LessonVersion />} />
          <Route path="/class/:classId/lessons/view/:lessonId" element={<SingleLesson />} />
          <Route path="/class/:classId/lessons/:lessonId/quizzes/view/:guid" element={<SingleQuiz />} />
          <Route path="/lessons/:lessonId/quizzes/:guid/students/view/:userId" element={<StudentQuizResult />} />
          <Route path="/students/:userId/view/lessons" element={<StudentLessons />} />
          <Route path="/students/:userId/lessons/:lessonId/view/quizzes" element={<StudentQuizzes />} />
        </Route>
        {/* if page not found - redirect to homepage */}
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
