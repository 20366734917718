import './home.scss';
import qiLogo from '../../assets/images/qi_logo.png';
import Auth from '../../utils/auth';

const Home = () => {
  const url = window.location.toString();
  const urlArray = url.split('.');
  const userType = Auth.getUserType();

  if (url.includes('token')) {
    const loggedInUser = Auth.loggedIn();
    if (loggedInUser) {
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_type');
    }

    const token = window.location.toString().split('=')[window.location.toString().split('=').length - 1];
    const refreshToken = 'no-implemented';
    let type = '';

    if (urlArray[2] === 'com') {
      type = 'com';
    } else if (urlArray[1] === 'com') {
      type = 'com';
    } else {
      type = 'gov';
    }
    Auth.autoLogin(token, refreshToken, type);
  }

  if(userType && !url.includes('token')) {
    window.location.assign('/login');
  }

  return (
    <>
      <div>
        <section>
          <div>
            <img src={qiLogo} alt="Quantum Interface Logo."></img>
          </div>
          <h1>Welcome to QIVX</h1>
          <p id="qivx-info">QIVX is Quantum Interface's digital trianing platform, available on web and desktop.</p>
          <button id="login-page-btn" type="button" onClick={() => window.location.assign('/login')}>
              <span>Log In</span>
            </button>
        </section>
      </div>
    </>
  );
};

export default Home;
