import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { AddAppSelectStateDTO } from '../dto/addApp.selectState.dto';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './addQiAppForm.scss'

const AddQiAppForm: React.FC<QiEmployeeProps> = (props) => {
    const { qiEmployee, userCustomerId } = props;
    const [message, setMessage] = useState('');
    const [selectState, setSelectState] = useState<AddAppSelectStateDTO>({
        customerId: undefined,
        useCaseId: undefined
    })
    const [formState, setFormState] = useState({
        type: '',
        title: '',
        description: '',
        notes: '',
    })
    const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([{
        customerId: 0,
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        location: ''
    }])
    const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([{
        useCaseId: 0,
        title: '',
        description: '',
        notes: ''
    }])

    const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMessage('');
        if (!qiEmployee) {
            setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value), customerId: userCustomerId })
            return
        }
        setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value) })
    }

    const { customerId, useCaseId } = selectState
    const { title, description, type, notes } = formState

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage('');
        setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        if (
            type === '' ||
            title === '' ||
            description === '' ||
            customerId === undefined || customerId === 0 ||
            useCaseId === undefined || useCaseId === 0
        ) {
            setMessage('Make sure all required fields are completed.')
            return
        }

        const resp = await VxAPI.addApp({
            customerId,
            useCaseId,
            title,
            description,
            type,
            notes
        })

        if (resp.appId) {
            window.location.assign('/apps')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const customerData = await VxAPI.getAllCustomersData();

            if (customerData) {
                setCustomerArray(customerData)
            }

            const useCaseData = await VxAPI.getAllUseCasesData();

            if (useCaseData) {
                setUseCaseArray(useCaseData)
            }
        }

        fetchData()
            .catch(console.error)
    }, []);

    return (
        <div id='add-app-div'>
            <h1>Add a New App</h1>
            <form onSubmit={handleSubmit}>
                <div className='dropdown-div'>
                    {qiEmployee ? (
                        <div className="add-app-dropdown">
                        <label htmlFor="customerId">Customer *</label>
                        <select name="customerId" onChange={handleSelection}>
                            <option value={0}>---</option>
                            {customerArray ? customerArray.map((customer: CustomerObjectDTO, index: number) => (
                                <option key={index} value={customer.customerId}>{customer.firstName} {customer.lastName}</option>
                            )) : null}
                        </select>
                    </div>
                    ) : (
                        <div className='add-app-cust'>
                            <label htmlFor='name'>Customer</label>
                            <p id='app-cust-name'>{customerArray.find(customer => customer.customerId === userCustomerId)?.firstName} {customerArray.find(customer => customer.customerId === userCustomerId)?.lastName}</p>
                        </div>
                    )}
                    <div className="add-app-dropdown">
                        <label htmlFor="useCaseId">Use Case *</label>
                        <select name="useCaseId" onChange={handleSelection}>
                            <option value={0}>---</option>
                            {useCaseArray ? useCaseArray.map((useCase: UseCasesObjectDTO, index: number) => (
                                <option key={index} value={useCase.useCaseId}>{useCase.title}</option>
                            )) : null}
                        </select>
                    </div>
                </div>
                <div className='add-app-row'>
                    <div className='add-app-form-input'>
                        <label htmlFor='title'>App Name *</label>
                        <input type='text' name='title' defaultValue={title} onChange={handleChange} />
                    </div>
                    <div className='add-app-form-input'>
                        <label htmlFor='type'>Type *</label>
                        <input type='text' name='type' defaultValue={type} onChange={handleChange} />
                    </div>
                </div>
                <div className='add-app-form-textarea'>
                    <label htmlFor='description'>Description *</label>
                    <textarea name='description' defaultValue={description} onChange={handleChange} />
                </div>
                <div className='add-app-form-textarea'>
                    <label htmlFor='notes'>Notes</label>
                    <textarea name='notes' defaultValue={notes} onChange={handleChange} />
                </div>
                <div id="add-app-btns">
                    <button type="button" onClick={() => window.location.assign('/apps')}><span>Cancel</span></button>
                    <button type="submit"><span>Confirm</span></button>
                </div>
            </form>
            {message ? (
                <h3 className='message'>{message}</h3>
            ) : null}
        </div>
    )

}

export default AddQiAppForm;