import { QPointState, ViewType } from "./Enums";
import IQINode from "./IQINode";
import QINodeManager from "./QINodeManager";
import Vector2 from "./Utilities/Vector2";
import WebNode from "./WebNode";

export default class WebQINodeManager extends QINodeManager {
    selectedRoot: IQINode | null;
    currentCursorX: number;
    currentCursorY: number;
    cursorPositionValid: boolean;
    initTime: number;
    scrollCancelDistance: number;
    touchInitiatedInRange: boolean;
    inIt: Function;
    constructor() {
        super()
        this.selectedRoot = null;
        this.currentCursorX = 0;
        this.currentCursorY = 0;
        this.cursorPositionValid = false;
        this.initTime = 0;
        this.scrollCancelDistance = 0;
        this.touchInitiatedInRange = false;
        this.inIt = this._init;

        if(typeof window != 'undefined') {
            return this.inIt();
        }
    }

    _init () {
        // TODO: add logic to add scroll event listener to update qpoint position
        window.addEventListener('resize', () => {
            for (let i = 0; i < this.qiNodeCollection.size; i++) {
                // i LOVE casting - MJG
                const webNode = this.qiNodeCollection.get(i) as WebNode;
                if (webNode?.anchorElement && webNode.view) {
                    // Invalidate anchored positions.
                    webNode.view.updatedPositionOnce = false;
                }
            }
        });
        document.addEventListener('pointermove', (e: MouseEvent) => {
            this.currentCursorX = e.clientX + document.body.scrollLeft;
            this.currentCursorY = e.clientY + document.body.scrollTop;
            this.cursorPositionValid = true;
        });
        //MJG ADDED TO CHANGE THE POSITION OF QP IF USER SCROLLS WINDOW
        window.addEventListener('scroll', (e) => {
            e.preventDefault();
            this.qiNodeCollection.forEach( qp => {
                let foundQp = qp as WebNode;
                let foundQpEl = document.getElementById(foundQp.viewData.name)
                
                // set position to new x,y coords of qpoint on scroll
                if(foundQp.qiNode.isHidden === false) {
                    if(foundQpEl) {
                        let qpointRect = foundQpEl.getBoundingClientRect();
                        foundQp.qiNode.blueprint.position = new Vector2((qpointRect.left + qpointRect.right) / 2.0 ,(qpointRect.top + qpointRect.bottom) / 2.0);
                    }
                }
            })
        })
        
        // document.addEventListener('touchstart', (e: TouchEvent) => {
        //     console.log('on touch start event happening')
        //     this.currentCursorX = e.touches[0].clientX + document.body.scrollLeft;
        // this.currentCursorY = e.touches[0].clientY + document.body.scrollTop;
        // this.cursorPositionValid = true;

        // for (let i = 0; i < this.qiNodeCollection.size; i++) {
        //     const webNode = this.qiNodeCollection.get(i) as WebNode;
        //     if(webNode.qiNode.blueprint.position) {
        //         let distance = webNode.qiNode.blueprint.position
        //         .distanceTo(this.getCurrentMousePos());

        //         if (distance < this.scrollCancelDistance) {
        //             e.preventDefault();
        //             this.touchInitiatedInRange = true;
        //             return;
        //         }
        //     }
        // }

        // this.touchInitiatedInRange = false;
        // } , { passive: false }); 
        // document.addEventListener('touchmove', (e: TouchEvent) => {
        //     if (this.touchInitiatedInRange) {
        //         console.log('on touch move event happening')
        //         this.currentCursorX = e.touches[0].clientX + document.body.scrollLeft;
        //         this.currentCursorY = e.touches[0].clientY + document.body.scrollTop;
        //         this.cursorPositionValid = true;
        //     }
    
        //     // TODO: Doesn't seem to work, can't cancel scroll from this event. (MJG Question: is this why {passive: false} set on event listeners?)
        //     // for (let i = 0; i < this.roots.length; i++) {
        //     //     if (this.selectedRoot) {
        //     //         e.preventDefault();
        //     //         break;
        //     //     }
        //     // }
        // }, { passive: false });
        // document.addEventListener('touchend', () => {
        //     console.log('on touch end event happening')
        //     this.cursorPositionValid = false;
        //     this.touchInitiatedInRange = false;
        // }, { passive: false });

        this.initTime = Date.now();

        // Kick-off the animation update loop.
        this._onAnimationFrame();
    }

    _onAnimationFrame () {
        // console.log('TS engine: ', this)
        this._update();
        window.requestAnimationFrame(this._onAnimationFrame.bind(this));
    }

    // _onWindowResize () {
    //     for (let i = 0; i < this.qiNodeCollection.size; i++) {
    //         // i LOVE casting - MJG
    //         const webNode = this.qiNodeCollection.get(i) as WebNode;
    //         if (webNode?.anchorElement && webNode.view) {
    //             // Invalidate anchored positions.
    //             webNode.view.updatedPositionOnce = false;
    //         }
    //     }
    // }

    // _onMouseMove (e: MouseEvent) {
    //     this.currentCursorX = e.clientX + document.body.scrollLeft;
    //     this.currentCursorY = e.clientY + document.body.scrollTop;
    //     this.cursorPositionValid = true;

    // }

    // _onTouchMove (e: TouchEvent) {

    //     if (this.touchInitiatedInRange) {
    //         this.currentCursorX = e.touches[0].clientX + document.body.scrollLeft;
    //         this.currentCursorY = e.touches[0].clientY + document.body.scrollTop;
    //         this.cursorPositionValid = true;
    //     }

    //     // TODO: Doesn't seem to work, can't cancel scroll from this event. (MJG Question: is this why {passive: false} set on event listeners?)
    //     // for (let i = 0; i < this.roots.length; i++) {
    //     //     if (this.selectedRoot) {
    //     //         e.preventDefault();
    //     //         break;
    //     //     }
    //     // }
    // }

    // _onTouchStart(e: TouchEvent) {
    //     this.currentCursorX = e.touches[0].clientX + document.body.scrollLeft;
    //     this.currentCursorY = e.touches[0].clientY + document.body.scrollTop;
    //     this.cursorPositionValid = true;

    //     for (let i = 0; i < this.qiNodeCollection.size; i++) {
    //         const webNode = this.qiNodeCollection.get(i) as WebNode;
    //         if(webNode.qiNode.blueprint.position) {
    //             let distance = webNode.qiNode.blueprint.position
    //             .distanceTo(this.getCurrentMousePos());

    //             if (distance < this.scrollCancelDistance) {
    //                 e.preventDefault();
    //                 this.touchInitiatedInRange = true;
    //                 return;
    //             }
    //         }
    //     }

    //     this.touchInitiatedInRange = false;
    // }

    // _onTouchEnd() {
    //     this.cursorPositionValid = false;
    //     this.touchInitiatedInRange = false;
    // }

    _update() {
        // If this changes, make sure to check the corresponding
        // function on the QPoint class.
            let currentMousePosition = this.getCurrentMousePos();
            // console.log('currentMousePosition: ', currentMousePosition)
            this.updateConfidence(currentMousePosition);
        
    }

    getCurrentMousePos() {
        return new Vector2(
            this.currentCursorX, this.currentCursorY);
    }

    // Get the time elapsed since init in milliseconds.
    getTimeSinceInit() {
        return Date.now() - this.initTime;
    }

    // Get the time in milliseconds.
    getTime() {
        return Date.now();
    }

    // updateConfidence(inputPosition: Vector2) {
    //     for(let i=0; i < this.qiNodeCollection.size; i++)
    //         {
                
    //             if (this.qiNodeCollection.get(i) === null)
    //             {
    //                 this.reusableIds.enqueue(this.qiNodeCollection.get(i)?.qiNode.id);
    //                 this.qiNodeCollection.delete(i);
    //                 continue;
    //             }
    //             // MJG & DB added lines 61 & 62
    //             let webNode = this.qiNodeCollection.get(i) as WebNode;
    //             let iq = this.qiNodeCollection.get(i) as IQINode;
    //             let qiNode = iq!.qiNode;
    //             if (qiNode.isHidden || qiNode.confidenceLogic == null) continue;
    //             // console.log(qiNode)
    //             // console.log('input position: ', inputPosition)
    //             // console.log('qiNode position: ', qiNode.blueprint.position)
    //             let distance = qiNode.confidenceLogic.getConfidence(inputPosition, qiNode.blueprint.position);
    //             qiNode.currentConfidence = distance;
    //             this.confidenceTracker.tryUpdateConfidence(qiNode);
    //             //check if this node has any children nodes that are being interacted with if so continue we dont want to deselect the root
    //             if (this.checkForOpenChild(qiNode)) continue;
    //             if (qiNode.currentConfidence > qiNode.blueprint.animationConfidence && qiNode.state.toString() != QPointState.Selected)
    //             {
    //                 //check if we should select before adding the animation action to queue
    //                 if (qiNode.currentConfidence > qiNode.blueprint.selectionConfidence && qiNode.state.toString() != QPointState.Selected)
    //                 {
    //                     qiNode.onSelected();
    //                     this.shouldNotifyRootSelection(this.qiNodeCollection.get(i)!);
    //                     qiNode.state = QPointState.Selected;
    //                     if(webNode.viewData.type === ViewType.Image) {
    //                         const imgElement = document.getElementById(webNode.viewData.name) as HTMLImageElement;
    //                         if (imgElement && webNode.viewData.imgSrcSelected) {
    //                             imgElement.src = webNode.viewData.imgSrcSelected;
    //                         }
    //                     }
    //                     this.showChildren(qiNode, true);
    //                 }
    //                 else
    //                 {
    //                     //we need to animate
    //                     if (qiNode.state != QPointState.Selected)
    //                     {
    //                         qiNode.onAnimate();
    //                         qiNode.state = QPointState.Animation;
    //                     }
    //                 }
    //             }
    //             else
    //             {
    //                 //check if we should fire the deselection action
    //                 if (qiNode.currentConfidence < qiNode.blueprint.deselectionConfidence && qiNode.state != QPointState.Deselected)
    //                 {
    //                     qiNode.onDeselected();
    //                     this.shouldNotifyRootDeselection(this.qiNodeCollection.get(i)!);
    //                     qiNode.state = QPointState.Deselected;
    //                     if(webNode.viewData.type === ViewType.Image) {
    //                         const imgElement = document.getElementById(webNode.viewData.name) as HTMLImageElement;
    //                         if (imgElement && webNode.viewData.imgSrcUnselected) {
    //                             imgElement.src = webNode.viewData.imgSrcUnselected;
    //                         }
    //                     }
    //                     this.showChildren(qiNode, false);
    //                 }
    //             }
    //         }
    // }

    //MJG TO UNCOMMENT

    // destroyMenus() {
    //     while (this.qiNodeCollection.size > 0) {
    //         const webNode = this.qiNodeCollection.get(0) as WebNode;
    //         webNode.destroyMenu();
    //     }
    // }
   
}