import { useEffect, useState, MouseEvent } from 'react';

import './latestBuild.scss';
import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';

const LatestBuild = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/login');
  }

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loggedIn) {
      setLoading(false);
    }
  }, [loggedIn]);

  const handleHeadsetDownload = async () => {
    const resp = await VxAPI.downloadLatestBuild('QVXHeadsetLatest.apk');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };

  const handleDesktopDownload = async () => {
    const resp = await VxAPI.downloadLatestBuild('QVXDesktopLatest.zip');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };

  const handleQHAMDownload = async () => {
    const resp = await VxAPI.downloadLatestBuild('QHAMLatest.zip');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };

  const handleQuickLauncherDownload = async () => {
    const resp = await VxAPI.downloadLatestBuild('windows-amd64.zip');

    if (resp.signedS3Url) {
      window.location.assign(resp.signedS3Url);
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <>
          <div className="download-page">
            <div className="build-download">
              <h1>Latest Builds!</h1>
              <a href="#" onClick={handleHeadsetDownload}>
                QIVX Headset APK
              </a>
              <a href="#" onClick={handleDesktopDownload}>
                QIVX Desktop App
              </a>
            </div>
            <div className="build-download">
              <h1>Latest App Managers!</h1>
              <a href="#" onClick={handleQHAMDownload}>
                Quantum Headset Application Manager (QHAM)
              </a>
              <a href="#" onClick={handleQuickLauncherDownload}>
                QIVX Quick Launcher
              </a>
            </div>
            <div className="build-download">
              <h1>Latest Help Guides!</h1>
              <a
                href="https://qint-my.sharepoint.com/:w:/g/personal/dbryant_quantuminterface_com/EfKQLCH6KARLnAkb8jJaSnQBN_xIxDoBjHD5HLYxTEI8yw?e=0qR1BQ"
                target="_blank"
              >
                QIVX Quick Start Guide
              </a>
              <a
                href="https://qint-my.sharepoint.com/:w:/g/personal/dbryant_quantuminterface_com/EdoEjWk2D_dNjpq-xiOz6fIBa4S3LbTfMiOZeceJq2XGFQ"
                target="_blank"
              >
                QIVX Manual (In depth version of Quick Start Guide)
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LatestBuild;
