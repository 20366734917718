import React, { useEffect, useState } from "react";

import { VxAPI } from "../../api/vx.api";
import Auth from '../../utils/auth';
import { AddCustomerSelectStateDTO } from "../dto/addCustomer.selectState.dto";
import { LocationObjectDTO } from "../dto/locationObject.dto";
import './addCustomerForm.scss'

const AddCustomerForm = () => {
    const [message, setMessage] = useState('');
    const [selectState, setSelectState] = useState<AddCustomerSelectStateDTO>({
        location: undefined
    })
    const [formState, setFormState] = useState({
        lastName: '',
        firstName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
    })
    const [locationArray, setLocationArray] = useState<LocationObjectDTO[]>([{
        locationId: 0,
        title: '',
    }])

    const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMessage('');
        if (e.target.value === 'select') {
            setSelectState({ ...selectState, [e.target.name]: undefined })
        }

        setSelectState({ ...selectState, [e.target.name]: e.target.value })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage('');
        setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        const {
            location
        } = selectState

        const {
            lastName,
            firstName,
            address,
            address2,
            city,
            state,
            zip,
            phone,
            email
        } = formState;

        if (
            firstName === '' ||
            address === '' ||
            city === '' ||
            email === '' ||
            state === '' ||
            phone === '' ||
            zip === '' ||
            location === '' || location === undefined || location === 'select'
        ) {
            setMessage('Make sure all required fields are completed.')
            return
        }

        const resp = await VxAPI.addCustomer({
            lastName,
            firstName,
            address,
            address2,
            city,
            state,
            zip,
            phone,
            email,
            location
        })

        if (resp.customerId) {
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );

            window.location.assign('/customers')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const locationData = await VxAPI.getAllLocations();

            if (locationData) {
                setLocationArray(locationData)
            }
        }

        fetchData()
            .catch(console.error)
    }, []);

    return (
        <div id='add-cust-div'>
            <h1>Add a Customer</h1>
            <form onSubmit={handleSubmit}>
                <h2>1. Personal Information</h2>
                <div className="add-cust-row">
                    <div className="add-cust-form-input">
                        <label htmlFor='firstName'>First/Company Name *</label>
                        <input type='text' name='firstName' defaultValue={formState.firstName} onChange={handleChange} />
                    </div>
                    <div className="add-cust-form-input">
                        <label htmlFor='lastName'>Last Name</label>
                        <input type='text' name='lastName' defaultValue={formState.lastName} onChange={handleChange} />
                    </div>
                </div>
                <div id="add-cust-location">
                    <label htmlFor="location">Location *</label>
                    <select name="location" onChange={handleSelection}>
                        <option value='select'>---</option>
                        {locationArray ? locationArray.map((location: LocationObjectDTO, index: number) => (
                            <option key={index} value={location.title}>{location.title}</option>
                        )) : null}
                    </select>
                </div>
                <h2>2. Contact Information</h2>
                <div className="add-cust-row">
                    <div className="add-cust-form-input">
                        <label htmlFor='email'>Email Address *</label>
                        <input type='email' name='email' defaultValue={formState.email} onChange={handleChange} />
                    </div>
                    <div className="add-cust-form-input">
                        <label htmlFor='phone'>Phone *</label>
                        <input type='phone' name='phone' defaultValue={formState.phone} onChange={handleChange} />
                    </div>
                </div>
                <div className="add-cust-row">
                    <div className="add-cust-form-input">
                        <label htmlFor='address'>Mailing Address Line 1 *</label>
                        <input type='text' name='address' defaultValue={formState.address} onChange={handleChange} />
                    </div>
                    <div className="add-cust-form-input">
                        <label htmlFor='address2'>Mailing Address Line 2</label>
                        <input type='text' name='address2' defaultValue={formState.address2} onChange={handleChange} />
                    </div>
                </div>
                <div className="add-cust-row">
                    <div className="add-cust-form-input">
                        <label htmlFor='city'>City *</label>
                        <input type='text' name='city' defaultValue={formState.city} onChange={handleChange} />
                    </div>
                    <div className="add-cust-form-input">
                        <label htmlFor='state'>State *</label>
                        <input type='text' name='state' defaultValue={formState.state} onChange={handleChange} />
                    </div>
                </div>
                <div className="add-cust-row">
                    <div className="add-cust-form-input">
                        <label htmlFor='zip'>Zip *</label>
                        <input type='text' name='zip' defaultValue={formState.zip} onChange={handleChange} />
                    </div>
                </div>
                <div id="add-cust-btns">
                    <button type="button" onClick={() => window.location.assign('/customers')}><span>Cancel</span></button>
                    <button type="submit"><span>Add</span></button>
                </div>
            </form>
            {message ? (
                <h3 className="message">{message}</h3>
            ) : null}
        </div>
    )
}

export default AddCustomerForm;