import ConfidenceTracker from "./ConfidenceTracker";
import { QPointState } from "./Enums";
import IQINode from "./IQINode";
import QINode from "./QINode";
import Queue from "./Utilities/Queue";
import Vector2 from "./Utilities/Vector2";
import WebNode from "./WebNode";

// MJG ? to understand
// Should I be making a WebQINodeManager that extends this class?
export default class QINodeManager
{
    confidenceTracker: ConfidenceTracker;
    qiNodeCollection: Map<number, IQINode>;
    reusableIds: Queue<number | undefined>;
    currentIndex: number;
    // MJG removed confidenceTracker from constructor and assigned default as 'new ConfidenceTracker()'
    // MJG removed qiNodeCollection from constructor and assigned default as 'new Map()'
    // MJG removed reusableIds from constructor and assigned default as 'new Queue()'
    // MJG removed currentIndex from constructor and assigned default as '0'
    constructor() {
        this.confidenceTracker = new ConfidenceTracker();
        this.qiNodeCollection = new Map<number, IQINode>();
        this.reusableIds = new Queue();
        this.currentIndex = 0;
    }

    register(qiNode: IQINode)
    {
        let node = qiNode.qiNode;
        // console.log(`${node.id} is :`, node);
        if (this.reusableIds.count() > 0)
        {
            node.id = this.reusableIds.dequeue()!;
        }
        else
        {
            node.id = this.currentIndex;
            this.currentIndex++;
        }

        this.qiNodeCollection.set(node.id, qiNode);
        
        qiNode.initializeCallbacks();
        if(node.children) {
            node.children.forEach( child => {
                this.register(child); 
            })
        }
        
    }

    unregister(qiNode: IQINode)
    {
        let node = qiNode.qiNode;
        qiNode.unsubscribeCallbacks();
        this.reusableIds.enqueue(node.id);
        this.qiNodeCollection.delete(node.id);
    }

    updateConfidence(inputPosition: Vector2)
    {
        for(let i=0; i < this.qiNodeCollection.size; i++)
        {
            if (this.qiNodeCollection.get(i) === null)
            {
                this.reusableIds.enqueue(this.qiNodeCollection.get(i)?.qiNode.id);
                this.qiNodeCollection.delete(i);
                continue;
            }
            // MJG & DB added lines 61 & 62
            let webNode = this.qiNodeCollection.get(i) as WebNode;
            
            let iq = this.qiNodeCollection.get(i) as IQINode;
            let qiNode = iq!.qiNode;
            
            if (qiNode.isHidden || qiNode.confidenceLogic == null) continue;
            let distance = qiNode.confidenceLogic.getConfidence(inputPosition, qiNode.blueprint.position);
            qiNode.currentConfidence = distance;
            // if(qiNode.currentConfidence !== 0){
            //     console.log(`${webNode.viewData.name}'s confidece = ${qiNode.currentConfidence}`);
            // } 
            // MJG ADDED TO UPDATE CONFIDENCE LABEL
            // get label element
            if(webNode.view?.labelEl) {
            let formattedConfidence = (qiNode.currentConfidence * 100).toFixed(2);
            webNode.view.labelEl.textContent = `${formattedConfidence} %`
            }
            this.confidenceTracker.tryUpdateConfidence(qiNode);
            //check if this node has any children nodes that are being interacted with if so continue we dont want to deselect the root
            if (this.checkForOpenChild(qiNode)) continue;
            let siblings: WebNode[] = [];
            let hasLockedSibling = false;
            let hasSelectedSibling = false;
            if(webNode.parent && webNode.parent.qiNode.children.size > 0 && qiNode.currentConfidence !== 0) {
                webNode.parent.qiNode.children.forEach(child => {
                    const foundChild = child as WebNode;
                    if(foundChild === webNode) {
                        return;
                    } else {
                        if(foundChild.view) {
                            siblings.push(foundChild);
                        }
                    }
                })
            } else {
                this.qiNodeCollection.forEach(node => {
                    const foundNode = node as WebNode;
                    if(foundNode.viewData.name === webNode.viewData.name)  {
                        return;
                    } else  {
                        if(foundNode.isRoot && foundNode.view) {
                            siblings.push(foundNode)
                        }
                    }
                })
            }
            if(siblings.length > 0) {
                hasLockedSibling = !!siblings.find(siblingNode => siblingNode.wasClicked)
                hasSelectedSibling = !!siblings.find(siblingNode => siblingNode.qiNode.state === QPointState.Selected);
            }
            if(!hasLockedSibling) {
                webNode.view?.update(webNode.qiNode.currentConfidence)
                webNode.view?.checkOverflow();
            }   
                if (qiNode.currentConfidence > qiNode.blueprint.animationConfidence && qiNode.state.toString() != QPointState.Selected && qiNode.state.toString() !== QPointState.Previewed && hasLockedSibling === false && webNode.view?.viewOverflow === false)
            {
                //check if we should select before adding the animation action to queue
                if (qiNode.currentConfidence > qiNode.blueprint.selectionConfidence)
                {
                    if(qiNode.onSelected) {
                        qiNode.onSelected();
                    }
                    this.shouldNotifyRootSelection(this.qiNodeCollection.get(i)!);
                    qiNode.state = QPointState.Selected;
                    if(qiNode.onShowListDiv) {
                        qiNode.onShowListDiv(true);
                    }
                    this.showChildren(qiNode, false);
                }
                else
                {
                    // preview logic
                    if(qiNode.children && qiNode.state === QPointState.Deselected) {
                        qiNode.children.forEach( child => {
                            let foundChild = child as WebNode;
                            if(foundChild.viewData.disablePreview === false && child.qiNode.onPreview) {
                                child.qiNode.state = QPointState.Previewed
                                child.qiNode.onPreview();
                            }
                        })
                    }
                    //we need to animate
                    if(qiNode.onAnimate) {
                        qiNode.onAnimate();
                    }
                        
                        //MJG added to reset any qp's that have 'wasClicked=true' to false to reset search for dynamic tag
                        if(webNode.isRoot) {
                            this.qiNodeCollection.forEach(node => {
                                const webNode = node as WebNode;
                                if(webNode.wasClicked) {
                                    webNode.wasClicked = false;
                                    const lockEl = document.getElementById(`${webNode.viewData.name}-lock`);
                                    if(lockEl) {
                                        lockEl.remove();
                                    }
                                    if(webNode.view?.qPointEl && !webNode.isRoot) {
                                        webNode.view.qPointEl.style.color = webNode.viewData.textColor as string;
                                    }
                                    if(qiNode.onShowListDiv) {
                                        qiNode.onShowListDiv(false);
                                    }
                                }
                            })
                        }
                        qiNode.state = QPointState.Animation;
                }
            }
            else
            {
                //check if we should fire the deselection action
                if (qiNode.currentConfidence < qiNode.blueprint.deselectionConfidence && qiNode.state != QPointState.Deselected && qiNode.state !== QPointState.Previewed && hasLockedSibling === false)
                {
                    if(qiNode.onDeselected) {
                        qiNode.onDeselected();
                    }
                    this.shouldNotifyRootDeselection(this.qiNodeCollection.get(i)!);
                    qiNode.state = QPointState.Deselected;
                    // MJG added if statement to keep children open if clicked or if any children are animating
                    const children: IQINode[] = [];
                    qiNode.children.forEach(child => {
                        children.push(child)
                    })
                    // children.forEach(animatingChild => console.log(`${webNode.viewData.name}'s animating child: `, animatingChild));
                    // console.log(`${webNode.viewData.name} has an animating child?: ${foundAnimatingChild}`)
                    if(!webNode.wasClicked) {
                        this.showChildren(qiNode, true);
                        if(qiNode.children.size > 0) {
                            qiNode.children.forEach( node  => {
                                this.showChildren(node.qiNode, true);
                            })
                        } else {
                            if(qiNode.onShowListDiv) {
                                qiNode.onShowListDiv(false);
                            }
                        }
                    }
                }
            }
        }
    }
    // MJG Added to give developer a way to easily empty qiNode collection and remove node elements from webpage DOM
    destroyNodes() 
    {
        const collectionSize = this.qiNodeCollection.size;
        for(let i = 0; i < collectionSize; i++)
        {
            let webNode = this.qiNodeCollection.get(i) as WebNode;
            const anchorEl = document.getElementById(webNode.viewData.anchorElementId as string);
            if(anchorEl) {
                anchorEl.innerHTML = '';
            }
            this.unregister(webNode);
        }
    }
        
    shouldNotifyRootSelection(iqiNode: IQINode)
    {
        let node = iqiNode.qiNode;
        if (node.children.size == 0) return;
        node.onRootSelection?.invoke(iqiNode);
    }

    shouldNotifyRootDeselection(iqiNode: IQINode)
    {
        let node = iqiNode.qiNode;
        if (node.children.size == 0) return;
        node.onRootDeselection?.invoke(iqiNode);
    }

    //TODO dont allow selection of another node unless its a child of the current node
    //TODO Do not let a child be selected unless parent is selected

    checkForOpenChild(qiNode: QINode):boolean
    {
        //check if any children are being animate if so we shouldnt perform any animation sequences below
        let childIsOpen = false;
        qiNode.children.forEach(child => 
            {
                if (child.qiNode.state == QPointState.Selected || child.qiNode.state == QPointState.Animation)
                {
                    childIsOpen = true;
                    return;
                }
            });
        return childIsOpen;
    }

    showChildren(qiNode: QINode,isHidden: boolean)
    {
        qiNode.children.forEach(child => 
            {
                if(child.qiNode.onShowChild) {
                    child.qiNode.isHidden = isHidden;
                  child.qiNode.onShowChild(isHidden);
                }
            });
    }

}