import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './editQiAppForm.scss'
import { AppObjectDTO } from '../dto/appObject.dto';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';

const EditQiAppForm: React.FC<QiEmployeeProps> = (props) => {
    const { userCustomerId, qiEmployee } = props;

    const params = useParams();
    const paramsAppId = Number(params.appId);

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isQiEmployee, setIsQiEmployee] = useState<boolean | null | undefined>(null);
    const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);

    const [appInfo, setAppInfo] = useState<AppObjectDTO>({
        type: '',
        title: '',
        description: '',
        notes: '',
        customerId: 0,
        useCaseId: 0
    })
    const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([{
        customerId: 0,
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        location: ''
    }])
    const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([{
        useCaseId: 0,
        title: '',
        description: '',
        notes: ''
    }])

    const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMessage('');
        setAppInfo({ ...appInfo, [e.target.name]: Number.parseInt(e.target.value) })
    }

    const { title, description, type, notes, customerId, useCaseId } = appInfo

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage('');
        setAppInfo({ ...appInfo, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        if (
            type === '' ||
            title === '' ||
            description === '' ||
            useCaseId === undefined || useCaseId === 0
        ) {
            setMessage('Make sure all required fields are completed.')
            return
        }

        const resp = await VxAPI.editApp({
            useCaseId,
            title,
            description,
            type,
            notes
        }, paramsAppId)

        if (resp.appId) {
            window.location.assign('/apps')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const appData = await VxAPI.getSingleAppData(paramsAppId);

            if (appData) {
                setAppInfo(appData);
                setLoading(false);
            }

            const customerData = await VxAPI.getAllCustomersData();

            if (customerData) {
                setCustomerArray(customerData)
            }

            const useCaseData = await VxAPI.getAllUseCasesData();

            if (useCaseData) {
                setUseCaseArray(useCaseData)
            }
        }

        fetchData()
            .catch(console.error)
    }, []);

    useEffect(() => {
        setIsQiEmployee(qiEmployee);
        setLoggedInUserCustomerId(userCustomerId);

        if (qiEmployee !== null && userCustomerId !== null && appInfo.customerId !== 0) {
            if (!qiEmployee && appInfo.customerId !== userCustomerId) {
                window.location.assign('/')
            }
        }
    }, [userCustomerId, qiEmployee, appInfo])

    return (
        <>
            {loading ? (
                <div>
                    <h1 className="loading">Loading...</h1>
                </div>
            ) : (
                <div id='edit-app-div'>
                    <h1>Edit App</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='dropdown-div'>
                            <div className='edit-app-cust'>
                                <label htmlFor='name'>Customer</label>
                                <p id='app-cust-name'>{customerArray.find(customer => customer.customerId === appInfo.customerId)?.firstName} {customerArray.find(customer => customer.customerId === appInfo.customerId)?.lastName}</p>
                            </div>
                            <div className="edit-app-dropdown">
                                <label htmlFor="useCaseId">Use Case *</label>
                                <select name="useCaseId" onChange={handleSelection}>
                                    <option value={appInfo.useCaseId}>{useCaseArray.find(useCase => useCase.useCaseId === appInfo.useCaseId)?.title}</option>
                                    {useCaseArray ? useCaseArray.map((useCase: UseCasesObjectDTO, index: number) => (
                                        <option style={{ display: useCase.useCaseId === appInfo.useCaseId ? 'none' : 'block' }} key={index} value={useCase.useCaseId}>{useCase.title}</option>
                                    )) : null}
                                </select>
                            </div>
                        </div>
                        <div className='edit-app-row'>
                            <div className='edit-app-form-input'>
                                <label htmlFor='title'>App Name *</label>
                                <input type='text' name='title' defaultValue={title} onChange={handleChange} />
                            </div>
                            <div className='edit-app-form-input'>
                                <label htmlFor='type'>Type *</label>
                                <input type='text' name='type' defaultValue={type} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='edit-app-form-textarea'>
                            <label htmlFor='description'>Description *</label>
                            <textarea name='description' defaultValue={description} onChange={handleChange} />
                        </div>
                        <div className='edit-app-form-textarea'>
                            <label htmlFor='notes'>Notes</label>
                            <textarea name='notes' defaultValue={notes} onChange={handleChange} />
                        </div>
                        <div id="edit-app-btns">
                            <button type="button" onClick={() => window.location.assign('/apps')}><span>Cancel</span></button>
                            <button type="submit"><span>Save</span></button>
                        </div>
                    </form>
                    {message ? (
                        <h3 className='message'>{message}</h3>
                    ) : null}
                </div>
            )}
        </>
    )

}

export default EditQiAppForm;