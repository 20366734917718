import { QPointState, ViewType } from "./Enums";
import InverseDistanceBasicConfidence from "./InverseDistanceBasicConfidence";
import IQINode from "./IQINode";
import IViewData from "./IViewData";
import QINode from "./QINode";
import Vector2 from "./Utilities/Vector2";
import View from "./View";
import WebQINodeManager from "./WebQINodeManager";


export default class WebNode implements IQINode {
   qiNode: QINode;
   viewData: IViewData;
   view: View | null;
   ignoreInputUntilTimeMs: number;
   anchorElement: HTMLElement | null;
   confidenceModule: InverseDistanceBasicConfidence | null;
   confidence: number | undefined;
   parent: WebNode | null;
   selectCallBack: Function;
   deselectCallBack: Function;
   wasClicked: boolean;

   constructor(qiNode: QINode, viewData: IViewData, selectCallBack: Function, deselectCallBack: Function, wasClicked: boolean) {
    this.qiNode = qiNode
    this.viewData = viewData;
    this.view = null;
    this.ignoreInputUntilTimeMs = 0;
    this.anchorElement = null;
    this.confidenceModule = qiNode.confidenceLogic;
    this.confidence = 0;
    this.parent = null;
    this.selectCallBack = selectCallBack;
    this.deselectCallBack = deselectCallBack;
    this.wasClicked = wasClicked;
       }
    unsubscribeCallbacks() {
        this.qiNode.onAnimate = undefined;
        this.qiNode.onDeselected = undefined;
        this.qiNode.onSelected = undefined;
        this.qiNode.onPreview = undefined;
        this.qiNode.onShowChild = undefined;
    }
    initializeCallbacks() {
       this.qiNode.onShowChild = (isHidden: boolean) => {
        let parentEl = document.getElementById(this.parent?.viewData.name as string);
        let htmlEl = document.getElementById(this.viewData.name);
        let labelEl = document.getElementById(`${this.viewData.name}-confidence`);
        let listDivEl = document.getElementById(this.viewData.anchorElParentId as string);
        let anchorEl = document.getElementById(this.viewData.anchorElementId as string)
        if(htmlEl) {
            if(isHidden) {
                if(listDivEl)  {
                    listDivEl.style.display = 'none';
                }
                htmlEl.style.display = 'none';
                if(this.viewData.type === ViewType.List) {
                    htmlEl.style.color = this.viewData.textColor as string;
                }
            } else {
                if(this.viewData.height && this.viewData.width && this.parent && this.parent.viewData.height && parentEl) {
                    if(this.viewData.type === ViewType.Image) {
                    let parentRect = parentEl.getBoundingClientRect();
                    if(this.qiNode.state === QPointState.Previewed) {
                        htmlEl.style.height = `${this.viewData.height * 0.3}px`
                        htmlEl.style.width = `${this.viewData.width * 0.3}px`
                    }
                    htmlEl.style.position = 'absolute';
                    htmlEl.style.left = `${(this.parent.qiNode.blueprint.position.x) + this.viewData.positionXoffParent}px`
                    htmlEl.style.top = `${parentRect.top + (this.parent.viewData.height / 10) + this.viewData.positionYoffParent + window.scrollY}px`
                }

                    htmlEl.style.display = 'block';

                    if(this.viewData.type === ViewType.List && this.viewData.anchorElParentId) {
                        let parentRect = parentEl.getBoundingClientRect();
                        if(listDivEl && parentRect) {
                            listDivEl.style.display = 'flex';
                            listDivEl.style.flexDirection = 'column';
                            // listDivEl.style.left = `${(this.viewData.dynamicListPosX? this.viewData.dynamicListPosX : 0) + (this.viewData.dynamicListOffsetX? this.viewData.dynamicListOffsetX : 0)}px`;
                            // listDivEl.style.top = `${this.viewData.dynamicListPosY? this.viewData.dynamicListPosY : 0 + (this.viewData.dynamicListOffsetY? this.viewData.dynamicListOffsetY : 0) + window.scrollY}px`;
                        }
                        htmlEl.style.display = 'flex';
                        htmlEl.style.flexDirection = 'row';
                        htmlEl.style.alignItems = 'center';
                        htmlEl.style.justifyContent = 'center';
                    }
                }
                
                let qpointRect = htmlEl.getBoundingClientRect();
                // to update the position of qpoint for confidence calc
                this.qiNode.blueprint.position = new Vector2((qpointRect.left + qpointRect.right) / 2.0 ,(qpointRect.top + qpointRect.bottom) / 2.0);
            }
        }

        if(labelEl) {
            if(isHidden) {
                labelEl.style.display = 'none'
            } else {
                if(this.parent) {
                    labelEl.style.display = 'block'
                    labelEl.style.position = 'absolute';
                    labelEl.style.left = `${this.qiNode.blueprint.position.x + 50}px`
                    labelEl.style.top = `${this.qiNode.blueprint.position.y + 35}px`
                }
            }
        }
        
       }
       this.qiNode.onShowListDiv = (b: boolean) => {
        let anchorParentEl = document.getElementById(this.viewData.anchorElementId as string)?.parentElement;
        if(anchorParentEl) {
            const childListDivParentEl = anchorParentEl.nextSibling as HTMLDivElement;
            if(childListDivParentEl) {
                if(b === true) {
                    childListDivParentEl.style.display = 'flex';
                    childListDivParentEl.style.flexDirection = 'column';
                } else {
                    childListDivParentEl.style.display = 'none';
                    if(this.isRoot)
                    {
                        const grandChildListDivEl = childListDivParentEl.nextSibling as HTMLDivElement;
                        if(grandChildListDivEl)
                        {
                            grandChildListDivEl.style.display = 'none';
                        }
                    }
                }
            }
        }

        }

       this.qiNode.onPreview = () => {
        // console.log(`${this.viewData.name} is being previewed`)
        this.qiNode.isHidden = false
        let previewScale = 0.3;
        if(this.qiNode.onShowChild) {
            this.qiNode.onShowChild(this.qiNode.isHidden);
        }
    
        
        


        // FIXME: hardcoded base beingPreviewed scale.
        // if(this.view && this.view.transform && this.view.transform.parent)
        // this.view.transition = new Transition(
        //             this.view.transform,
                    // new Transform(null, this.parent?._restPosition, 0, 0),
                    // new Transform(null, this.view.getPreviewAdjustedRestPosition(previewScale), previewScale, 0),
        //             this.view.transform.parent?.getTime(),
        //             200,
        //             new LinearEasingFn(),
        //             this
        //         );
       }
        this.qiNode.onAnimate = () => {

            // if qinode.children.size > 0 run layoutmodule.updateChildPositions 
            // console.log(`${this.viewData.name} animating as state ${this.qiNode.state}`);

            // ScaleByConfidence.updateScale(this.qiNode.currentConfidence, 2, this)
            // let center = this.qiNode.blueprint.position;

            // let scale = lerp(1, 2, this.qiNode.currentConfidence);
         

            // if(this.viewData && this.viewData.height && this.viewData.width) {
            //     let adjustedHeight: number = this.viewData.height * scale
            //     let adjustedWidth: number = this.viewData.width * scale;
               
            //     let imageEl = document.getElementById(this.viewData.name)
            //     if(imageEl) {
                  
            //         imageEl.style.height = `${adjustedHeight}px`
            //         imageEl.style.width = `${adjustedWidth}px`
                    
            //         // imageEl.style.left = `${center.x}px`;
            //         // imageEl.style.top = `${center.y}px`;
                    // imageEl.style.transform = `translate(-50%, -50%) scale(0.8) rotate(0deg)`;

            //     }
            // }

            // if(this.view && this.view.transform) {
            //     this.view.transition = new Transition(
            //         this.view.transform,
            //         new Transform(null, this.view.transform._position, 0, 0),
            //         new Transform(null, this.view.getPreviewAdjustedRestPosition(previewScale), previewScale, 0),
            //         Date.now(),
            //         200,
            //         new LinearEasingFn(),
            //         this
            //     );

            //     this.view.update();
            // }




        }

        this.qiNode.onSelected = () => {
            // console.log(`${this.viewData.name} selected`);
           
            const qPointEl = document.getElementById(this.viewData.name)
            // to change the image to a selected version
            if(this.viewData.type === ViewType.Image) {
                const imgElement = qPointEl as HTMLImageElement;
                if (imgElement && this.viewData.imgSrcSelected) {
                    imgElement.src = this.viewData.imgSrcSelected;
                }
            }
            // change the style of list element on select 
            if(this.viewData.type === ViewType.List) {
                if(this.selectCallBack && !this.wasClicked) {
                    this.selectCallBack(this.viewData.name)
                }
                const listEl = qPointEl as HTMLLIElement;
                if(listEl && this.viewData.hoverBackgroundColor) {
                    listEl.style.color = this.viewData.hoverBackgroundColor;
                    listEl.style.cursor = 'pointer';
                }
            }
            // take the children out of preview state and change state to deselect, and height/width back to original px per viewData
            if(this.qiNode.children) {
                this.qiNode.children.forEach( child =>  {
                    let foundChild = child as WebNode;
                    foundChild.qiNode.state = QPointState.Deselected
                    let foundChildEl = document.getElementById(foundChild.viewData.name) as HTMLImageElement;
                    if(foundChildEl) {
                        foundChildEl.style.height = `${foundChild.viewData.height}px`
                        foundChildEl.style.width = `${foundChild.viewData.width}px`
                    }
                })
            }
           

        }

        this.qiNode.onDeselected = () => {
            // console.log(`${this.viewData.name} unselected`);
            const qPointEl = document.getElementById(this.viewData.name)
            // to change the img to a deselected version
            if(this.viewData.type === ViewType.Image) {
                const imgElement = qPointEl as HTMLImageElement;
                if (imgElement && this.viewData.imgSrcUnselected) {
                    imgElement.src = this.viewData.imgSrcUnselected;
                }
            }

             // change the style of list element on deselect 
            if(this.deselectCallBack && this.wasClicked === false) {
                if(!this.parent) {
                    this.deselectCallBack('')
                }
                
                this.deselectCallBack(this.parent?.viewData.name)

                if(this.viewData.type === ViewType.List) {
                    const listEl = qPointEl as HTMLLIElement;
                    if(listEl && this.viewData.hoverBackgroundColor) {
                        listEl.style.color = this.viewData.textColor as string;
                        listEl.style.cursor = 'unset';
                    }
                }
            }

           

            
        }

        //  // Disable previews for children.
        //  if(this.qiNode.children) {
        //     this.qiNode.children.forEach(child => {
        //     let foundChild = child as WebNode;
        //     foundChild.qiNode.state = QPointState.Deselected;
        //     // Set a transition for each child for a nice animation
        //     // into the unselected child state.
        //     if(foundChild.view?.transform && foundChild.parent) {
        //         let previewScale = 0.3;  // FIXME: hardcoded base beingPreviewed scale.
        //         let endT = foundChild.view.transform.copy();
        //         endT.position = foundChild.restPosition;
        //         endT.scale = 0.8; // FIXME: hardcoded base unselected scale.
        //         foundChild.view.transition = new Transition(
        //             new Transform(null, this.parent?._restPosition, 0, 0),
        //             new Transform(null, foundChild.view.getPreviewAdjustedRestPosition(previewScale), previewScale, 0),
        //         endT,
        //         foundChild.parent?.getTime(),
        //         250,
        //         new LinearEasingFn(),
        //         foundChild
        //         );
        //     }
        //     })
        // }
    }

   setView(view: View) {
    this.view = view
   }

    createWebNode(qp: WebNode) {
        let root = qp;
        root.confidenceModule = root.confidenceModule;
        if (root.viewData.anchorElementId) {
            root.anchorElement = document.getElementById(root.viewData.anchorElementId as string);
        }

        root.setView(new View(root.viewData, root))

        const qpoint = document.getElementById(root.viewData.name) as Element;
        if(qpoint && !root.parent) {
            let qpointRect = qpoint.getBoundingClientRect();
            const centerOfQpX = (qpointRect.left + qpointRect.right) / 2.0;
            const centerOfQpY = (qpointRect.top + qpointRect.bottom) / 2.0;
            const centerPos = new Vector2(centerOfQpX, centerOfQpY);
            root.qiNode.blueprint.position = centerPos;
            for(let i = 0; i < root.qiNode.children.size; i++) {
                let children = root.qiNode.children;
                let foundChild = [...children][i] as WebNode;
                // to set the child position on creation
                // check if child is hidden
                // hidden: pos = parents pos
                // notHidden: pos = pos of html element on page
                foundChild.qiNode.blueprint.position = centerPos;
                foundChild.parent = root;
                this.createWebNode(foundChild);
            }
        } else {
            for(let i = 0; i < root.qiNode.children.size; i++) {
                let children = root.qiNode.children;
                let foundChild = [...children][i] as WebNode;
                foundChild.qiNode.blueprint.position = root.qiNode.blueprint.position
                foundChild.parent = root;
                this.createWebNode(foundChild);
            }
        }
    }

    setHidden(b: boolean) {
        if(this.view) {
            this.view.setHidden(b);
            this.qiNode.isHidden = b;
        }
    }

    // Set visibility of child QPoints.
    // setChildrenVisible(b:  boolean) {
    //     for (let i = 0; i < this.qiNode.children.size; i++) {
    //         this.children[i].setHidden(b);
    //     }
    // }

    // Set visibility of sibling QPoints.
    // setSiblingsVisible(b: boolean) {
    //     if (this.parent) {
    //         for (let i = 0; i < this.qiNode.children.size; i++) {
    //             if (this.parent.children[i] != this) {
    //                 this.parent.children[i].setHidden(b);
    //             }
    //         }
    //     }
    // }

    getTime() {
        return Date.now();
    }

    get isRoot() {
        return !this.parent;
    }

    get root() {
        let r: WebNode = this;
        while (r.parent) {
            r = r.parent;
        }
        return r;
    }

    // MJG NEED TO UNCOMMENT
    // ignoreInputForMs(durationMs: number) {
    //     this.root._ignoreInputForMs(durationMs);
    // }

    // MJG NEED TO UNCOMMENT
    // _ignoreInputForMs(durationMs: number) {
    //     this.ignoreInputUntilTimeMs = this.webQINodeManager.getTime() + durationMs;
    //     for (let i = 0; i < this.qiNode.children.size; i++) {
    //         this.qiNode.children[i]._ignoreInputForMs(durationMs);
    //     }
    // }

    // MJG TO UNCOMMENT

    // destroyMenu(removeSelfFromParent = true) {
    //     // First, recursively destroy our children.
    //     // But they shouldn't remove themselves from us just yet.
    //     for (let i = 0; i < this.children.length; i++) {
    //         this.children[i].destroyMenu(false);
    //     }
    //     // Instead, we just wipe all of our children at once after they
    //     // destroy themselves.
    //     this.children.length = 0;

    //     // Clean up objects for this node.
    //     this.view.destroy();
    //     this.view = null;

    //     if (removeSelfFromParent) {
    //         if (this.parent) {
    //             this.parent.children =
    //                 this.parent.children.filter(x => x != this);
    //         } else {
    //             this.webQINodeManager.roots =
    //                 this.webQINodeManager.roots.filter(x => x != this);
    //         }
    //     }
    // }

}