import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './addCourseForm.scss';
import { AddCourseSelectStateDTO } from '../dto/addCourse.selectState.dto';
import { ClassObjectDTO } from '../dto/classObject.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';
import { useUserRoleData } from '../../pages/account/Account';

const AddCourseForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId, loggedInId } = props;
  const [message, setMessage] = useState('');
  const [selectState, setSelectState] = useState<AddCourseSelectStateDTO>({
    customerId: undefined,
    useCaseId: undefined,
  });
  const [selectClassesState, setSelectClassesState] = useState<(number | undefined)[]>([]);
  const [selectTagsState, setSelectTagsState] = useState<(number | undefined)[]>([]);
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    notes: '',
  });
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [classArray, setClassArray] = useState<ClassObjectDTO[]>([
    {
      title: '',
      description: '',
      notes: '',
      customerId: 0,
      useCaseId: 0,
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    setSelectState({ ...selectState, [e.target.name]: Number.parseInt(e.target.value), customerId: userCustomerId });
  };

  const { customerId, useCaseId } = selectState;
  const { title, description, notes } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (
      title === '' ||
      description === '' ||
      customerId === undefined ||
      customerId === 0 ||
      useCaseId === undefined ||
      useCaseId === 0
    ) {
      setMessage('Make sure all required fields are completed.');
      return;
    }

    const creator = loggedInId;
    let tags: (number | undefined)[] = selectTagsState;
    if (selectTagsState?.length > 0) {
      selectTagsState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let createdDate = currentDate;

    setMessage('Adding...');
    const resp = await VxAPI.addCourse({
      customerId,
      useCaseId,
      title,
      description,
      notes,
      creator,
      tags,
      classes: selectClassesState,
      createdDate,
    });

    if (resp.courseId) {
      window.location.assign('/courses');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }

      const classData = await VxAPI.getAllClassesData();

      if (classData) {
        setClassArray(classData);
      }

      const tagData = await VxAPI.getAllTagsData();
      if (tagData) {
        setTagArray(tagData);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userCustomerId !== null) {
      setSelectState({ ...selectState, customerId: userCustomerId });
    }
  }, [userCustomerId]);

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName !== 'Admin') {
        window.location.assign('/');
      }
    }
  }, [userRoleName]);

  const handleMultiSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newItems = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newItems.push(Number(selectedOptions[i].value));
    }

    switch (e.target.name) {
      case 'classArray':
        setSelectClassesState(newItems);
        break;
      case 'tagArray':
        setSelectTagsState(newItems);
        break;
    }
  };

  return (
    <div id="add-course-div">
      <h1>Add a New Course</h1>
      <form onSubmit={handleSubmit}>
        <div className="dropdown-div">
          <div className="add-course-hardcode">
            <label htmlFor="name">Customer</label>
            <p id="course-cust-name">
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.firstName}{' '}
              {customerArray.find((customer) => customer.customerId === userCustomerId)?.lastName}
            </p>
          </div>
          <div className="add-usecase-dropdown">
            <label htmlFor="useCaseId">Use Case *</label>
            <select name="useCaseId" onChange={handleSelection}>
              <option value={0}>---</option>
              {useCaseArray
                ? useCaseArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map((useCase: UseCasesObjectDTO, index: number) => (
                    <option key={index} value={useCase.useCaseId}>
                      {useCase.title}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="add-course-row">
          <div className="add-course-form-input">
            <label htmlFor="title">Course Name *</label>
            <input type="text" name="title" defaultValue={title} onChange={handleChange} />
          </div>
        </div>
        <div className="selection-div">
          <div className="add-class-dropdown">
            <label id="class-dropdown-header" htmlFor="selectClassesState[]">
              Add Classes
            </label>
            <h2 id="class-select-header">Hold CTRL or CMD to select multiple</h2>
            <select multiple size={classArray?.length} name="classArray" onChange={handleMultiSelection}>
              {classArray
                ? classArray
                    .filter((custClass) => custClass.useCaseId === selectState.useCaseId)
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((custClass, index: number) => (
                      <option key={index} value={custClass.classId}>
                        {custClass.title}
                      </option>
                    ))
                : null}
            </select>
          </div>
          <div className="add-tag-dropdown">
            <label id="tag-dropdown-header" htmlFor="selectTagsState[]">
              Add Tags
            </label>
            <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
            <select multiple size={tagArray?.length} name="tagArray" onChange={handleMultiSelection}>
              {tagArray
                ? tagArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .map((tag: TagObjectDTO, index: number) => (
                    <option key={index} value={tag.tagId}>
                      {tag.title}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="add-course-form-textarea">
          <label htmlFor="description">Description *</label>
          <textarea name="description" defaultValue={description} onChange={handleChange} />
        </div>
        <div className="add-course-form-textarea">
          <label htmlFor="notes">Notes</label>
          <textarea name="notes" defaultValue={notes} onChange={handleChange} />
        </div>
        <div id="add-course-btns">
          <button type="button" onClick={() => window.location.assign('/courses')}>
            <span>Cancel</span>
          </button>
          <button type="submit">
            <span>Confirm</span>
          </button>
        </div>
      </form>
      {message ? <h3 className="message">{message}</h3> : null}
    </div>
  );
};

export default AddCourseForm;
