import IQueue from './IQueue';

export default class Queue<T> implements IQueue<T>
    {
        private storage: T[] = [];
        constructor(private capacity: number = Infinity) {}
      
        enqueue(item: T): void 
        {
          if (this.count() === this.capacity) {
            throw Error("Queue has reached max capacity, you cannot add more items");
          }
          this.storage.push(item);
        }

        dequeue(): T | undefined 
        {
          return this.storage.shift();
        }

        count(): number 
        {
          return this.storage.length;
        }
    }