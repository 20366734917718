import AddCustomerForm from "../../components/addCustomerForm/AddCustomerForm";
import { useUserData } from "../account/Account";

const AddCustomers = () => {

    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;

    if (superUser === false) {
        window.location.assign('/')
    }

    return (
        <>
            <AddCustomerForm />
        </>
    )
}

export default AddCustomers;