import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';

import './resetPw.scss';
import qiLogo from '../../assets/images/qi_logo.png';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validatePin } from '../../utils/helpers';

interface PwCriteria {
  Length: boolean;
  Lower: boolean;
  Numeric: boolean;
  Special: boolean;
  Upper: boolean;
  Match: boolean;
}

const ResetPw = () => {
  useEffect(() => {
    const loggedInUser = Auth.loggedIn();

    if (loggedInUser) {
      localStorage.removeItem('id_token');
      localStorage.removeItem('refresh_token');
    }
  });

  const params = useParams();

  const resetPasswordToken = window.location.toString().split('=')[window.location.toString().split('=').length - 1];

  const email = params.email;
  const [passVisible, setPassVisible] = useState(false);
  const [pinVisible, setPinVisible] = useState(false);
  const [pwCriteria, setPwCriteria] = useState<PwCriteria>({
    Length: false,
    Lower: false,
    Numeric: false,
    Special: false,
    Upper: false,
    Match: false,
  });
  const [message, setMessage] = useState('');
  const [formState, setFormState] = useState({
    newPassword: '',
    confirmPassword: '',
    newPin1: '',
    newPin2: '',
    newPin3: '',
    newPin4: '',
    newPin5: '',
    newPin6: '',
    newPin7: '',
    newPin8: '',
    confirmPin1: '',
    confirmPin2: '',
    confirmPin3: '',
    confirmPin4: '',
    confirmPin5: '',
    confirmPin6: '',
    confirmPin7: '',
    confirmPin8: '',
  });
  const [pinLen, setPinLen] = useState<number>(4);

  const {
    newPassword,
    confirmPassword,
    newPin1,
    newPin2,
    newPin3,
    newPin4,
    newPin5,
    newPin6,
    newPin7,
    newPin8,
    confirmPin1,
    confirmPin2,
    confirmPin3,
    confirmPin4,
    confirmPin5,
    confirmPin6,
    confirmPin7,
    confirmPin8,
  } = formState;

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    setMessage('');
    setFormState({ ...formState, [e.target.name]: e.target.value });

    const { maxLength, value, id } = e.target;
    const [fieldName, fieldIndex] = id.split('-');

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 16) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(`input[id=field-${fieldIntIndex + 1}]`) as HTMLElement | null;

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {
      e.preventDefault();

      const newPinString = (newPin1 + newPin2 + newPin3 + newPin4 + newPin5 + newPin6 + newPin7 + newPin8).trim();
      const confirmPinString = (
        confirmPin1 +
        confirmPin2 +
        confirmPin3 +
        confirmPin4 +
        confirmPin5 +
        confirmPin6 +
        confirmPin7 +
        confirmPin8
      ).trim();
      let { Match, Length, Lower, Numeric, Special, Upper } = pwCriteria;
      if (newPassword === confirmPassword && newPassword.length > 0) {
        Match = true;
      } else {
        Match = false;
      }

      if (newPassword.length >= 8) {
        Length = true;
      } else {
        Length = false;
      }

      if (!!newPassword.match(/[a-z]/) === true) {
        Lower = true;
      } else {
        Lower = false;
      }

      if (!!newPassword.match(/[A-Z]/) === true) {
        Upper = true;
      } else {
        Upper = false;
      }

      if (!!newPassword.match(/[0-9]/) === true) {
        Numeric = true;
      } else {
        Numeric = false;
      }

      if (!!newPassword.match(/[!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~]/) === true) {
        Special = true;
      } else {
        Special = false;
      }

      if (newPinString === '' && confirmPinString === '') {
        if (
          Length === false ||
          Lower === false ||
          Match === false ||
          Numeric === false ||
          Special === false ||
          Upper === false
        ) {
          setMessage("Your password doesn't meet all requirements. Please correct all red X's.");
          return;
        }
        const resp = await VxAPI.resetPw({
          email,
          newPassword,
          resetPasswordToken,
        });
        if (resp.ok) {
          Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
          setMessage('Your password has been reset. You will be redirected to login.');

          setTimeout(() => {
            window.location.assign('/login');
          }, 2000);
        } else {
          setMessage(
            'Your request was not processed. If this persists, go back and request a new reset password link.',
          );
        }
      } else if (newPassword === '' && confirmPassword === '') {
        const validPin = validatePin(newPinString);
        if (typeof validPin === 'string') {
          setMessage(validPin);
          return;
        }
        if (newPinString !== confirmPinString) {
          setMessage('Your PINs do not match. Please reenter and try again.');
          return;
        }
        let pin = newPinString;
        const resp = await VxAPI.resetPw({
          email,
          resetPasswordToken,
          pin,
        });
        if (resp.ok) {
          Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
          setMessage('Your PIN has been reset. You will be redirected to login.');

          setTimeout(() => {
            window.location.assign('/login');
          }, 2000);
        } else {
          setMessage(
            'Your request was not processed. If this persists, go back and request a new reset password link.',
          );
        }
      } else {
        if (
          Length === false ||
          Lower === false ||
          Match === false ||
          Numeric === false ||
          Special === false ||
          Upper === false
        ) {
          setMessage("Your password doesn't meet all requirements. Please correct all red X's.");
          return;
        }
        const validPin = validatePin(newPinString);
        if (typeof validPin === 'string') {
          setMessage(validPin);
          return;
        }
        if (newPassword === '' || confirmPassword !== newPassword || newPinString !== confirmPinString) {
          setMessage('Your passwords and/or PINs do not match. Please reenter and try again.');
          return;
        }
        let pin = newPinString;
        const resp = await VxAPI.resetPw({
          email,
          newPassword,
          resetPasswordToken,
          pin,
        });

        if (resp.ok) {
          Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
          setMessage('Your password & PIN has been reset. You will be redirected to login.');

          setTimeout(() => {
            window.location.assign('/login');
          }, 3000);
        } else {
          setMessage(
            'Your request was not processed. If this persists, go back and request a new reset password link.',
          );
        }
      }
    },
    [
      confirmPassword,
      newPassword,
      resetPasswordToken,
      email,
      newPin1,
      newPin2,
      newPin3,
      newPin4,
      newPin5,
      newPin6,
      newPin7,
      newPin8,
      confirmPin1,
      confirmPin2,
      confirmPin3,
      confirmPin4,
      confirmPin5,
      confirmPin6,
      confirmPin7,
      confirmPin8,
    ],
  );

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
        ev.preventDefault();
        handleSubmit(ev);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [newPassword, confirmPassword, handleSubmit]);

  useEffect(() => {
    setPwCriteria((prevCriteria) => ({
      ...prevCriteria,
      Length: newPassword.length >= 8,
      Lower: !!newPassword.match(/[a-z]/) === true,
      Upper: !!newPassword.match(/[A-Z]/) === true,
      Numeric: !!newPassword.match(/[0-9]/) === true,
      Special: !!newPassword.match(/[!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~]/) === true,
      Match: newPassword.length > 0 && newPassword === confirmPassword ? true : false,
    }));
  }, [newPassword, confirmPassword]);

  return (
    <div className="box" id="pw-box">
      <div>
        <h1 id="resetPw-title" className="title">
          Reset Password
        </h1>
      </div>
      <form onSubmit={handleSubmit} className="resetPwForm">
        <div className="row flex-dir-col pwInputs">
          <label style={{ display: 'flex', flexWrap: 'wrap' }} htmlFor="newPassword">
            New Password{' '}
            {passVisible ? (
              <VisibilityOffOutlinedIcon onClick={() => setPassVisible(!passVisible)} />
            ) : (
              <VisibilityOutlinedIcon onClick={() => setPassVisible(!passVisible)} />
            )}
          </label>
          <input
            type={passVisible ? 'text' : 'password'}
            name="newPassword"
            defaultValue={newPassword}
            onChange={handlePassChange}
          />
        </div>
        <div className="row flex-dir-col pwInputs">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type={passVisible ? 'text' : 'password'}
            name="confirmPassword"
            defaultValue={confirmPassword}
            onChange={handlePassChange}
          />
        </div>
        <div className="row flex-dir-col pw-criteria" id="pw-criteria">
          <div className="pwCritItems">
            {!pwCriteria.Length ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Minimum 8 Characters</p>
          </div>
          <div className="pwCritItems">
            {!pwCriteria.Lower ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Lowercase</p>
          </div>
          <div className="pwCritItems">
            {!pwCriteria.Upper ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Uppercase</p>
          </div>
          <div className="pwCritItems">
            {!pwCriteria.Numeric ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Number</p>
          </div>
          <div className="pwCritItems">
            {!pwCriteria.Special ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Special Characters (ASCII)</p>
          </div>
          <div className="pwCritItems">
            {!pwCriteria.Match ? (
              <FontAwesomeIcon className={'notMet'} icon={faXmark} />
            ) : (
              <FontAwesomeIcon className={'met'} icon={faCheck} />
            )}
            <p>Passwords Match</p>
          </div>
        </div>
        <div className="row flex-dir-col">
          <label
            style={{ display: 'flex', flexWrap: 'wrap', marginTop: '3rem' }}
            className="pin-label"
            htmlFor="newPin"
          >
            New PIN (4 - 8 length, Alphanumeric){' '}
            {pinVisible ? (
              <VisibilityOffOutlinedIcon onClick={() => setPinVisible(!pinVisible)} />
            ) : (
              <VisibilityOutlinedIcon onClick={() => setPinVisible(!pinVisible)} />
            )}
          </label>
          <div className="pin-input-div">
            <input
              id="field-1"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin1"
              defaultValue={newPin1}
              onChange={handlePinChange}
            />
            <input
              id="field-2"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin2"
              defaultValue={newPin2}
              onChange={handlePinChange}
            />
            <input
              id="field-3"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin3"
              defaultValue={newPin3}
              onChange={handlePinChange}
            />
            <input
              id="field-4"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin4"
              defaultValue={newPin4}
              onChange={handlePinChange}
            />
            <input
              id="field-5"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin5"
              defaultValue={newPin5}
              onChange={handlePinChange}
            />
            <input
              id="field-6"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin6"
              defaultValue={newPin6}
              onChange={handlePinChange}
            />
            <input
              id="field-7"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin7"
              defaultValue={newPin7}
              onChange={handlePinChange}
            />
            <input
              id="field-8"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="newPin8"
              defaultValue={newPin8}
              onChange={handlePinChange}
            />
          </div>
        </div>
        <div className="row flex-dir-col">
          <label className="pin-label" htmlFor="confirmPin">
            Confirm PIN
          </label>
          <div className="pin-input-div">
            <input
              id="field-9"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin1"
              defaultValue={confirmPin1}
              onChange={handlePinChange}
            />
            <input
              id="field-10"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin2"
              defaultValue={confirmPin2}
              onChange={handlePinChange}
            />
            <input
              id="field-11"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin3"
              defaultValue={confirmPin3}
              onChange={handlePinChange}
            />
            <input
              id="field-12"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin4"
              defaultValue={confirmPin4}
              onChange={handlePinChange}
            />
            <input
              id="field-13"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin5"
              defaultValue={confirmPin5}
              onChange={handlePinChange}
            />
            <input
              id="field-14"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin6"
              defaultValue={confirmPin6}
              onChange={handlePinChange}
            />
            <input
              id="field-15"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin7"
              defaultValue={confirmPin7}
              onChange={handlePinChange}
            />
            <input
              id="field-16"
              className="pin-input"
              maxLength={1}
              type={pinVisible ? 'text' : 'password'}
              name="confirmPin8"
              defaultValue={confirmPin8}
              onChange={handlePinChange}
            />
          </div>
        </div>
        <div className="row justify-center form-btn-div">
          <button id="resetPwBtn" type="submit">
            <span>Reset Password</span>
          </button>
        </div>
      </form>
      {message ? (
        <div className="row message-div">
          <p className="message">{message}</p>
        </div>
      ) : null}
      <div id="qi-logo-div">
        <img src={qiLogo} alt="Quantum Interface Logo."></img>
      </div>
    </div>
  );
};

export default ResetPw;
