import { useEffect, useState } from 'react';

import { VxAPI } from '../../api/vx.api';
import Auth from '../../utils/auth';
import './updatePw.scss';
import { validatePin } from '../../utils/helpers';

const UpdatePw = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessagePin, setErrorMessagePin] = useState('');
  const [messagePin, setMessagePin] = useState('');
  const [formState, setFormState] = useState({
    currentPassword: '',
    newPassword: '',
    currentPin: '',
    newPin: '',
  });

  const [email, setEmail] = useState('');
  const [pinSet, setPinSet] = useState<boolean>(false);

  useEffect(() => {
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      setEmail(loggedInUser.email);
      setPinSet(loggedInUser.pinSet);
    }
  }, []);

  const { newPassword, currentPassword, currentPin, newPin } = formState;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');

    if (!e.target.value.length) {
      setErrorMessage(`Current & new password are required to submit.`);
    } else {
      setErrorMessage('');
    }

    if (!errorMessage) {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  const handleChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessagePin('');

    if (!e.target.value.length) {
      setErrorMessagePin(`Current & new pins are required to submit.`);
    } else {
      setErrorMessagePin('');
    }

    if (!errorMessage) {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (currentPassword === '' || newPassword === '') {
      setMessage('Please fill out all required fields');
      return;
    }

    if (!errorMessage) {
      const resp = await VxAPI.updatePw({
        email,
        currentPassword,
        newPassword,
      });

      if (resp.ok) {
        Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

        setMessage('Password successfully updated.');

        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setMessage('Invalid credentials provided. Please check current password & try again.');
      }
    }
  };

  const handleSubmitPin = async (e: React.MouseEvent<HTMLButtonElement>, pinSet: boolean) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (pinSet) {
      if (currentPin === '' || newPin === '') {
        setMessagePin('Please fill out all required fields');
        return;
      }

      const validPin = validatePin(newPin);
      if (typeof validPin === 'string') {
        setMessagePin(validPin);
        return;
      }

      if (!errorMessage) {
        const resp = await VxAPI.updatePin({
          email,
          currentPin,
          newPin,
        });

        if (resp.ok) {
          Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

          setMessagePin('Pin successfully updated.');

          setTimeout(() => {
            setMessagePin('');
          }, 3000);
        } else {
          setMessagePin('Invalid credentials provided. Please check current pin & try again.');
        }
      }
    } else {
      if (newPin === '') {
        setMessagePin('Please fill out new pin.');
        return;
      }

      const validPin = validatePin(newPin);
      if (typeof validPin === 'string') {
        setMessagePin(validPin);
        return;
      }

      if (!errorMessage) {
        const resp = await VxAPI.setPin({
          email,
          newPin,
        });

        if (resp.token) {
          Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));

          setMessagePin('Pin successfully set.');
          Auth.updateToken(resp.token);
          setTimeout(() => {
            window.location.assign('/');
          }, 2000);
        } else {
          setMessagePin('Pin was not set. Please try again later.');
        }
      }
    }
  };

  return (
    <div className="update-div">
      <div id="update-pw">
        <h2>Update Your Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="row flex-dir-col">
            <label className="update-pw-label" htmlFor="currentPassword">
              Current Password:{' '}
            </label>
            <input
              className="update-pw-input"
              type="password"
              name="currentPassword"
              defaultValue={currentPassword}
              onChange={handleChange}
            />
          </div>
          <div className="row flex-dir-col">
            <label className="update-pw-label" htmlFor="newPassword">
              New Password:{' '}
            </label>
            <input
              className="update-pw-input"
              type="password"
              name="newPassword"
              defaultValue={newPassword}
              onChange={handleChange}
            />
          </div>
          {errorMessage ? <h3 id="error-message">{errorMessage}</h3> : null}
          {message ? <h3 id="message">{message}</h3> : null}
          <div id="update-pw-btn-div" className="row justify-center">
            <button type="submit">
              <span>Confirm</span>
            </button>
          </div>
        </form>
      </div>
      <>
        {pinSet ? (
          <>
            <div id="update-pw">
              <h2>Update Your Pin</h2>
              <form>
                <div className="row flex-dir-col">
                  <label className="update-pw-label" htmlFor="currentPin">
                    Current Pin:{' '}
                  </label>
                  <input
                    className="update-pw-input"
                    type="password"
                    name="currentPin"
                    defaultValue={currentPin}
                    onChange={handleChangePin}
                  />
                </div>
                <div className="row flex-dir-col">
                  <label className="update-pw-label" htmlFor="newPin">
                    New Pin:{' '}
                  </label>
                  <input
                    className="update-pw-input"
                    type="password"
                    name="newPin"
                    defaultValue={newPin}
                    onChange={handleChangePin}
                  />
                </div>
                {errorMessagePin ? <h3 id="error-message">{errorMessagePin}</h3> : null}
                {messagePin ? <h3 id="message">{messagePin}</h3> : null}
                <div id="update-pw-btn-div" className="row justify-center">
                  <button onClick={(e) => handleSubmitPin(e, pinSet)}>
                    <span>Confirm</span>
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            <div id="update-pw">
              <h2>Set Your Pin</h2>
              <form>
                <div className="row flex-dir-col">
                  <label className="update-pw-label" htmlFor="newPin">
                    New Pin:{' '}
                  </label>
                  <input
                    className="update-pw-input"
                    type="password"
                    name="newPin"
                    defaultValue={newPin}
                    onChange={handleChangePin}
                  />
                </div>
                {errorMessagePin ? <h3 id="error-message">{errorMessagePin}</h3> : null}
                {messagePin ? <h3 id="message">{messagePin}</h3> : null}
                <div id="update-pw-btn-div" className="row justify-center">
                  <button onClick={(e) => handleSubmitPin(e, pinSet)}>
                    <span>Confirm</span>
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default UpdatePw;
