import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';

import './tags.scss';
import { VxAPI } from '../../api/vx.api';
import { TagObjectDTO } from '../../components/dto/tagObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import Auth from '../../utils/auth';
import Bounds from '../../assets/script/QiNodeEngine/API/Bounds';
import { ViewType } from '../../assets/script/QiNodeEngine/API/Enums';
import InverseDistanceBasicConfidence from '../../assets/script/QiNodeEngine/API/InverseDistanceBasicConfidence';
import QINode from '../../assets/script/QiNodeEngine/API/QINode';
import QINodeblueprint from '../../assets/script/QiNodeEngine/API/QINodeBlueprint';
import Quaternion from '../../assets/script/QiNodeEngine/API/Utilities/Quaternion';
import Vector2 from '../../assets/script/QiNodeEngine/API/Utilities/Vector2';
import Vector3 from '../../assets/script/QiNodeEngine/API/Utilities/Vector3';
import WebNode from '../../assets/script/QiNodeEngine/API/WebNode';
import WebQINodeManager from '../../assets/script/QiNodeEngine/API/WebQINodeManager';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { UserDataDTO } from '../../components/dto/userData.dto';
import { AddTagDTO } from '../../api/dto/addTag.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';

declare global {
  var tagQPointManager: WebQINodeManager;
}

global.tagQPointManager = new WebQINodeManager();

const Tags = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const filterTypes = ['Users', 'Courses', 'Modules', 'Lessons', 'Classes'];

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  const [roleName, setRoleName] = useState<string>('');
  const [qiEmployee, setQiEmployee] = useState<boolean | null>(null);
  const [mouseOverCount, setMouseOverCount] = useState<EventTarget[]>([]);

  if (qiEmployee === false && roleName !== 'Admin') {
    window.location.assign('/');
  }

  const [tagsToAddTier1Object, setTagsToAddTier1Object] = useState<any[]>([]);
  const [tagsToAddTier2Object, setTagsToAddTier2Object] = useState<any[]>([]);
  const [tagsToAddTier3Object, setTagsToAddTier3Object] = useState<any[]>([]);
  const [selectedTag, setSelectedTag] = useState();
  const [selectedTagTree, setSelectedTagTree] = useState<string[]>([]);
  const [selectedTagId, setSelectedTagId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>([
    {
      customerId: 0,
      tagId: 0,
      title: '',
      type: '',
      sub1: '',
      sub2: '',
      description: '',
      createdDate: new Date(),
    },
  ]);
  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>([
    {
      useCaseId: 0,
      customerId: 0,
      courseId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      tags: [0],
    },
  ]);
  const [moduleObjectArrayData, setModuleObjectArrayData] = useState<ModuleObjectDTO[]>([
    {
      moduleId: 0,
      courseId: 0,
      title: '',
      version: 0,
      description: '',
      notes: '',
      tags: [0],
    },
  ]);
  const [lessonObjectArrayData, setLessonObjectArrayData] = useState<LessonObjectDTO[]>([
    {
      moduleId: 0,
      lessonId: 0,
      title: '',
      author: 0,
      principals: [0],
      description: '',
      notes: '',
      tags: [0],
    },
  ]);
  const [classObjectArrayData, setClassObjectArrayData] = useState<ClassObjectDTO[]>([
    {
      customerId: 0,
      useCaseId: 0,
      classId: 0,
      title: '',
      creator: 0,
      description: '',
      notes: '',
      courses: [0],
      principals: [0],
      tags: [0],
    },
  ]);
  const [usersObjectArrayData, setUsersObjectArrayData] = useState<UserDataDTO[]>();
  const [updatedCoursesArray, setUpdatedCoursesArray] = useState<CourseObjectDTO[]>();
  const [updatedModulesArray, setUpdatedModulesArray] = useState<ModuleObjectDTO[]>();
  const [updatedLessonsArray, setUpdatedLessonsArray] = useState<LessonObjectDTO[]>();
  const [updatedClassesArray, setUpdatedClassesArray] = useState<ClassObjectDTO[]>();
  const [updatedUsersArray, setUpdatedUsersArray] = useState<UserDataDTO[]>();
  const [selectedItemTypeArray, setSelectedItemTypeArray] = useState<string[]>([]);
  const [tier1Message, setTier1Message] = useState('');
  const [tier2Message, setTier2Message] = useState('');
  const [tier3Message, setTier3Message] = useState('');
  const [tagsWithOnlyType, setTagsWithOnlyType] = useState<TagObjectDTO[]>([]);
  const [tagsUpToSub, setTagsUpToSub] = useState<TagObjectDTO[]>([]);
  const [tagsUpToSub2, setTagsUpToSub2] = useState<TagObjectDTO[]>([]);
  const [tierListPos, setTierListPos] = useState({
    xPos: 0,
    yPos: 0,
  });
  const [renderTags, setRenderTags] = useState(true);
  const [currentInputFieldValue, setCurrentInputFieldValue] = useState<string>('');
  const [newTier1TagDivs, setNewTier1TagDivs] = useState<number[]>([]);
  const [numOfNewTier1TagDivs, setNumOfNewTier1TagDivs] = useState<number>(2);
  const [newTier2TagDivs, setNewTier2TagDivs] = useState<number[]>([]);
  const [numOfNewTier2TagDivs, setNumOfNewTier2TagDivs] = useState<number>(2);
  const [newTier3TagDivs, setNewTier3TagDivs] = useState<number[]>([]);
  const [numOfNewTier3TagDivs, setNumOfNewTier3TagDivs] = useState<number>(2);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const userData = await VxAPI.getAllUserData();
      if (userData && isMounted) {
        setUsersObjectArrayData(userData);
        setUpdatedUsersArray(userData);
      }
      const courseData = await VxAPI.getAllCoursesData();
      if (courseData && isMounted) {
        setCourseObjectArrayData(courseData);
        setUpdatedCoursesArray(courseData);
      }
      const moduleData = await VxAPI.getAllModulesData();
      if (moduleData && isMounted) {
        setModuleObjectArrayData(moduleData);
        setUpdatedModulesArray(moduleData);
      }
      const lessonData = await VxAPI.getAllLessonsData();
      if (lessonData && isMounted) {
        setLessonObjectArrayData(lessonData);
        setUpdatedLessonsArray(lessonData);
      }
      const classData = await VxAPI.getAllClassesHierarchyData();
      if (classData && isMounted) {
        setClassObjectArrayData(classData);
        setUpdatedClassesArray(classData);
      }
      const tagData = await VxAPI.getAllTagsData();
      if (tagData && isMounted) {
        setTagObjectArrayData(tagData);
        const localStorageKey = 'qivx-tag-filter';
        const previouslySelectedFilterTypes = JSON.parse(localStorage.getItem(localStorageKey) || 'false');
        if (!previouslySelectedFilterTypes && isMounted) {
          setSelectedItemTypeArray(['Lessons']);
        } else {
          setSelectedItemTypeArray(previouslySelectedFilterTypes);
        }
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData().catch(console.error);
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedTag) {
      const foundTag = tagObjectArrayData.find((tag) => tag.title === selectedTag);
      const tagId = foundTag?.tagId;
      const foundTagTree: string[] = [];
      if (foundTag?.type) {
        foundTagTree.push(foundTag.type);
      }
      if (foundTag?.sub1) {
        foundTagTree.push(foundTag.sub1);
      }
      if (foundTag?.sub2) {
        foundTagTree.push(foundTag.sub2);
      }
      setSelectedTagTree(foundTagTree);
      setSelectedTagId(tagId as number);
    } else {
      setSelectedTagId(0);
      setSelectedTagTree([]);
    }
  }, [selectedTag]);

  useEffect(() => {
    if ((userRoleName && superUser === false) || superUser === true) {
      setRoleName(userRoleName ? userRoleName : '');
      setQiEmployee(superUser === false || superUser === true ? superUser : null);
    }

    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName, superUser]);

  useEffect(() => {
    if (selectedTagId === 0) {
      setUpdatedCoursesArray(courseObjectArrayData);
      setUpdatedUsersArray(usersObjectArrayData);
      setUpdatedModulesArray(moduleObjectArrayData);
      setUpdatedLessonsArray(lessonObjectArrayData);
      setUpdatedClassesArray(classObjectArrayData);
    } else {
      const newUpdatedCourses = courseObjectArrayData?.filter((course) => course.tags?.includes(selectedTagId));
      const newUpdatedUsers = usersObjectArrayData?.filter((user) => user.tags?.includes(selectedTagId));
      const newUpdatedModules = moduleObjectArrayData?.filter((module) => module.tags?.includes(selectedTagId));
      const newUpdatedLessons = lessonObjectArrayData?.filter((lesson) => lesson.tags?.includes(selectedTagId));
      const newUpdatedClasses = classObjectArrayData?.filter((foundClass) => foundClass.tags?.includes(selectedTagId));
      setUpdatedCoursesArray(newUpdatedCourses);
      setUpdatedUsersArray(newUpdatedUsers);
      setUpdatedModulesArray(newUpdatedModules);
      setUpdatedLessonsArray(newUpdatedLessons);
      setUpdatedClassesArray(newUpdatedClasses);
    }
  }, [selectedTagId]);

  useEffect(() => {
    if (tagObjectArrayData) {
      let filteredTagsWithOnlyType: TagObjectDTO[] = [];
      let filteredTagsUpToSub: TagObjectDTO[] = [];
      let filteredTagsUpToSub2: TagObjectDTO[] = [];
      tagObjectArrayData.forEach((tag) => {
        if (tag.type && !tag.sub1 && !tag.sub2) {
          filteredTagsWithOnlyType.push(tag);
        } else if (tag.type && tag.sub1 && !tag.sub2) {
          filteredTagsUpToSub.push(tag);
        } else {
          filteredTagsUpToSub2.push(tag);
        }
      });
      setTagsWithOnlyType(filteredTagsWithOnlyType);
      setTagsUpToSub(filteredTagsUpToSub);
      setTagsUpToSub2(filteredTagsUpToSub2);
    }
  }, [tagObjectArrayData]);

  useEffect(() => {
    if (loading === false && renderTags === true && global.tagQPointManager && tierListPos) {
      global.tagQPointManager.destroyNodes();

      tagsWithOnlyType
        .sort((a: any, b: any) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        })
        .forEach((tag) => {
          if (tag.type) {
            const tagBluePrint = new QINodeblueprint(
              new Vector2(0, 0),
              new Quaternion(0, 0, 0, 0),
              new Bounds(new Vector3(0, 0, 0), new Vector2(0, 0)),
              0.98,
              0.9,
              0.95,
              new Vector2(1, 1),
            );

            const tagConfidenceLogic = new InverseDistanceBasicConfidence(700);

            const tagNode = new QINode(tagBluePrint, tagConfidenceLogic, false);

            const tagData = {
              name: tag.type,
              type: ViewType.List,
              anchorElementId: 'qp-list-tier-1',
              height: 100,
              width: 100,
              positionXoffParent: 0,
              positionYoffParent: 0,
              hoverBackgroundColor: 'rgb(82,80,171)',
              textColor: 'white',
              fontSize: 20,
              listTitle: 'Tier 1',
              debug: false,
              onClick: onClickQp,
            };

            const tagQP = new WebNode(
              tagNode,
              tagData,
              setSelectedTag,
              setSelectedTag,
              selectedTagTree?.includes(tagData?.name) ? true : false,
            );

            let counter = 0;

            tagsUpToSub
              .sort((a: any, b: any) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .forEach((child) => {
                if (child.type && child.sub1 && child.type === tag.type) {
                  {
                    const childConfidenceLogic = new InverseDistanceBasicConfidence(250);

                    const childBlueprint = new QINodeblueprint(
                      new Vector2(0, 0),
                      new Quaternion(0, 0, 0, 0),
                      new Bounds(new Vector3(0, 0, 0), new Vector2(0, 0)),
                      0.9,
                      0.85,
                      0.8,
                      new Vector2(1, 1),
                    );

                    const childNode = new QINode(
                      childBlueprint,
                      childConfidenceLogic,
                      selectedTagTree.includes(child.type) ? false : true,
                    );

                    const childData = {
                      name: child.sub1,
                      type: ViewType.List,
                      anchorElementId: 'qp-list-tier-2',
                      anchorElParentId: 'dynamic-list-tier-2',
                      height: 100,
                      width: 100,
                      dynamicListPosX: tierListPos.xPos,
                      dynamicListPosY: tierListPos.yPos,
                      dynamicListOffsetX: 90,

                      positionXoffParent: 50,
                      positionYoffParent: 0 + counter,
                      hoverBackgroundColor: 'rgb(250,114,40)',
                      textColor: 'white',
                      fontSize: 20,
                      disablePreview: true,
                      listTitle: 'Tier 2',
                      debug: false,
                      onClick: onClickQp,
                    };

                    const childQP = new WebNode(
                      childNode,
                      childData,
                      setSelectedTag,
                      setSelectedTag,
                      selectedTagTree?.includes(childData?.name) ? true : false,
                    );

                    let grandChildCounter = 0;

                    tagsUpToSub2
                      .sort((a: any, b: any) => {
                        if (a.title < b.title) {
                          return -1;
                        }
                        if (a.title > b.title) {
                          return 1;
                        }
                        return 0;
                      })
                      .forEach((grandChild) => {
                        if (
                          grandChild.type &&
                          grandChild.sub1 &&
                          grandChild.sub2 &&
                          grandChild.type === tag.type &&
                          grandChild.sub1 === child.sub1
                        ) {
                          const grandChildConfidenceLogic = new InverseDistanceBasicConfidence(125);

                          const grandChildBlueprint = new QINodeblueprint(
                            new Vector2(0, 0),
                            new Quaternion(0, 0, 0, 0),
                            new Bounds(new Vector3(0, 0, 0), new Vector2(0, 0)),
                            0.75,
                            0.65,
                            0.6,
                            new Vector2(1, 1),
                          );

                          const grandChildData = {
                            name: grandChild.sub2,
                            type: ViewType.List,
                            anchorElementId: 'qp-list-tier-3',
                            anchorElParentId: 'dynamic-list-tier-3',
                            height: 100,
                            width: 100,
                            dynamicListOffsetX: 90,
                            dynamicListOffsetY: -280,
                            positionXoffParent: 50,
                            positionYoffParent: 0 + grandChildCounter,
                            hoverBackgroundColor: 'rgb(82,80,171)',
                            textColor: 'white',
                            fontSize: 20,
                            disablePreview: true,
                            listTitle: 'Tier 3',
                            debug: false,
                            onClick: onClickQp,
                          };

                          const grandChildNode = new QINode(
                            grandChildBlueprint,
                            grandChildConfidenceLogic,
                            selectedTagTree.includes(grandChild.sub1) ? false : true,
                          );

                          const grandChildQP = new WebNode(
                            grandChildNode,
                            grandChildData,
                            setSelectedTag,
                            setSelectedTag,
                            selectedTagTree?.includes(grandChildData?.name) ? true : false,
                          );
                          // console.log('grandChildQP: ', grandChildQP.viewData.name);
                          childQP.qiNode.children.add(grandChildQP);

                          grandChildCounter += 100;
                        }
                      });
                    // console.log('childQP: ', childQP.viewData.name);
                    tagQP.qiNode.children.add(childQP);

                    counter += 100;
                  }
                }
              });

            // console.log('tagQP: ', tagQP)
            tagQP.createWebNode(tagQP);

            global.tagQPointManager.register(tagQP);
            // console.log('qp manager: ', global.tagQPointManager);
          }
        });
      setRenderTags(false);
      // console.log(global.tagQPointManager)
      // setTagObjectWithOnlyType(tagWithOnlyType);
    }
  }, [loading, tagsWithOnlyType, tagsUpToSub, tagsUpToSub2]);

  const handleCheck = (ev: Event) => {
    const e = ev as unknown as React.ChangeEvent<HTMLInputElement>;
    if (e.target.checked) {
      selectedItemTypeArray.push(e.target.value);
      setSelectedItemTypeArray([...selectedItemTypeArray]);
    } else {
      const targetIndex = selectedItemTypeArray.indexOf(e.target.value);
      selectedItemTypeArray.splice(targetIndex, 1);
      setSelectedItemTypeArray([...selectedItemTypeArray]);
    }

    // add selected filter types to local storage
    localStorage.setItem('qivx-tag-filter', JSON.stringify(selectedItemTypeArray));
  };

  const handleModal = () => {
    if (openModal) {
      const modalEl = document.createElement('div');
      modalEl.id = 'filter-modal';
      modalEl.style.position = 'absolute';
      modalEl.style.zIndex = '10';
      modalEl.style.marginTop = '1rem';
      modalEl.style.right = '0.25rem';
      modalEl.style.backgroundColor = '#2A2C2D';
      modalEl.style.padding = '1rem';
      modalEl.style.borderRadius = '1.5rem';
      modalEl.style.width = '17rem';
      modalEl.addEventListener('mouseout', (e) => {
        const event = e as unknown as React.ChangeEvent<HTMLElement>;
        if (event.target && event.target.id === 'filter-modal') {
          mouseOverCount.push(event.target);
          setMouseOverCount([...mouseOverCount]);
        }
      });
      const filterDivEl = document.getElementById('filter');
      if (filterDivEl) {
        filterDivEl.appendChild(modalEl);
        filterTypes.forEach((filterType) => {
          const filterTypeDivEl = document.createElement('div');
          filterTypeDivEl.className = 'filter-type-div';
          const filterTypeLabelEl = document.createElement('label');
          filterTypeLabelEl.id = `filter-type-${filterType}`;
          filterTypeLabelEl.innerHTML = filterType;
          filterTypeLabelEl.className = 'checkbox-container';
          const filterTypeCheckboxEl = document.createElement('input');
          filterTypeCheckboxEl.type = 'checkbox';
          filterTypeCheckboxEl.id = `filter-checkbox-${filterType}`;
          filterTypeCheckboxEl.value = filterType;
          filterTypeCheckboxEl.name = filterType;
          filterTypeCheckboxEl.className = 'checkbox-input';
          const customizedCheckboxEl = document.createElement('span');
          customizedCheckboxEl.className = 'checkmark';
          if (selectedItemTypeArray.includes(filterType)) {
            filterTypeCheckboxEl.defaultChecked = true;
          }
          filterTypeCheckboxEl.onchange = handleCheck;
          filterTypeLabelEl.appendChild(filterTypeCheckboxEl);
          filterTypeLabelEl.appendChild(customizedCheckboxEl);
          filterTypeDivEl.appendChild(filterTypeLabelEl);
          modalEl.appendChild(filterTypeDivEl);
        });
      }
      modalEl.focus();
    }

    if (!openModal) {
      closeModal();
    }

    setOpenModal(!openModal);
  };

  const closeModal = () => {
    const modalEl = document.getElementById('filter-modal');
    const filterDivEl = document.getElementById('filter');
    if (modalEl && filterDivEl) {
      filterDivEl.removeChild(modalEl);
    }
  };

  const onClickQp = () => {};

  useEffect(() => {
    if (mouseOverCount?.length > 1) {
      closeModal();
      setMouseOverCount([]);
      setOpenModal(!openModal);
    }
  }, [mouseOverCount]);

  const saveTags = async (e: React.MouseEvent<HTMLOrSVGElement>) => {
    const targetEl = e.target as SVGElement;
    let targetParentEl = null;
    if (targetEl.nodeName === 'path') {
      targetParentEl = targetEl.parentElement?.parentElement?.parentElement;
    } else {
      targetParentEl = targetEl.parentElement?.parentElement;
    }
    const parentElId = targetParentEl?.id;
    const parentElIdArray = parentElId?.split('-');
    const currentTime = Date.now(); // get date as integer
    const currentDate = new Date(currentTime); // conver integer into date with timezone
    const createdDate = currentDate;
    const tagsTier1: TagObjectDTO[] = [];
    const tagsTier2: TagObjectDTO[] = [];
    const tagsTier3: TagObjectDTO[] = [];
    let tagObject = null;
    if (parentElIdArray) {
      const tierNum = parentElIdArray[3];
      switch (tierNum) {
        case '1':
          setTier1Message('');
          if (tagsToAddTier1Object.length === 0) {
            setTier1Message('Please type the name of a tag you would like to add.');
            return;
          }
          // check if any duplicate tags
          let duplicateTagsTier1: string[] = [];
          tagsToAddTier1Object.forEach((tag) => {
            const count = tagsToAddTier1Object.reduce(
              (acc, curr) => (curr.tag.toLowerCase().trim() === tag.tag.toLowerCase().trim() ? acc + 1 : acc),
              0,
            );
            if (count > 1) {
              duplicateTagsTier1.push(tag.tag);
            }
            const foundTier1Tag = tagsWithOnlyType.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier1Tag) {
              duplicateTagsTier1.push(tag.tag);
            }
            const foundTier2Tag = tagsUpToSub.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier2Tag) {
              duplicateTagsTier1.push(tag.tag);
            }
            const foundTier3Tag = tagsUpToSub2.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier3Tag) {
              duplicateTagsTier1.push(tag.tag);
            }
          });
          if (duplicateTagsTier1.length > 0) {
            setTier1Message(`${duplicateTagsTier1?.join(', ')} already exist(s).`);
            duplicateTagsTier1 = [];
            return;
          }
          tagsToAddTier1Object.forEach((tag) => {
            // return in tag field is empty
            if (tag.tag.trim() === '') {
              return;
            }
            tagObject = {
              customerId: userData?.customerId,
              createdDate: createdDate,
              title: tag.tag,
              type: tag.tag,
              sub1: null,
              sub2: null,
            };
            tagsTier1.push(tagObject);
            // reset input fields after submit
            const inputsTier1 = document.querySelectorAll(
              `input[placeholder='Tier ${tierNum} Tag Name']`,
            ) as NodeListOf<HTMLInputElement>;
            Array.from(inputsTier1).forEach((input) => (input.value = ''));
            // remove all new tag divs added by dom
            const newTagDivsTier1 = document.querySelectorAll(`.new-tag-div-tier-${tierNum}`);
            Array.from(newTagDivsTier1).forEach((div) => div.remove());
          });
          // remove tag from tags to add array
          setTagsToAddTier1Object([]);
          setTier1Message('Adding...');
          const respTier1 = await VxAPI.addMultipleTags({ bulk: tagsTier1 as AddTagDTO[] });
          if (respTier1[0].tagId) {
            setTier1Message('Tags Added!');
            setTimeout(() => {
              setTier1Message('');
            }, 2000);
            respTier1.forEach((tag: TagObjectDTO) => {
              tagsWithOnlyType.push(tag);
              setTagsWithOnlyType([...tagsWithOnlyType]);
              tagObjectArrayData.push(tag);
              setTagObjectArrayData([...tagObjectArrayData]);
            });
          } else {
            setTier1Message('An error has occurred. Please try again.');
          }
          break;
        case '2':
          if (tagsToAddTier2Object.length === 0) {
            setTier2Message('Please type the name of a tag you would like to add.');
            return;
          }
          // check if any duplicate tags
          let duplicateTagsTier2: string[] = [];
          tagsToAddTier2Object.forEach((tag) => {
            const count = tagsToAddTier2Object.reduce(
              (acc, curr) => (curr.tag.toLowerCase().trim() === tag.tag.toLowerCase().trim() ? acc + 1 : acc),
              0,
            );
            if (count > 1) {
              duplicateTagsTier2.push(tag.tag);
            }
            const foundTier1Tag = tagsWithOnlyType.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier1Tag) {
              duplicateTagsTier2.push(tag.tag);
            }
            const foundTier2Tag = tagsUpToSub.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier2Tag) {
              duplicateTagsTier2.push(tag.tag);
            }
            const foundTier3Tag = tagsUpToSub2.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier3Tag) {
              duplicateTagsTier2.push(tag.tag);
            }
          });
          if (duplicateTagsTier2.length > 0) {
            setTier2Message(`${duplicateTagsTier2?.join(', ')} already exist(s).`);
            return;
          }
          tagsToAddTier2Object.forEach((tag) => {
            // return in tag field is empty
            if (tag.tag.trim() === '') {
              return;
            }
            tagObject = {
              customerId: userData?.customerId,
              createdDate: createdDate,
              title: tag.tag,
              type: selectedTagTree[0],
              sub1: tag.tag,
              sub2: null,
            };
            tagsTier2.push(tagObject);
            // reset input fields after submit
            const inputsTier2 = document.querySelectorAll(
              `input[placeholder='Tier ${tierNum} Tag Name']`,
            ) as NodeListOf<HTMLInputElement>;
            Array.from(inputsTier2).forEach((input) => (input.value = ''));
            // remove all new tag divs added by dom
            const newTagDivsTier2 = document.querySelectorAll(`.new-tag-div-tier-${tierNum}`);
            Array.from(newTagDivsTier2).forEach((div) => div.remove());
          });
          // remove tag from tags to add array
          setTagsToAddTier2Object([]);
          setTier2Message('Adding...');
          const respTier2 = await VxAPI.addMultipleTags({ bulk: tagsTier2 as AddTagDTO[] });

          if (respTier2[0].tagId) {
            setTier2Message('Tags Added!');
            setTimeout(() => {
              setTier2Message('');
            }, 2000);
            respTier2.forEach((tag: TagObjectDTO) => {
              tagsUpToSub.push(tag);
              setTagsUpToSub([...tagsUpToSub]);
              tagObjectArrayData.push(tag);
              setTagObjectArrayData([...tagObjectArrayData]);
            });
          } else {
            setTier2Message('An error has occurred. Please try again.');
          }
          break;
        default:
          if (tagsToAddTier3Object.length === 0) {
            setTier3Message('Please type the name of a tag you would like to add.');
            return;
          }
          // check if any duplicate tags
          let duplicateTagsTier3: string[] = [];
          tagsToAddTier3Object.forEach((tag) => {
            const count = tagsToAddTier3Object.reduce(
              (acc, curr) => (curr.tag.toLowerCase().trim() === tag.tag.toLowerCase().trim() ? acc + 1 : acc),
              0,
            );
            if (count > 1) {
              duplicateTagsTier3.push(tag.tag);
            }
            const foundTier1Tag = tagsWithOnlyType.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier1Tag) {
              duplicateTagsTier3.push(tag.tag);
            }
            const foundTier2Tag = tagsUpToSub.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier2Tag) {
              duplicateTagsTier3.push(tag.tag);
            }
            const foundTier3Tag = tagsUpToSub2.find(
              (type) => type.title?.toLowerCase().trim() === tag.tag.toLowerCase().trim(),
            );
            if (foundTier3Tag) {
              duplicateTagsTier3.push(tag.tag);
            }
          });
          if (duplicateTagsTier3.length > 0) {
            setTier3Message(`${duplicateTagsTier3?.join(', ')} already exist(s).`);
            return;
          }
          tagsToAddTier3Object.forEach((tag) => {
            // return in tag field is empty
            if (tag.tag.trim() === '') {
              return;
            }
            tagObject = {
              customerId: userData?.customerId,
              createdDate: createdDate,
              title: tag.tag,
              type: selectedTagTree[0],
              sub1: selectedTagTree[1],
              sub2: tag.tag,
            };
            tagsTier3.push(tagObject);
            // reset input fields after submit
            const inputsTier3 = document.querySelectorAll(
              `input[placeholder='Tier ${tierNum} Tag Name']`,
            ) as NodeListOf<HTMLInputElement>;
            Array.from(inputsTier3).forEach((input) => (input.value = ''));
            // remove all new tag divs added by dom
            const newTagDivsTier3 = document.querySelectorAll(`.new-tag-div-tier-${tierNum}`);
            Array.from(newTagDivsTier3).forEach((div) => div.remove());
          });
          // remove tag from tags to add array
          setTagsToAddTier3Object([]);
          setTier3Message('Adding...');
          const respTier3 = await VxAPI.addMultipleTags({ bulk: tagsTier3 as AddTagDTO[] });

          if (respTier3[0].tagId) {
            setTier3Message('Tags Added!');
            setTimeout(() => {
              setTier3Message('');
            }, 2000);
            respTier3.forEach((tag: TagObjectDTO) => {
              tagsUpToSub2.push(tag);
              setTagsUpToSub2([...tagsUpToSub2]);
              tagObjectArrayData.push(tag);
              setTagObjectArrayData([...tagObjectArrayData]);
            });
          } else {
            setTier3Message('An error has occurred. Please try again.');
          }
          break;
      }
      setRenderTags(true);
    }
  };

  const addTagInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const parentEl = e.target.parentElement?.parentElement;
    const parentElId = parentEl?.id;
    const idArray = parentElId?.split('-');
    if (idArray) {
      if (e.target.value.trim().length === 0) {
        return;
      }
      if (e.target.value.trim() === currentInputFieldValue) {
        return;
      }
      if (currentInputFieldValue !== '') {
        return;
      }
      const tierNum = idArray[3];
      switch (tierNum) {
        case '1':
          setTier1Message('');
          setNewTier1TagDivs((prevNumbers) => [...prevNumbers, numOfNewTier1TagDivs]);
          setNumOfNewTier1TagDivs((previousCount) => previousCount + 1);
          break;
        case '2':
          setTier2Message('');
          setNewTier2TagDivs((prevNumbers) => [...prevNumbers, numOfNewTier2TagDivs]);
          setNumOfNewTier2TagDivs((previousCount) => previousCount + 1);
          break;
        case '3':
          setTier3Message('');
          setNewTier3TagDivs((prevNumbers) => [...prevNumbers, numOfNewTier3TagDivs]);
          setNumOfNewTier3TagDivs((previousCount) => previousCount + 1);
          break;
      }
    }
  };

  const handleTier1TagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTier1Message('');
    const filteredTier1TagsToAdd = tagsToAddTier1Object.filter((tagObject) => {
      return tagObject.tagId === e.target.name;
    });
    if (filteredTier1TagsToAdd.length > 0) {
      tagsToAddTier1Object.forEach((tagObject) => {
        if (tagObject.tagId === e.target.name) {
          tagObject.tag = e.target.value;
        }
      });
      setTagsToAddTier1Object([...tagsToAddTier1Object]);
    }
    if (filteredTier1TagsToAdd.length === 0) {
      tagsToAddTier1Object.push({ tagId: e.target.name, tag: e.target.value });
      setTagsToAddTier1Object([...tagsToAddTier1Object]);
    }
  };
  const handleTier2TagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTier2Message('');
    const filteredTier2TagsToAdd = tagsToAddTier2Object.filter((tagObject) => {
      return tagObject.tagId === e.target.name;
    });
    if (filteredTier2TagsToAdd.length > 0) {
      tagsToAddTier2Object.forEach((tagObject) => {
        if (tagObject.tagId === e.target.name) {
          tagObject.tag = e.target.value;
        }
      });
      setTagsToAddTier2Object([...tagsToAddTier2Object]);
    }
    if (filteredTier2TagsToAdd.length === 0) {
      tagsToAddTier2Object.push({ tagId: e.target.name, tag: e.target.value });
      setTagsToAddTier2Object([...tagsToAddTier2Object]);
    }
  };
  const handleTier3TagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTier3Message('');
    const filteredTier3TagsToAdd = tagsToAddTier3Object.filter((tagObject) => {
      return tagObject.tagId === e.target.name;
    });
    if (filteredTier3TagsToAdd.length > 0) {
      tagsToAddTier3Object.forEach((tagObject) => {
        if (tagObject.tagId === e.target.name) {
          tagObject.tag = e.target.value;
        }
      });
      setTagsToAddTier3Object([...tagsToAddTier3Object]);
    }
    if (filteredTier3TagsToAdd.length === 0) {
      tagsToAddTier3Object.push({ tagId: e.target.name, tag: e.target.value });
      setTagsToAddTier3Object([...tagsToAddTier3Object]);
    }
  };

  const handleRemoveTagInputField = (ev: MouseEvent, tier: number) => {
    const targetEl = ev.target as HTMLButtonElement;
    const tagEl = targetEl.previousElementSibling as HTMLInputElement;
    console.log('tag el: ', tagEl)
    const parentEl = targetEl.parentNode as HTMLElement;
    const grandParentEl = parentEl.parentNode as HTMLElement;
    const grandParentElChildren = grandParentEl.children;
    const lastGrandParentChildInputEl = grandParentElChildren[grandParentElChildren.length - 1]
      .children[0] as HTMLInputElement;
    if (tagEl.value.trim().length === 0) {
      if (tagEl === lastGrandParentChildInputEl) return;
    }
    switch (tier) {
      case 1:
        // remove tag from tags to add array
        const targetIndex1 = tagsToAddTier1Object.findIndex((tagObject: any) => {
          return tagObject.tag === tagEl.value;
        });
        tagsToAddTier1Object.splice(targetIndex1, 1);
        setTagsToAddTier1Object([...tagsToAddTier1Object]);
        parentEl?.remove();
        break;
      case 2:
        // remove tag from tags to add array
        const targetIndex2 = tagsToAddTier2Object.findIndex((tagObject: any) => {
          return tagObject.tag === tagEl.value;
        });
        tagsToAddTier2Object.splice(targetIndex2, 1);
        setTagsToAddTier2Object([...tagsToAddTier2Object]);
        parentEl?.remove();
        break;
      case 3:
        // remove tag from tags to add array
        const targetIndex3 = tagsToAddTier3Object.findIndex((tagObject: any) => {
          return tagObject.tag === tagEl.value;
        });
        tagsToAddTier3Object.splice(targetIndex3, 1);
        setTagsToAddTier3Object([...tagsToAddTier3Object]);
        parentEl?.remove();
        break;
    }
  };

  useEffect(() => {
    if (loading === false) {
      const tier1ListDivEl = document.getElementById('dynamic-list-tier-1');
      const tier1ListDivElRect = tier1ListDivEl?.getBoundingClientRect();
      if (tier1ListDivElRect) {
        setTierListPos({
          xPos: tier1ListDivElRect?.left,
          yPos: tier1ListDivElRect.top,
        });
      }
    }
  }, [loading]);
  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <>
          <div id="page-header">
            <h1>
              Tags Search
              {selectedTagTree.map((tagTree) => {
                return ` > ${tagTree}`;
              })}
            </h1>
          </div>
          <div id="tags-page">
            <div className="dynamic-lists">
              <div id="dynamic-list-tier-1">
                <h2 className="dynamic-list-header">Tier 1</h2>
                <ul id="qp-list-tier-1"></ul>
                <div id="add-tag-tier-1" className="add-tag-div">
                  {tier1Message ? <p className="message">{tier1Message}</p> : null}
                  <div className="add-tag-btn">
                    <FontAwesomeIcon icon={faSquarePlus} onClick={saveTags} />
                    <p>Add Tier 1 Tag(s)</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Tier 1 Tag Name"
                    name="tier-1-tag-1"
                    className="add-tag-input"
                    onChange={handleTier1TagChange}
                    onBlur={addTagInput}
                    onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                  ></input>
                  {newTier1TagDivs?.map((number, index) => {
                    return (
                      <>
                        <div className="new-tag-div-tier-1">
                          <input
                            type="text"
                            placeholder="Tier 1 Tag Name"
                            name={`tier-1-tag-${number}`}
                            className="add-tag-input"
                            onChange={handleTier1TagChange}
                            onBlur={addTagInput}
                            onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                          />
                          <button
                            className="remove-tag-btn"
                            onClick={(e) => handleRemoveTagInputField(e as unknown as MouseEvent, 1)}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div id="dynamic-list-tier-2" style={{ display: 'none' }}>
                <h2 className="dynamic-list-header">Tier 2</h2>
                <ul id="qp-list-tier-2"></ul>
                <div id="add-tag-tier-2" className="add-tag-div">
                  {tier2Message ? <p className="message">{tier2Message}</p> : null}
                  <div className="add-tag-btn">
                    <FontAwesomeIcon icon={faSquarePlus} onClick={saveTags} />
                    <p>Add Tier 2 Tag(s)</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Tier 2 Tag Name"
                    name="tagName"
                    className="add-tag-input"
                    onChange={handleTier2TagChange}
                    onBlur={addTagInput}
                    onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                  ></input>
                  {newTier2TagDivs?.map((number, index) => {
                    return (
                      <>
                        <div className="new-tag-div-tier-2">
                          <input
                            type="text"
                            placeholder="Tier 2 Tag Name"
                            name={`tier-2-tag-${number}`}
                            className="add-tag-input"
                            onChange={handleTier2TagChange}
                            onBlur={addTagInput}
                            onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                          />
                          <button
                            className="remove-tag-btn"
                            onClick={(e) => handleRemoveTagInputField(e as unknown as MouseEvent, 2)}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div id="dynamic-list-tier-3" style={{ display: 'none' }}>
                <h2 className="dynamic-list-header">Tier 3</h2>
                <ul id="qp-list-tier-3"></ul>
                <div id="add-tag-tier-3" className="add-tag-div">
                  {tier3Message ? <p className="message">{tier3Message}</p> : null}
                  <div className="add-tag-btn">
                    <FontAwesomeIcon icon={faSquarePlus} onClick={saveTags} />
                    <p>Add Tier 3 Tag(s)</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Tier 3 Tag Name"
                    name="tagName"
                    className="add-tag-input"
                    onChange={handleTier3TagChange}
                    onBlur={addTagInput}
                    onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                  ></input>
                  {newTier3TagDivs?.map((number, index) => {
                    return (
                      <>
                        <div className="new-tag-div-tier-3">
                          <input
                            type="text"
                            placeholder="Tier 3 Tag Name"
                            name={`tier-3-tag-${number}`}
                            className="add-tag-input"
                            onChange={handleTier3TagChange}
                            onBlur={addTagInput}
                            onFocus={(e) => setCurrentInputFieldValue(e.target.value.trim())}
                          />
                          <button
                            className="remove-tag-btn"
                            onClick={(e) => handleRemoveTagInputField(e as unknown as MouseEvent, 3)}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div id="selected-tag">
              <div id="results-header">
                <h1 id="results-count">
                  Results:{' '}
                  {selectedItemTypeArray.length === 0
                    ? (updatedCoursesArray?.length ? updatedCoursesArray.length : 0) +
                      (updatedModulesArray?.length ? updatedModulesArray.length : 0) +
                      (updatedLessonsArray?.length ? updatedLessonsArray.length : 0) +
                      (updatedClassesArray?.length ? updatedClassesArray.length : 0) +
                      (updatedUsersArray?.length ? updatedUsersArray.length : 0)
                    : (updatedCoursesArray?.length && selectedItemTypeArray.includes('Courses')
                        ? updatedCoursesArray.length
                        : 0) +
                      (updatedModulesArray?.length && selectedItemTypeArray.includes('Modules')
                        ? updatedModulesArray.length
                        : 0) +
                      (updatedLessonsArray?.length && selectedItemTypeArray.includes('Lessons')
                        ? updatedLessonsArray.length
                        : 0) +
                      (updatedClassesArray?.length && selectedItemTypeArray.includes('Classes')
                        ? updatedClassesArray.length
                        : 0) +
                      (updatedUsersArray?.length && selectedItemTypeArray.includes('Users')
                        ? updatedUsersArray.length
                        : 0)}{' '}
                  Items
                </h1>
                <div id="filter" className="filter-div">
                  <button id="filter-btn" className="filter-button" onClick={handleModal}>
                    <FontAwesomeIcon icon={faFilter} />
                  </button>
                </div>
              </div>
              <div id="results-titles">
                <div id="primary-titles">
                  <h2 id="item-type-title">Item Type</h2>
                  <h2 id="item-name-title">Name</h2>
                </div>
                <div id="secondary-titles">
                  <h2 id="item-more-title">More</h2>
                </div>
              </div>
              {selectedItemTypeArray.length === 0 ? (
                <div id="list">
                  {updatedCoursesArray?.map((course) => {
                    return (
                      <>
                        <div id="item-list">
                          <div className="primary-item">
                            <p className="item-type">Course</p>
                            <p className="item-name">{course.title}</p>
                          </div>
                          <div className="secondary-item">
                            <div className="item-more">
                              <FontAwesomeIcon
                                onClick={() => window.location.assign(`/courses/edit/${course.courseId}`)}
                                icon={faArrowUpRightFromSquare}
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                        </div>
                        <div className="item-line"></div>
                      </>
                    );
                  })}
                  {updatedUsersArray?.map((user) => {
                    return (
                      <>
                        <div id="item-list">
                          <div className="primary-item">
                            <p className="item-type">User</p>
                            <p className="item-name">
                              {user.firstName} {user.lastName}
                            </p>
                          </div>
                          <div className="secondary-item">
                            <div className="item-more">
                              <FontAwesomeIcon
                                onClick={() => window.location.assign(`/users/edit/${user.userId}`)}
                                icon={faArrowUpRightFromSquare}
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                        </div>
                        <div className="item-line"></div>
                      </>
                    );
                  })}
                  {updatedModulesArray?.map((module) => {
                    return (
                      <>
                        <div id="item-list">
                          <div className="primary-item">
                            <p className="item-type">Module</p>
                            <p className="item-name">{module.title}</p>
                          </div>
                          <div className="secondary-item">
                            <div className="item-more">
                              <FontAwesomeIcon
                                onClick={() => window.location.assign(`/modules/edit/${module.moduleId}`)}
                                icon={faArrowUpRightFromSquare}
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                        </div>
                        <div className="item-line"></div>
                      </>
                    );
                  })}
                  {updatedLessonsArray?.map((lesson) => {
                    return (
                      <>
                        <div id="item-list">
                          <div className="primary-item">
                            <p className="item-type">Lesson</p>
                            <p className="item-name">{lesson.title}</p>
                          </div>
                          <div className="secondary-item">
                            <div className="item-more">
                              <FontAwesomeIcon
                                onClick={() => window.location.assign(`/lessons/edit/${lesson.lessonId}`)}
                                icon={faArrowUpRightFromSquare}
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                        </div>
                        <div className="item-line"></div>
                      </>
                    );
                  })}
                  {updatedClassesArray?.map((foundClass) => {
                    return (
                      <>
                        <div id="item-list">
                          <div className="primary-item">
                            <p className="item-type">Class</p>
                            <p className="item-name">{foundClass.title}</p>
                          </div>
                          <div className="secondary-item">
                            <div className="item-more">
                              <FontAwesomeIcon
                                onClick={() => window.location.assign(`/classes/edit/${foundClass.classId}`)}
                                icon={faArrowUpRightFromSquare}
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                        </div>
                        <div className="item-line"></div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <div id="list">
                  {updatedCoursesArray && selectedItemTypeArray.includes('Courses')
                    ? updatedCoursesArray.map((course) => {
                        return (
                          <>
                            <div id="item-list">
                              <div className="primary-item">
                                <p className="item-type">Course</p>
                                <p className="item-name">{course.title}</p>
                              </div>
                              <div className="secondary-item">
                                <div className="item-more">
                                  <FontAwesomeIcon
                                    onClick={() => window.location.assign(`/courses/edit/${course.courseId}`)}
                                    icon={faArrowUpRightFromSquare}
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            </div>
                            <div className="item-line"></div>
                          </>
                        );
                      })
                    : null}
                  {updatedUsersArray && selectedItemTypeArray.includes('Users')
                    ? updatedUsersArray.map((user) => {
                        return (
                          <>
                            <div id="item-list">
                              <div className="primary-item">
                                <p className="item-type">User</p>
                                <p className="item-name">
                                  {user.firstName} {user.lastName}
                                </p>
                              </div>
                              <div className="secondary-item">
                                <div className="item-more">
                                  <FontAwesomeIcon
                                    onClick={() => window.location.assign(`/users/edit/${user.userId}`)}
                                    icon={faArrowUpRightFromSquare}
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            </div>
                            <div className="item-line"></div>
                          </>
                        );
                      })
                    : null}
                  {updatedModulesArray && selectedItemTypeArray.includes('Modules')
                    ? updatedModulesArray.map((module) => {
                        return (
                          <>
                            <div id="item-list">
                              <div className="primary-item">
                                <p className="item-type">Module</p>
                                <p className="item-name">{module.title}</p>
                              </div>
                              <div className="secondary-item">
                                <div className="item-more">
                                  <FontAwesomeIcon
                                    onClick={() => window.location.assign(`/modules/edit/${module.moduleId}`)}
                                    icon={faArrowUpRightFromSquare}
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            </div>
                            <div className="item-line"></div>
                          </>
                        );
                      })
                    : null}
                  {updatedLessonsArray && selectedItemTypeArray.includes('Lessons')
                    ? updatedLessonsArray.map((lesson) => {
                        return (
                          <>
                            <div id="item-list">
                              <div className="primary-item">
                                <p className="item-type">Lesson</p>
                                <p className="item-name">{lesson.title}</p>
                              </div>
                              <div className="secondary-item">
                                <div className="item-more">
                                  <FontAwesomeIcon
                                    onClick={() => window.location.assign(`/lessons/edit/${lesson.lessonId}`)}
                                    icon={faArrowUpRightFromSquare}
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            </div>
                            <div className="item-line"></div>
                          </>
                        );
                      })
                    : null}
                  {updatedClassesArray && selectedItemTypeArray.includes('Classes')
                    ? updatedClassesArray.map((foundClass) => {
                        return (
                          <>
                            <div id="item-list">
                              <div className="primary-item">
                                <p className="item-type">Class</p>
                                <p className="item-name">{foundClass.title}</p>
                              </div>
                              <div className="secondary-item">
                                <div className="item-more">
                                  <FontAwesomeIcon
                                    onClick={() => window.location.assign(`/classes/edit/${foundClass.classId}`)}
                                    icon={faArrowUpRightFromSquare}
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            </div>
                            <div className="item-line"></div>
                          </>
                        );
                      })
                    : null}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Tags;
