import { useEffect, useState } from "react";

import AddModuleForm from "../../components/addModuleForm/AddModuleForm";
import { useUserData, useUserRoleData } from "../account/Account";

const AddModules = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const userCustomerId = userData?.customerId
    const userId = userData?.userId

    const [ loggedInId, setLoggedInId] = useState<number | undefined>(0)
    
    useEffect(() => {
        if (userRoleName && userRoleName !== '') {
          if (userRoleName !== 'Admin') {
            window.location.assign('/');
          }
        }
        setLoggedInId(userId)
      }, [userRoleName]);

    return (
        <>
            <AddModuleForm 
            userCustomerId = {userCustomerId}
            loggedInId = {loggedInId}
            />
        </>
    )
}

export default AddModules;