import { QPointState, ViewType } from "./Enums";
import IViewData from "./IViewData";
import Vector2 from "./Utilities/Vector2";
import WebNode from "./WebNode";


export default class View {
    viewData: IViewData;
    qpoint: WebNode;
    src: string | null | undefined;
    qPointEl: HTMLImageElement | HTMLLinkElement | HTMLButtonElement | HTMLLIElement | null;
    labelEl: HTMLLabelElement | null;
    updatedPositionOnce: boolean;
    viewOverflow: boolean;
    anchorEl: HTMLElement | null;
    selectDebugLineEl: HTMLElement | null;
    deselectDebugLineEl: HTMLElement | null;
    animateDebugLineEl: HTMLElement | null;
    constructor (
        viewData: IViewData,
        webNode: WebNode
    )
    {
        this.viewData = viewData;
        this.qpoint = webNode;
        this.src = null;
        this.qPointEl = null;
        this.labelEl = null;
        this.updatedPositionOnce = false;
        this.anchorEl = null;
        this.selectDebugLineEl = null;
        this.deselectDebugLineEl = null;
        this.animateDebugLineEl = null;
        this.viewOverflow = false;

        if(viewData.type === ViewType.Image) {
            this.src = viewData.imgSrcUnselected
            this.qPointEl = document.createElement("img");
            this.qPointEl.id = viewData.name;
        if(viewData.height) {
            this.qPointEl.style.height = `${viewData.height}px`;
        }
        if(viewData.width) {
            this.qPointEl.style.width = `${viewData.width}px`;
        }
       
        this.qPointEl.src = this.src as string;

        // MJG - initialize confidence label on img creation
        this.labelEl = document.createElement('label');
        this.labelEl.textContent = this.qpoint.qiNode.currentConfidence.toString();
        this.labelEl.id = `${this.viewData.name}-confidence`
        this.labelEl.style.fontSize = '1.5rem'
        this.labelEl.style.color = 'white'

        if(this.qpoint.qiNode.isHidden) {
            this.qPointEl.style.display = 'none';
            this.labelEl.style.display = 'none';
        }

        this.anchorEl = webNode.anchorElement;

        this.anchorEl?.append(this.qPointEl);
        this.anchorEl?.append(this.labelEl)
        }

        if(viewData.type === ViewType.List) {
            this.qPointEl = document.createElement("li");
            this.qPointEl.id = viewData.name;
            this.qPointEl.textContent = viewData.name;
            if(this.qpoint.qiNode.children.size > 0) {
                const chevronEl = document.createElement('span');
                chevronEl.textContent = '›';
                this.qPointEl.appendChild(chevronEl);
            }
            if(this.qpoint.wasClicked) {
                const lockEl = document.createElement('i');
                lockEl.className = 'fa-solid fa-lock';
                lockEl.id = `${this.qpoint.viewData.name}-lock`;
                this.qPointEl?.appendChild(lockEl);
            }
            this.qPointEl.style.zIndex = '10';
            if(viewData.height) {
                this.qPointEl.style.height = `${viewData.height}px`;
            }
            if(viewData.width) {
                this.qPointEl.style.width = `${viewData.width}px`;
            }
            if(viewData.textColor) {
                this.qPointEl.style.color = this.qpoint.wasClicked ? viewData.hoverBackgroundColor as string : viewData.textColor; 
            }
            if(viewData.fontSize) {
                this.qPointEl.style.fontSize = `${viewData.fontSize}px`; 
            }
            if(viewData.marginTop) {
                this.qPointEl.style.marginTop = `${viewData.marginTop}px`
            }
            if(viewData.marginBottom) {
                this.qPointEl.style.marginBottom = `${viewData.marginBottom}px`
            }
            if(viewData.marginLeft) {
                this.qPointEl.style.marginLeft = `${viewData.marginLeft}px`
            }
            if(viewData.marginRight) {
                this.qPointEl.style.marginRight = `${viewData.marginRight}px`
            }
            if(this.qpoint.qiNode.isHidden) {
                this.qPointEl.style.display = 'none';
            } else {
                this.qPointEl.style.display = 'flex';
                this.qPointEl.style.flexDirection = 'row';
                this.qPointEl.style.alignItems = 'center';
                this.qPointEl.style.justifyContent = 'center';
            }
            if(this.qpoint.viewData.debug) {
                // create debug lines
                // const selectDebugEl = document.createElement('div')
                // selectDebugEl.id = `${viewData.name}-select-threshold`;
                // selectDebugEl.style.position = 'absolute';
                // selectDebugEl.style.height = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.selectionConfidence) + (this.qpoint.viewData.height ? this.qpoint.viewData.height * (this.qpoint.qiNode.blueprint.selectionConfidence): 0)}px`;
                // selectDebugEl.style.width = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.selectionConfidence) + (this.qpoint.viewData.width ? this.qpoint.viewData.width  * (this.qpoint.qiNode.blueprint.selectionConfidence): 0)}px`;
                // selectDebugEl.style.border = 'solid green 1px';
                // selectDebugEl.style.borderRadius = '50%';
                // this.selectDebugLineEl = selectDebugEl;

                // const deselectDebugEl = document.createElement('div')
                // deselectDebugEl.id = `${viewData.name}-deselect-threshold`;
                // deselectDebugEl.style.position = 'absolute';
                // deselectDebugEl.style.height = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.deselectionConfidence) + (this.qpoint.viewData.height ? this.qpoint.viewData.height : 0)}px`;
                // deselectDebugEl.style.width = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.deselectionConfidence) + (this.qpoint.viewData.width ? this.qpoint.viewData.width : 0)}px`;
                // deselectDebugEl.style.border = 'solid red 1px';
                // deselectDebugEl.style.borderRadius = '50%';
                // this.deselectDebugLineEl = deselectDebugEl;

                const animateDebugEl = document.createElement('div')
                animateDebugEl.id = `${viewData.name}-animate-threshold`;
                animateDebugEl.style.position = 'absolute';
                animateDebugEl.style.height = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.animationConfidence) + (this.qpoint.viewData.height ? this.qpoint.viewData.height : 0)}px`;
                animateDebugEl.style.width = `${(this.qpoint.confidenceModule ? this.qpoint.confidenceModule.maxDistance : 0) * (1 - this.qpoint.qiNode.blueprint.animationConfidence) + (this.qpoint.viewData.width ? this.qpoint.viewData.width : 0)}px`;
                animateDebugEl.style.border = 'solid yellow 1px';
                animateDebugEl.style.borderRadius = '50%';
                this.animateDebugLineEl = animateDebugEl;

                // append to anchor el
                if(this.qpoint.isRoot) {
                    // webNode.anchorElement?.appendChild(selectDebugEl);
                    // webNode.anchorElement?.appendChild(deselectDebugEl);
                    webNode.anchorElement?.appendChild(animateDebugEl);
                } else {
                    // this.qPointEl?.appendChild(selectDebugEl);
                    // this.qPointEl?.appendChild(deselectDebugEl);
                    this.qPointEl?.appendChild(animateDebugEl);
                }
                
            }
            if(viewData.onClick) {
                const setWasClicked = (qp: WebNode) => {
                    if(qp.qiNode.state === QPointState.Selected) {
                        if(!qp.wasClicked === true) {
                            const lockEl = document.createElement('i');
                            lockEl.className = 'fa-solid fa-lock';
                            lockEl.id = `${qp.viewData.name}-lock`;
                            this.qPointEl?.appendChild(lockEl);
                        }
                        if(!qp.wasClicked === false) {
                            const lockEl = document.getElementById(`${qp.viewData.name}-lock`);
    
                            if(lockEl) {
                                lockEl.remove();
                             
                                if(qp.selectCallBack) {
                                    qp.selectCallBack(qp.viewData.name)
                                }
                                if(qp.qiNode.children.size > 0) {
                                    qp.qiNode.children.forEach(child => {
                                        const foundChild = child as WebNode;
                                        
                                        if(foundChild.wasClicked === true) {
                                           
                                            setWasClicked(foundChild);
                                        }
                                    })
                                }
                            }
                        }
                    } else {
                        const lockEl = document.getElementById(`${qp.viewData.name}-lock`);
                      
                        if(lockEl) {
                            lockEl.remove();
                          
                            if(qp.view?.qPointEl) {
                                qp.view.qPointEl.style.color = qp.viewData.textColor as string;
                            }
                            if(qp.qiNode.children.size > 0) {
                                qp.qiNode.children.forEach(child => {
                                    const foundChild = child as WebNode;
                                   
                                    if(foundChild.wasClicked === true) {
                                        setWasClicked(foundChild);
                                    }
                                })
                            }
                        }
                    }
                    qp.wasClicked = !qp.wasClicked;
                }
                // this is the onclick callback so we can update react state
                this.qPointEl.addEventListener('click', viewData.onClick)
                // this is the onclick callback to update qp state in node manager
                this.qPointEl.addEventListener('click', () => {
                    setWasClicked(this.qpoint);
                })
                
            }
            this.anchorEl = webNode.anchorElement;
            // add event listener to dynamic list ul element  (anchor);
            this.anchorEl?.addEventListener('scroll', (e) => {
                e.preventDefault();
                if(this.qpoint.qiNode.isHidden === false && this.qPointEl) {
                    let qpointRect = this.qPointEl.getBoundingClientRect();
                        this.qpoint.qiNode.blueprint.position = new Vector2((qpointRect.left + qpointRect.right) / 2.0 ,(qpointRect.top + qpointRect.bottom) / 2.0);
                } 
            })
            if(!this.qpoint.parent) {
                if(this.anchorEl) {
                    this.anchorEl.style.listStyle = 'none';
                }
              this.anchorEl?.append(this.qPointEl);
              return
            } 
            this.anchorEl?.append(this.qPointEl);
            if(!this.qpoint.qiNode.isHidden) {
                let childRect = this.qPointEl.getBoundingClientRect();
                const centerOfQpX = (childRect.left + childRect.right) / 2.0;
                const centerOfQpY = (childRect.top + childRect.bottom) / 2.0;
                const centerPos = new Vector2(centerOfQpX, centerOfQpY);
                // to update the position of qpoint for confidence calc when qpoint is not hidden upon creation
                this.qpoint.qiNode.blueprint.position = centerPos;
            }
        }


    }

    setHidden(b: any) {
        if (!this.qPointEl) return; // if destroyed.

        if (b) {
            this.qPointEl.style.display = "none";
            if(this.labelEl) {
                this.labelEl.style.display = "none";
            } 
        } else {
            this.qPointEl.style.display = "";
            if(this.labelEl) {
                this.labelEl.style.display = "";
            }
        }
    }

    checkOverflow() {
        if(this.anchorEl) {
            if(this.qPointEl) {
                const anchorBoundingRects = this.anchorEl.getBoundingClientRect();
                const qpRect = this.qPointEl.getBoundingClientRect();
                const qpMidPoint = (qpRect.top + qpRect.bottom)/2;
                if(qpMidPoint > anchorBoundingRects.bottom || qpMidPoint < anchorBoundingRects.top) {
                    this.viewOverflow = true;
                } else {
                    this.viewOverflow = false;
                }
            }
        }
    }

    update(confidence: number) {
        if(this.qPointEl) {
            let scale = 1 + (confidence / 2) // TODO: add way to allow user decide scale;
            this.qPointEl.style.transform = `scale(${scale}) rotate(0deg)`
        }
    //     if (!this.qPointEl) return; // if destroyed.

    //     // Execute transition if we have one.
    //     if (this.transition) {
    //         if (this.transition.isDone) {
    //             this.transition = null;
    //         } else {
    //             this.transition.update();
    //         }

    //     // Otherwise, update the view regularly.
    //     } else {
    //         if (this.qpoint.qiNode.state == QPointState.Previewed) {
    //             let previewScale = 0.3;

    //             // Don't update position if has anchor element and has already updated once.
    //             //
    //             // This won't work if the anchor element moves across the page or something,
    //             // or in cases where the user resizes the page or something. The resize case
    //             // is currently handled by invalidating the position on window resize
    //             // externally from this class.
    //             //
    //             // This is currently needed because if the user scrolls, our
    //             // root node image lags behind the menu bg bubble because of the way
    //             // javascript updates. This is not a good long term solution and will
    //             // likely need to be reworked in the future when problems arise.
    //             if (this.qpoint.anchorElement && !this.updatedPositionOnce && this.transform) {
    //                 this.transform.position = this.getPreviewAdjustedRestPosition(previewScale);
    //             }
    //             if(this.transform) {
    //                 this.transform.scale = previewScale;
    //             }
    //         } else {

    //             // Don't update position if has anchor element and has already updated once.
    //             // Same reason as before.
    //             if (this.anchorEl && !this.updatedPositionOnce) {
    //                 if(this.transform) {
    //                     this.transform.position = this.qpoint.qiNode.blueprint.position;
    //                 }
    //             }

    //             if(this.transform) {
    //                 this.transform.scale = 0.8 + this.qpoint.qiNode.currentConfidence * 0.4;
    //             }
    //         }
    //     }

    //     this.updatedPositionOnce = true;

    //     // Finally, apply the transform changes if any.
    //     if(this.transform) {
    //         if (this.transform.isDirty()) {
    //             this.transform.applyTo(this.qPointEl);
    //             if(this.labelEl) {
    //                 this.transform.applyTo(this.labelEl);
    //             }
    //             this.transform.clearDirty();
    //         }
    //     }
    // }

    // Get the rest position adjusted for preview mode.
    // // TODO: move this somewhere more appropriate.
    // getPreviewAdjustedRestPosition(previewScale: number) {
    //     // MJG - changed data type to set to get '.add' method
    //     if(this.qpoint.parent) {
    //         let startPosition = this.qpoint.parent.restPositionAbsolute
    //     // MJG - tried to change position to a String to get add 'sub' method but received a message saying that was depricated
    //     let disp = startPosition
    //         .sub(this.qpoint.restPositionAbsolute);
    //     let dispLen = disp.magnitude();
    //     // TODO: this is not fully solved, how can the previewed and
    //     // scaled child nodes be correctly placed along the edge of
    //     // the parent? This changes depending on the graphic used..
    //     let scale = previewScale + this.qpoint.qiNode.currentConfidence * 0.1;
    //     let adj = dispLen * (scale / (scale + 1));
    //     let dispAdj = disp.normalized().scale(adj);

    //     return this.qpoint.restPosition.add(dispAdj);
    //     }
    }

    destroy() {
        // MJG Updated - on destroy, removing div el which has the children of img & label
        // this.qpointDivElt.remove();
        // this.qpointDivElt = null;
        if(this.qPointEl) {
            this.qPointEl.remove();
        }
        this.qPointEl = null;
    }
}