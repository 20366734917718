import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { VxAPI } from "../../api/vx.api";
import Auth from '../../utils/auth';
import { CustomerObjectDTO } from "../dto/customerObject.dto";
import { LocationObjectDTO } from "../dto/locationObject.dto";
import './editCustomerForm.scss'

const EditCustomerForm = () => {
    const params = useParams();
    const paramsCustomerId = Number(params.customerId);

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [customerInfo, setCustomerInfo] = useState<CustomerObjectDTO>({
        lastName: '',
        firstName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        location: ''
    })
    const [locationArray, setLocationArray] = useState<LocationObjectDTO[]>([{
        locationId: 0,
        title: '',
    }])

    const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMessage('');

        setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage('');
        setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newToken = await Auth.refreshToken();

        const {
            lastName,
            firstName,
            address,
            address2,
            city,
            state,
            zip,
            phone,
            email,
            location
        } = customerInfo

        if (
            firstName === '' ||
            address === '' ||
            city === '' ||
            email === '' ||
            state === '' ||
            phone === '' ||
            zip === '' ||
            location === '' || location === undefined || location === 'select'
        ) {
            setMessage('Make sure all required fields are completed.')
            return
        }

        const resp = await VxAPI.editCustomer({
            lastName,
            firstName,
            address,
            address2,
            city,
            state,
            zip,
            phone,
            email,
            location
        }, paramsCustomerId)

        if (resp.customerId) {
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );

            window.location.assign('/customers')

        } else {
            setMessage('An error has occured. Please try again. If error persists contact your administrator.')
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            const customerData = await VxAPI.getSingleCustomerData(paramsCustomerId);

            if (customerData) {
                setCustomerInfo(customerData);
                setLoading(false);
            }

            const locationData = await VxAPI.getAllLocations();

            if (locationData) {
                setLocationArray(locationData)
            }
        }

        fetchData()
            .catch(console.error)
    }, []);

    return (
        <>
            {loading ? (
                <div>
                    <h1 className="loading">Loading...</h1>
                </div>
            ) : (
                <div id='add-cust-div'>
                    <h1>Edit Customer</h1>
                    <form onSubmit={handleSubmit}>
                        <h2>1. Personal Information</h2>
                        <div className="add-cust-row">
                            <div className="add-cust-form-input">
                                <label htmlFor='firstName'>First/Company Name *</label>
                                <input type='text' name='firstName' defaultValue={customerInfo.firstName} onChange={handleChange} />
                            </div>
                            <div className="add-cust-form-input">
                                <label htmlFor='lastName'>Last Name</label>
                                <input type='text' name='lastName' defaultValue={customerInfo.lastName} onChange={handleChange} />
                            </div>
                        </div>
                        <div id="add-cust-location">
                            <label htmlFor="location">Location *</label>
                            <select name="location" onChange={handleSelection}>
                                <option value={customerInfo.location}>{customerInfo.location}</option>
                                {locationArray ? locationArray.map((location: LocationObjectDTO, index: number) => (
                                    <option style={{ display: location.title === customerInfo.location ? 'none' : 'block' }} key={index} value={location.title}>{location.title}</option>
                                )) : null}
                            </select>
                        </div>
                        <h2>2. Contact Information</h2>
                        <div className="add-cust-row">
                            <div className="add-cust-form-input">
                                <label htmlFor='email'>Email Address *</label>
                                <input type='email' name='email' defaultValue={customerInfo.email} onChange={handleChange} />
                            </div>
                            <div className="add-cust-form-input">
                                <label htmlFor='phone'>Phone *</label>
                                <input type='phone' name='phone' defaultValue={customerInfo.phone} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="add-cust-row">
                            <div className="add-cust-form-input">
                                <label htmlFor='address'>Mailing Address Line 1 *</label>
                                <input type='text' name='address' defaultValue={customerInfo.address} onChange={handleChange} />
                            </div>
                            <div className="add-cust-form-input">
                                <label htmlFor='address2'>Mailing Address Line 2</label>
                                <input type='text' name='address2' defaultValue={customerInfo.address2} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="add-cust-row">
                            <div className="add-cust-form-input">
                                <label htmlFor='city'>City *</label>
                                <input type='text' name='city' defaultValue={customerInfo.city} onChange={handleChange} />
                            </div>
                            <div className="add-cust-form-input">
                                <label htmlFor='state'>State *</label>
                                <input type='text' name='state' defaultValue={customerInfo.state} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="add-cust-row">
                            <div className="add-cust-form-input">
                                <label htmlFor='zip'>Zip *</label>
                                <input type='text' name='zip' defaultValue={customerInfo.zip} onChange={handleChange} />
                            </div>
                        </div>
                        <div id="add-cust-btns">
                            <button type="button" onClick={() => window.location.assign('/customers')}><span>Cancel</span></button>
                            <button type="submit"><span>Save</span></button>
                        </div>
                    </form>
                    {message ? (
                        <h3 className="message">{message}</h3>
                    ) : null}
                </div>
            )}
        </>
    )
}

export default EditCustomerForm;