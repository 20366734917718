import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../dto/customerObject.dto';
import Auth from '../../utils/auth';
import { QiEmployeeProps } from '../dto/QiEmployeeProps.dto';
import { UseCasesObjectDTO } from '../dto/useCasesObject.dto';
import './editCourseForm.scss';
import { CourseObjectDTO } from '../dto/courseObject.dto';
import { ClassObjectDTO } from '../dto/classObject.dto';
import { TagObjectDTO } from '../dto/tagObject.dto';

const EditCourseForm: React.FC<QiEmployeeProps> = (props) => {
  const { userCustomerId } = props;
  const params = useParams();
  const paramsCourseId = Number(params.courseId);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loggedInUserCustomerId, setLoggedInUserCustomerId] = useState<number | null | undefined>(null);

  const [courseInfo, setCourseInfo] = useState<CourseObjectDTO>({
    title: '',
    description: '',
    notes: '',
    customerId: 0,
    useCaseId: 0,
  });
  const [customerArray, setCustomerArray] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      location: '',
    },
  ]);
  const [useCaseArray, setUseCaseArray] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);
  const [tagArray, setTagArray] = useState<TagObjectDTO[]>([]);
  const [classes, setClasses] = useState<ClassObjectDTO[]>();
  const [selectClassState, setSelectClassState] = useState<(number | undefined)[]>([]);
  const [currentlySelectedClassIds, setCurrentlySelectedClassIds] = useState<(number | undefined)[]>([]);
  const [availableTags, setAvailableTags] = useState<TagObjectDTO[]>();
  const [selectTagState, setSelectTagState] = useState<(number | undefined)[]>([]);
  const [currentlySelectedTagIds, setCurrentlySelectedTagIds] = useState<(number | undefined)[]>([]);
  const { title, description, notes, customerId, useCaseId } = courseInfo;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage('');
    setCourseInfo({ ...courseInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newToken = await Auth.refreshToken();

    if (title === '' || description === '' || customerId === undefined || customerId === 0) {
      setMessage('Make sure all required fields are completed.');
      return;
    }
    let tags: (number | undefined)[] = selectTagState;
    if (selectTagState?.length > 0) {
      selectTagState.forEach((tagId) => {
        const foundTag = tagArray.find((tag) => tag.tagId === tagId);
        const tagType = tagArray.find((tag) => tag.title === foundTag?.type)?.tagId;
        const tagSub1 = tagArray.find((tag) => tag.title === foundTag?.sub1)?.tagId;
        const tagSub2 = tagArray.find((tag) => tag.title === foundTag?.sub2)?.tagId;
        if (tagType && !tags.includes(tagType)) {
          tags.push(tagType);
        }
        if (tagSub1 && !tags.includes(tagSub1)) {
          tags.push(tagSub1);
        }
        if (tagSub2 && !tags.includes(tagSub2)) {
          tags.push(tagSub2);
        }
      });
    }
    let currentTime = Date.now(); // get date as integer
    let currentDate = new Date(currentTime); // conver integer into date with timezone
    let modifiedDate = currentDate;

    const resp = await VxAPI.editCourse(
      {
        title,
        description,
        notes,
        tags,
        classes: selectClassState,
        modifiedDate,
      },
      paramsCourseId,
    );

    if (resp.courseId) {
      window.location.assign('/courses');
    } else {
      setMessage('An error has occured. Please try again. If error persists contact your administrator.');
      return;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const courseData = await VxAPI.getSingleCourseData(paramsCourseId);

      if (courseData) {
        setCourseInfo(courseData);
        if (courseData.tags && courseData.tags[0] !== 0) {
          setSelectTagState(courseData.tags);
          setCurrentlySelectedTagIds(courseData.tags);
        }
        if (courseData.classes && courseData.classes[0] !== 0) {
          setSelectClassState(courseData.classes);
          setCurrentlySelectedClassIds(courseData.classes);
        }
      }

      const customerData = await VxAPI.getAllCustomersData();

      if (customerData) {
        setCustomerArray(customerData);
      }

      const useCaseData = await VxAPI.getAllUseCasesData();

      if (useCaseData) {
        setUseCaseArray(useCaseData);
      }

      const tagsData = await VxAPI.getAllTagsData();

      if (tagsData) {
        setTagArray(tagsData);
        setAvailableTags(tagsData);
      }

      const classData = await VxAPI.getAllClassesData();
      if (classData) {
        const filteredClassData = classData.filter(
          (foundClass: ClassObjectDTO) => foundClass.useCaseId === courseData.useCaseId,
        );
        setClasses(filteredClassData);
      }
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setLoggedInUserCustomerId(userCustomerId);

    if (userCustomerId !== null && courseInfo.customerId !== 0) {
      if (courseInfo.customerId !== userCustomerId) {
        window.location.assign('/');
      }
    }
  }, [userCustomerId, courseInfo]);

  const handleTagSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newTags = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newTags.push(Number(selectedOptions[i].value));
    }

    setSelectTagState(newTags);
  };

  const handleClassSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMessage('');
    const selectedOptions = e.currentTarget.selectedOptions;
    const newClasses = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      newClasses.push(Number(selectedOptions[i].value));
    }

    setSelectClassState(newClasses);
  };

  const handleTagReset = () => {
    setSelectTagState(currentlySelectedTagIds);
  };

  const handleClassReset = () => {
    setSelectClassState(currentlySelectedClassIds);
  };

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="edit-course-div">
          <h1>Edit Course</h1>
          <form onSubmit={handleSubmit}>
            <div className="dropdown-div">
              <div className="edit-course-hardcode">
                <label htmlFor="name">Customer</label>
                <p id="course-cust-name">
                  {customerArray.find((customer) => customer.customerId === courseInfo.customerId)?.firstName}{' '}
                  {customerArray.find((customer) => customer.customerId === courseInfo.customerId)?.lastName}
                </p>
              </div>
              <div className="edit-course-hardcode">
                <label htmlFor="useCaseId">Use Case</label>
                <p id="course-cust-useCase">
                  {useCaseArray.find((useCase) => useCase.useCaseId === courseInfo.useCaseId)?.title}
                </p>
              </div>
            </div>
            <div className="edit-course-row">
              <div className="edit-course-form-input">
                <label htmlFor="title">Course Name *</label>
                <input type="text" name="title" defaultValue={title} onChange={handleChange} />
              </div>
            </div>
            <div className="selection-div">
              <div className="edit-class-dropdown">
                <label id="class-dropdown-header" htmlFor="selectClassState[]">
                  Edit Classes
                </label>
                <h2 id="class-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={classes?.length} name="classArray" onChange={handleClassSelection}>
                  {classes
                    ? classes
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((foundClass, index: number) => (
                        <option
                          {...(selectClassState.includes(Number(foundClass.classId)) ? { selected: true } : null)}
                          key={index}
                          value={foundClass.classId}
                        >
                          {foundClass.title}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleClassReset}>
                  <span>Reset</span>
                </button>
              </div>
              <div className="edit-tag-dropdown">
                <label id="tag-dropdown-header" htmlFor="selectTagState[]">
                  Edit Tags
                </label>
                <h2 id="tag-select-header">Hold CTRL or CMD to select multiple</h2>
                <select multiple size={availableTags?.length} name="tagArray" onChange={handleTagSelection}>
                  {availableTags
                    ? availableTags
                    .sort((a: any, b: any) => {
                      if (a.title < b.title) {
                        return -1;
                      }
                      if (a.title > b.title) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((tag, index: number) => (
                        <option
                          {...(selectTagState.includes(Number(tag.tagId)) ? { selected: true } : null)}
                          key={index}
                          value={tag.tagId}
                        >
                          {tag.title}
                        </option>
                      ))
                    : null}
                </select>
                <button type="button" id="reset-btn" onClick={handleTagReset}>
                  <span>Reset</span>
                </button>
              </div>
            </div>
            <div className="edit-course-form-textarea">
              <label htmlFor="description">Description *</label>
              <textarea name="description" defaultValue={description} onChange={handleChange} />
            </div>
            <div className="edit-course-form-textarea">
              <label htmlFor="notes">Notes</label>
              <textarea name="notes" defaultValue={notes} onChange={handleChange} />
            </div>
            <div id="edit-course-btns">
              <button type="button" onClick={() => window.location.assign('/courses')}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Confirm</span>
              </button>
            </div>
          </form>
          {message ? <h3 className="message">{message}</h3> : null}
        </div>
      )}
    </>
  );
};

export default EditCourseForm;
