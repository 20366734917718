import { useEffect, useRef, useState } from 'react';
import { ClassObjectDTO } from '../../components/dto/classObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import SidebarChart, { SideBarChartPropsForScreenSize } from '../../components/charts/bar/SideBar';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { averageArrays, returnFormattedDate } from '../../utils/helpers';
import Certificate, { CertificateProps } from '../../components/certificate/CertificatePDF';
import './studentDash.scss';

export interface StudentDashProps {
  studentData?: UserObjectDTO | null;
  studentClassData: ClassObjectDTO[];
}

const StudentDashboard: React.FC<StudentDashProps> = ({ studentData, studentClassData }) => {
  const [coursesCollapse, setCoursesCollapse] = useState<boolean[]>([]);
  const [modulesCollapse, setModulesCollapse] = useState<boolean[]>([]);
  const [lessonsCollapse, setLessonsCollapse] = useState<boolean[]>([]);
  const courseRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const moduleRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const lessonRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [nonNullModuleRefs, setNonNullModuleRefs] = useState<HTMLDivElement[]>([]);
  const [nonNullLessonRefs, setNonNullLessonRefs] = useState<HTMLDivElement[]>([]);
  const chartHeight = 6;
  const [displayCert, setDisplayCert] = useState<boolean>(false);
  const [certificateData, setCertificateData] = useState<CertificateProps | null>(null);

  const [sideBarProps, setSideBarProps] = useState<SideBarChartPropsForScreenSize>({
    borderWidth: 1,
    displayLegend: false,
    minBarLength:
      window.innerWidth < 576
        ? 1
        : window.innerWidth < 768
        ? 2
        : window.innerWidth < 992
        ? 3
        : window.innerWidth < 1200
        ? 4
        : 5,
    dataMax: 100,
    dataMin: 0,
    titleFontSize:
      window.innerWidth < 576
        ? 10
        : window.innerWidth < 768
        ? 10
        : window.innerWidth < 992
        ? 12
        : window.innerWidth < 1200
        ? 14
        : 16,
    yAxisLabelFontSize:
      window.innerWidth < 576
        ? 10
        : window.innerWidth < 768
        ? 10
        : window.innerWidth < 992
        ? 12
        : window.innerWidth < 1200
        ? 14
        : 16,
    height: 100,
    width: 100,
    padding: 10,
  });

  const handleCoursesArrow = (index: number) => {
    setCoursesCollapse((prevState) => {
      const previousIndexState = prevState[index];
      const newState = new Array(prevState.length).fill(false); // Set all values to false
      newState[index] = !previousIndexState; // Set the specified index to true
      return newState;
    });
    setModulesCollapse([]);
  };

  const handleModulesArrow = (index: number) => {
    setModulesCollapse((prevState) => {
      const previousIndexState = prevState[index];
      const newState = new Array(prevState.length).fill(false); // Set all values to false
      newState[index] = !previousIndexState; // Set the specified index to true
      return newState;
    });
    setLessonsCollapse([]);
  };

  const handleLessonsArrow = (index: number) => {
    setLessonsCollapse((prevState) => {
      const previousIndexState = prevState[index];
      const newState = new Array(prevState.length).fill(false); // Set all values to false
      newState[index] = !previousIndexState; // Set the specified index to true
      return newState;
    });
  };

  const setCourseRef = (courseId: string, element: HTMLDivElement | null) => {
    courseRefs.current[courseId] = element;
  };

  const setModuleRef = (moduleId: string, element: HTMLDivElement | null) => {
    console.log('setting module ref: ', element);
    moduleRefs.current[moduleId] = element;
  };

  const setLessonRef = (lessonId: string, element: HTMLDivElement | null) => {
    lessonRefs.current[lessonId] = element;
  };

  const getNonNullRefs = (refs: { [key: string]: HTMLDivElement | null }) => {
    return Object.values(refs).filter((ref) => ref !== null);
  };

  useEffect(() => {
    if (!courseRefs.current) return;
    const nonNullModuleRefs = getNonNullRefs(moduleRefs.current);
    const nonNullLessonRefs = getNonNullRefs(lessonRefs.current);
    if (nonNullModuleRefs.length <= 0) return;
    const courseId = nonNullModuleRefs[0]?.id.split('-')[0];
    setNonNullModuleRefs(nonNullModuleRefs as HTMLDivElement[]);
    setNonNullLessonRefs(nonNullLessonRefs as HTMLDivElement[]);
    // console.log('course: ', courseRefs.current[courseId as string]);
    // console.log('modules: ', nonNullModuleRefs);
    // console.log('lessons: ', nonNullLessonRefs);
  }, [lessonsCollapse, modulesCollapse, coursesCollapse]);

  const handleCertficateData = (date: string, name: string, course: string, signatureName: string) => {
    if (certificateData === null) {
      setCertificateData({ date, name, course, signatureName });
      setDisplayCert(true);
    } else {
      setCertificateData(null);
      setDisplayCert(false);
    }
  };

  return (
    <>
      {displayCert && certificateData ? (
        <>
          <Certificate
            name={certificateData.name}
            course={certificateData.course}
            date={certificateData.date}
            signatureName={certificateData.signatureName}
            callback={() => handleCertficateData('', '', '', '')}
          />
        </>
      ) : (
        <div className="student-dash">
          <h1 className="student-dash-h1">
            Hello {studentData?.firstName} {studentData?.lastName}!
          </h1>
          <div className="dashboard">
            {studentClassData.map((classData: ClassObjectDTO, index: number) => {
              return (
                <>
                  <div className="unit-header">
                    <h1 className="unit-title">{classData.title}</h1>
                    <div className={coursesCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'}>
                      <h1>Courses</h1>
                      <FontAwesomeIcon
                        id={`courses-dd-icon`}
                        icon={coursesCollapse[index] ? faAngleUp : faAngleDown}
                        onClick={() => handleCoursesArrow(index)}
                      />
                    </div>
                    {coursesCollapse[index] ? (
                      <div className="unit-dd">
                        <div className="unit-details">
                          <>
                            {classData.courseList?.map((course: CourseObjectDTO, index: number) => {
                              const classStudents: (UserObjectDTO | undefined)[] | undefined = [
                                studentData as UserObjectDTO,
                              ];
                              let lessonCompletedDates: string[] = [];
                              const studentCompletedLessonIds = classStudents?.map((student) =>
                                student?.studentMetrics?.lessons.map((lesson) => {
                                  if (lesson.dateCompleted) {
                                    lessonCompletedDates.push(lesson.dateCompleted as unknown as string);
                                    return lesson.lessonId;
                                  }
                                }),
                              );
                              const latestCompletedDate = lessonCompletedDates.reduce((latest, current) => {
                                return current > latest ? current : latest;
                              }, lessonCompletedDates[0]);
                              const studentNames = classStudents?.map((student) => {
                                return `${student?.firstName} ${student?.lastName}`;
                              });

                              // count the number of lessons for the course
                              const courseLessonsIds = course.courseModules?.flatMap((module: ModuleObjectDTO) => {
                                return module.lessons?.map((lesson: LessonObjectDTO) => {
                                  return lesson.lessonId;
                                });
                              });
                              // count if lesson id exists on student metrics and has completed date
                              // get progress value and multiply by 100
                              const progressArray = courseLessonsIds?.map((id) => {
                                return studentCompletedLessonIds?.map((lId) => {
                                  let count = 0;
                                  if (lId?.includes(id)) count = 1;
                                  return lId?.length ? count : 0;
                                });
                              });

                              const studentsDataValues = averageArrays(progressArray as number[][], true);

                              // Determine bar colors based on data values
                              const backgroundColors = studentsDataValues?.map((value) => {
                                if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                if (value > 0 && value < 100) return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                              });

                              const modules = course.courseModules;

                              let chartDivHeight =
                                (studentNames?.length as number) > 1
                                  ? chartHeight * (studentNames?.length as number)
                                  : 12;
                              const nonNullModuleKeys =
                                Object.keys(moduleRefs.current).filter((key) => moduleRefs.current[key] !== null)[0] ||
                                null;
                              console.log('moduleRefs: ', moduleRefs.current);
                              const modulesOpen = modulesCollapse.some((value) => value === true);
                              // console.log('modules open: ', modulesOpen);
                              // console.log(nonNullModuleKeys);
                              const currentCourseId = nonNullModuleKeys ? nonNullModuleKeys.split('-')[0] : null;
                              // console.log('current course id: ', currentCourseId);
                              const hideElement =
                                currentCourseId && currentCourseId !== `${course.courseId}` && modulesOpen;
                              // console.log('hide element: ', hideElement);
                              const expandedElement =
                                currentCourseId && currentCourseId === `${course.courseId}` && modulesOpen;
                              // console.log('expanded el: ', expandedElement);

                              return (
                                <div
                                  style={{
                                    display: hideElement ? 'none' : 'block',
                                  }}
                                  className={expandedElement ? 'student-info expanded' : 'student-info'}
                                  id={`${course.courseId}`}
                                  ref={(el) => setCourseRef(`${course.courseId}`, el)}
                                >
                                  <h1 className={modulesCollapse[index] ? 'active-font' : undefined}>{course.title}</h1>
                                  <div className="datachart">
                                    <div
                                      className="course-progress-chart"
                                      style={{
                                        height: `${chartDivHeight}rem`,
                                      }}
                                    >
                                      <SidebarChart
                                        dataValues={studentsDataValues}
                                        yLabels={studentNames as string[]}
                                        barColors={backgroundColors}
                                        barBorderColors={backgroundColors}
                                        borderWidth={sideBarProps.borderWidth}
                                        displayLegend={sideBarProps.displayLegend}
                                        title="Course Progress"
                                        className="course-progress-chart"
                                        minBarLength={sideBarProps.minBarLength}
                                        dataMax={sideBarProps.dataMax}
                                        dataMin={sideBarProps.dataMin}
                                        titleFontSize={sideBarProps.titleFontSize}
                                        yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                        height={
                                          (studentNames?.length as number) > 1
                                            ? chartHeight * (studentNames?.length as number)
                                            : 12
                                        }
                                        width={sideBarProps.width}
                                        padding={sideBarProps.padding}
                                      />
                                      <div
                                        className="cert-btn-divs"
                                        style={{
                                          marginTop: `${
                                            chartDivHeight /
                                            (studentsDataValues?.length ? studentsDataValues.length + 1 : 1)
                                          }rem`,
                                        }}
                                      >
                                        {studentsDataValues.map((score, index) => {
                                          const currentStudent =
                                            studentNames && studentNames?.length > 0 ? studentNames[index] : '';
                                          if (score === 100) {
                                            return (
                                              <div
                                                className="cert-btn"
                                                style={{
                                                  height: `${
                                                    chartDivHeight /
                                                    (studentsDataValues?.length ? studentsDataValues.length + 1 : 1)
                                                  }rem`,
                                                  alignContent:
                                                    studentsDataValues.length && studentsDataValues.length > 2
                                                      ? 'center'
                                                      : undefined,
                                                }}
                                              >
                                                <button
                                                  onClick={() =>
                                                    handleCertficateData(
                                                      returnFormattedDate(latestCompletedDate),
                                                      currentStudent,
                                                      course.title as string,
                                                      'Authorizing Official',
                                                    )
                                                  }
                                                >
                                                  Cert
                                                </button>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div
                                                className="no-cert-btn"
                                                style={{
                                                  height: `${
                                                    chartDivHeight /
                                                    (studentsDataValues?.length ? studentsDataValues.length + 1 : 1)
                                                  }rem`,
                                                }}
                                              ></div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={modulesCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'}>
                                    <h1>Modules</h1>
                                    <FontAwesomeIcon
                                      id={`modules-dd-icon-${course.courseId}`}
                                      icon={modulesCollapse[index] ? faAngleUp : faAngleDown}
                                      onClick={() => handleModulesArrow(index)}
                                    />
                                  </div>
                                  <>
                                    {modulesCollapse[index] ? (
                                      <>
                                        {modules?.map((module: ModuleObjectDTO, index: number) => {
                                          const lessons = module.lessons;
                                          const moduleProgressArray = lessons?.map((lesson) => {
                                            let count = 0;
                                            return studentCompletedLessonIds?.map((lId) => {
                                              // console.log('lId: ', lId);
                                              if (lId?.includes(lesson.lessonId)) count++;
                                              return lId?.length ? count : 0;
                                            });
                                          });
                                          const moduleDataValues = averageArrays(
                                            moduleProgressArray as number[][],
                                            true,
                                          );

                                          // Determine bar colors based on data values
                                          const moduleBackgroundColors = moduleDataValues?.map((value) => {
                                            if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                            if (value > 0 && value < 100) return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                            return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                          });

                                          const nonNullLessonKeys =
                                            Object.keys(lessonRefs.current).filter(
                                              (key) => lessonRefs.current[key] !== null,
                                            )[0] || null;
                                          // console.log('lessonRefs: ', lessonRefs.current);
                                          const lessonsOpen = lessonsCollapse.some((value) => value === true);
                                          // console.log('lessons open: ', lessonsOpen);
                                          // console.log(nonNullLessonKeys);
                                          const currentModuleId = nonNullLessonKeys
                                            ? nonNullLessonKeys.split('-')[1]
                                            : null;
                                          // console.log('current Module id: ', currentModuleId);

                                          return (
                                            <div
                                              style={{
                                                display:
                                                  currentModuleId &&
                                                  currentModuleId !== `${module.moduleId}` &&
                                                  lessonsOpen
                                                    ? 'none'
                                                    : 'block',
                                              }}
                                              className="module-info"
                                              id={`${course.courseId}-${module.moduleId}`}
                                              ref={(el) => setModuleRef(`${course.courseId}-${module.moduleId}`, el)}
                                            >
                                              <h1 className={lessonsCollapse[index] ? 'active-font' : undefined}>
                                                {module.title}
                                              </h1>
                                              <div className="datachart">
                                                <div
                                                  className="course-progress-chart"
                                                  style={{
                                                    height: `${chartDivHeight}rem`,
                                                  }}
                                                >
                                                  <SidebarChart
                                                    dataValues={moduleDataValues}
                                                    yLabels={studentNames as string[]}
                                                    barColors={moduleBackgroundColors}
                                                    barBorderColors={moduleBackgroundColors}
                                                    borderWidth={sideBarProps.borderWidth}
                                                    displayLegend={sideBarProps.displayLegend}
                                                    title="Module Progress"
                                                    className="course-progress-chart"
                                                    minBarLength={sideBarProps.minBarLength}
                                                    dataMax={sideBarProps.dataMax}
                                                    dataMin={sideBarProps.dataMin}
                                                    titleFontSize={sideBarProps.titleFontSize}
                                                    yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                                    height={
                                                      (studentNames?.length as number) > 1
                                                        ? chartHeight * (studentNames?.length as number)
                                                        : 12
                                                    }
                                                    width={sideBarProps.width}
                                                    padding={sideBarProps.padding}
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  lessonsCollapse[index] ? 'unit-header-dd active' : 'unit-header-dd'
                                                }
                                              >
                                                <h1>Lessons</h1>
                                                <FontAwesomeIcon
                                                  id={`lessons-dd-icon-${module.moduleId}`}
                                                  icon={lessonsCollapse[index] ? faAngleUp : faAngleDown}
                                                  onClick={() => handleLessonsArrow(index)}
                                                />
                                              </div>
                                              <>
                                                {lessonsCollapse[index] ? (
                                                  <>
                                                    {lessons?.map((lesson: LessonObjectDTO, index: number) => {
                                                      const lessonProgressArray: number[] = [];
                                                      studentCompletedLessonIds?.map((lId) => {
                                                        if (lId?.includes(lesson.lessonId)) {
                                                          lessonProgressArray.push(100);
                                                        } else {
                                                          lessonProgressArray.push(0);
                                                        }
                                                      });

                                                      // Determine bar colors based on data values
                                                      const lessonBackgroundColors = lessonProgressArray?.map(
                                                        (value) => {
                                                          if (value === 0) return 'rgba(255, 0, 0, 0.6)'; // Red for Not Started
                                                          if (value > 0 && value < 100)
                                                            return 'rgba(255, 206, 86, 0.6)'; // Yellow for Started
                                                          return 'rgba(0, 255, 0, 0.6)'; // Green for Completed
                                                        },
                                                      );
                                                      return (
                                                        <div
                                                          className="lesson-info"
                                                          id={`${course.courseId}-${module.moduleId}-${lesson.lessonId}`}
                                                          ref={(el) =>
                                                            setLessonRef(
                                                              `${course.courseId}-${module.moduleId}-${lesson.lessonId}`,
                                                              el,
                                                            )
                                                          }
                                                        >
                                                          <h1>{lesson.title}</h1>
                                                          <div className="datachart">
                                                            <div
                                                              className="course-progress-chart"
                                                              style={{
                                                                height: `${chartDivHeight}rem`,
                                                              }}
                                                            >
                                                              <SidebarChart
                                                                dataValues={lessonProgressArray}
                                                                yLabels={studentNames as string[]}
                                                                barColors={lessonBackgroundColors}
                                                                barBorderColors={lessonBackgroundColors}
                                                                borderWidth={sideBarProps.borderWidth}
                                                                displayLegend={sideBarProps.displayLegend}
                                                                title="Lesson Progress"
                                                                className="course-progress-chart"
                                                                minBarLength={sideBarProps.minBarLength}
                                                                dataMax={sideBarProps.dataMax}
                                                                dataMin={sideBarProps.dataMin}
                                                                titleFontSize={sideBarProps.titleFontSize}
                                                                yAxisLabelFontSize={sideBarProps.yAxisLabelFontSize}
                                                                height={
                                                                  (studentNames?.length as number) > 1
                                                                    ? chartHeight * (studentNames?.length as number)
                                                                    : 12
                                                                }
                                                                width={sideBarProps.width}
                                                                padding={sideBarProps.padding}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                  </>
                                                ) : null}
                                              </>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default StudentDashboard;
