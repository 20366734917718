import IConfidenceLogic from "./IConfidenceLogic";
import Vector2 from "./Utilities/Vector2";

export default class InverseDistanceBasicConfidence implements IConfidenceLogic {
    maxDistance: number;
    constructor(maxDistance: number) {
        this.maxDistance = maxDistance;
    }
    getConfidence(gazePosition: Vector2, qPointPosition: Vector2): number {
        let mouseToRest = qPointPosition.sub(gazePosition);
        
        let newConfidence = mouseToRest.magnitude();
        if(this.maxDistance === 20) {
            //  console.log('Conf Func mouseToRest: ', mouseToRest)
            // console.log('Conf Func newConfidence: ', newConfidence)
        }
        if (newConfidence > this.maxDistance) {
            newConfidence = 0;
        } else {
            newConfidence = 1.0 - (newConfidence / this.maxDistance);
        }
       
        return newConfidence;
    }
    
    // calculateConfidence(mousePos: Vector2, targetQP: WebNode) {
    //     if(targetQP.restPositionAbsolute) {
    //         let mouseToRest = targetQP.restPositionAbsolute.sub(mousePos);

    //     let newConfidence = mouseToRest.magnitude();
    //     if (newConfidence > this.maxDistance) {
    //         newConfidence = 0;
    //     } else {
    //         newConfidence = 1.0 - (newConfidence / this.maxDistance);
    //     }
       
    //     return newConfidence;
    //     }
    // }
}