import decode from 'jwt-decode';
import { VxAPI } from '../api/vx.api';
import { DecodedTokenDTO } from './dto/decodedToken.dto';

class AuthService {
    getProfile(token: string) {
        const profile = this.getToken(token);

        if (profile) {
            const decodedT = decode<DecodedTokenDTO>(profile);
            const userType = this.getUserType();
            if(userType)decodedT.userType = userType;
            return decodedT;
        } else {
            return false;
        }
    }

    loggedIn() {
        const token = this.getToken('id_token');
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token: string) {
        try {
            const decoded = decode<DecodedTokenDTO>(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    getToken(token: string) {
        const returnToken = localStorage.getItem(token);

        if (returnToken) {
            return returnToken;
        } else {
            return false
        }
    }

    login(idToken: string, refreshToken: string) {
        localStorage.setItem('id_token', idToken);
        localStorage.setItem('refresh_token', refreshToken);

        window.location.assign('/');
    }

    autoLogin(idToken: string, refreshToken: string, userType: string) {
        localStorage.setItem('id_token', idToken);
        localStorage.setItem('refresh_token', refreshToken);
        localStorage.setItem('user_type', userType);

        window.location.assign('/');
    }

    updateToken(idToken: string) {
        localStorage.setItem('id_token', idToken)
    }

    async logout() {
        const loggedInUser = this.getProfile('id_token');

        if (loggedInUser) {
            localStorage.removeItem('id_token');
            localStorage.removeItem('refresh_token')

            window.location.assign('/');
        }
    }

    async refreshToken() {
        const token = this.getToken('id_token')
        if (token) {
            const refreshToken = this.getToken('refresh_token')
            if (refreshToken) {
                const tokenExpired = this.isTokenExpired(token)
                if (tokenExpired) {
                    try {
                        const resp = await VxAPI.getRefreshToken()

                        if (resp.error) {
                            this.logout()
                            return
                        } else {
                            const { token, refreshToken } = resp

                            localStorage.setItem('id_token', token);
                            localStorage.setItem('refresh_token', refreshToken);
                        }
                    } catch (e) {
                        console.error(e)
                    }
                } else {
                    return false;
                }
            }
        }
    }

    addUserType = (type: string) => {
        localStorage.setItem('user_type', type);
    }

    getUserType = () => {
        return localStorage.getItem('user_type');
    }
}
export default new AuthService();