import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './lessonVersion.scss';
import { VxAPI } from '../../api/vx.api';
import { useUserData, useUserRoleData } from '../account/Account';
import Auth from '../../utils/auth';
import { LessonObjectDTO } from '../../components/dto/lessonObject.dto';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { formatTimeStamp } from '../../utils/helpers';

const LessonVersion = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const navigate = useNavigate();
  const params = useParams();
  const paramsLessonId = Number(params.lessonId);
  const [roleName, setRoleName] = useState<string>('');

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName === 'Admin' || userRoleName === 'Producer') {
        return;
      } else {
        window.location.assign('/');
      }
    }
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const [loading, setLoading] = useState(true);
  const [lessonObjectData, setLessonObjectData] = useState<LessonObjectDTO>({
    moduleId: 0,
    lessonId: 0,
    parent: 0,
    title: '',
    author: 0,
    principals: [0],
    description: '',
    notes: '',
    tags: [0],
  });

  const [parentLessonObjectData, setParentLessonObjectData] = useState<LessonObjectDTO>();
  const [authorObject, setAuthorObject] = useState<UserObjectDTO>();

  useEffect(() => {
    const fetchData = async () => {
      const lessonData = await VxAPI.getSingleLessonById(paramsLessonId);
      if (lessonData) {
        const formattedLessonCreatedDate = formatTimeStamp(lessonData.createdDate);
        lessonData.formattedCreatedDate = formattedLessonCreatedDate;
        const formattedLessonModifiedDate = formatTimeStamp(lessonData.modifiedDate);
        lessonData.formattedModifiedDate = formattedLessonModifiedDate;
        setLessonObjectData(lessonData);
        if (lessonData.parent && lessonData.parent !== 0) {
          const parentData = await VxAPI.getSingleLessonById(lessonData.parent);
          if (parentData) {
            const formattedParentLessonCreatedDate = formatTimeStamp(parentData.createdDate);
            parentData.formattedCreatedDate = formattedParentLessonCreatedDate;
            const formattedParentLessonModifiedDate = formatTimeStamp(parentData.modifiedDate);
            parentData.formattedModifiedDate = formattedParentLessonModifiedDate;
            setParentLessonObjectData(parentData);
            if (parentData.author && parentData.author !== 0) {
              const authorData = await VxAPI.getSingleUserData(parentData.author);
              if (authorData) {
                setAuthorObject(authorData);
              }
            }
          }
        } else {
          if (lessonData.author && lessonData.author !== 0) {
            const authorData = await VxAPI.getSingleUserData(lessonData.author);
            if (authorData) {
              setAuthorObject(authorData);
            }
          }
        }
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <>
          <div id="parent-lessons-div">
            <div className="row justify-space-btw">
              <h1>Version History: {lessonObjectData.title}</h1>
              <div id="parent-lessons-titles">
                <h2 id="parent-lesson-name-header">Parent Lesson Name</h2>
                <div id="secondary-parent-lesson-headers" className="row">
                  <h2 id="parent-lesson-author-header">Author</h2>
                  <h2 id="parent-lesson-email-header">Email</h2>
                  <h2 id="parent-lesson-phone-header">Phone</h2>
                  <h2 id="parent-lesson-createdDate-header">Created Date</h2>
                  <h2 id="parent-lesson-modifiedDate-header">Last Modified</h2>
                </div>
              </div>
            </div>
            <div id="parent-lesson-info-div">
              <div id="parent-lesson-name">
                <p>{parentLessonObjectData?.title ? parentLessonObjectData.title : 'THIS LESSON IS THE ORIGINAL'}</p>
              </div>
              <div id="secondary-parent-lesson-data">
                <p id="parent-lesson-author">
                  {authorObject?.firstName} {authorObject?.lastName}
                </p>
                <a id="parent-lesson-email" href={`mailto: ${authorObject?.email}`}>{authorObject?.email}</a>
                <a id="parent-lesson-phone" href={`tel:${authorObject?.phone}`}>{authorObject?.phone}</a>
                <div id="parent-lesson-created">
                  <p id="parent-lesson-created-date">
                    {parentLessonObjectData
                      ? parentLessonObjectData?.formattedCreatedDate?.date
                      : lessonObjectData.formattedCreatedDate?.date}
                  </p>
                  <p id="parent-lesson-created-time">
                    {parentLessonObjectData
                      ? parentLessonObjectData?.formattedCreatedDate?.time
                      : lessonObjectData.formattedCreatedDate?.time}
                  </p>
                </div>
                <div id="parent-lesson-modified">
                  <p id="parent-lesson-modified-date">
                    {parentLessonObjectData
                      ? parentLessonObjectData?.formattedModifiedDate?.date
                      : lessonObjectData.formattedModifiedDate?.date}
                  </p>
                  <p id="parent-lesson-modified-time">
                    {parentLessonObjectData
                      ? parentLessonObjectData?.formattedModifiedDate?.time
                      : lessonObjectData.formattedModifiedDate?.time}
                  </p>
                </div>
              </div>
            </div>
            <div className="parent-lesson-line"></div>
            <div className="back-btn-div">
              <button className="back-btn" type="button" onClick={() => navigate(-1)}>
                <span>Back</span>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LessonVersion;
