import { useEffect, useState, MouseEvent } from 'react';

import './modules.scss';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import { ModuleObjectDTO } from '../../components/dto/moduleObject.dto';
import Auth from '../../utils/auth';
import { CourseObjectDTO } from '../../components/dto/courseObject.dto';

const Modules = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName !== 'Admin') {
        window.location.assign('/');
      }
    }

    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const [loading, setLoading] = useState(true);
  const [moduleObjectArrayData, setModuleObjectArrayData] = useState<ModuleObjectDTO[]>([
    {
      moduleId: 0,
      courseId: 0,
      title: '',
      version: 0,
      description: '',
      notes: '',
      tags: [0],
    },
  ]);

  const [modulesObjectArrayWithUseCaseNames, setModulesObjectArrayWithUseCaseNames] = useState<ModuleObjectDTO[]>();

  const [updatedModulesArray, setUpdatedModulesArray] = useState<ModuleObjectDTO[]>([
    {
      moduleId: 0,
      courseId: 0,
      title: '',
      version: 0,
      description: '',
      notes: '',
      tags: [0],
      courseTitle: '',
      useCaseTitle: '',
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [courseObjectArrayData, setCourseObjectArrayData] = useState<CourseObjectDTO[]>();

  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const moduleData = await VxAPI.getAllModulesData();
      if (moduleData) {
        setModuleObjectArrayData(moduleData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      const useCaseData = await VxAPI.getAllUseCasesData();

      setUseCasesObjectArrayData(useCaseData);

      const coursesData = await VxAPI.getAllCoursesData();

      setCourseObjectArrayData(coursesData);
    };

    fetchData().catch(console.error);
  }, []);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const moduleId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/modules/edit/${moduleId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (useCasesObjectArrayData[0].useCaseId !== 0 && courseObjectArrayData) {
      const modulesWithUpdatedStringData = moduleObjectArrayData.map((module) => {
        let courseName = courseObjectArrayData?.find((course) => course.courseId === module.courseId)?.title;
        let courseUseCaseId = courseObjectArrayData?.find((course) => course.courseId === module.courseId)?.useCaseId;
        let useCaseName = useCasesObjectArrayData.find((useCase) => useCase.useCaseId === courseUseCaseId)?.title;
        module.courseTitle = courseName;
        module.useCaseTitle = useCaseName;
        return module;
      });
      setModulesObjectArrayWithUseCaseNames(modulesWithUpdatedStringData);
      setUpdatedModulesArray(modulesWithUpdatedStringData);
      setLoading(false);
    }
  }, [moduleObjectArrayData, useCasesObjectArrayData, courseObjectArrayData]);

  useEffect(() => {
    if (modulesObjectArrayWithUseCaseNames) {
      if (searchText === '') {
        setUpdatedModulesArray(modulesObjectArrayWithUseCaseNames);
      }

      const updatedModules = modulesObjectArrayWithUseCaseNames?.filter(
        (module) =>
          module.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          module.useCaseTitle?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          module.courseTitle?.toLowerCase()?.includes(searchText.toLowerCase()),
      );

      setUpdatedModulesArray(updatedModules);
    }
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="modules-div">
          <div className="row justify-space-btw">
            <h1>Modules</h1>
            <div id="module-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button
                id="add-module-btn"
                type="button"
                onClick={() => window.location.assign('/modules/add')}
              >
                <span>+Add Module</span>
              </button>
            </div>
            <div id="modules-titles">
              <h2 id="module-name-header">Module Name</h2>
              <div id="secondary-module-headers" className="row">
                <h2 id="module-cust-header">Customer</h2>
                <h2 id="module-useCase-header">Use Case</h2>
                <h2 id="module-courseName-header">Course</h2>
                <h2 id="module-version-header">Version</h2>
                <h2 id="module-desc-header">Description</h2>
                <h2 id="module-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedModulesArray
            ? updatedModulesArray
                .sort((a: any, b: any) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                })
                .slice(0, itemsToShow)
                .map((module: ModuleObjectDTO, index: number) => {
                  const foundCourse = courseObjectArrayData?.find((course) => course.courseId === module.courseId);
                  return (
                    <>
                      <div id="module-info-div" key={index}>
                        <div id="module-name">
                          <p>{module.title}</p>
                        </div>
                        <div id="secondary-module-data">
                          <p id="module-cust">
                            {
                              customersObjectArrayData.find(
                                (customer) => customer.customerId === foundCourse?.customerId,
                              )?.firstName
                            }{' '}
                            {
                              customersObjectArrayData.find(
                                (customer) => customer.customerId === foundCourse?.customerId,
                              )?.lastName
                            }
                          </p>
                          <p id="module-useCase">{module.useCaseTitle}</p>
                          <p id="module-courseName">{foundCourse?.title}</p>
                          <p id="module-version">{module.version}</p>
                          <p id="module-desc">{module.description}</p>
                          <div id="module-more">
                            <button
                              type="button"
                              id={`edit-module-btn-${module.moduleId}`}
                              className="edit-btn"
                              onClick={handleEditBtnClick}
                            >
                              <span>Edit</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="module-line"></div>
                    </>
                  );
                })
            : null}
          <>
            {!expanded && updatedModulesArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedModulesArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Modules;
