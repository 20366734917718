import EditUseCaseForm from "../../components/editUseCaseForm/EditUseCaseForm";
import { useUserData } from "../account/Account";

const EditUseCase = () => {

    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;
    
    if(superUser === false) {
        window.location.assign('/')
    }

    return (
        <>
            <EditUseCaseForm />
        </>
    )
}

export default EditUseCase;