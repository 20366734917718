import { useState, useEffect, ChangeEvent } from 'react';
import { useUserRoleData } from '../../pages/account/Account';
import Papa from 'papaparse';
import Auth from '../../utils/auth';
import { DecodedTokenDTO } from '../../utils/dto/decodedToken.dto';
import { VxAPI } from '../../api/vx.api';
import './addMultipleUsers.scss';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export interface BulkUser {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  pin: string;
  userRole: number | string;
  customerId?: number;
}

export interface AddMultiplerUserProps {
  emailList: string[];
}

const AddMultipleUsers: React.FC<AddMultiplerUserProps> = ({ emailList }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [bulkUsers, setBulkUsers] = useState<BulkUser[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<BulkUser[]>([]);
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const loggedInUser = Auth.getProfile('id_token') as DecodedTokenDTO;

  const [roleName, setRoleName] = useState<string>('');

  if (roleName === 'Student' || roleName === 'Instructor') {
    window.location.assign('/');
  }
  useEffect(() => {
    if (userRoleName) {
      setRoleName(userRoleName ? userRoleName : '');
    }
  }, [userRoleName]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    const file = event.target.files?.[0];
    if (file) {
      let rowIndex = 0;
      const rows: any[] = [];
      const expectedHeaders = ['firstName', 'lastName', 'password', 'email', 'pin', 'userRole'];
      Papa.parse(file, {
        header: false,
        step: (row: any, parser: any) => {
          rowIndex++;
          if (rowIndex > 3) {
            if (rowIndex === 4) {
              const headerRow = row.data;
              const missingHeaders = expectedHeaders.filter((header) => !headerRow.includes(header));
              if (missingHeaders.length > 0) {
                setMessage('Incorrect template format: Missing headers ' + missingHeaders.join(', '));
                parser.abort(); // Stop further processing
                return;
              }
            } else {
              const userData = row.data;
              const user: any = {};

              expectedHeaders.forEach((header, index) => {
                user[header] = userData[index];
              });

              // Check for missing fields
              const missingFields = expectedHeaders.filter((header) => !user[header]);

              if (missingFields.length > 0) {
                setMessage(`Row ${rowIndex} is missing required field(s). Please fix and reupload.`);
                parser.abort();
                return;
              }
            }
            rows.push(row.data);
          }
        },
        complete: () => {
          const headerRow = rows[0];
          const dataRows = rows.slice(1);
          const users: BulkUser[] = dataRows.map((userData: any) => {
            const user: any = {};
            headerRow.forEach((header: string, index: number) => {
              user[header] = userData[index];
            });
            return {
              firstName: user.firstName,
              lastName: user.lastName,
              password: user.password,
              email: user.email.toLowerCase(),
              pin: user.pin,
              userRole:
                user.userRole.toLowerCase() === 'admin'
                  ? 3
                  : user.userRole.toLowerCase() === 'instructor'
                  ? 2
                  : user.userRole.toLowerCase() === 'producer'
                  ? 4
                  : 1,
              customerId: loggedInUser.customerId,
            };
          });
          const filteredUsers = users.filter((user: BulkUser) => !emailList.includes(user.email.toLowerCase()));
          setUsersToAdd(filteredUsers);
          setBulkUsers(users);
        },
      });
    }
  };

  const handleSumbitMultipleUsers = async () => {
    if (usersToAdd.length === 0) {
      setMessage('No new users to add.');
      return;
    }
    setLoading(true);
    const resp = await VxAPI.addMultipleUsers({ bulk: usersToAdd });
    if (resp.length && resp.length > 0) {
      setShowSuccessMessage(true);
    } else {
      setLoading(false);
      setMessage(`Failed to add users. ${resp.message}. Please update the template and submit again.`);
    }
  };

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        window.location.assign('/users/add');
      }, 2000);
    }
  }, [showSuccessMessage]);

  const handleRemoveCSV = () => {
    setBulkUsers([]);
    setUsersToAdd([]);
    setMessage('');
  };

  return (
    <>
      {loading ? (
        <div className="loading-animated">
          {showSuccessMessage ? (
            <>
              <h1>Successfully added {usersToAdd.length} users!</h1>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faSpinner} />
            </>
          )}
        </div>
      ) : (
        <div className="multi-users-container">
          {bulkUsers.length > 0 ? (
            <>
              <h1>Remove CSV</h1>
              <button className="clear-btn" onClick={handleRemoveCSV}>
                X
              </button>
              <div className="users-add-list">
                <h2>Users to Add: {usersToAdd.length > 0 ? usersToAdd.length : '0'}</h2>
                <div className="usernames">
                  {bulkUsers.map((user: BulkUser) => {
                    return (
                      <>
                        {emailList.includes(user.email.toLowerCase()) ? (
                          <>
                            <p style={{ color: 'white', backgroundColor: 'red' }}>
                              Name: {user.firstName} {user.lastName}, Email: {user.email.toLowerCase()} ALREADY EXISTS
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: 'white', backgroundColor: 'green' }}>
                              Name: {user.firstName} {user.lastName}, Email: {user.email.toLowerCase()}
                            </p>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              {message ? <h3 className="message">{message}</h3> : null}
              {usersToAdd.length > 0 && !message ? (
                <button className="add-multi-users-btn" onClick={handleSumbitMultipleUsers}>
                  Submit Users
                </button>
              ) : null}
            </>
          ) : (
            <>
              <h1>Add Multiple Users (.csv files ONLY)</h1>
              <div className="dl-template">
                <a href="/templates/addMultiUserTemplate.csv" download="addMultiUserTemplate.csv">
                  <button>CSV Upload Template</button>
                </a>
              </div>
              <input className="csv-btn" type="file" accept=".csv" onChange={handleFileUpload} />
              {message ? <h3 className="message">{message}</h3> : null}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AddMultipleUsers;
