import { useEffect, useState } from "react";
import AddClassForm from "../../components/addClassForm/AddClassForm";
import { useUserData, useUserRoleData } from "../account/Account";

const AddClasses = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const userCustomerId = userData?.customerId
    const userId = userData?.userId
   
    const [ loggedInId, setLoggedInId] = useState<number | undefined>(0)
    useEffect(() => {
        if (userRoleName && userRoleName !== '' && userId) {
          if (userRoleName !== 'Admin') {
            window.location.assign('/');
          }
        }
        setLoggedInId(userId)
      }, [userRoleName, userId]);
    
    return (
        <>
            <AddClassForm 
            userCustomerId = {userCustomerId}
            loggedInId = {loggedInId}
            />
        </>
    )
}

export default AddClasses;