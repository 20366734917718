import { useState, useEffect, MouseEvent } from 'react';
import { UserObjectDTO } from '../../api/dto/userObject.dto';
import { VxAPI } from '../../api/vx.api';
import { useInstructorLessonsArrayData, useUserData } from '../../pages/account/Account';
import { HierarchicalLessonObjectDTO } from '../dto/hierarchicalLessonObject.dto';
import './lessonsDisplay.scss';

const LessonsDisplay = () => {
  const [loading, setLoading] = useState(true);

  const { userData } = useUserData();
  const { instructorLessonsArrayData } = useInstructorLessonsArrayData();
  const [students, setStudents] = useState<UserObjectDTO[]>();
  const [screensizeWidth, setScreensizeWidth] = useState<number>(window.innerWidth);
  const [itemsToShow, setItemsToShow] = useState<number>(screensizeWidth < 992 ? 10 : 9);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (instructorLessonsArrayData && instructorLessonsArrayData?.length > 0 && userData?.userId) {
      let studentsEnrolledInLessons: number[] = [];
      instructorLessonsArrayData.forEach((lesson) => {
        lesson.students?.forEach((studentId) => {
          studentsEnrolledInLessons.push(studentId);
        });
      });
      let uniqueStudentIdsEnrolledInLessons = [...new Set(studentsEnrolledInLessons)];
      const fetchData = async () => {
        const studentData = await VxAPI.getUsersByArrayOfIds({ userIdArray: uniqueStudentIdsEnrolledInLessons });
        if (studentData) {
          setStudents(studentData);
        }
      };
      fetchData().catch(console.error);
    }
    setLoading(false);
  }, [instructorLessonsArrayData, userData]);

  window.addEventListener('resize', () => {
    setScreensizeWidth(window.innerWidth);
    setItemsToShow(window.innerWidth < 992 ? 10 : 9);
  });

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + (screensizeWidth < 992 ? 10 : 9));
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - (screensizeWidth < 992 ? 10 : 9));
    if (itemsToShow === (screensizeWidth < 992 ? 20 : 18)) {
      await setExpanded(false);
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="lessons-dash">
          <h2>My Lessons</h2>
          {!instructorLessonsArrayData?.length ? (
            <h3 id="no-lessons-header">
              You do not have any lessons assigned to you. Please contact your admin to assign you as an Instructor to
              lessons
            </h3>
          ) : null}
          <div id="lessons-display">
            <div id="lessons-row">
              {instructorLessonsArrayData
                ? instructorLessonsArrayData
                    .slice(0, itemsToShow)
                    .map((instructorLesson: HierarchicalLessonObjectDTO, index: number) => {
                      let quizGuidArray: string[] = [];
                      let lessonStudentsArray: (number | undefined)[] = [];
                      instructorLesson.students?.forEach((studentId) => {
                        lessonStudentsArray.push(studentId);
                      });
                      students?.map((student) => {
                        if (lessonStudentsArray?.includes(student.userId)) {
                          const foundLesson = student?.studentMetrics?.lessons?.find(
                            (lesson) => lesson.lessonId === instructorLesson.lesson?.lessonId,
                          );
                          foundLesson?.quizResults?.map((quiz) => {
                            if (!quiz.guid) return;
                            quizGuidArray.push(quiz.guid);
                          });
                        }
                      });
                      const uniqueQuizGuidArray = [...new Set(quizGuidArray)];
                      return (
                        <div
                          className="lesson-card"
                          key={index}
                          onClick={() =>
                            window.location.assign(
                              `/class/${instructorLesson.classId}/lessons/view/${instructorLesson?.lesson?.lessonId}`,
                            )
                          }
                        >
                          <div className="lesson-info">
                            <h3>{instructorLesson?.lesson?.title}</h3>
                            <p>Class: {instructorLesson.className}</p>
                            <p>
                              {!instructorLesson?.students?.length
                                ? `0 Students`
                                : instructorLesson?.students?.length === 1
                                ? `1 Student`
                                : `${instructorLesson?.students?.length} Students`}
                            </p>
                            <p>
                              {uniqueQuizGuidArray.length === 1
                                ? `${uniqueQuizGuidArray.length} Quiz`
                                : `${uniqueQuizGuidArray?.length} Quizzes`}
                            </p>
                          </div>
                        </div>
                      );
                    })
                : null}
            </div>
          </div>
          <>
            {!expanded &&
            instructorLessonsArrayData &&
            instructorLessonsArrayData.length <= (screensizeWidth < 992 ? 10 : 9) ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : instructorLessonsArrayData && instructorLessonsArrayData.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default LessonsDisplay;
