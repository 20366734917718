import { QPointState } from "./Enums";
import InverseDistanceBasicConfidence from "./InverseDistanceBasicConfidence";
import IQINode from "./IQINode";
import QINodeblueprint from "./QINodeBlueprint";
import Action from "./Utilities/Action";
import Vector2 from "./Utilities/Vector2";

export default class QINode
    {
        state: QPointState;
        confidenceLogic: InverseDistanceBasicConfidence;
        id: number;
        currentConfidence: number;
        children: Set<IQINode>;
        blueprint: QINodeblueprint;
        onSelected? = () => {};
        onDeselected? = () => {};
        onAnimate? = () => {};
        onPreview? = () => {};
        onShouldHideChildren: Action<IQINode> | null;
        onRootSelection: Action<IQINode> | null;
        onRootDeselection: Action<IQINode> | null;
        onShowChild? = (b: boolean) => {};
        onShowListDiv? = (b: boolean)  => {};
        isHidden: boolean;
        restPositionAbsolute: Vector2 | null;

        constructor(
        blueprint: QINodeblueprint, 
        confidenceLogic: InverseDistanceBasicConfidence,
        isHidden: boolean
        ) {
            this.state = QPointState.Deselected
            this.id = 0;
            this.currentConfidence = 0;
            this.children = new Set<IQINode>();
            this.isHidden = isHidden;
            this.blueprint = blueprint;
            this.confidenceLogic = confidenceLogic;
            this.onShouldHideChildren = null;
            this.onRootSelection = null;
            this.onRootDeselection = null;
            this.restPositionAbsolute = null;
        }
    }