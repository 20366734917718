import { useEffect, useState, MouseEvent } from 'react';

import './qiApps.scss';
import { VxAPI } from '../../api/vx.api';
import { AppObjectDTO } from '../../components/dto/appObject.dto';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { UseCasesObjectDTO } from '../../components/dto/useCasesObject.dto';
import { useUserData, useUserRoleData } from '../account/Account';
import Auth from '../../utils/auth';

const QiApps = () => {
  const loggedIn = Auth.loggedIn();

  if (!loggedIn) {
    window.location.assign('/');
  }

  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  const [roleName, setRoleName] = useState<string>('');
  const [qiEmployee, setQiEmployee] = useState<boolean | null>(null);

  if (qiEmployee === false && roleName !== 'Admin') {
    window.location.assign('/');
  }

  const [loading, setLoading] = useState(true);
  const [appObjectArrayData, setAppObjectArrayData] = useState<AppObjectDTO[]>([
    {
      customerId: 0,
      useCaseId: 0,
      appId: 0,
      title: '',
      type: '',
      description: '',
      notes: '',
    },
  ]);

  const [appsObjectArrayWithUseCaseNames, setAppsObjectArrayWithUseCaseNames] = useState<AppObjectDTO[]>();

  const [updatedAppsArray, setUpdatedAppsArray] = useState<AppObjectDTO[]>([
    {
      customerId: 0,
      useCaseId: '',
      appId: 0,
      title: '',
      type: '',
      description: '',
      notes: '',
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [useCasesObjectArrayData, setUseCasesObjectArrayData] = useState<UseCasesObjectDTO[]>([
    {
      useCaseId: 0,
      title: '',
      description: '',
      notes: '',
    },
  ]);

  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const appData = await VxAPI.getAllAppsData();
      if (appData) {
        setAppObjectArrayData(appData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      const useCaseData = await VxAPI.getAllUseCasesData();

      setUseCasesObjectArrayData(useCaseData);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if ((userRoleName && superUser === false) || superUser === true) {
      setRoleName(userRoleName ? userRoleName : '');
      setQiEmployee(superUser === false || superUser === true ? superUser : null);
    }

    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName, superUser]);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const appId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/apps/edit/${appId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (useCasesObjectArrayData[0].useCaseId !== 0) {
      const appsWithUseCaseName = appObjectArrayData.map((app) => {
        app.useCaseId = useCasesObjectArrayData.find((useCase) => useCase.useCaseId === app.useCaseId)?.title;
        return app;
      });
      setAppsObjectArrayWithUseCaseNames(appsWithUseCaseName);
      setUpdatedAppsArray(appsWithUseCaseName);
      setLoading(false);
    }
  }, [appObjectArrayData, useCasesObjectArrayData]);

  useEffect(() => {
    if (appsObjectArrayWithUseCaseNames) {
      if (searchText === '') {
        setUpdatedAppsArray(appsObjectArrayWithUseCaseNames);
      }

      const updatedApps = appsObjectArrayWithUseCaseNames?.filter(
        (app) =>
          app.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          app.type?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          app.useCaseId?.toString().toLowerCase()?.includes(searchText.toLowerCase()),
      );

      setUpdatedAppsArray(updatedApps);
    }
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="apps-div">
          <div className="row justify-space-btw">
            <h1>Apps</h1>
            <div id="app-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button id="add-app-btn" type="button" onClick={() => window.location.assign('/apps/add')}>
                <span>+Add App</span>
              </button>
            </div>
            <div id="apps-titles">
              <h2 id="app-name-header">App Name</h2>
              <div id="secondary-app-headers" className="row">
                <h2 id="app-cust-header">Customer</h2>
                <h2 id="app-useCase-header">Use Case</h2>
                <h2 id="app-type-header">Type</h2>
                <h2 id="app-desc-header">Description</h2>
                <h2 id="app-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedAppsArray
            ? updatedAppsArray.slice(0, itemsToShow).map((app: AppObjectDTO, index: number) => (
                <>
                  <div id="app-info-div" key={index}>
                    <div id="app-name">
                      <p>{app.title}</p>
                    </div>
                    <div id="secondary-app-data">
                      <p id="app-cust">
                        {customersObjectArrayData.find((customer) => customer.customerId === app.customerId)?.firstName}{' '}
                        {customersObjectArrayData.find((customer) => customer.customerId === app.customerId)?.lastName}
                      </p>
                      <p id="app-useCase">
                        {app.useCaseId}
                      </p>
                      <p id="app-type">{app.type}</p>
                      <p id="app-desc">{app.description}</p>
                      <div id="app-more">
                        <button
                          type="button"
                          id={`edit-app-btn-${app.appId}`}
                          className="edit-btn"
                          onClick={handleEditBtnClick}
                        >
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="app-line"></div>
                </>
              ))
            : null}
          <>
            {!expanded && updatedAppsArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedAppsArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default QiApps;
