import { RequiredAsteriskProps } from "../dto/requiredAsterisk.props";

const RequiredAsterisk: React.FC<RequiredAsteriskProps> = (props) => {
    let asteriskStyle: any = {
        color: `${props.color}`
    };
    if(props.screensizeWidth < 576) {
        asteriskStyle.fontSize = `1.25rem`
    } else if (props.screensizeWidth <  992) {
        asteriskStyle.fontSize = `1.5rem`
    } else {
        asteriskStyle.fontSize = `1.75rem`
    }
return (
    <span style={asteriskStyle}>*</span>
)
}

export default RequiredAsterisk;