import { useState, useEffect, MouseEvent } from 'react';
import { VxAPI } from '../../api/vx.api';
import { CustomerObjectDTO } from '../../components/dto/customerObject.dto';
import { TagObjectDTO } from '../../components/dto/tagObject.dto';
import { useUserRoleData, useUserData } from '../account/Account';
import Auth from '../../utils/auth';
import './tagsTable.scss';

const TagsTable = () => {
  const { userRoleData } = useUserRoleData();
  const userRoleName = userRoleData?.title;
  const { userData } = useUserData();
  const superUser = userData?.isSuperuser;

  const [loading, setLoading] = useState(true);
  const [tagObjectArrayData, setTagObjectArrayData] = useState<TagObjectDTO[]>([
    {
      customerId: 0,
      tagId: 0,
      title: '',
      type: '',
      sub1: '',
      sub2: '',
      description: '',
      createdDate: new Date(),
    },
  ]);

  const [updatedTagsArray, setUpdatedTagsArray] = useState<TagObjectDTO[]>([
    {
      customerId: 0,
      tagId: 0,
      title: '',
      type: '',
      sub1: '',
      sub2: '',
      description: '',
      createdDate: new Date(),
    },
  ]);

  const [customersObjectArrayData, setCustomersObjectArrayData] = useState<CustomerObjectDTO[]>([
    {
      customerId: 0,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      location: '',
    },
  ]);

  const [searchText, setSearchText] = useState<string>('');
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const tagData = await VxAPI.getAllTagsData();
      if (tagData) {
        setTagObjectArrayData(tagData);
        setUpdatedTagsArray(tagData);
      }

      const customerData = await VxAPI.getAllCustomersData();

      setCustomersObjectArrayData(customerData);

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (userRoleName && userRoleName !== '') {
      if (userRoleName !== 'Admin') {
        window.location.assign('/');
      }
    }
    const loggedInUser = Auth.getProfile('id_token');

    if (loggedInUser) {
      if (userData?.userId !== loggedInUser?.userId) {
        window.location.assign('/login');
      }
    }
  }, [userRoleName]);

  const handleEditBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const tagId = e.currentTarget.id.split('-').pop();

    window.location.assign(`/tags/edit/${tagId}`);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShowMoreBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setExpanded(true);
    await setItemsToShow(itemsToShow + 10);
  };

  const handleShowLessBtn = async (e: MouseEvent<HTMLButtonElement>) => {
    await setItemsToShow(itemsToShow - 10);
    if (itemsToShow === 20) {
      await setExpanded(false);
    }
  };

  useEffect(() => {
    if (tagObjectArrayData) {
      if (searchText === '') {
        setUpdatedTagsArray(tagObjectArrayData);
      }

      const updatedTags = tagObjectArrayData?.filter(
        (tag) =>
          tag.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          tag.type?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          tag.sub1?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          tag.sub2?.toLowerCase()?.includes(searchText.toLowerCase()),
      );

      setUpdatedTagsArray(updatedTags);
    }
  }, [searchText]);

  return (
    <>
      {loading ? (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      ) : (
        <div id="tags-div">
          <div className="row justify-space-btw">
            <h1>Tags Table</h1>
            <div id="tag-action">
              <input
                id="search-text"
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
              ></input>
              <button id="add-tag-btn" type="button" onClick={() => window.location.assign('/tags/add')}>
                <span>+Add Tag</span>
              </button>
            </div>
            <div id="tags-titles">
              <h2 id="tag-name-header">Tag Name</h2>
              <div id="secondary-tag-headers" className="row">
                <h2 id="tag-cust-header">Customer</h2>
                <h2 id="tag-type-header">Tier 1 Name</h2>
                <h2 id="tag-sub1-header">Tier 2 Name</h2>
                <h2 id="tag-sub2-header">Tier 3 Name</h2>
                <h2 id="tag-desc-header">Description</h2>
                <h2 id="tag-more-header">More</h2>
              </div>
            </div>
          </div>
          {updatedTagsArray
            ? updatedTagsArray
            .sort((a: any, b: any) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            })
            .slice(0, itemsToShow)
            .map((tag: TagObjectDTO, index: number) => (
                <>
                  <div id="tag-info-div" key={index}>
                    <div id="tag-name">
                      <p>{tag.title}</p>
                    </div>
                    <div id="secondary-tag-data">
                      <p id="tag-cust">
                        {customersObjectArrayData.find((customer) => customer.customerId === tag.customerId)?.firstName}{' '}
                        {customersObjectArrayData.find((customer) => customer.customerId === tag.customerId)?.lastName}
                      </p>
                      <p id="tag-type">{tag.type}</p>
                      <p id="tag-sub1">{tag.sub1}</p>
                      <p id="tag-sub2">{tag.sub2}</p>
                      <p id="tag-desc">{tag.description}</p>
                      <div id="tag-more">
                        <button
                          type="button"
                          id={`edit-tag-btn-${tag.tagId}`}
                          className="edit-btn"
                          onClick={handleEditBtnClick}
                        >
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tag-line"></div>
                </>
              ))
            : null}
          <>
            {!expanded && updatedTagsArray.length <= 10 ? null : !expanded ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
              </div>
            ) : updatedTagsArray.length > itemsToShow ? (
              <div id="show-more-div">
                <button id="show-more-btn" onClick={handleShowMoreBtn}>
                  <span>Show More </span>
                </button>
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            ) : (
              <div id="show-more-div">
                <button id="show-less-btn" onClick={handleShowLessBtn}>
                  <span>Show Less </span>
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default TagsTable;
