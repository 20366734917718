import { useEffect, useState } from "react";

import EditQiAppForm from "../../components/editQiAppForm/EditQiAppForm";
import { useUserData, useUserRoleData } from "../account/Account";

const EditQiApp = () => {

    const { userRoleData } = useUserRoleData();
    const userRoleName = userRoleData?.title;
    const { userData } = useUserData();
    const superUser = userData?.isSuperuser;
    const userCustomerId = userData?.customerId

    const [roleName, setRoleName] = useState<string>('')
    const [qiEmployee, setQiEmployee] = useState<boolean | null>(null)

    if (qiEmployee === false && roleName !== 'Admin') {
        window.location.assign('/')
    }

    useEffect(() => {
        if (userRoleName && superUser === false || superUser === true) {
            setRoleName(userRoleName ? userRoleName : '')
            setQiEmployee(superUser === false || superUser === true ? superUser : null)
        }
    }, [userRoleName, superUser])

    return (
        <>
            <EditQiAppForm
                qiEmployee={qiEmployee}
                userCustomerId={userCustomerId}
            />
        </>
    )
}

export default EditQiApp;